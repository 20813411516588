import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';

const { pmp: priorityfetch } = pricingMethods;
const { utils } = pricingUtilities;

import {
  mkemptyvalidatedprice,
  resetaverage,
  finishaverage,
  computeaverage,
  createMissingAttr,
  resetSets,
  setOneTimeAndPriceSet,
} from './partutils';

const { findactualdate } = utils;

export const store = {
  parts: [],
  externalmasters: [],
  altparts: [],
};

function setindexes(part, tag, definition, actualdate, pmpset) {
  if (part.fullcostingratio) {
    part.fullcostingratio.DEFAULT[Symbol.for('actualdateindex')] = findactualdate(part.fullcostingratio.DEFAULT, actualdate);
  }
  if (part.pricingmethod) {
    part.pricingmethod.DEFAULT[Symbol.for('actualdateindex')] = findactualdate(part.pricingmethod.DEFAULT, actualdate);
  }
  if (part.price && part.price[tag]) {
    part.price[tag][Symbol.for('actualdateindex')] = findactualdate(part.price[tag], actualdate);
  }
  if (part[definition.common.pmpattr]) {
    // find the actual pmp set
    const { set, idx } = pmpset(part, definition.common.pmpattr, definition, actualdate, findactualdate);
    part[definition.common.pmpattr][Symbol.for('actualset')] = set;
    if (idx !== -1) part[definition.common.pmpattr][set][Symbol.for('actualdateindex')] = idx;
  }
}
export const setMasterParts = (parts, settings, state) => {
  const actualdate = state.currenttime;
  const sidx = Symbol.for('partindex');
  store.externalmasters = parts;
  const { definition } = state.rule;
  const { tag } = state.rule;
  const { pmpset } = state.lib;
  parts.forEach((part, pidx) => {
    part[sidx] = pidx;
    // default values
    createMissingAttr(part, state.rule.definition.attributes, state.defs.defs2);
    // let's add the set (we do not have it as for now)
    Object.entries(part).forEach(([name, value]) => {
      value.set = state.defs.defs2[name].set;
    });
    // find actual index for price
    setindexes(part, tag, definition, actualdate, pmpset);
    if (part.validatedprice === undefined) {
      part.validatedprice = mkemptyvalidatedprice(state, part, settings.one);
    }
  });
};

export const setupParts = (parts, settings, state, target) => {
  const actualdate = state.currenttime;
  const sidx = Symbol.for('partindex');
  store[target] = parts;
  parts.forEach((part, pidx) => {
    part[sidx] = pidx;
    // default values
    createMissingAttr(part, state.rule.definition.attributes, state.defs.defs2);
    if (settings.classification && part.classification && part.classification.v) {
      part.origclassification = {
        v: part.classification.v,
        name: 'part.classification.v',
        type: 'string',
      };
    }
    // let's add the set (we do not have it as for now)
    Object.entries(part).forEach(([name, value]) => {
      value.set = state.defs.defs2[name].set;
    });
    // find actual index for price
    setindexes(part, state.rule.tag, state.rule.definition, actualdate, state.lib.pmpset);
    if (part.validatedprice === undefined) {
      part.validatedprice = mkemptyvalidatedprice(state, part, settings.one);
    }
  });
};

export const setAltFamilyParts = (parts, rule, currentime, pmpset) => {
  store.altparts = parts;
  parts.forEach((part) => {
    setindexes(part, rule.tag, rule.definition, currentime, pmpset);
  });
};

export const setFamilyParts = (parts, settings, state) => {
  resetSets(state);
  let actualturnover = 0;
  const references = state.rule.definition.pivot.map((a) => a.name);
  resetaverage(references, state.averagereferences, '@@@@@');
  parts.forEach((part) => {
    if (part.price && part.price[state.rule.tag]
      && part.price[state.rule.tag].length
      && part.price[state.rule.tag][Symbol.for('actualdateindex')] >= 0) {
      const activeprice = part.price[state.rule.tag][part.price[state.rule.tag][Symbol.for('actualdateindex')]].v.number;

      if (part[state.rule.definition.common.salesattr] && part[state.rule.definition.common.salesattr].DEFAULT.length) {
        const qty = part[state.rule.definition.common.salesattr].DEFAULT[0].v.number;
        actualturnover += qty * activeprice;
      }
      if (state.averagereferences.price !== undefined) {
        state.averagereferences.price.count += 1;
        state.averagereferences.price.value += activeprice;
      }
    }
    state.rule.func(part);
    setOneTimeAndPriceSet(part, state.rule.tag);
    computeaverage(references, state.averagereferences, part);
  });
  finishaverage(references, state.averagereferences, '@@@@');
  state.actualturnover = actualturnover;
};

export const getStore = () => store;

export default {
  store,
  setFamilyParts,
  setAltFamilyParts,
  getStore,
  setupParts,
};
