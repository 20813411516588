<template>
<div class="drawer-container" >
  <div class="shadow-2">
    <q-toolbar class="bg-primary text-white">
      <q-btn flat round dense icon="arrow_back" @click="goback"/>
      <q-toolbar-title>
        Add an attribute to the rule
        <span slot="subtitle">Please choose the weight type</span>
      </q-toolbar-title>
    </q-toolbar>
    <q-item v-if="attr.label" class="bg-primary text-white attr-tile">
      <q-item-section avatar><q-icon name="style"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{getlabel(attr.label)}}</q-item-label>
        <q-item-label caption>{{attr.description}}</q-item-label>
      </q-item-section>
      <q-item-section side right>
        <q-chip color="secondary">{{attr.type}}</q-chip>
      </q-item-section>
    </q-item>
  </div>
  <div v-if="attr.label">

    <q-list no-border>
      <q-item-label header>Please choose the apropriate weight type :</q-item-label>
      <q-item tag="label"
        v-for="(weight, index) in $store.state.pricing.defs.defs2[this.$route.params.name].weight"
          :key="index">
        <q-item-section side>
          <q-radio @blur="$v.weighttype.$touch" v-model="weighttype" :val="weight.type"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{weightInfo(weight.type).label}}</q-item-label>
          <q-item-label caption>{{weightInfo(weight.type).description}}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <q-list no-border>
      <q-item-label header>Also decide whether this attribute should be part of the virtual pivot :</q-item-label>
      <q-item>
        <q-item-section side><q-checkbox v-model="pivot" :val="true" /></q-item-section>
        <q-item-section><q-item-label>Yes, it should</q-item-label></q-item-section>
      </q-item>
    </q-list>
  </div>
  <q-banner class="bg-warning text-white">
    <template v-slot:avatar>
      <q-icon name="mood" color="white" />
    </template>
    You sould check here only for dimensions unless you know what your are doing.
  </q-banner>

  <q-btn color="primary" label="Add" class="fixed ruleparams-fab" @click="addit"/>
</div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      pivot: this.setpivot(),
      weighttype: '',
    };
  },
  computed: {
    attr() {
      if (this.$store.state.pricing && this.$store.state.pricing.defs && this.$store.state.pricing.defs.defs2) {
        return this.$store.state.pricing.defs.defs2[this.$route.params.name];
      }
      return {};
    },
  },
  validations: {
    weighttype: { required },
  },
  watch: {
    attr(o, v) {
      if (o && o.root && this.$store.state.pricing.sets.sets[o.root] && this.$store.state.pricing.sets.sets[o.root].name === 'dimensions') {
        this.pivot = [true];
      }
    },
  },
  methods: {
    setpivot() {
      if (this.$store.state.pricing && this.$store.state.pricing.defs && this.$store.state.pricing.defs.defs2
        && this.$store.state.pricing.sets && this.$store.state.pricing.sets.sets) {
        const o = this.$store.state.pricing.defs.defs2[this.$route.params.name];
        if (o.root && this.$store.state.pricing.sets.sets[o.root]
            && this.$store.state.pricing.sets.sets[o.root].name === 'dimensions') return [true];
      }
      return [];
    },
    getlabel(label) {
      return typeof label === 'object' ? label[this.$store.state.auth.locale] : label;
    },
    weightInfo(name) {
      switch (name) {
        case 'rate':
          return { label: 'Indice', description: 'Attribute is going to influence the price based on a coefficient.' };
        default:
      }
      return { label: 'Amount', description: 'Attribute is going to add a markup to the price.' };
    },
    goback() {
      this.$router.go(-1);
    },
    addit() {
      this.$v.weighttype.$touch();
      if (this.$v.weighttype.$error) {
        return this.$q.notify({ message: 'Please select a weight type', icon: 'warning' });
      }

      this.$store.commit('pricing/addRuleAttr', {
        name: this.attr.name,
        weighttype: this.weighttype,
        pivot: this.pivot[0] || false,
        currency: 'CHF',
        locale: this.$store.state.auth.locale,
        settings: this.$store.state.general.settings,
      });
      return this.$router.push({ name: 'ruleparams' });
    },
  },
};
</script>
<style lang="stylus">
aside.q-layout-drawer {
  background: #f0f0f0;
}
.attr-tile {
  background white
}
</style>
