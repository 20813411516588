<template>
  <div class="drawer-container" style ="width:320px;">
  <modale v-if="showEdit" :show="showEdit" @closemodale="closeModale" :smoothID="currentsmoothID"></modale>

  <q-list v-if="nrstore().parts.length" style ="width: 100%;">

    <q-expansion-item  expand-separator :default-opened = "true" style="border-bottom: 1px solid grey;"
    v-for="(smooth, smoothID) in $store.state.pricing.rule.definition.smoothing" :key="smooth.attribute">

      <template v-slot:header>
        <q-item-section avatar>
          {{ localized($store.state.pricing.defs.defs2[smooth.attribute].label) | uppercase }}
        </q-item-section>
        <q-item-section></q-item-section>
      </template>

      <highcharts
        v-if="debug"
        :options="debugChart(smoothID)"
        style="width: 350px; height: 200px; margin-left: -32px;"
        >
      </highcharts>

      <div style="width:300px; margin: auto; height:40px; margin-top: 0px; margin-bottom: 5px;">
        <q-btn style="width:50px; float: left;" @click="openModale(smoothID)" icon="edit"/>
        <q-btn style="width:50px; float: right;" @click="deleteRefine(smoothID)" icon="delete"/>
      </div>
    </q-expansion-item>
  </q-list>

  <q-btn round color="primary" icon="add"
    class="fixed ruleparams-fab" @click="$router.push({name: 'selectattr'})"/>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import { localization } from '../../lib/mixins';
import modale from './RuleRefineEdit';
import { store } from '../../lib/nrstore';

stockInit(Highcharts);

export default {
  components: {
    modale,
    highcharts: Chart,
  },
  mixins: [localization],
  data() {
    return {
      svgSetting: {
        xmin: 0,
        xmax: 100,
        ymin: 0,
        ymax: 2,
        w: 800,
        h: 400,
        grid: {
          show: true,
          snap: true,
          size: 25,
        },
      },
      debug: true,
      slices: 100,
      showEdit: false,
      currentsmoothID: -1,
    };
  },
  filters: {
    uppercase(value) {
      if (!value) return '';
      return value.toUpperCase();
    },
  },
  methods: {
    nrstore() {
      return store;
    },
    debugChart(smoothID) {
      const smooth = this.$store.state.pricing.rule.definition.smoothing[smoothID];
      // this.$store.commit('pricing/smoothingRate', { id: this.smoothID, nv: this.$refs.svgDisplay.save() });

      if (!smooth.func) {
        return {};
      }

      const nb = 100;
      const drawMe = [];
      for (let i = 0; i < nb; i += 1) {
        drawMe.push({});
        drawMe[i][smooth.attribute] = {
          v: {
            number: smooth.min + ((smooth.max - smooth.min) / nb) * i,
          },
        };
      }

      drawMe[0][smooth.attribute] = {
        v: {
          number: 0,
        },
      };
      drawMe[1][smooth.attribute] = {
        v: {
          number: smooth.min,
        },
      };
      drawMe[drawMe.length - 2][smooth.attribute] = {
        v: {
          number: smooth.max,
        },
      };
      drawMe[drawMe.length - 1][smooth.attribute] = {
        v: {
          number: smooth.max + 100,
        },
      };

      const chartdatatmp = {
        chart: {
          zoomType: 'x',
          inverted: false,
          reflow: true,
          width: 350,
          height: 250,
        },
        title: {
          text: '',
        },
        subTitle: {
          text: '',
        },
        xAxis: {
          // min: 0, // smooth.min,
          // max: smooth.max + 100,
          title: null,
          categories: drawMe.map((p) => Math.round(p[smooth.attribute].v.number * 10) / 10),
        },
        yAxis: {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: 'Coef',
          },
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        series: [
          {
            type: 'line',
            name: 'Coef',
            color: '#90E310',
            data: drawMe.map((p) => Math.round(smooth.func(smooth, p[smooth.attribute].v.number) * 100) / 100),
          },
        ],
      };

      return chartdatatmp;
    },
    getSetting(smoothID) {
      const tmp = JSON.parse(JSON.stringify(this.svgSetting));
      tmp.xmin = this.$store.state.pricing.rule.definition.smoothing[smoothID].min;
      tmp.xmax = this.$store.state.pricing.rule.definition.smoothing[smoothID].max;
      return tmp;
    },
    openModale(smoothID) {
      this.currentsmoothID = smoothID;
      this.showEdit = true;
    },
    closeModale() {
      this.showEdit = false;
      this.$forceUpdate();
    },
    deleteRefine(i) {
      this.$store.state.pricing.rule.definition.smoothing.splice(i, 1);
    },
    /** *************************************************************************** */
    /** *************************************************************************** */
    /** *************************************************************************** */
    upTo(aid, fid, bid, upTo) {
      this.cachedViewbox = undefined;
      this.$store.commit('pricing/changeUpTo', {
        aid,
        fid,
        bid,
        upTo,
      });
    },
    newBlock(params) {
      this.cachedViewbox = undefined;
      this.$router.push({ name: 'selectblock', params });
    },
    subtitle(index) {
      switch (index + 1) {
        case 1:
          return '1st function';
        case 2:
          return '2nd function';
        case 3:
          return '3rd function';
        default:
      }
      return `${index + 1}th function`;
    },
    viewbox(indexa, index, indexb) {
      function serialize(v) {
        return `${v.xmin} ${v.ymin} ${v.xmax - v.xmin} ${v.ymax - v.ymin}`;
      }
      const attr = this.$store.state.pricing.rule.definition.smoothing[indexa].attribute;
      if (this.cachedViewbox
        && this.cachedViewbox[attr]
        && this.cachedViewbox[attr].functions[index]) {
        return serialize(this.cachedViewbox[attr].functions[index].blocks[indexb].viewbox);
      }
      // need to compute everything for that function
      if (!this.cachedViewbox) this.cachedViewbox = {};
      if (!this.cachedViewbox[attr]) {
        this.cachedViewbox[attr] = {
          xrange: this.boundaries(attr),
          functions: [],
        };
      }
      if (!this.cachedViewbox[attr].functions[index]) {
        const foo = { viewbox: { ymin: Infinity, ymax: 150 }, blocks: [], useless: 1 };
        this.cachedViewbox[attr].functions[index] = foo;
        this.$store.state.pricing.rule.definition.smoothing[indexa].functions[index].forEach((block, i) => {
          this.$store.commit('pricing/setBlockRange', {
            aid: indexa,
            fid: index,
            bid: i,
            upTo: this.cachedViewbox[attr].xrange.max,
            from: i
              ? this.$store.state.pricing.rule.definition.smoothing[indexa].functions[index][i - 1].upTo
              : this.cachedViewbox[attr].xrange.min,
          });
          const viewbox = { xmax: block.upTo, xmin: block.from };
          const step = (block.upTo - block.from) / this.slices;
          const path = [];

          for (let x = 0; x < (block.upTo - block.from); x += step) {
            const y = block.func(x);
            if (y > foo.viewbox.ymax) foo.viewbox.ymax = y;
            if (y < foo.viewbox.ymin) foo.viewbox.ymin = y;
            path.push({ x: x + block.from, y });
          }

          this.cachedViewbox[attr].functions[index].blocks[i] = {
            path,
            viewbox,
          };
        });
        // recompute y viewport
        foo.blocks.forEach((b) => Object.assign(b.viewbox, foo.viewbox));
      }
      return serialize(this.cachedViewbox[attr].functions[index].blocks[indexb].viewbox);
    },
    path(attr, index, indexb) {
      return this.cachedViewbox[attr]
        .functions[index]
        .blocks[indexb]
        .path.reduce((path, point, i) => path.concat(`${i ? ' L' : 'M'} ${point.x} ${point.y}`), '');
    },
    boundaries(name) {
      return store.parts.reduce((boundaries, part) => {
        let value;
        switch (part[name].type) {
          case 'number':
            value = part[name].v.number;
            break;
          default:
        }
        if (value === undefined) return boundaries;
        if (value > boundaries.max) boundaries.max = value;
        if (value < boundaries.min) boundaries.min = value;
        return boundaries;
      }, { min: Infinity, max: -Infinity });
    },
  },
};
</script>

<style lang="stylus" scoped>
.function-row {
  padding-bottom 1rem
  overflow: auto;
  .thumbnails, .boundaries {
    display flex
    flex-wrap nowrap
  }
  .thumbnails {

  }
  .boundaries {
    .boundary {
      margin-left 110px
      width: 100px;
    }
  }
}
.function-block {
  width: 160px;
  min-height: 207px;
  min-width: 160px;
  background: white;
  margin-right: 10px;
  position relative
  .q-card-container {
    padding: 4px;
  }
  svg {
        width: 100%
        height: 150px
        transform-origin 50% 50%
        transform scale(1,-1)
  }
  &:not(:last-child)::after {
    content: ''
    position absolute
    right: -5px
    top 0
    width 0
    border-right 1px dashed red
    bottom: -10px
  }
  &.empty-block {
    display: flex; align-items: center;
  }
}
</style>
