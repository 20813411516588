<template>
    <div v-if="data && data.v" :class="['pricing', (wrap === 'yes')?'wrapped':'unwrapped']">
      <span>{{`${data.v.fixed} ${data.v.unit}`}}</span>
      <span class="op" v-if="wrap === 'yes' && !contentstyle.includes('minimal') && data.v.qty">{{`(for ${data.v.qty.fixed} ${data.v.qty.unit})`}}</span>
      <span class="op" v-else-if="!contentstyle.includes('minimal') && data.v.qty">{{`/${data.v.qty.fixed} ${data.v.qty.unit}`}}</span>
      <span class="daterange" v-else-if="contentstyle.includes('double')">{{formatt(data.t)}}</span>
    </div>
</template>
<script>

export default {
  props: {
    data: Object,
    def: Object,
    wrap: String,
    contentstyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    formatt(range) {
      return range.replace(/[[()\]]/g, '').split(',').map((b) => (b || '∞')).join(' → ');
    },
  },
};
</script>

<style scoped lang="stylus">

.pricing {
  flex: 1
  max-width 100%
  text-align right
  &.wrapped {
    span {
      display: block
   }
  }
  &.unwrapped {
    white-space nowrap
  }
}
.currency-cell {
  text-align: right;
}
.daterange {
  font-size: .7rem;
  text-align center
}
.op {
  opacity: 0.7;
}
</style>
