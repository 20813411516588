<template>
  <!-- TODO implement vuelidate -->
  <div class="content-step2">
    <Template />

    <q-select v-if="$store.state.importExport.step1.file.uploaded.type === 'text/csv'"
      label="Encoding"
      v-model="form.encoding.value"
      :options="form.encoding.data"
      placeholder="Encoding"
      @input="changeValue"
    />

    <q-select v-if="$store.state.importExport.step1.file.uploaded.type === 'text/csv'"
    label="Separator"
      v-model="form.separator.value"
      placeholder="Separator"
      :options="form.separator.data"
      @input="changeValue"
    />

    <q-input
      v-model="form.headingLines.value"
      type="number"
      label="heading Lines"
      @input="checkTemplate"
    />

    <q-checkbox
      v-model="form.checkbox.value"
      val="noupdate"
      label="No update"
      @input="checkTemplate"
    />

    <q-checkbox
      v-model="form.checkbox.value"
      val="noinsert"
      label="No Insert"
      @input="checkTemplate"
    />
  </div>
</template>

<script>
import Template from './template/template';

export default {
  components: { Template },
  data() {
    return {};
  },
  computed: {
    template: {
      get() {
        return this.$store.state.importExport.template;
      },
      set(value) {
        this.$store.commit('importExport/setTemplate', value);
      },
    },
    form: {
      get() {
        return this.$store.state.importExport.step2.form;
      },
      set(value) {
        this.$store.commit('importExport/setForm', value);
      },
    },
  },
  methods: {
    changeValue() {
      this.checkTemplate();
      this.$store.dispatch(
        'importExport/parseFile',
        this.$store.state.importExport.step1.file.uploaded,
      ).catch((err) => {
        this.$q.notify({
          message: 'Import uploaded file error',
          icon: 'warning',
        });
      });
    },
    checkTemplate(v) {
      this.$store.commit('importExport/checkTemplate');
    },
    changeTemplate() {
      this.$store.dispatch('importExport/changeTemplate', this.template);
    },
  },
  mounted() {},
};
</script>

<style lang="stylus" scoped>

.sub-content {
  padding: 24px;
}

.content-step2 {
  width: 100%;
}
</style>
