<template>
  <div class="treeSelectItem" :class="currentDeep === 0 ? 'first' : ''" >
    <div v-for="(item,i) in childOf(parent)" :key="i+Math.random()">
      <div class="last" :class="{selected: item.select, disabled:item.secured}" :id="item.id">

        <q-icon v-if="disabled" class="q-mr-sm" name="disabled_visible" size="sm"></q-icon>
        <span class="material-icons icon" v-else-if="item.secured" style="padding-left:10px; margin-right:-20px;min-height: 35px;" >locked</span>

        <template v-if="!item.leaf">
          <q-btn flat dense @click="collapse(item)" icon="arrow_right" class="icon" v-if="item.collapsed"/>
          <q-btn flat dense @click="collapse(item)" icon="arrow_drop_down" class="icon" v-else/>
        </template>

        <span class="material-icons icon" @click="setConfirm(item.id)" v-if="!item.select" style="padding-left:10px; padding-right:10px;min-height: 35px;" >radio_button_unchecked</span>
        <span class="material-icons icon" @click="setConfirm(null)" v-else style="padding-left:10px; padding-right:10px;min-height: 35px;" >radio_button_checked</span>

        <p class="label">{{ item.label }}</p>
        <q-btn v-if="showConfirm === item.id" @click="select(item)" :label="$t('Confirm ?')" flat color="primary"  style="min-width: 120px;"/>
      </div>

      <tree-select-item
        v-if="currentDeep < maxDeep && !item.collapsed && !item.leaf && childOf(item.id).length > 0"
        :disabled="disabled"
        :parent="item.id"
        :maxDeep="maxDeep"
        :currentDeep="currentDeep + 1"
        :loadChild="loadChild"
        :data="data"
        @input="$emit('input', $event)"
        :showConfirm="showConfirm"
        @setConfirm="$emit('setConfirm', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeSelectItem',
  data() {
    return {
      // showConfirm: null,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    parent: {
      type: String,
      required: false,
    },
    maxDeep: {
      type: Number,
      required: false,
    },
    currentDeep: {
      type: Number,
      required: false,
    },
    loadChild: {
      type: Function,
      required: false,
    },
    showConfirm: {
      type: String,
      required: false,
    },
  },
  emits: ['input', 'setConfirm'],
  methods: {
    setConfirm(value) {
      console.log('setConfirm', value);
      this.$emit('setConfirm', value);
    },
    childOf(id) {
      return this.data.filter((child) => child.parent === id);
    },
    collapse(item) {
      if (this.disabled) return;
      if (item.leaf) return;
      if (item.secured) return;
      this.loadChild(item.id);
      item.collapsed = !item.collapsed;
    },
    select(item) {
      if (this.disabled) return;
      if (item.secured) return;
      this.$emit('input', item);
      this.$emit('setConfirm', null);
      // this.showConfirm = null;
    },
  },
};
</script>

<style scoped>
.first {
  margin-left: 0px !important;
}

.treeSelectItem {
  min-height: 30px;
  margin-left: 25px;
  user-select: none;
}

.last {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  margin-top: 5px;

  border-radius: 3px;

}
.icon {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: #b3b3b3;
  }
}
.label {
  margin: 0px;
  padding: 0px;
  min-height: 35px;
  line-height: 35px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding-left:10px;
  border-radius: 2px;
  &:hover {
    background-color: #b3b3b3;
  }
}
.collapse {
  cursor: pointer;
}

.selected {
  background-color: #b3b3b3;
}

.disabled {
  cursor: not-allowed;
  background-color: #f0f0f0;
}
</style>
