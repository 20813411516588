<template>
  <div>
    <q-toolbar class="bg-grey-4">
      <q-tabs v-model="tab" dense shrink stretch>
        <q-tab name="std" icon="filter_list" />
        <q-tab name="t" icon="date_range" :disable="!serie" />
      </q-tabs>
      <q-space />
      <q-btn v-close-popup dense flat icon="close"/>
    </q-toolbar>
    <q-separator />
    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="std">
        <slot :proxy="proxy"></slot>
      </q-tab-panel>
      <q-tab-panel name="t">
        <q-select :map-options="true" :emit-value="true" class="lg-filter-input" :label="$t('Date range constraint')" dense filled square v-model="datefilter.action" :options="toptions"  />
        <q-input mask="##/##/####" fill-mask class="lg-filter-input" dense square filled v-model="datefilter.value" v-if="datefilter.action !== 'noop'">
          <template v-slot:append>
            <q-btn-group flat>
              <q-btn color="primary" @click="setDate(-1)" dense>1m</q-btn>
              <q-btn color="primary" @click="setDate(-6)" dense>6m</q-btn>
              <q-btn color="primary" @click="setDate(-12)" dense>1y</q-btn></q-btn-group>
          </template>
        </q-input>
      </q-tab-panel>
    </q-tab-panels>
    <div class="no-empty">
      <label><q-checkbox class="lg-filter-input" v-model="noempty" dense filled square/> {{$t('exclude all empty values')}}</label>
      <label><q-checkbox class="lg-filter-input" v-model="withempty" dense filled square/> {{$t('allow attribute to be empty')}} <q-btn size="xs" dense round color="warning">?
        <q-tooltip content-class="bg-amber text-black shadow-4" :offset="[10, 10]">
          {{$t('Empty means actually empty, not present.')}}
        </q-tooltip>
        </q-btn></label>
    </div>
    <div class="lg-filter-buttons">
      <q-btn dense v-close-popup @click="remove()" :label="$t('Reset')"/>
      <div class="spacer"> </div>
      <q-btn dense v-close-popup @click="remove(name)" :label="$t('Clear')"/>
      <q-btn dense v-close-popup @click="fetch()" color="secondary" :label="$t('Filter')" />
    </div>
  </div>
</template>
<script>
import { localization } from '../../../lib/mixins';

function swapdate(date, sep = '/', sep2 = '-') {
  const [y, m, d] = date.split(sep);
  return [d, m, y].join(sep2);
}

export default {
  name: 'filterBase',
  props: ['name', 'serie', 'settings'],
  mixins: [localization],
  data() {
    const me = this;
    return {
      tab: 'std',
      value: {},
      proxy: {
        update(v) {
          me.value = v;
        },
        init() {
          return me.value;
        },
      },
      datefilter: {
        action: '',
        value: '',
      },
      noempty: false,
      withempty: false,
      toptions: [
        { label: this.localized('No restriction'), value: '' },
        { label: this.localized('Older than'), value: 'older' },
        { label: this.localized('More recent than'), value: 'younger' },
      ],
    };
  },
  created() {
    const { value } = (this.$store.state.catalog.filters.find((f) => (f.name === this.name)) || { value: [] });
    // let's reshuffle everything
    value.forEach((filter) => {
      switch (filter.action) {
        case 'younger':
        case 'older':
          // refactor value
          filter.value = swapdate(filter.value, '-', '/');
          this.datefilter = filter;
          break;
        case 'not_empty':
          this.noempty = true;
          break;
        case 'tag_empty':
        case 'tag_equals':
          break;
        default:
          // we need to remove the OrNull
          if (filter.action.endsWith('OrNull')) {
            this.withempty = true;
            filter.action = filter.action.slice(0, -6);
          }
          this.value = filter;
          break;
      }
    });
  },
  methods: {
    setDate(months) {
      const now = new Date();
      now.setMonth(now.getMonth() + months);
      this.datefilter.value = [
        now.getDate().toString().padStart(2, 0),
        (now.getMonth() + 1).toString().padStart(2, 0),
        now.getFullYear(),
      ].join('/');
    },
    remove(name) {
      this.$emit('remove', name);
    },
    reformatvalue(v, empty) {
      if (!empty) return v;
      const nv = JSON.parse(JSON.stringify(v));
      nv.action += 'OrNull';
      return nv;
    },
    fetch() {
      // let's update the store
      // but first deal with tag
      let tagfilter = [];
      if (this.settings && this.settings.tags && this.settings.tags.length) {
        const tag = this.settings.defaulttag || this.settings.tags[0];
        if (tag === 'DEFAULT') {
          tagfilter = [{ action: 'tag_empty', value: [] }];
        } else {
          tagfilter = [{ action: 'tag_equals', value: [tag] }];
        }
      }
      this.$store.commit('catalog/setFilter', {
        name: this.name,
        value: [
          ...(this.value.action ? [this.reformatvalue(this.value, this.withempty)] : []),
          ...(this.noempty ? [{ action: 'not_empty', value: [] }] : []),
          ...((this.datefilter.action && this.datefilter.value) ? [{ action: this.datefilter.action, value: swapdate(this.datefilter.value) }] : []),
          ...tagfilter,
        ],
      });
      // and emit the event
      this.$emit('update');
    },
  },
};
</script>
<style lang="stylus" scoped>
.no-empty {
  border-top: 1px solid gray
  padding 1rem
  display flex
  flex-direction: column
}
.lg-filter-buttons {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #e0e0e0;
    .spacer {
      flex 1
    }
}
</style>
