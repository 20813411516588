/* eslint-disable */

export default {
  data: {},
  pages: {
    current: 1,
    min: 1,
    max: null
  },
  trim: {},
  when: new Date(),
  filters: [],
  filteropts: {
  },
  drawer: {
    model: false,
    content: {
      type: 'settings',
      data: {}
    },
    column: {
      options: []
    }
  }
};
