import i18next from 'i18next';
import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import VueI18Next from '@panter/vue-i18next'; // eslint-disable-line no-unused-vars

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    // ...other options
    ns: ['common'],
    defaultNS: 'common',
    // lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    saveMissing: true,
    saveMissingTo: 'all',
    nsSeparator: false,
    keySeparator: false,
    backend: {
      projectId: '97b5e1b2-2f18-4a8a-8b1f-aa33cd9784a6',
      apiKey: '58475218-95fc-47ec-82a2-f7ba517c3d68',
      referenceLng: 'en-US',
      allowedAddOrUpdateHosts: ['localhost'],
    },
  });

const i18n = { };

export default ({ app, Vue }) => {
  Vue.use(VueI18Next);
  app.i18n = new VueI18Next(i18next);
  i18n.instance = app.i18n;
};

export { i18n };
