import { Notify } from 'quasar';
import axios from 'axios';
import xlsx from 'xlsx'; // eslint-disable-line 
import XlsxPopulate from 'xlsx-populate';
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';
import {
  setFamilyParts,
  setAltFamilyParts,
  setupParts,
  store,
} from '../../lib/nrstore';

const { utils, pricinglib } = pricingUtilities;
const { pmp: priorityfetch, methods } = pricingMethods;
const {
  labeltrim, findactualdate, findpricedate, mkEmptyRule, getPartValue, setPartValue, isZero,
  serial,
} = utils;
const { findCurrentRule } = pricinglib;

function localized(value, l) {
  const defaultlocale = 'en-US';
  if (typeof value === 'string') return value;
  if (typeof value !== 'object') return 'BL!';
  const locale = l || defaultlocale;
  if (value[locale]) return value[locale];
  if (value[defaultlocale]) return value[defaultlocale];
  const fallback = Object.keys(value)[0];
  if (fallback) return value[fallback];
  return 'ML!';
}

function toISODate(d) {
  const yyyy = d.getFullYear();
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  return `${yyyy.toString().padStart(4, 0)}-${mm.toString().padStart(2, 0)}-${dd.toString().padStart(2, 0)}`;
}
export const save = ({ commit, state, rootState }) => {
  if (!state.rule.t) {
    return Notify.create({ type: 'negative', message: 'Please choose a validity date', icon: 'done' });
  }
  if (state.rule.active && !state.rule.activated_at) state.rule.activated_at = (new Date()).toISOString();
  if (!state.rule.active) state.rule.activated_at = null;

  state.rule.modified_by = rootState.auth.user.email;
  state.rule.updated_at = (new Date()).toISOString();
  commit('updateRuleName', labeltrim(state.rule.label));
  const rulecopy = JSON.parse(JSON.stringify(state.rule));
  if (rulecopy.definition && rulecopy.definition.smoothing) {
    rulecopy.definition.smoothing.forEach((s) => {
      s.cache = undefined;
    });
  }

  return axios({
    method: (!state.rule.empty && state.rule.id) ? 'PATCH' : 'POST',
    url: (!state.rule.empty && state.rule.id) ? `/rules/${state.rule.id}` : '/rules',
    data: { ...rulecopy, rules: null, t: state.rule.active ? toISODate(state.rule.t) : undefined },
  })
    .then((response) => {
      const rule = response.data.data;
      commit('saveRule', { rule, locale: rootState.auth.locale, settings: rootState.general.settings });
      Notify.create({ type: 'positive', message: 'Rule saved', icon: 'done' });
    })
    .catch((error) => {
      console.error('error', error);
      Notify.create({ type: 'negative', message: 'Unable to save this rule', icon: 'warning' });
    });
};
function mkZero(type) {
  if (type.endsWith('[]')) return [];
  if (type === 'breakdown') return {};
  switch (type) {
    case 'number':
    case 'amount':
    case 'pricing':
      return { number: 0, fixed: '0.00' };
    case 'string':
    case 'localizedstring':
    case 'collection':
      return '';
    case 'boolean':
      return false;
    default:
      return null;
  }
}

export const storecomputed = ({ commit, state, rootState }) => {
  // const { attrs } = state.lib.params;
  const attrs = ['fullcosting', 'fullcostingratio', 'lfrecommended', 'lowerpricerange_lowerbound',
    'lowerpricerange_upperbound', 'margin', 'minimalprice', 'mpr', 'partstatus', 'pricediff', 'pricediffpercent',
    'proposedprice', 'purchasealert', 'rawproposedprice', 'rawproposedpricesr', 'referenceprice', 'salesmarkup',
    'selectedpmp', 'sfrecommended', 'smallflowaddvalue', 'turnoverdiff', 'turnoverdiffpercent',
    'upperpricerange_lowerbound', 'upperpricerange_upperbound', 'vmargin']; // FIXME: should be computed
  serial(store.parts.map((part) => {
    const data = Object.entries(part).reduce((_, [a, v]) => {
      if (!attrs.includes(a)) return _;
      if (!v) _[a] = mkZero(state.defs.defs2[a].type);
      if (v.DEFAULT) {
        _[a] = v.DEFAULT[0].v;
      } else {
        _[a] = v;
      }
      return _;
    }, { validationdate: '(,)' });
    return () => axios({
      method: 'PATCH',
      url: `/products/${part.id.v}`,
      data,
    });
  })).then((response) => {
    Notify.create({ type: 'positive', message: 'Computed attributes saved', icon: 'done' });
  }).catch((error) => {
    console.error('error', error);
    Notify.create({ type: 'negative', message: 'Unable to save the computed attributes', icon: 'warning' });
  });
};
export const clone = ({ commit, state, rootState }) => {
  const rule = JSON.parse(JSON.stringify(state.rule));
  rule.id = undefined;
  rule.name = undefined;
  rule.active = false;
  rule.activated_at = null;
  rule.created_at = (new Date()).toISOString();
  rule.updated_at = rule.created_at;
  rule.by = rootState.auth.user.email;
  // rule.modified_by = rule.modified_by;
  rule.label = Object.entries(rule.label).reduce((_, [k, v]) => {
    _[k] = k.startsWith('fr') ? `Clone de ${v}` : `Clone of ${v}`;
    return _;
  }, {});

  const tmp = new Date(rule.t);
  commit('setRule2', { rule, locale: rootState.auth.locale, settings: rootState.general.settings });
  rule.t = tmp;
};

export const load = ({ commit, state, rootState }, { id, locale, familyid }) => {
  axios.get(id ? `/rules/${id}` : '/rules/empty').then((response) => {
    if (!response.data.data.family) {
      // enforce the currenty family
      response.data.data.family = familyid;
    }
    if (!response.data.data.tag) {
      response.data.data.tag = state.priceset;
    }
    commit('setRule2', { rule: response.data.data, locale, settings: rootState.general.settings });
    Notify.create({ type: 'positive', message: 'Rule loaded', icon: 'done' });
  })
    .catch((error) => {
      Notify.create({ type: 'negative', message: 'Unable to load this rule', icon: 'warning' });
    });
};
function setPartsParams(state, method, weight) {
  if (state.single && method && methods[method].kit) {
    return { full: 'yes', kit: state.singlePart.id.v, includes: [state.singlePart.id.v] };
  }
  if (state.single) {
    const result = {
      full: 'yes',
      '@limit': state.rule.similars.max,
      includes: [state.singlePart.name.v, ...(state.rule.similars.hasincludes ? state.rule.similars.includes : [])],
    };
    if (state.rule.similars.sellers) result.sellers = true;
    if (state.rule.similars.closests && state.singlePart[weight] && state.singlePart[weight].v) result.closests = state.singlePart[weight].v.number;
    if (state.rule.similars.identical) {
      const value = state.singlePart[state.rule.similars.identical] ? state.singlePart[state.rule.similars.identical].v : undefined;
      if (value) {
        result.closestop = `${state.rule.similars.identical}=${state.singlePart[state.rule.similars.identical].v}`;
      }
    }
    return result;
  }
  return { full: 'yes' };
}
const redecimal = /\.?0*$/;

export const setParts = ({ commit, state, rootState }) => {
  const method = getPartValue(state.singlePart, 'pricingmethod', state.priceset);
  return Promise.all([
    axios.get(`/families/${state.family}/parts`, {
      params: setPartsParams(state, method, rootState.general.settings.attrs.weight),
    }),
    (state.single && method && methods[method].kit)
      ? axios.get(`/products/${state.singlePart.id.v}/components`)
      : Promise.resolve(),
    ...(state.single && ['vp', 'cpt'].includes(state.rule.method) && state.rule.definition.pivottype === 'multi2'
      ? [axios.get(`/families/${state.family}/parts`, {
        params: { full: 'yes' },
      })]
      : []),
  ]).then(([response, response2, response3]) => {
    const parts = response.data.data;
    const { masters } = response.data.meta;
    const slaves = parts.reduce((_, p) => {
      const masterpartref = getPartValue(p, 'masterpart', state.rule.tag);
      const me = getPartValue(p, 'name');
      if (masterpartref && me !== masterpartref) _.push(me);
      return _;
    }, []);
    if (state.single) {
      parts.unshift(parts.splice(parts.findIndex((item) => item.name.v === state.singlePart.name.v), 1)[0]);
    }
    // commit('setParts', { data: parts, settings: rootState.general.settings });
    if (state.single && method && methods[method].kit) {
      const components = response2.data.data;
      const nonpriced = [];
      const subnoms = [];

      const status = parts.reduce((_, part) => {
        if (part.id.v === state.singlePart.id.v) return true;
        const record = components.find((c) => (c.component === part.id.v));
        record.qty = record.qty.replace(redecimal, '');
        if (record !== undefined) {
          // let's make sure there is a price
          const cmethod = getPartValue(part, 'pricingmethod', state.rule.tag);
          if (cmethod === 'kit') subnoms.push(part.name.v);
          if (part.price === undefined || part.price[state.rule.tag] === undefined) {
            nonpriced.push(part.name.v);
          } else {
            const price = getPartValue(part, 'price', state.rule.tag, state.currenttime);
            // const idx = findactualdate(part.price[state.rule.tag], findpricedate(state.rule.t, state.rule.t2));
            if (!price || isZero(price)) {
              nonpriced.push(part.name.v);
            }
          }
          setPartValue(part, 'itemqty', { number: parseFloat(record.qty), fixed: record.qty, unit: record.unit }, state.defs.defs2);
          const discount = record.discount !== undefined ? parseFloat(record.discount / 100) : 0;
          setPartValue(part, 'discount', { number: discount, fixed: discount.toFixed(2), unit: '%' }, state.defs.defs2);
        } else {
          // some component was not found
          console.error('a component is missing', part.name.v);
          _ = 'missing';
        }
        return _;
      }, 'ok');
      if (status === 'missing') return Promise.reject('missing');
      if (nonpriced.length) {
        commit('setNonPriced', Object.freeze(nonpriced));
        Notify.create({ type: 'negative', message: `Some components have no price, you will not be able to price/save : ${nonpriced.join(', ')}.`, icon: 'warning' });
      }
      if (subnoms.length) {
        commit('setSubNoms', Object.freeze(subnoms));
        Notify.create({ color: 'orange', message: `Some components are also kits : ${subnoms.join(', ')}.`, icon: 'warning' });
      }
    }
    if (slaves.length) {
      commit('setSlaves', Object.freeze(slaves));
      Notify.create({ color: 'orange', message: 'Some parts are slave parts', icon: 'warning' });
    }
    setupParts(parts, rootState.general.settings, state, 'parts');
    setupParts(masters || [], rootState.general.settings, state, 'externalmasters');
    if (response3) {
      setAltFamilyParts(response3.data.data, state.rule, state.currenttime, state.lib.pmpset);
    } else {
      store.altparts = store.parts;
    }
    if (state.rule.definition.pivottype === 'multi2'
      && !(state.rule.definition.multipivot2 && state.rule.definition.multipivot2.powercurve
        && state.rule.definition.multipivot2.powercurve.family
        && state.rule.definition.multipivot2.powercurve.family.a)) {
      commit('recomputeMultipivot2Parameters', rootState.general.settings);
    }
    setFamilyParts(parts, rootState.general.settings, state);

    return Promise.resolve();
  });
};
function firstsegmentation(product) {
  if (!(product.families && product.families.v.length)) return [];
  return product.families.v[0];
}

export const fullLoad = function fullLoad({
  commit, dispatch, state, rootState,
}) {
  const { id } = this.$router.currentRoute.params;
  const single = this.$router.currentRoute.path.startsWith('/one');
  commit('clearPricing');
  commit('setuplib', rootState.general.settings);
  commit('setSingle', single);
  let family = id;
  commit('setProgress', 'Fetching parts');
  const locale = rootState.auth.user && rootState.auth.user.locale ? rootState.auth.user.locale : undefined;
  return (single
    ? axios.get(`/products/${id}`)
      .then((response) => {
        const part = response.data.data;
        commit('setSinglePart', part);
        if (!part.families && part.families.v.length && part.families.v[0].length) {
          commit('setProgress', null);
          commit('header/setHeader', { title: 'Something went wrong', subtitle: 'Further information is available below' }, { root: true });
          return Promise.reject();
        }
        const f = part.families.v[0][part.families.v[0].length - 1];
        family = f.id;
        const fseg = firstsegmentation(part);
        const parent = part.families.v[0][part.families.v[0].length - 2];
        commit('header/setHeader', {
          title: `${part.name.v} : ${localized(part.label.v, locale)}`,
          subtitle: fseg.map((child) => localized(child.label, locale)).join(' → '),
          translated: true,
        }, { root: true });
        return Promise.resolve();
      })
    : axios.get(id ? `/families/${id}` : '/families')
      .then((response) => {
        // commit('pricing/setFamily', response.data.data);
        commit('header/setHeader', {
          title: localized(response.data.data.label, locale),
          // eslint-disable-next-line no-underscore-dangle
          subtitle: 'Pricing and rule setup',
          translated: true,
        }, { root: true });
        if (response.data.data.parent) {
          axios.get(`/families/${response.data.data.parent}`).then((p) => {
            commit('header/setParent', p.data.data, { root: true });
          });
        }
        return Promise.resolve();
      })
  )
    .then(() => commit('setFamily', family))
    .then(() => Promise.all([
      // axios.get(`/families/${family}/rules/latest`),
      axios.get(`/families/${family}/rules`),
      single ? Promise.resolve() : axios.get(`/families/${family}/siblings`, { params: { nolimit: 'yes', sort: 'label' } }),
      axios.get('/rules/empty'),
      axios.get(`/rates/ALL/ALL/ALL/${(new Date()).toISOString().split('T')[0]}`),
    ]))
    .then(([rules, siblings, empty, rates]) => {
      commit('clearRuleStore', rootState.general.settings.defaultpriceset);
      commit('setEmpty', empty.data.data);
      commit('setRates', rates.data.data);
      commit('loadRules', {
        rules: rules.data.data,
        locale,
        currency: 'CHF',
        settings: rootState.general.settings,
      });
      commit('setProgress', 'Computing prices...');
      if (siblings) {
        commit('header/setSiblings', { id: family, siblings: siblings.data.data.filter((s) => (s.id !== family)) }, { root: true });
      }
      let rule = findCurrentRule(rules.data.data, rootState.general.settings.defaultpriceset, state.currenttime);

      if (!rule) {
        rule = mkEmptyRule(state.emptyrule, state.priceset, state.family);
        if (single) rule.method = getPartValue(state.singlePart, 'pricingmethod', state.priceset, state.currenttime);
        if (methods[rule.method] && methods[rule.method].empty) methods[rule.method].empty(rule);
      }
      /*
      commit('clearRuleStore');
      commit('setEmpty', empty.data.data);
      */
      commit('setRule', { rule, locale, settings: rootState.general.settings });
      commit('setLoaded2', true);
      return dispatch('setParts');
    })
    .then((response) => commit('setProgress', null))
    .catch((error) => {
      console.error('error', error);
      Notify.create({ type: 'negative', message: 'Something went wrong while processing parts', icon: 'done' });
      return commit('setProgress', null);
    });
};

export const delRule = ({ commit, state, rootState }) => {
  if (!state.rule.id) return load({ commit, state, rootState }, { locale: rootState.auth.locale, familyid: state.rule.family });
  return axios({
    method: 'DELETE',
    url: `/rules/${state.rule.id}`,
  })
    .then(() => axios.get(`/families/${state.rule.family}/rules/latest`))
    .then((response) => {
      let rule = response.data.data.find((r) => (r.tag === rootState.general.settings.defaultpriceset));
      if (!rule) rule = mkEmptyRule(state.emptyrule, state.priceset, state.family);

      commit('saveRule', { rule, locale: rootState.auth.locale, settings: rootState.general.settings });
      Notify.create({ type: 'positive', message: 'Rule successfully deleted. Newly active rule got loaded', icon: 'done' });
    })
    .catch((error) => {
      console.error('error', error);
      Notify.create({ type: 'negative', message: 'Unable to delete this rule', icon: 'warning' });
    });
};
const sactualdateindex = Symbol.for('actualdateindex');
const sactualset = Symbol.for('actualset');

function attrclasses(settings) {
  const { attrclasses: classes } = settings;
  const mapping = Object.entries(classes).reduce((_, [k, v]) => {
    v.forEach((c) => {
      _[c] = k;
    });
    return _;
  }, {});
  return (attr) => (attr?.endsWith('$') ? 'pricing' : mapping[attr]);
}

function toSheetValue(_, rec, name, def, locale, rule, foo) {
  const fieldsep = '#';
  let value;
  let from;
  let to;
  if (!rec) return;
  let tag;
  if (def.timeserie || (typeof rec === 'object' && rec.DEFAULT)) {
    switch (foo(name)) {
      case 'pmp':
        tag = rec[sactualset] || 'DEFAULT';
        break;
      case 'pricing':
        tag = rule.tag || 'DEFAULT';
        break;
      default:
        tag = 'DEFAULT';
    }
    const idx = ((rec[tag] || [])[sactualdateindex]) || 0;
    if (!(rec[tag] && rec[tag].length)) return;
    if (!rec[tag][idx]) return;
    if (!rec[tag][idx].t) {
      from = undefined;
      to = undefined;
    } else if (typeof rec[tag][idx].t === 'object') {
      from = rec[tag][idx].t.from;
      to = rec[tag][idx].t.to;
    } else {
      // eslint-disable-next-line no-useless-escape
      [, from, to] = rec[tag][idx].t.split(/[\[\]\(\),]/g);
    }
    value = rec[tag][idx].v;
    if (from) _[`${name}${fieldsep}from`] = new Date(from);
    if (to) _[`${name}${fieldsep}to`] = new Date(to);
    if (tag !== 'DEFAULT') _[`${name}${fieldsep}tag`] = tag;
  } else {
    value = rec.v;
  }
  if (!value) return;
  switch (def.type) {
    case 'boolean':
    case 'collection':
    case 'string':
      _[name] = value;
      break;
    case 'localizedstring':
      _[name] = localized(value, locale);
      break;
    case 'pricing':
      if (value.qty) {
        const n = parseFloat(value.qty.fixed);
        if (!Number.isNaN(n)) _[`${name}${fieldsep}uom`] = n;
        _[`${name}${fieldsep}uom${fieldsep}unit`] = value.qty.unit;
      }
    // eslint-disable-next-line no-fallthrough
    case 'number':
    case 'amount':
      {
        const n = parseFloat(value.fixed);
        if (Number.isNaN(n)) return;
        _[name] = n;
        _[`${name}${fieldsep}unit`] = value.unit;
      }
      break;
    default:
  }
}

// todo: make it for more than ZZ
function getCellLetter(y) {
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const mod = y % 26;
  const r = (y - mod) / 26;
  let out = alphabet[mod];
  if (r >= 1) {
    out = alphabet[r - 1] + out;
  }
  return out;
}

function setSheetRange(XlsxWorkbook, x, y, data) {
  data.map((item, i) => {
    const tmp = XlsxWorkbook.cell(`${getCellLetter(i + x)}${y}`);
    if (typeof item.data === 'string' && item.data[0] === '=') {
      // Is formula
      tmp.clear();
      tmp.formula(item.data);
      tmp.style(item.style);
    } else {
      // Is value
      tmp.clear();
      tmp.value(item.data);
      tmp.style(item.style);
    }
    return item;
  });
}

function getSheetRange(XlsxWorkbook, x, y, length) {
  const res = [];
  for (let i = 0; i < length; i += 1) {
    if (XlsxWorkbook.sheet('TEMPLATE').cell(`${getCellLetter(x + i)}${y}`).formula()) {
      res.push({
        data: `=${XlsxWorkbook.sheet('TEMPLATE').cell(`${getCellLetter(x + i)}${y}`).formula().replaceAll(y, '##')}`,
        style: XlsxWorkbook.sheet('TEMPLATE').cell(`${getCellLetter(x + i)}${y}`).style(['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'fontSize', 'fontFamily', 'fontGenericFamily', 'fontScheme', 'fontColor']),
      });
    } else {
      res.push({
        data: '',
        style: XlsxWorkbook.sheet('TEMPLATE').cell(`${getCellLetter(x + i)}${y}`).style(['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'fontSize', 'fontFamily', 'fontGenericFamily', 'fontScheme', 'fontColor']),
      });
    }
  }
  return res;
}

function getDifferentialPrice(part) {
  const now = new Date(Date.now());
  let old24 = new Date(Date.now());
  old24 = new Date(old24.setMonth(old24.getMonth() - 24));

  let last = 0;
  part.price.DEFAULT.map((price, i) => {
    if (new Date(price.updated_at) > old24) {
      last = i;
    }
    return price;
  });
  return (part.price.DEFAULT[last].v.number - part.price.DEFAULT[0].v.number) / part.price.DEFAULT[last].v.number;
}

function checkMe(item, attrs) {
  let last = item;
  attrs.map((attr) => {
    if (last && last[attr]) {
      last = last[attr];
    } else {
      last = null;
    }
    return attr;
  });
  return last;
}

export const exportPricer = ({ commit, state, rootState }) => {
  axios.get('/template1.xlsx', { baseURL: '/', responseType: 'arraybuffer' })
    .then((response) => {
      XlsxPopulate.fromDataAsync(response.data).then((workbook) => {
        const formulaLine = getSheetRange(workbook, 1 /* B */, 6, 39);

        const page1 = workbook.sheet('TEMPLATE');
        const pmpset = priorityfetch(rootState.general.settings.pmppriority || []);
        const actualdate = findpricedate(state.rule.t, state.rule.t2);

        // Debug stuff
        // console.log(formulaLine);
        // console.log(state.parts[0]);
        // console.log(rootState);
        // console.log(state);
        // console.log(pmpset);
        const ratio = (new Date()).getMonth() / 12;
        store.parts.map((part, i) => {
          const resPf = pmpset(part, state.rule.definition.common.pmpattr, state.rule.definition, actualdate, findactualdate);

          // Columns to fill
          const column = [
            part.name.v || null, // Part Number
            localized(part.label.v) || null, // Description
            localized(part.families.v[0][part.families.v[0].length - 1].label) || null, // Product Family
            localized(part.families.v[0][part.families.v[0].length - 2].label) || null, // Material Family
            checkMe(part, [state.rule.definition.common.pmpattr, resPf.set, resPf.idx, 'v', 'number']), // Prix d'achat
            checkMe(part, ['price', 'DEFAULT', 0, 'v', 'number']), // SAP Price
            checkMe(part, ['minimalprice', 'v', 'number']), // Margin Price
            checkMe(part, ['proposedprice', 'v', 'number']), // Rules price
            checkMe(part, ['salesy', 'DEFAULT', 3, 'v', 'number']), // Average 2018
            checkMe(part, ['salesy', 'DEFAULT', 2, 'v', 'number']), // Average 2019
            checkMe(part, ['salesy', 'DEFAULT', 1, 'v', 'number']), // Average 2020
            (checkMe(part, ['salescy', 'DEFAULT', 0, 'v', 'number']) / ratio) || null, // Average 2021
            getDifferentialPrice(part), // Differential 2021
          ];

          let cnt = 0;
          const tmpF = formulaLine.map((item, j) => {
            let res = '';
            if (item.data === '' && cnt < column.length) {
              res = {
                data: column[cnt],
                style: item.style,
              };
              cnt += 1;
            } else {
              res = {
                data: item.data.replaceAll('##', 6 + i),
                style: item.style,
              };
            }
            return res;
          });

          setSheetRange(page1, 1, 6 + i, tmpF);
          return part;
        });

        // Vlookup part replace end
        const toRemplace = ['evolQty1', 'evolQty2', 'evolCA1', 'evolCA2'];
        toRemplace.map((item) => {
          const val = `=${workbook.definedName(item).formula().replaceAll('0', store.parts.length + 5)}`;
          workbook.definedName(item).formula(val);
          return item;
        });

        // Defined Name for arrayData
        const startY = 5;
        const startX = getCellLetter(1);
        const stopX = getCellLetter(formulaLine.length);
        const range = page1.range(`${startX}${startY}:${stopX}${startY + store.parts.length}`);
        workbook.definedName('arrayData', range);

        // Export it
        workbook.outputAsync()
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = 'exportPricer.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          });
      });
    });
};

export const exportParts = ({ commit, state, rootState }) => {
  const locale = rootState.auth.user && rootState.auth.user.locale ? rootState.auth.user.locale : undefined;
  const foo = attrclasses(rootState.general.settings);
  const parts = store.parts.map((part) => Object.keys(part).filter((attr) => !['self', 'id', 'validatedprice'].includes(attr)).reduce((_, attr) => {
    toSheetValue(_, part[attr], attr, state.defs.defs2[attr], locale, state.rule, foo);
    return _;
  }, {}));
  const keys = Array.from(parts.reduce((_, part) => {
    _ = _.union(new Set(Object.keys(part)));
    return _;
  }, new Set()));
  const fieldsep = '#';
  keys.sort((a, b) => {
    const [aa, asuffix1, asuffix2] = a.split(fieldsep);
    const [ba, bsuffix1, bsuffix2] = b.split(fieldsep);
    const ax = state.sets.order.indexOf(state.sets.sets[state.defs.defs2[aa].root].name);
    const bx = state.sets.order.indexOf(state.sets.sets[state.defs.defs2[ba].root].name);
    if (ax < bx) return -1;
    if (ax > bx) return 1;
    const ay = state.defs.order.indexOf(aa);
    const by = state.defs.order.indexOf(ba);
    if (ay < by) return -1;
    if (ay > by) return 1;
    const sorders = ['unit', 'uom', 'tag', 'from', 'to'];
    if (sorders.indexOf(asuffix1) < sorders.indexOf(bsuffix1)) return -1;
    if (sorders.indexOf(asuffix1) > sorders.indexOf(bsuffix1)) return 1;
    if (sorders.indexOf(asuffix2) < sorders.indexOf(bsuffix2)) return -1;
    if (sorders.indexOf(asuffix2) > sorders.indexOf(bsuffix2)) return 1;
    // should never happen
    return 0;
  });
  const labels = keys.map((k) => {
    const [attr, suffix1, suffix2] = k.split(fieldsep);
    const l = localized(state.defs.defs2[attr].label, locale);
    const suffixes = {
      from: localized({ 'fr-FR': 'de', 'en-US': 'from' }, locale),
      to: localized({ 'fr-FR': 'à', 'en-US': 'to' }, locale),
      tag: localized({ 'fr-FR': 'tag', 'en-US': 'tag' }, locale),
      uom: localized({ 'fr-FR': 'qté', 'en-US': 'qty' }, locale),
      unit: localized({ 'fr-FR': 'unité', 'en-US': 'unit' }, locale),
    };
    const s = [];
    if (suffix1) s.push(suffixes[suffix1]);
    if (suffix2) s.push(suffixes[suffix2]);
    if (s.length) return `${l} (${s.join(' ')})`;
    return l;
  });
  const sheet = xlsx.utils.json_to_sheet(parts, { header: keys });
  xlsx.utils.sheet_add_aoa(sheet, [labels], { origin: 'A1' });
  const wsName = 'Parts';
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, sheet, wsName);
  xlsx.writeFile(wb, 'family.xlsx', { compression: true });
};

export const exportM3 = ({ commit, state, rootState }) => {
  const exportedattr = 'proposedprice'; // price
  const wb = {
    SheetNames: ['Paramètres', 'Tarifs', 'Tarifs_Palier', 'Version_Fichier'],
    Sheets: {
      Paramètres: {
        A1: {
          v: 'PARAMETRES',
          t: 's',
          s: {
            fill: { patternType: 'solid', fgColor: { rgb: 'FFCCCCCC' } },
            font: { name: 'Calibri', sz: 18, bold: true },
            alignment: { vertical: 'center', horizontal: 'center' },
          },
        },
        A2: {
          v: 'TYPE_PARAMETRE',
          t: 's',
        },
        B2: {
          v: 'CODE',
          t: 's',
        },
        C2: {
          v: 'Code_Client',
          t: 's',
        },
        D2: {
          v: 'Date_de_début_de_validité__YYYYMMDD_',
          t: 's',
        },
        E2: {
          v: 'Email',
          t: 's',
        },
        '!merges': [
          { e: { c: 4, r: 0 }, s: { c: 0, r: 0 } },
        ],
      },
      Tarifs: {
        A1: {
          v: 'Codes_Article',
          t: 's',
        },
        B1: {
          v: 'Date_de_début_de_validité_à_mettre_à_jour',
          t: 's',
        },
      },
      Tarifs_Palier: {
        A1: {
          v: 'Codes_Article',
          t: 's',
        },
        B1: {
          v: 'Date_de_début_de_validité_à_mettre_à_jour',
          t: 's',
        },
        C1: {
          v: 'Tarifs',
          t: 's',
        },
        D1: {
          v: 'Devise',
          t: 's',
        },
        E1: {
          v: 'Qté',
          t: 's',
        },
        F1: {
          v: 'Prix',
          t: 's',
        },
        '!ref': xlsx.utils.encode_range({ e: { c: 5, r: 1 }, s: { c: 0, r: 0 } }),
      },
      Version_Fichier: {
        '!merges': [],
        '!ref': xlsx.utils.encode_range({ e: { c: 0, r: 1 }, s: { c: 0, r: 0 } }),
        A1: {
          v: 'Version',
          t: 's',
        },
        A2: {
          t: 's',
          v: '1.0',
        },
      },
    },
    Props: {
      Title: 'Fichier de Chargement Haulotte',
      Author: 'Logiparts',
    },
  };
  // exports all price list, including parts
  // const pricelists = [state.rule.tag];
  const pricelists = state.rule.rules.map((r) => r.tag);

  const y = state.rule.t.getFullYear().toString();
  let m = (state.rule.t.getMonth() + 1).toString();
  let d = state.rule.t.getDate().toString();
  if (d.length === 1) d = `0${d}`;
  if (m.length === 1) m = `0${m}`;
  const date = y + m + d; // state.rule.t.toISOString().split('T')[0].replace(/-/g, '');
  const emails = [rootState.auth.user.email.trim()];

  pricelists.map((p) => p.split('/')[0]).forEach((p, i) => {
    wb.Sheets['Paramètres'][xlsx.utils.encode_cell({ c: 0, r: i + 2 })] = { t: 's', v: 'TARIF' };
    wb.Sheets['Paramètres'][xlsx.utils.encode_cell({ c: 1, r: i + 2 })] = { t: 's', v: p };
    wb.Sheets['Paramètres'][xlsx.utils.encode_cell({ c: 2, r: i + 2 })] = { t: 's', v: '' };
    wb.Sheets['Paramètres'][xlsx.utils.encode_cell({ c: 3, r: i + 2 })] = { t: 's', v: date };
    wb.Sheets['Paramètres'][xlsx.utils.encode_cell({ c: 4, r: i + 2 })] = { t: 's', v: emails.join(';') };
  });

  wb.Sheets['Paramètres']['!ref'] = xlsx.utils.encode_range({ e: { c: 4, r: Object.keys(pricelists).length + 1 }, s: { c: 0, r: 0 } });

  const sheet = wb.Sheets.Tarifs;
  let lines = 0;
  pricelists.forEach((pricelist, i) => {
    const [num, currency] = pricelist.split('/');

    sheet[xlsx.utils.encode_cell({ c: 2 + i, r: 0 })] = { t: 's', v: `${currency}-${num}` };
    let j = 0;
    store.parts.forEach((part) => {
      // const k = i * j;
      if (!(part[exportedattr] && part[exportedattr][pricelist])) return;
      const idx = findactualdate(part[exportedattr][pricelist], findpricedate(state.rule.t, state.rule.t2));
      if (idx < 0) return;

      sheet[xlsx.utils.encode_cell({ c: 0, r: j + 1 })] = { t: 's', v: part.name.v };
      sheet[xlsx.utils.encode_cell({ c: 1, r: j + 1 })] = { t: 's', v: date };
      sheet[xlsx.utils.encode_cell({ c: 2 + i, r: j + 1 })] = { t: 'n', v: parseFloat(part[exportedattr][pricelist][idx].v.fixed) };
      // eslint-disable-next-line no-plusplus
      lines++; j++;
    });
  });
  sheet['!ref'] = xlsx.utils.encode_range({ e: { c: 1 + Object.keys(pricelists).length, r: lines }, s: { c: 0, r: 0 } });
  // create Tarifs Paliers
  // Version
  const name = `ExportM3_${date}.xml`;
  xlsx.writeFile(wb, name, { bookType: 'xlml', compression: true });
};
