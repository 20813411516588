<template>
  <!-- eslint-disable max-len -->
  <div>
    <q-page-container>
      <q-page>
        <div
          class="welcome"
          v-if="impExp.currentStep < 4 && this.$store.state.importExport.step1.file.parsed === null"
        >
          <p v-t>Welcome in the import Wizard</p>
          <p v-t>The import wizard is going to guide you in each step of the import process.</p>
          <p v-t>in order to start the import drag & drop you file onto the dedicated area in the right drawer.</p>
        </div>
        <div v-else class="table">
          <Table></Table>
        </div>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <Stepper></Stepper>
    </q-drawer>
  </div>
</template>

<script>
// TODO check for quasar import
import Table from '../../components/Table';
import Stepper from '../../components/drawer/ImpExp/stepper';

export default {
  components: {
    Table,
    Stepper,
  },
  data() {
    return {};
  },
  computed: {
    drawer() {
      // return this.$route.name !== 'upload';
      return true;
    },
    impExp() {
      return this.$store.state.importExport;
    },
  },
  methods: {
    goToStep(step) {
      this.$store.commit('header/setHeader', {
        title: 'Import CSV',
        subtitle: 'Upload',
      });
      this.$store.commit('importExport/setCurrentStep', step);
    },
  },
  created() {
    this.goToStep(1);
  },
};
</script>

<style lang="stylus" scoped>

.welcome {
  padding: 50px;
}

.q-field {
  padding: 5px;

  .label { // crado
    color: #979797;
    line-height: 18px;
    font-size: 0.75rem;
  }
}

.table {
  height: calc(100vh - 51px);
  width: 100%;
  overflow: auto;
}

/* TODO remove on quasar v1.0 https://github.com/quasarframework/quasar/issues/3126 */
.q-stepper-horizontal.q-stepper-contractable > {
  .q-stepper-header {
    min-height: 72px;

    .q-stepper-tab {
      padding: 24px 0;

      &:not(:last-child) {
        .q-stepper-dot:after {
          display: block !important;
        }
      }
    }

    .q-stepper-dot {
      margin: 0;
    }

    .q-stepper-label {
      display: none;
    }
  }
}
</style>
