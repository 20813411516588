<template>
  <div>
    <div class="shadow2">
      <q-toolbar class="bg-primary text-white">
        <q-btn flat round dense icon="cancel" @click="goback"/>
        <q-toolbar-title>
          {{title}}
          <span slot="subtitle">{{subtitle}}</span>
        </q-toolbar-title>
        <q-icon
          v-if="action.startsWith('details')"
          class="locked"
          :name="locked ? 'lock': 'lock_open'"
          @click.native="toggleLock()"
          size="1.5rem"
        ></q-icon>
      </q-toolbar>
      <q-tabs class="bg-primary text-white" indicator-color="secondary" dense narrow-indicator v-model="tab">
        <q-tab name="general" icon="build" label="General" />
        <q-tab v-if="type === 'attributes'" name="rule" icon="format_paint" label="Rule Defaults"/>
        <q-tab v-if="type === 'attributes'" name="type" icon="tune" label="Type Settings"/>
        <q-tab v-if="type === 'attributes'" name="import" icon="get_app" label="Import parameters"/>
      </q-tabs>
    </div>
    <q-tab-panels v-model="tab" animated class="drawer-container">
      <q-tab-panel name="general">
          <!-- <pre>{{curentTag}}</pre> -->
            <q-input bottom-slots filled square dense
              v-model="form.name"
              name="name"
              :disable="locked"
              type="text"
              label="Name"
            />
            <localizedstring label="Label" name="label" :disable="locked" v-if="form.label" editable="yes" :def="{name: 'label'}" :data="{ v: form.label }"
              :rules="rules" @change="dochange" />
            <div v-else>no form.label</div>
          <div v-if="type === 'attributes'">
              <q-select square filled bottom-slots
                :error="$v.form.type.model.$error" :error-message="getError('model')"
                v-model="form.type.model"
                :disable="locked"
                :options="form.type.options"
                label="Attribute type"
              />
            <div class="text-subtitle2">Options</div>
            <q-list dense>
              <q-item tag="label" v-ripple>
                <q-item-section side>
                  <q-checkbox v-model="form.options" :disable="locked" val="readonly"/>
                </q-item-section>
                <q-item-section>Read-Only</q-item-section>
              </q-item>
              <q-item tag="label" v-ripple>
                <q-item-section side>
                  <q-checkbox :disable="(form.type.model !== 'string') || locked" v-model="form.options" val="groupable"/>
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    Groupable
                  </q-item-label>
                  <q-tooltip  content-class="bg-secondary lg-drawer-tooltip shadow-2">This attribute can be used to identify groups of similar parts within a family or a selection.<br>It's only avalaible for string attributes.</q-tooltip>
                </q-item-section>
              </q-item>
              <q-item tag="label" v-ripple>
                <q-item-section side>
                  <q-checkbox v-model="form.options" :disable="locked" val="timeserie"/>
                </q-item-section>
                <q-item-section>timeserie</q-item-section>
              </q-item>
              <q-item tag="label" v-ripple>
                <q-item-section side>
                  <q-checkbox v-model="form.options" :disable="locked" val="sync"/>
                </q-item-section>
                <q-item-section>Sync</q-item-section>
              </q-item>
              <q-item tag="label" v-ripple>
                <q-item-section side>
                  <q-checkbox v-model="form.options" :disable="locked" val="computed"/>
                </q-item-section>
                <q-item-section>computed</q-item-section>
              </q-item>
            </q-list>
          </div>
      </q-tab-panel>
      <q-tab-panel name="rule">
            <q-list>
              <q-item-label header>Weight in rule</q-item-label>
              <q-item>
                <q-item-section side>
                  <q-checkbox v-model="form.weight" :disable="locked" val="rate"/>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Rate</q-item-label>
                  <div class="defaultvalue">
                    <input type="number" v-model="form.weightrate" placeholder="Default value" :disabled="disablerate" />
                    <select v-model="form.weightrateunit" :disabled="disablerate">
                      <option value=""></option>
                      <optgroup v-for="(g, index) in units" :key="index" :label="localized(g.label)">
                        <option v-for="o in g.options" :key="o" :value="o">{{o}}</option>
                      </optgroup>
                    </select>
                  </div>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section side>
                  <q-checkbox v-model="form.weight" :disable="locked" val="amount"/>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Amount</q-item-label>
                  <div class="defaultvalue">
                    <input type="number" v-model="form.weightamount" placeholder="Default value" :disabled="disableamount" />
                    <select v-model="form.weightamountunit" :disabled="disableamount">
                      <option value=""></option>
                      <optgroup v-for="(g, index) in units" :key="index" :label="localized(g.label)">
                        <option v-for="o in g.options" :key="o" :value="o">{{o}}</option>
                      </optgroup>
                    </select>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
      </q-tab-panel>
      <q-tab-panel name="type">
        <p>Depending on the attribute type, you will be able to tune some type specific defaults.</p>
        <p>Not all types have settings.</p>
          <div v-if="form.type.model === 'number'">
            <label>Default unit :</label>
            <select :disabled="locked" v-model="form.settings.unit">
              <option value=""></option>
                <optgroup v-for="(g, index) in units" :key="index" :label="localized(g.label)">
                  <option v-for="o in g.options" :key="o" :value="o">{{o}}</option>
                </optgroup>
              </select>
          </div>
            <!--<q-input v-if="form.type.model === 'number'"
              v-model="form.settings.unit"
              :disable="locked"
              type="text"
              label="Default Unit"
            />-->
      </q-tab-panel>
      <q-tab-panel name="import">
        <q-list>
          <q-item-label header>Attribute component spread</q-item-label>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-radio v-model="form.expandable" val="1" :disable="this.locked"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>A single column</q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-radio v-model="form.expandable" val="2" :disable="this.locked"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>One or two columns</q-item-label>
            </q-item-section>
          </q-item>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-radio v-model="form.expandable" val="4" :disable="this.locked"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>Two or four columns</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <p>
          This option helps the importer by telling how data for this attribute can be split.
        </p>
        <p>
          E.g. :  A price is made of four components : the actual price, the currency, the UOM and
          at last the associated quantity.
        </p>
        <p>
          Those four can be spread in either two or four columns. That is, each component in a decicated
          column, or price with currency and UOM with quantity like the following : "1023.50 €" and "1 PC".
        </p>
      </q-tab-panel>
    </q-tab-panels>
    <div class="validation">
      <q-btn :disable="locked || id === null" @click="del" label="Delete" color="negative" />
      <q-btn @click="goback">cancel</q-btn>
      <q-btn :disable="locked" color="secondary" @click="save()">save</q-btn>
    </div>
  </div>
</template>
<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import localization from '../../../lib/mixins'; // eslint-disable-line
import localizedstring from '../../attributesDisplay/localizedstring';

const units = Object.freeze([
  {
    label: { 'fr-FR': 'Poids et mesures', 'en-US': 'Weights and measures' },
    options: [
      'g', 'kg', 'mm', 'm', 'mm2', 'm2', 'mm3', 'm3',
    ],
  },
  {
    label: { 'fr-FR': 'Devises', 'en-US': 'Currencies' },
    options: ['USD', 'EUR', 'CHF', 'GBP', 'RMB', 'JPY'],
  },
]);
export default {
  mixins: [localization],
  components: {
    localizedstring,
  },
  computed: {
    disablerate() {
      return this.locked || !this.form.weight.includes('rate');
    },
    disableamount() {
      return this.locked || !this.form.weight.includes('amount');
    },
  },
  data() {
    return {
      units,
      tab: 'general',
      search: '',
      rules(fullmodel) {
        return [
          (val) => Object.entries(fullmodel).filter(([k, v]) => !!v).every(([k, v]) => v.length > 3)
            || `Label cannot be shorter than 3 characters, ${val}`,
          (val) => (Object.entries(fullmodel).filter(([k, v]) => !!v).length >= 1)
            || 'At least a label in one language is required',
        ];
      },
      form: {
        name: null,
        label: null,
        type: {
          model: null,
          options: [
            { label: 'String', value: 'string' },
            { label: 'Localizedstring', value: 'localizedstring' },
            { label: 'Date', value: 'date' },
            { label: 'Interval', value: 'interval' },
            { label: 'Number', value: 'number' },
            { label: 'Amount', value: 'amount' },
            { label: 'Pricing', value: 'pricing' },
            { label: 'Boolean', value: 'boolean' },
          ],
        },
        settings: {
          // default values & unit
        },
        weight: [],
        options: [],
        expandable: '1',
      },
      action: null,
      column: null,
      id: null,
      type: null,
      curentTag: null,
      locked: true,
      locale: this.$store.state.auth.locale,
      title: '',
      subtitle: '',
    };
  },
  validations: {
    form: {
      label: { required, minLength: minLength(3) },
      name: { required },
      type: {
        model: {
          required: requiredIf(function checktype(nestedModel) {
            return this.type === 'attributes';
          }),
        },
      },
    },
  },
  methods: {
    del() {
      if (!this.id) return;
      this.$axios({
        method: 'DELETE',
        url: `/attributes/${this.id}`,
      }).then(() => {
        this.$q.notify({ type: 'positive', message: 'Attribute deleted', icon: 'done' });
        this.goback();
      }).catch((error) => {
        console.error(error);
        const message = (error.response && error.response.data && error.response.data.name === 'notempty')
          ? 'This attribute is still used'
          : 'Attribute cannot be deleted';
        this.$q.notify({ type: 'negative', message, icon: 'warning' });
      });
    },
    setTitle() {
      if (this.action === 'new') {
        this.title = this.type === 'attributes' ? 'New attribute' : 'New attributeset';
        if (this.id) {
          this.fetch('attributesets', this.id).then((response) => {
            this.subtitle = `in ${this.localized(response.label)}`;
          });
        } else {
          this.subtitle = '';
        }
      }
      if (this.curentTag) {
        if (this.type === 'attributes') {
          this.title = this.locked ? 'Attribute details' : 'Attribute edit';
        } else {
          this.title = this.locked
            ? 'Attributeset details'
            : 'Attributeset edit';
        }
        this.subtitle = `in ${this.localized(this.curentTag.label)}`;
      }
      return '';
    },
    toggleLock() {
      this.locked = !this.locked;
    },
    dochange(v) {
      this.form.label = v.label;
    },
    go() {
      this.$router.push({ name: 'newattr' });
    },
    getError(elem) {
      return '3 character minimun.';
    },
    goback() {
      const index = this.$route.path.indexOf('/drawer');
      this.$router.push(this.$route.path.substr(0, index));
    },
    expandableToForm(data) {
      if (!data || data.length === 0) return '1';
      return data[1] === '1' ? '2' : '4';
    },
    expandableToJSON(value) {
      if (value === '2') return [1, 2];
      if (value === '4') return [2, 4];
      return [];
    },
    save() {
      // if (true) {
      const params = {
        label: this.form.label,
        name: this.form.name,
      };
      if (this.type === 'attributes') {
        if (this.action === 'new') {
          params.set = this.id;
        }
        params.type = this.form.type.model.value;
        params.readonly = this.form.options.includes('readonly');
        params.primary = this.form.options.includes('primary');
        params.groupable = this.form.options.includes('groupable');
        params.computed = this.form.options.includes('computed');
        params.timeserie = this.form.options.includes('timeserie');
        params.weight = [];
        params.settings = this.form.settings;
        if (this.form.weight.includes('rate')) {
          const weight = {
            type: 'rate',
          };
          if (this.form.weightrate !== undefined || this.form.weightrateunit) {
            weight.v = {
              fixed: this.form.weightrate,
              number: parseFloat(this.form.weightrate),
              unit: this.form.weightrateunit || '',
            };
          }
          params.weight.push(weight);
        }
        if (this.form.weight.includes('amount')) {
          const weight = {
            type: 'amount',
          };
          if (this.form.weightamount !== undefined || this.form.weightamountunit) {
            weight.v = {
              fixed: this.form.weightamount,
              number: parseFloat(this.form.weightamount),
              unit: this.form.weightamountunit || '',
            };
          }
          params.weight.push(weight);
        }
        params.expandable = this.expandableToJSON(this.form.expandable);
      } else if (this.action === 'new') {
        params.parent = this.id;
      }
      let req = null;
      if (this.action.startsWith('details')) req = this.$axios.patch(`/${this.type}/${this.id}`, params);
      if (this.action === 'new') req = this.$axios.post(`/${this.type}`, params);
      if (req) {
        req.then((response) => {
          this.form.label = response.data.data.label;
          this.form.name = response.data.data.name;
          if (this.type === 'attributes') {
            this.$store.commit('pricing/setAttribute', response.data.data);
          }
          this.goback();
          this.$q.notify({ type: 'positive', message: 'Attribute(set) created', icon: 'done' });
        }).catch((error) => {
          if (error?.request?.getResponseHeader('content-type')?.startsWith('application/json')) {
            const info = JSON.parse(error.request.response) || {};
            if (info.name === 'duplicate') {
              return this.$q.notify({ type: 'negative', message: 'Duplicate name', icon: 'warning' });
            }
          }
          return this.$q.notify({ type: 'negative', message: 'Error while saving', icon: 'warning' });
        });
      }
    },
    fetch(type, id) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/${type}/${id}`).then((response) => {
          resolve(response.data.data);
        });
      });
    },
    setId(route) {
      const routeParams = route.params.pathMatch.split('/');
      const i = this.column ? this.column - 1 : routeParams.length - 1;
      if (this.action === 'new' && this.column === 0) {
        this.id = null;
      } else {
        this.id = routeParams[i].substring(2);
      }
    },
    setType(route) {
      if (this.action.startsWith('new')) {
        if (this.action.endsWith('attributesets')) {
          this.type = 'attributesets';
        } else {
          this.type = 'attributes';
        }
        this.action = 'new';
      } else {
        this.type = this.action.endsWith('set')
          ? 'attributesets'
          : 'attributes';
      }
    },
    fetchAttribute() {
      this.fetch(this.type, this.id).then((response) => {
        this.curentTag = response;
        this.setTitle();
        this.form.label = response.label;
        this.form.name = response.name;
        this.form.type.model = response.type;
        this.form.settings = response.settings || {};
        this.form.weight = [];
        if (response.weight) {
          response.weight.filter((w) => (!!w.type)).forEach((w) => {
            this.form.weight.push(w.type);
            if (w.v) {
              const { fixed, unit } = w.v;
              if (w.type === 'rate') {
                this.form.weightrate = fixed;
                this.form.weightrateunit = unit;
              } else {
                this.form.weightamount = fixed;
                this.form.weightamountunit = unit;
              }
            }
          });
        }
        this.form.options = [];
        ['readonly', 'groupable', 'timeserie', 'sync', 'computed'].forEach((o) => {
          if (response[o]) {
            this.form.options.push(o);
          }
        });

        this.form.expandable = this.expandableToForm(response.expandable);
      });
    },
    initAttribute() {
      this.curentTag = undefined;
      this.form.label = { [this.$store.state.auth.locale]: 'New Label' };
      this.form.name = null;
      this.form.type.model = null;
      this.form.weight = [];
      this.form.options = [];
      this.form.settings = {};
      this.setTitle();
    },
    fetchAttributeSet() {},
    initData(route) {
      this.action = route.params.action;
      this.column = route.params.column;
      this.setType(route);
      this.setId(route);
      if (this.action === 'new') {
        this.locked = false;
        this.initAttribute();
      } else {
        this.locked = true;
        this.fetchAttribute();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to);
    next();
  },
  created() {
    this.initData(this.$route);
  },
};
</script>
<style lang="stylus">
 .lg-drawer-tooltip {
  max-width: 250px;
  font-size: .8rem;
}
</style>
<style lang="stylus" scoped>
.defaultvalue {
  display: flex;
  gap: 4px
  input {
    flex 1
  }
  select {
    width: 50px;
  }
}
.drawer-container {
  height: calc(100vh - 240px);
}
.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.content {
  padding: 1rem;
}
</style>
