import { render, staticRenderFns } from "./template.vue?vue&type=template&id=0db4524f&scoped=true&"
import script from "./template.vue?vue&type=script&lang=js&"
export * from "./template.vue?vue&type=script&lang=js&"
import style0 from "./template.vue?vue&type=style&index=0&id=0db4524f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db4524f",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QBtn,QField});
