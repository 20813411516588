import { render, staticRenderFns } from "./DecisionGrid.vue?vue&type=template&id=5d2bfe77&scoped=true&"
import script from "./DecisionGrid.vue?vue&type=script&lang=js&"
export * from "./DecisionGrid.vue?vue&type=script&lang=js&"
import style0 from "./DecisionGrid.vue?vue&type=style&index=0&id=5d2bfe77&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2bfe77",
  null
  
)

export default component.exports
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtnDropdown,QList,QItem,QIcon,QBtn,QField,QSelect,QChip});
