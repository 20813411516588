<template>
  <q-chip class="chip-confirm" :icon="data.icon" :color="data.color">{{data.value}}</q-chip>
</template>
<script>
export default {
  props: ['data'],
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="stylus">
.chip-confirm {
  margin-left 10px
}
</style>
