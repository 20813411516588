<template>
  <div>
    <div class="shadow-2">

      <q-toolbar class="bg-primary text-white">
        <q-btn flat round dense icon="cancel" @click="goback" style ="margin-top: -45px;"/>
        <q-toolbar-title style="padding-top: 10px;">
          {{localized($store.state.pricing.rule.label)}} <q-btn size="sm" dense color="positive">{{$store.state.pricing.rule.method}}</q-btn>

          <div style="display: block; margin-bottom: 5px; overflow-x: auto; width: 250px;">
            <div v-for="r in $store.state.pricing.rule.rules" :key="r.id" style="display: inline-block;">
                <q-btn v-if="$store.state.general.settings.pricesetselector" size="sm" dense color="secondary">{{r.tag}}</q-btn>
                <q-btn v-if="r.id !== $store.state.pricing.rule.id" flat round dense :disable="true" icon="arrow_right"/>
            </div>
          </div>
         </q-toolbar-title>
      </q-toolbar>

      <q-tabs class="bg-primary text-white" indicator-color="secondary">
        <q-route-tab :title="$t('Rule definition')" append icon="tune" @click.native="path('params')"/>
        <q-route-tab :title="$t('Smoothing')" @click.native="path('refine')"  append icon="timeline" />
        <q-route-tab v-if="$store.state.general.settings.vdiscount" :title="$t('Volume Discount')" @click.native="path('vdiscount')" append icon="loyalty" />
        <q-route-tab :title="$t('CRC Parameters')" @click.native="path('crcparam')" append icon="home_repair_service" />
        <!-- <q-route-tab :disable="$store.state.pricing.single" title="Comparison tool" :to="path('history')" append icon="bar_chart" />
        <q-route-tab :disable="$store.state.pricing.single" title="Attribute show" :to="path('ruleattribute')" append icon="colorize" /> -->
      </q-tabs>
    </div>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      tab: '1',
    };
  },
  methods: {
    path(step) {
      const base = this.$store.state.pricing.single ? '/one' : '/pricing';
      const href = `${base}/${this.$route.params.id}/rule/${step}`;

      if (this.$route.fullPath === href) return;
      this.$router.push({ path: href });
    },
  },
};
</script>
