<template>
  <div class="helper-drawer">
    <q-toolbar class="shadow-2" color="secondary">
      <q-btn round flat icon="cancel" @click="showhelper(false)"/>
      <q-toolbar-title>Help</q-toolbar-title>
            <q-input v-model="search" :after="[{ icon: 'search', handler}]"/>

    </q-toolbar>
    <div class="helper-topic q-pa-sm">
      <iframe :src="topicurl"></iframe>
    </div>
  </div>
</template>
<script>
const baseurl = process.env.HELPSERVER;

export default {
  data() {
    return {
      search: '',
      topicurl: baseurl,
    };
  },
  methods: {
    handler() {},
    showhelper(show = true) {
      const method = show ? 'add' : 'remove';
      const classes = [
        { selector: 'helper-drawer', name: 'active' },
        { selector: 'q-layout-header', name: 'helper-drawer-shift' },
        { selector: 'q-layout-drawer-right', name: 'helper-drawer-shift' },
        { selector: 'q-layout-page-container', name: 'helper-drawer-pad' },
      ];
      classes.forEach(({ selector, name }) => {
        const elem = document.getElementsByClassName(selector)[0];
        if (elem) elem.classList[method](name);
      });
    },
  },
  created() {
    this.$root.$on('help-show', (topics) => {
      this.showhelper();
      const url = new URL(baseurl);
      url.searchParams.append('vendor', window.vendor);
      url.searchParams.append('release', window.apprelease);
      url.searchParams.append('topics', topics && topics.length ? topics.join(',') : 'home');
      this.topicurl = url.toString();
    });
  },
  destroy() {
    this.$root.$off('help-show');
  },
};
</script>
<style lang="stylus">
.help-cursor {
  cursor help
}
.helper-drawer {
  position: absolute;
  right 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: #eeeeee;
  z-index 2999
  display: none;
  &.active {
    display: block;
  }
  .helper-topic {
    background: white;
    height: calc(100vh - 64px)
    overflow-y scroll
    iframe {
      width: 100%;
      border: 0;
      height 100%
    }
  }
}
.helper-drawer-shift {
  right: 300px;
}
.helper-drawer-pad {
  padding-right 300px;
}
</style>
