<template>
  <div>
    <q-layout v-if="authenticated" class="helper-opened" view="lHh Lpr lFf">
      <router-view name="header"/>
      <router-view name="menu"/>
      <router-view name="main"/>
    </q-layout>
    <login/>
    <help/>
  </div>
</template>
<script>
import Login from '../components/Login.vue';
import Help from '../components/help/HelperDrawer.vue';

export default {
  components: {
    Login,
    Help,
  },
  computed: {
    authenticated() {
      return this.$store.state.auth.token && this.$store.state.auth.token !== 'invalid';
    },
  },
};
</script>
