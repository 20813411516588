let reloging = false;
const jwtDecode = require('jwt-decode');

export const login = (state, data) => {
  state.token = data;
  state.user = jwtDecode(state.token);
  if (state.user && state.user.locale) state.locale = state.user.locale;
  reloging = false;
};

export const logout = (state) => {
  state.token = 'invalid';
  state.user = null;
  window.location.href = '/';
};

export const invalidate = (state, data) => {
  if (!reloging) {
    state.token = undefined;
    reloging = true;
    if (data.info && data.info.scheme) {
      state.subscheme = data.info;
    }
  }
};

export const setObservable = (state, sse) => {
  if (sse.observable) {
    state.sse.observable.push(sse.observable);
  }
};

export const unsetObservable = (state, path) => {
  for (
    let i = 0; i < state.sse.observable.length; i += 1
  ) {
    if (
      state.sse.observable[i].regex === path
    ) {
      state.sse.observable.splice(i, 1);
    }
  }
};
