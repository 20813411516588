import { i18n } from '../../boot/locize';

export const setHeader = (state, h) => { // eslint-disable-line import/prefer-default-export
  state.title = h.translated ? h.title : i18n.instance.t(h.title);
  state.subtitle = h.translated ? h.subtitle : i18n.instance.t(h.subtitle);
  state.parent = {};
  state.siblings = [];
};

export const setSiblings = (state, { id, siblings }) => {
  state.siblings = siblings;
};
export const setParent = (state, parent) => {
  if (parent && parent.id) state.parent = parent;
};
