<template>
<div>

  <div class="header">
      <q-card inline class="card">
        <q-card-section>
          <attribute v-if="product.price" :data="product.price" :def="$store.state.pricing.defs.defs2.price" space="compact" />
          <div v-else>Unset</div>
          <div class="title">{{$t('Current Price')}}</div>
          <div class="minor" v-if="product.proposed_price">
            (proposed :
            <pricing inline :data="product.proposed_price" />)
          </div>
        </q-card-section>
      </q-card>
      <q-card inline class="card">
        <q-card-section>
          <attribute v-if="product[$store.state.general.settings.attrs.pmp]" :data="product[$store.state.general.settings.attrs.pmp]" :def="$store.state.pricing.defs.defs2[$store.state.general.settings.attrs.pmp]" space="compact" />
          <div class="title">{{$t('Purchasing Cost')}}</div>
        </q-card-section>
      </q-card>
      <q-card inline class="card">
        <q-card-section>
          <div style="display: flex; justify-content: center;">
            <collection v-if="product.pricingmethod && product.pricingmethod.DEFAULT" :data="product.pricingmethod.DEFAULT[0]" />
          </div>
          <div class="title">{{$t('Pricing Method')}}</div>
        </q-card-section>
      </q-card>
      <q-card inline class="card" v-if="$store.state.general.settings.classification">
        <q-card-section>
          <div style="display: flex; justify-content: center;">
            <collection v-if="product.classification && product.classification.v" :data="product.classification" />
          </div>
          <div class="title">{{$t('Classification')}}</div>
        </q-card-section>
      </q-card>
      <q-card inline class="card">
        <q-card-section>
          <pricestatus :data="product.pricestatus" />
          <div class="title">{{$t('Price Type')}}</div>
        </q-card-section>
      </q-card>
      <q-card inline class="card">
        <q-card-section>
          <partstatus :data="product.partstatus" />
          <div class="title">{{$t('Price Status')}}</div>
        </q-card-section>
      </q-card>
  </div>
</div>
</template>

<script>
import attribute from '../../../components/attributesDisplay/attribute';
import pricing from '../../../components/attributesDisplay/pricing';
import partstatus from '../../../components/attributesDisplay/partstatus';
import pricestatus from '../../../components/attributesDisplay/pricestatus';
import collection from '../../../components/attributesDisplay/collection';

import localization from '../../../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization],
  props: ['product'],
  components: {
    attribute,
    pricing,
    partstatus,
    pricestatus,
    collection,
  },
  computed: {
    firstfamily() {
      if (!(this.product.families && this.product.families.v.length)) return [];
      return this.product.families.v[0];
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.bc {
  padding-left: .5rem;
  margin: 0;
  padding-right: .5rem;
}
.header {
  width 100%
  display: flex;
  align-items: stretch;
  padding-left: .4rem;
  margin: 0;
  padding-right: .4rem;

  .card {
    margin: 10px;
    position: relative;
    flex 1
    >>> .q-card__section {
      font-size: 1.5rem;
      text-align: center;
      height: 6rem;
      .title {
        position: absolute;
        bottom: 0;
        font-size: 1rem;
        text-align: left;
      }

      .minor {
        font-size: 0.8rem;
      }
    }
  }
}</style>
