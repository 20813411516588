<template>
  <div>
    <q-page-container>
      <q-page :style-fn="pagestyle">
        <finder ref="finder" :fetch="fetch">
          <q-item
            clickable
            slot="item"
            slot-scope="{ item, select }"
            @click="details(item, select)"
          >
            <q-item-section avatar><q-icon :name="item.id ? 'folder' : 'local_offer'"/></q-item-section>
            <q-item-section>{{localized(item.label)}}</q-item-section>

          </q-item>
          <q-btn
            slot="new"
            round
            size="lg"
            color="secondary"
            slot-scope="{ parent }"
            @click="create(parent)"
            icon="add"
            class="finder-fab"
           />
        </finder>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <tags-drawer :isnew="isnew" v-if="drawer" :item="selected" :close="close" :save="save"/>
    </q-drawer>
  </div>
</template>
<script>
import Finder from '../../../components/Finder2.vue';
import tagsDrawer from '../../../components/drawer/tags/tagsDrawer';
import localization from '../../../lib/mixins'; // eslint-disable-line

function deepclone(value) {
  if (value === undefined) return value;
  return JSON.parse(JSON.stringify(value));
}

export default {
  mixins: [localization],
  components: {
    Finder,
    tagsDrawer,
  },
  data() {
    return {
      locked: true,
      isnew: false,
      selected: false,
      locale: this.$store.state.auth.locale,
    };
  },
  computed: {
    drawer() {
      return this.selected !== false;
    },
    route() {
      return [this.$route.path, this.$route.params];
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.tagsetsp = this.$axios.get('/attributes', {
        params: {
          '@filters': JSON.stringify([
            { column: 'tag', op: 'equals', args: [true] },
          ]),
        },
      }).then((response) => {
        this.tagsets = response.data.data;
        this.tagsets.forEach((tagset) => {
          if (tagset.settings && tagset.settings.values && tagset.settings.values.length) {
            tagset.settings.values.forEach((v) => {
              v.parent = tagset.id;
            });
          }
        });
        return Promise.resolve(this.tagsets);
      });
    },
    save(from, to) {
      this.locked = true;
      if (this.isnew === 'tagset') {
        const set = Object.values(this.$store.state.pricing.sets.sets).find((s) => (s.name === 'other')).id;
        this.$axios.post('/attributes', {
          ...to,
          type: 'tag',
          tag: true,
          set,
        }).then((response) => {
          this.$refs.finder.refresh();
        });
      } else {
        const patch = {};
        if (from.parent || this.isnew) {
          patch.settings = deepclone(this.tagsets.find((tagset) => (tagset.id === (from.parent || this.isnew))).settings);
          if (!patch.settings) patch.settings = {};
          if (!patch.settings.values) patch.settings.values = [];
          const index = patch.settings.values.findIndex((v) => (v.name === to.name));
          if (index < 0) {
            patch.settings.values.push(to);
          } else {
            patch.settings.values.splice(index, 1, to);
          }
        } else {
          Object.assign(patch, to);
        }
        // save attribute
        this.$axios.patch(`/attributes/${from.parent || this.isnew || from.id}`, patch).then((response) => {
          this.$refs.finder.refresh(from.id ? undefined : from.parent || this.isnew);
        });
      }
    },
    close() {
      this.selected = false;
    },
    details(item, select) {
      select(!!item.id);
      this.isnew = false;
      this.selected = item;
    },
    create(parent) {
      this.isnew = parent || 'tagset';
      this.selected = { label: { } };
    },
    pagestyle(offset) {
      return { height: offset ? `calc(100vh - ${offset}px)` : '100vh' };
    },
    fetch(id, reload = false) {
      if (reload) this.load();
      if (id) {
        return this.tagsetsp.then((tagsets) => {
          const tagset = tagsets.find((t) => (t.id === id));
          if ((tagset === undefined)
              || (tagset.settings === undefined)
              || (tagset.settings.values === undefined)) { return Promise.resolve([]); }
          return Promise.resolve(tagset.settings.values);
        });
      }
      return this.tagsetsp;
    },
  },
};
</script>
<style lang="stylus" scoped>
.top {
  padding-top: 100px;
}
</style>
