<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>Move some parts</q-toolbar-title>
    </q-toolbar>
    <div class="drawer-content">
        <q-banner v-if="selected.length>0" rounded class="price-banner bg-positive text-white">
          <template v-slot:avatar>
            <q-icon name="check_circle_outline" color="white" />
          </template>
          {{selected.length}} {{$t('part(s) is/are going to be moved.')}}
        </q-banner>
        <q-banner v-else rounded class="price-banner bg-negative text-white">
          <template v-slot:avatar>
            <q-icon name="warning" color="white" />
          </template>
           {{$t('This action will be applied to all parts matching the current filter. Checkboxes are ignored so be extra careful with this action.')}}
        </q-banner>
      <q-input :label="$t('Validity date :')" mask="##/##/####" fill-mask class="full-width" v-model="activationdateinput" />
      <div class="q-field" style="position: relative;"><div class="q-field--float">
        <span class="q-field__label absolute ellipsis">{{$t('Target family :')}}</span>
        <Treeselect ref="treeselect" :placeholder="$t('Please make a selection')"
          zIndex="7000"  :appendToBody="true" v-model="value" :options="options" :load-options="loadOptions" class="lg-treeselect"/>
      </div></div>
    </div>
    <div class="lg-rule-actions">
      <q-btn :label="$t('Cancel')" color="primary" @click="closeDrawer"/>
&nbsp;
      <q-btn :disable="!value" :label="$t('Move')" color="secondary" @click="resegment" />
    </div>
  </div>
</template>
<script>
import { Treeselect, LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { localization } from '../../../lib/mixins';

export default {
  mixins: [localization],
  props: ['selected', 'data'],
  components: { Treeselect },
  data() {
    return {
      activationdate: this.$store.state.catalog.when,
      options: null,
      value: null,
    };
  },
  computed: {
    activationdateinput: {
      get() {
        return this.toLocaleDate(this.activationdate);
      },
      set(v) {
        this.activationdate = this.fromLocaleDate(v);
      },
    },
  },
  methods: {
    closeDrawer() {
      this.data.type = undefined;
    },
    resegment() {
      // need to patch the family
      const root = this.$refs.treeselect.selectedNodes[0].ancestors.map((a) => a.id).pop();
      (this.selected.length
        ? this.$axios.post('/products/move', {
          family: this.value,
          parts: this.selected.map((item) => item.id),
        })
        : this.$axios.post('/bulk/move', {
          filter: this.$store.state.catalog.filters,
          family: {
            id: this.value,
            when: this.activationdate,
          },
          when: this.$store.state.catalog.when,
        })
      ).then((response) => {
        this.$q.notify({ type: 'positive', message: this.$t('Part(s) got moved'), icon: 'done' });
        this.$emit('update');
      }).catch((error) => {
        this.$q.notify({ type: 'negative', message: this.$t('Something wrong happen trying to move the part(s)'), icon: 'warning' });
      });
    },
    loadOptions({ action, parentNode, callback }) {
      switch (action) {
        case LOAD_CHILDREN_OPTIONS:
        case LOAD_ROOT_OPTIONS:
          this.$axios
            .get(parentNode
              ? `/families/${parentNode.id}/children`
              : '/families', { params: { nolimit: 'yes', sort: 'label' } })
            .then((response) => {
              const options = response.data.data.map((f) => ({ id: f.id, children: null, label: this.localized(f.label) }));
              if (parentNode) {
                parentNode.children = options;
              } else {
                this.options = options;
              }
              callback();
            })
            .catch((error) => callback(error));
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-content {
  padding: .5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.lg-treeselect {
  padding-top: 2rem;
}
</style>
