<template>
  <div class="drawer-container">
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="arrow_back" @click="$router.go(-1)"/>
      <q-toolbar-title>
        {{$t('Select an Attribute')}}
        <span slot="subtitle">{{$t('It will be used as input to smooth price')}}</span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="incontainer" v-if="$store.state.pricing.rule.definition.attributes">
      <q-field @blur="$v.selected.$touch()"
        :error="$v.selected.$error" helper="Please select the attribute you want to use"
        :error-label="$t('An attribute is mandatory to go on.')">
        <q-option-group v-model="selected" :options="filter()" />
      </q-field>
    </div>
    <q-btn color="primary" :label="$t('select')" class="fixed ruleparams-fab" @click="addit"/>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import localization from '../../lib/mixins'; // eslint-disable-line
export default {
  mixins: [localization],
  data() {
    return {
      locale: this.$store.state.auth.locale,
      selected: '',
    };
  },
  validations: {
    selected: { required },
  },
  methods: {
    addit() {
      this.$v.selected.$touch();
      if (this.$v.selected.$error) {
        this.$q.notify({ message: 'Please make to select an attribute', icon: 'warning' });
      } else {
        let exist = false;
        if (!this.$store.state.pricing.rule.definition.smoothing) this.$store.state.pricing.rule.definition.smoothing = [];
        this.$store.state.pricing.rule.definition.smoothing.map((el) => {
          if (el.attribute === this.selected) {
            exist = true;
          }
          return el;
        });
        if (exist) {
          this.$q.notify({ message: 'A smoothing function already exist for this attribute', icon: 'warning' });
          return;
        }

        const option = { attrName: this.selected, succes: false };
        this.$store.commit('pricing/addSmoothingAttribute', option);
        if (option.succes === true) {
          this.$q.notify({ type: 'positive', message: 'Attribute sucessfully added', icon: 'done' });
          this.$router.push({ name: 'refine' });
        } else {
          this.$q.notify({ type: 'negative', message: `Currents parts have no attribute ${this.selected.toUpperCase()} or min value = max value`, icon: 'error' });
        }
      }
    },
    filter() {
      const { defs2 } = this.$store.state.pricing.defs;
      return Object.entries(defs2).filter(([, attr]) => {
        // if (attr.computed) return false; // FIX ME : computed vs price computed ?
        switch (attr.type) {
          case 'number':
          case 'amount':
            return true;
          default:
        }
        return false;
      }).map(([name, attr]) => ({ label: this.localized(attr.label), value: name }));
    },
  },
};
</script>
<style lang="stylus">
.drawer-container {
  div.incontainer {
    padding 1rem
    height calc(100vh - 100px)
    overflow auto
  }
}
</style>
