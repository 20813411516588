<template>
  <!-- eslint-disable max-len -->
  <div>
    <Template />

        <q-select
          label="Default Currency"
          v-model="$store.state.importExport.step3.currencies.value"
          placeholder="Select"
          :options="$store.state.importExport.step3.currencies.data"
          @input="checkTemplate"
        />

        <q-select v-if="$store.state.importExport.step1.file.uploaded.type === 'text/csv'"
          label="Locale"
          v-model="$store.state.importExport.step3.locales.value"
          placeholder="Select"
          :options="$store.state.importExport.step3.locales.data"
          @input="checkTemplate"
        />

        <q-input
          v-model="tagFilter.value"
          type="text"
          label="Tag filter"
          @input="updateTagFilter"
        />
      <draggable v-model="step3.filtredAttributesList" group="attributes">
        <q-chip
          v-for="element in step3.filtredAttributesList"
          :key="element.id"
        >
        <q-avatar :icon="getColor(element.type).icon"
          :color="getColor(element.type).color" text-color="white"/>
          {{localized(element.label)}}

        </q-chip>
      </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { localization } from '../../../lib/mixins';
import Template from './template/template';

export default {
  components: { draggable, Template },
  mixins: [localization],
  data() {
    return {};
  },
  computed: {
    template: {
      get() {
        return this.$store.state.importExport.template;
      },
      set(value) {
        this.$store.commit('importExport/setTemplate', value);
      },
    },
    step3: {
      // remove it
      get() {
        return this.$store.state.importExport.step3;
      },
      set(data) {
        this.$store.commit('importExport/setStep3', data);
      },
    },
    tagFilter: {
      get() {
        return this.$store.state.importExport.step3.tagFilter;
      },
      set(value) {
        this.$store.commit('importExport/updateTagFilter', {
          value,
          localized: (a) => this.localized(a),
        });
      },
    },
  },
  methods: {
    getColor(type) {
      const map = {
        collection: { icon: 'local_offer', color: 'blue-6' },
        number: { icon: 'calculate', color: 'green-6' },
        string: { icon: 'subject', color: 'orange-6' },
        localizedstring: { icon: 'flag', color: 'orange-6' },
        amount: { icon: 'attach_money', color: 'brown-6' },
        pricing: { icon: 'attach_money', color: 'indigo-6' },
        boolean: { icon: 'check', color: 'teal-6' },
        interval: { icon: 'date_range', color: 'pink-6' },
        segmentation: { icon: 'account_tree', color: 'indigo-6' },
        documentset: { icon: 'file_copy', color: 'yellow-6' },
      };
      return map[type] || { color: 'secondary' };
    },
    checkTemplate() {
      this.$store.commit('importExport/checkTemplate');
    },
    updateTagFilter(val) {
      this.tagFilter = val;
      this.checkTemplate();
    },
  },
  created() {
    // fix bug when we go back
    this.$store.dispatch('importExport/setAttributes');
  },
};
</script>

<style lang="stylus" scoped>
.chip-step3 {
  margin: 5px;
  cursor: pointer;
}

.input-btn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.input-btn .q-field {
  width: 100%;
}

.input-btn-sp {
  padding-bottom: 4px;
}

.header {
  padding: 24px;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

</style>
