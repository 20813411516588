<template>
  <div>
    <q-page-container>
      <q-page :style-fn="pagestyle">
        <div class="content">
          <DecisionGrid v-if="loaded" :tagsets="tagsets" :grid="grid">
            <template slot="resultContent" slot-scope="{ lineIndex }">
              <q-field>
                <q-select
                  :float-label="$route.params.name === 'segmentation' ? 'Segmentation' : 'Pricing method'"
                  v-model="grid[lineIndex].decision"
                  :options="methods"
                />
              </q-field>
            </template>
          </DecisionGrid>
          <div class="q-pa-sm text-right">
            <q-btn icon="save" label="Save" color="primary" @click="save()"/>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>
<script>
import DecisionGrid from '../../../components/grid/DecisionGrid.vue';

export default {
  components: {
    DecisionGrid,
  },
  data() {
    return {
      loaded: false,
      locale: this.$store.state.auth.locale,
      tagsets: [],
      methods: [],
      grid: [],
    };
  },
  watch: {
    '$route.params.name': function switchdata(name) {
      this.getData();
    },
  },
  methods: {
    pagestyle(offset) {
      return {
        height: offset ? `calc(100vh - ${offset}px)` : '100vh',
        overflow: 'auto',
      };
    },
    getData() {
      this.$axios.get(`/decisiongrids/${this.$route.params.name}`).then((grid) => {
        this.grid = grid.data.data.grid;
      }).catch((error) => {
        this.grid = [];
      }).finally(() => {
        // this.$axios.get('/tagsets').then((response) => {
        this.$axios.get('/attributes', {
          params: {
            '@filters': JSON.stringify([
              { column: 'tag', op: 'equals', args: [true] },
            ]),
          },
        }).then((response) => {
          this.tagsets = this.$route.params.name === 'segmentation'
            ? response.data.data
            : response.data.data.filter((item) => (item.name !== 'pricingmethod'));
          this.tagsets.forEach((tagset) => {
            tagset.tags = tagset.settings.values;
          });
          /*
            return Promise.all(this.tagsets.map(tagset => this.$axios.get(`/tagsets/${tagset.id}/tags`)));
        }).then((tags) => {
          tags.forEach((t, i) => {
            this.tagsets[i].tags = t.data.data;
          });
          */
          return this.$route.params.name === 'segmentation'
            ? this.$axios.get('/families')
            : Promise.resolve({
              data: {
                data: response.data.data.find((item) => (item.name === 'pricingmethod')).settings.values,
              },
            });
        }).then((methods) => {
          this.methods = methods.data.data.map((m) => ({ label: m.label[this.locale], value: m.name }));
          this.loaded = true;
        });
      });
    },
    save() {
      this.$axios.patch(`/decisiongrids/${this.$route.params.name}`, {
        grid: this.grid,
      }).then(() => {
        this.$q.notify({ icon: 'done', color: 'positive', message: 'Decision Grid saved' });
      }).catch((error) => {
        this.$q.notify({ icon: 'cancel', color: 'negative', message: 'Decision Grid saved' });
      });
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.commit('header/setHeader', {
      title: 'Pricing Method Selection',
      subtitle: 'Decision Grid',
    });
  },
};
</script>
<style scoped lang="stylus">

.addLine {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.topTitle {
  display: block;
  padding: 30px;
  font-size: 2rem;
  opacity: 0.5;
}

.content {
  width: 100%;
}

.error-content {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header {
  min-width: 400px;

  .title {
    display: flex;
  }
}

.icon {
  cursor: pointer;
}

.cell {
  display: flex;

  .operatorSelect {
    width: 100px;
  }

  .tagSelect {
    min-width: 300px;
    width: 100%;
  }
}
</style>
