/* eslint-disable */

import Vue from "vue";

const deepClone = value => JSON.parse(JSON.stringify(value));

export const setCatalog = (state, catalogData) => {
  state.data = catalogData;
};

export const setMaxPages = (state, data) => {
  if (data) {
    state.pages.max = data;
  }
};

export const toggleDrawer = (state) => {
  state.drawer.model = !state.drawer.model
};

export const setContentDrawer = (state, content) => {
  if (content.type) {
    state.drawer.content.type = content.type
  }
  if (content.data) {
    state.drawer.content.data = content.data
  }
};

export const setDrawerColumns = (state, defs) => {
  const attributes = Object.values(defs);
  state.drawer.column.options = attributes
    .filter(val => {
      return !state.data.meta.columns.find(subVal => subVal === val.name);
    })
    .map(val => {
      return {
        value: val.name,
        label: val.name
      };
    });
};

export const addColumn = (state, column) => {
  state.data.meta.columns.push(column);
};

export const dropColumn = (state, column) => {
  state.data.meta.columns = state.data.meta.columns.filter(val => {
    return val !== column;
  });
};

export const resetFilters = (state, name) => {
  if (name === undefined) {
    state.filters = [];
    return;
  }
  const idx = state.filters.findIndex((f) => (f.name === name));
  if (idx < 0) return;
  state.filters.splice(idx,1);
};

export const setWhen = (state, value) => {
  state.when = value;
};

export const setFilter = (state, { name, value }) => {
  const i = state.filters.findIndex((f) => (f.name === name));
  if (i < 0) return state.filters.push({ name, value });
  if (Array.isArray(value) && value.length === 0) {
    // we do remove that option.
    state.filters.splice(i,1);
  } else {
    Vue.set(state.filters[i], 'value', value);
  }
};
export const setFilters = (state, filters) => {
  state.filters = [];
  filters.forEach((f) => setFilter(state, f));
}

export const setTrim = (state, { name, value }) => {
  state.trim[name] = !!value;
};

export const setFilterOpt = (state, { name, value }) => {
  state.filteropts[name] = value;
};