<template>
<div>
<q-toolbar  class="bg-primary shadow2 text-white">
  <q-btn flat round dense icon="cancel" @click="goback"/>
  <q-toolbar-title>
    {{$t('Display Preferences')}}
  </q-toolbar-title>
</q-toolbar>
<div class="drawer-container">
  <div class="drawerPadding" v-if="this.$store.state.pricing.sets && this.$store.state.pricing.sets && $store.state.general.settings && $store.state.general.settings.attrs">

    <span class="title">{{`${$t("Rule attributes")} (${searchOptions.length})` }}</span>
    <q-select
      class="selectField"
      filled
      v-model="selectedModel"
      use-input
      hide-selected
      fill-input
      input-debounce="0"
      :options="options"
      @filter="filterFn"
      :hint="$t('This is an autocompleted field')"
      :label="$t('Choose an attribute to add')"
      >
    </q-select>
    <q-btn color="primary" :label="$t('Add')" @click="addAttr()" class="addButton"/>

    <!-- <div v-for="(def, i) in this.$store.state.pricing.defs.defs2" :key="'attr'+i" class="attributeElement">
      {{ def.name }}
      {{ def.primary }}
    </div> -->
    <!-- <div v-for="(def, i) in this.$store.state.pricing.rule.definition.pivot" :key="'attr'+i" class="attributeElement">
      {{ def.name }}
    </div> -->

    <span class="title">{{`${$t("Actual attributes")} (${tableData.length})` }}</span>
    <!-- <q-table class="attributeTable" :data="tableData" :columns="columns" row-key="name" virtual-scroll :rows-per-page-options="[0]" hide-header hide-bottom/> -->

    <div class="attributeList">
      <div v-for="(attr, i) in tableData" :key="'attr'+i" class="attributeElement">
        {{ attr.name }}
        <button @click="removeAttr(attr.value)" style="float:right;" v-if="!attr.fixed">X</button>
      </div>
    </div>

  </div>
</div>
</div>
</template>
<script>
import { localization } from '../../lib/mixins';
import { rulemixins } from './mixins';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      columns: [{
        name: 'name', required: true, label: 'Name', align: 'left', sortable: true, field: (row) => row.name,
      }],
      options: this.searchOptions,
      selectedModel: null,
    };
  },
  computed: {
    tableData() {
      let tmp = [...new Set([...this.$store.state.pricing.rule.definition.show, ...(this.$store.state.pricing.rule.definition.attributes || []).map((a) => a.name)])];
      tmp = tmp.map((item) => ({ name: this.localized(this.$store.state.pricing.defs.defs2[item].label), value: item, fixed: this.$store.state.pricing.rule.definition.attributes.find((a) => a.name === item) }));
      return tmp.sort((a, b) => (a.name < b.name));
    },

    searchOptions() {
      return Object.values(this.$store.state.pricing.defs.defs2).reduce((_, d) => {
        _.push({ value: d.name, label: this.localized(d.label) });
        return _;
      }, []).sort((a, b) => {
        const al = (a.label || '').trim().toLowerCase();
        const bl = (b.label || '').trim().toLowerCase();
        return (al > bl) - (al < bl);
      });
    },

  },
  methods: {
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.options = this.searchOptions.filter((v) => (v.label.toLowerCase().indexOf(needle) > -1));
      });
    },
    addAttr() {
      this.$store.commit('pricing/addShow', { toAdd: this.selectedModel.value, locale: this.$store.state.auth.locale, settings: this.$store.state.general.settings });
      this.selectedModel = null;
    },
    removeAttr(toRemove) {
      this.$store.commit('pricing/removeShow', { toRemove, locale: this.$store.state.auth.locale, settings: this.$store.state.general.settings });
    },
  },
};
</script>

<style lang="stylus" scoped>
.drawerPadding {
  padding: 20px;
}
.title{
  font-size: 1.2em;
}
.selectField {
  width: 75%;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 40px;
}
.addButton {
  display: inline-block;
  margin-left: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: -16px;
  width: 20%;
}
.attributeTable {
  height: calc(100vh - 460px);
  margin-top: 10px;
}

.drawer-container{
  //border:1px solid blue;
  max-height: calc(100% - 50px);
  height : 100%;
  // overflow: hidden;
}
.drawerPadding {
  // border: 1px solid red;
  height: 100%;
  //max-height: calc(100% - 50px);
  //overflow: hidden;
}

.attributeList {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.attributeElement {
  //border-bottom: 1px solid grey;
  padding: 10px;
  display: flex;
  margin-bottom:5px;
  margin-right: 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F2F2F2;
  border-radius: 5px;
}
</style>
