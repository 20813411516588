<template>
  <div class="content">{{data && data.v ? data.v : ''}}</div>
</template>

<script>
export default {
  props: ['data'],
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
