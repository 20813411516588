/*
export function someAction (context) {
}
*/
/* eslint-disable */

// export const sortCatalog = (state, by) => {
//   // state.data.data = state.data.data.sort((a, b) => {
//   //   return a.name.value < b.name.value ? -1 : 1;
//   // });
// };
