<template>
  <!-- eslint-disable max-len -->
  <div v-if="stop" class="error-container">
    <q-banner class="bg-negative text-white">
      <template v-slot:avatar>
        <q-icon name="warning" color="white" />
      </template>
      {{$t('Either the family of the part or the appropriate rule could not be found. Pricing is not available.')}}
    </q-banner>
  </div>
  <q-splitter v-else id="lg-pricing-spitter" reverse v-model="splitter" unit="px">
    <template v-slot:before>
      <q-page-container>

        <q-page v-if="!($store.state.pricing.rule && fullload && $store.state.general.settings)">{{$t('Loading...')}}</q-page>
        <q-page v-else>

          <q-banner inline-actions class="bg-orange text-white lg-comment-banner" v-if="mustshowcomment && (!$store.state.pricing.graphmode || $store.state.pricing.single)">
            <template v-slot:avatar>
              <q-icon name="warning" color="white" />
            </template>
            <div v-html="$store.state.pricing.rule.comment"></div>
            <template v-slot:action><div class="lg-pricing-alert-actions">
                <q-btn flat icon="close" round  @click="() => { showcomment = false; }" />
            </div></template>
          </q-banner>

          <!-- Chart component -->
          <PricingChart></PricingChart>

          <div v-if="$store.state.pricing.partsPage.length && (!$store.state.pricing.graphmode || $store.state.pricing.single)" class="bg-primary-lightest" :class="{'lg-commented-grid': mustshowcomment, 'lg-single-grid': $store.state.pricing.single }" id="grid-container" ref="actualgrid">
            <div class="grid">
                <div class="grid-col col-first">
                  <div class="grid-cell shadow-2 cell-first">
                    <ul>
                        <li class="photo-cell">
                          <div v-if="!$store.state.pricing.single" class="overview shadow-2 bg-secondary text-white">
                            <div class="text-negative overview-purchasealert" :title="$t('Purchase/Cost alert')">{{$store.state.pricing.stats.purchasealerts}} <q-icon name="error_outline"/></div>
                            <div class="overview-turnover-margin animated" @click="weighted=!weighted">
                              <div class="overview-turnover" :title="$t('Change in turnover')">
                                <q-icon
                                :name="updown($store.state.pricing.stats.turnover - $store.state.pricing.actualturnover)"/>
                                <div >{{friendlyunit($store.state.pricing.stats.turnover - $store.state.pricing.actualturnover)}} {{$store.state.pricing.rule.currency}}</div>
                                <div>({{(($store.state.pricing.stats.turnover - $store.state.pricing.actualturnover) / $store.state.pricing.actualturnover * 100).toFixed(2)}} %)</div>
                              </div>
                              <div class="overview-margin-validated" :title="$t('Current margin')">
                                <q-icon size="large" name="scale" v-if="weighted"/>
                                <div >{{weighted?instancecomputeweightedmargin($store.state.pricing.actualturnover, $store.state.pricing.stats.purchase):instancecomputestdmargin()}} %</div>
                                <q-icon  size="large" name="scale" v-if="weighted"/>
                              </div>
                              <div v-if="weighted" class="overview-margin" :title="$t('Expected margin')">
                                <q-icon
                                :name="updown((instancecomputeweightedmargin($store.state.pricing.stats.turnover, $store.state.pricing.stats.purchase) - instancecomputeweightedmargin($store.state.pricing.actualturnover, $store.state.pricing.stats.purchase)) / instancecomputeweightedmargin($store.state.pricing.actualturnover, $store.state.pricing.stats.purchase) * 100)"/>
                                <div >{{instancecomputeweightedmargin($store.state.pricing.stats.turnover, $store.state.pricing.stats.purchase)}} %</div>
                                <div>({{((instancecomputeweightedmargin($store.state.pricing.stats.turnover, $store.state.pricing.stats.purchase) - instancecomputeweightedmargin($store.state.pricing.actualturnover, $store.state.pricing.stats.purchase)) / instancecomputeweightedmargin($store.state.pricing.actualturnover, $store.state.pricing.stats.purchase)*100).toFixed(2)}} %)</div>
                              </div>
                              <div v-else class="overview-margin" :title="$t('Expected margin')">
                                <q-icon
                                :name="updown((instancecomputestdmargin(false) - instancecomputestdmargin()) / instancecomputestdmargin() * 100)"/>
                                <div >{{instancecomputestdmargin(false)}} %</div>
                                <div>({{((instancecomputestdmargin(false) - instancecomputestdmargin()) / instancecomputestdmargin() * 100).toFixed(2)}} %)</div>
                              </div>
                            </div>
                            <div class="overview-growing" :title="$t('Parts which price is up')">{{$store.state.pricing.stats.variations[2]}}<q-icon name="north_east"></q-icon></div>
                            <div class="overview-numberofparts" :title="$t('Number of parts in family')"><strong>{{getPartsLength()}}</strong></div>
                            <div class="overview-same" :title="$t('Parts which price remains the same')">{{$store.state.pricing.stats.variations[1]}}<q-icon name="east"></q-icon></div>
                            <div class="overview-anomalies text-negative" :title="$t('Parts which proposed price is negative')">{{$store.state.pricing.stats.negativeprices}}<q-icon name="ac_unit"/></div>
                            <div class="overview-shrinking" :title="$t('Parts which price is down')">{{$store.state.pricing.stats.variations[0]}}<q-icon name="south_east"></q-icon></div>
                          </div>
                        </li>
                        <li v-for="(group, index) in $store.state.pricing.rule.attrslayout[0]" :key="index" class="attr-set" >

                          <div class="attrset-first">
                            <div class="lg-attr-group" @click="toggleGroup(0, index)">{{localized(group.label)}}</div>
                          </div>
                          <div class="attrset-list">
                            <ul :class="[`group-${group.id}`, {collapsed: $store.state.pricing.rule.attrslayout[0][index].closed}]">
                              <li v-for="(attr, index2) in group.attrs" :key="index2" class="cell-item">
                               <!-- :class="{'double-line': attr.name === $store.state.pricing.rule.definition.common.priceattr}" -->
                                <div style="display: flex; flex-wrap: no-wrap; justify-content: space-between; ">
                                  <div class="ellipsisAttr">
                                    {{localized(attr.label)}}
                                    <q-tooltip>
                                      {{localized(attr.label)}}
                                    </q-tooltip>
                                  </div>
                                  <q-btn size="xs" dense round flat :color="getColor(attr.name)"
                                  @click="sort(attr)" icon="sort"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="cell-item validated-price">
                          <div style="display: flex; flex-wrap: no-wrap; align-items: stretch; justify-content: space-between; line-height: 48px; padding-left: 4px; ">
                            <div>{{$t('New Prices')}}</div>
                            <div>
                              <q-btn-group v-if="!$store.state.pricing.single" dense class="groupedactions bg-primary">
                                <q-btn :title="$t('Approve new prices')" @click="approve('new')" round size="sm" dense text-color="white" icon="fiber_new" />
                                <q-btn :title="$t('Approve increases')" @click="approve('up')" round size="sm" dense text-color="white" icon="arrow_upward" />
                                <q-btn :title="$t('Approve decreases')" @click="approve('down')" round size="sm" dense text-color="white" icon="arrow_downward" />
                                <q-btn :title="$t('Clear all')" @click="approve('none')" round size="sm" dense text-color="white" icon="cancel" />
                                <q-btn :title="$t('Approve everything')" @click="approve('all')" round size="sm" dense color="secondary" icon="check" />
                            </q-btn-group>
                            </div>
                          </div>
                        </li>
                    </ul>
                  </div>
                  <div class="grid-cell">
                    <ul>
                      <li v-for="(group, index) in $store.state.pricing.rule.attrslayout[1]" :key="index" class="attr-set">
                        <div class="attrset-first">
                          <div class="lg-attr-group bg-primary-lightest" @click="toggleGroup(1, index)">
                            {{localized(group.label)}}
                          </div>
                        </div>
                        <div class="attrset-list">
                          <ul :class="[`group-${group.id}`, {collapsed: $store.state.pricing.rule.attrslayout[1][index].closed}]">
                            <li v-for="(attr, index) in group.attrs" :key="index" class="cell-item" >
                              <!--:class="{'double-line': attr.name === $store.state.pricing.rule.definition.common.priceattr}"-->
                              <div style="display: flex; flex-wrap: no-wrap; justify-content: space-between; ">
                                <div class="ellipsisAttr">
                                  {{localized(attr.label)}}
                                  <q-tooltip>
                                    {{localized(attr.label)}}
                                  </q-tooltip>
                                </div>
                                <q-btn size="xs" dense round flat  :color="getColor(attr.name)" @click="sort(attr)" icon="sort"/>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-for="(item, index) in $store.state.pricing.partsPage" :key="index" :class="{'grid-col': true, 'nonpriced': isNonPriced(item.name.v), 'subnom': isSubNom(item.name.v), 'slavepart': isSlave(item.name.v), 'active-search': isResult(item) }" >
                  <div v-for="(bloc, idx) in $store.state.pricing.rule.attrslayout /*item.blocs*/" :key="idx" class="grid-cell" :class="{'cell-first': !idx, 'shadow-2': !idx, 'comparable': index && $store.state.pricing.single }">
                    <ul>
                        <li v-if="!idx" class="photo-cell" :class="item[variationcss]">
                            <template v-if="$store.state.general.settings.transparentproxypicture">
                              <img loading="lazy" :src="mkproxypath(item)" height="96" width="128" alt="Image 0">
                              <div class="lg-photos-dots">
                                  <div class="lg-photos-dot shadow-2">
                                      <q-tooltip>
                                          <img loading="lazy" :src="mkproxypath(item)" alt="Image 0">
                                      </q-tooltip>
                                  </div>
                              </div>
                            </template>
                            <template v-else>
                              <img loading="lazy" v-if="item.pictures && item.pictures.v && item.pictures.v.length" :src="mkpath(item.pictures.v[0])" height="96" width="128" alt="Image 0">
                              <img v-else height="96" width="128" src="/default.png">

                              <div class="lg-photos-dots" v-if="item.pictures && item.pictures.v && item.pictures.v.length">
                                  <div class="lg-photos-dot  shadow-2" v-for="(path, index) in item.pictures.v" :key="index">
                                      <q-tooltip>
                                          <img loading="lazy" :src="mkpath(path)" :alt="`Image ${index}`">
                                      </q-tooltip>
                                  </div>
                              </div>
                            </template>
                            <div class="infozone">
                                <q-icon v-if="item.comment && item.comment.v" name="info" @click="go('comment', { pid: item.id })" />
                            </div>
                        </li>

                        <li v-for="(group, index2) in bloc" :key="index2" class="attr-set" >
                            <div class="attrset-list">
                                <ul :class="[`group-${group.id}`, {collapsed: $store.state.pricing.rule.attrslayout[idx][index2]? $store.state.pricing.rule.attrslayout[idx][index2].closed: false}]" >

                                    <li v-for="(attr, name) in /*item.blocs[idx].groups[index2]*/group.attrs" :key="name" class="cell-item" >

                                        <div v-if="attr.name === 'proposedprice'" class="proposed-price" style="position: relative; display:block; float: left; margin-right: 5px; margin-top: -2px;">
                                          <q-btn v-if="!$store.state.pricing.single || (index === 0)" @click="movedown(item, index, attr.name)" icon="arrow_downward" color="secondary" dense round size="xs" />
                                        </div>

                                        <div v-if="(!$store.state.pricing.single || (index === 0)) && inRule(attr.name) && !inPivot(attr.name) && !$store.state.pricing.defs.defs2[attr.name].computed" class="cell-field">
                                          <input v-if="item.blocs[idx].groups[index2][attr.name] && item.blocs[idx].groups[index2][attr.name].type === 'number'" type="number" :value="item.blocs[idx].groups[index2][attr.name].v.number"
                                            @change="event => updateAttr(item, attr.name, event.target.value, index, item.blocs[idx].groups[index2][attr.name].v.unit)" class="lg-input-attribute"/>
                                          <span v-if="item.blocs[idx].groups[index2][attr.name] && item.blocs[idx].groups[index2][attr.name].type === 'boolean'"
                                            @click="updateAttr(item, attr.name, !item.blocs[idx].groups[index2][attr.name].v, index)" class="material-icons">{{item.blocs[idx].groups[index2][attr.name].v ?'radio_button_checked':'radio_button_unchecked'}}</span>
                                        </div>

                                        <!-- HERE -->
                                        <div v-else-if="item.blocs[idx].groups[index2][attr.name].id">
                                          <span style="cursor: pointer;" @click="go('attributehistory', { pid: item.id,attr: attr.name })" v-html="item.blocs[idx].groups[index2][attr.name].html"></span>
                                        </div>

                                        <div v-else-if="item.blocs[idx].groups[index2][attr.name].color" v-html="item.blocs[idx].groups[index2][attr.name].html" v-bind:style="{ backgroundColor: item.blocs[idx].groups[index2][attr.name].color, color : '#FFFFFF', cursor: 'pointer'}"
                                        @click="sortGroupable(item.nridx, attr.name, item.blocs[idx].groups[index2][attr.name].v )"/>

                                        <div v-else v-html="item.blocs[idx].groups[index2][attr.name].html"/>

                                        <template v-if="attr.name === 'name' && !$store.state.pricing.single && !($store.state.general.settings.ux && $store.state.general.settings.ux.nofamilysegmentation)">
                                          <input class="lg-part-segment" v-if="$store.state.pricing.modeMultipleSelect" type="checkbox" v-model="$store.state.pricing.selectedParts[item.id]" @click="/*console.log($store.state.pricing.selectedParts)*/" style="margin-right:5px"/>
                                          <q-btn v-else @click="$store.state.pricing.modeMultipleSelect = true; $store.state.pricing.selectedParts[item.id] = true; go('resegment', { pid: item.id })" flat round dense size="xs" color="primary" icon="account_tree" class="lg-part-segment"/>
                                        </template>

                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li v-if="!idx" class="validated-price">
                            <q-input v-if="(!($store.state.pricing.single || isSlave(item.name.v))) || ((index === 0) && $store.state.pricing.single)" filled square dense  align="right"
                                type="number" step="0.01"
                                :value="item.validatedprice.v.fixed"
                                @input="val => updateAttr(item, 'validatedprice', val, index, item.validatedprice.v.unit)"
                                :suffix="item.validatedprice.v.unit"
                            />
                        </li>
                    </ul>
                  </div>

              </div>

            </div>
          </div>

          <div class="lg-pagination">
            <div v-if="$store.state.pricing.progressmessage" class="lg-pagination-status">
              <q-spinner-hourglass color="primary" size="2em"/> {{$t($store.state.pricing.progressmessage)}}
            </div>
            <div class="lg-actual-pagination">
              <q-pagination
                v-if="!$store.state.pricing.single &&!$store.state.pricing.graphmode"
                @input="pagechange"
                :value="page"
                :max="pages"
                :direction-links="true"
                :boundary-links="true"
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
              >
              </q-pagination>
            </div>

            <div class="lg-time-travel" v-if="$store.state.pricing.searchFilter && $store.state.pricing.searchFilter != ''" style="display:flex; flex-direction: row; padding:0 20px 0 10px;">
              <q-btn flat round icon="search" color="secondary" style="margin:0;" @click="$store.state.pricing.searchFilter = ''; $store.state.pricing.alreadyrendered=true; renderGrid();"></q-btn>
              <p style="margin:auto 0;">{{ $store.state.pricing.searchFilter }}</p>
            </div>

            <div class="lg-time-travel" v-if="$store.state.pricing.searchGoTo && $store.state.pricing.searchGoTo != ''" style="display:flex; flex-direction: row; padding:0 20px 0 10px;">
              <q-btn flat round icon="link" color="secondary" style="margin:0;" @click="$store.state.pricing.searchGoTo = ''; $store.state.pricing.alreadyrendered=true; renderGrid();"></q-btn>
              <p style="margin:auto 0;">{{ $store.state.pricing.searchGoTo }}</p>
            </div>

            <div class="lg-time-travel" v-if="$store.state.pricing.partsFilter && $store.state.pricing.partsFilter.mode != 'disabled'" style="display:flex; flex-direction: row;  padding:0 20px 0 10px;">
              <q-btn flat round  icon="table_view" color="secondary" style="margin:0;" @click="$store.state.pricing.partsFilter = { groupable: {}, mode: 'disabled' }; $store.state.pricing.alreadyrendered=true; renderGrid();"></q-btn>
              <!-- {{$t('Mode')+':'+ $store.state.pricing.partsFilter.mode +' '+ $t('Value')+':'+ $store.state.pricing.partsFilter.groupable.v }} -->
              <p style="margin:auto 0;">{{$store.state.pricing.partsFilter.groupable.v }}</p>
            </div>

            <div class="lg-time-travel" v-if="$store.state.general.settings.pricesetselector">
                <q-select filled square dense :label="$t('Price set')" emit-value map-options v-model="priceset" :options="pricesets"></q-select>
            </div>
            <div class="lg-time-travel">
              <q-input debounce="1000" v-model="currenttime" :label="$t('Time cursor')" value="02/02/2020" filled square dense mask="##/##/####">
                <template v-slot:before>
                  <q-btn :title="$t('Click to sync with current rule. Button is red if current time does not match rule validity')" icon="event" flat round :color="inruleintervalle ? 'primary' : 'negative'"  @click="syncwithrule"/>
                </template>
              </q-input>
            </div>
          </div>

        </q-page>

      </q-page-container>
    </template>
    <template v-slot:after>
      <div class="drawerbar">
        <div>
          <q-btn flat round :title="$t('History')" icon="money" color="secondary" v-if="$route.name === 'attributehistory'"/>

          <q-btn flat round :title="$t('Comment')" icon="info" color="secondary" v-if="$route.name === 'comment'"/>
          <q-btn flat round :title="$t('Re-segment')" icon="account_tree" color="secondary" v-if="$route.name === 'resegment' && !$store.state.pricing.single"/>
          <q-btn flat round v-else-if="$store.state.pricing.single" title="Re-segment" icon="account_tree"
            @click="go('resegment', { pid: $store.state.pricing.partsPage[0].id })"
             :color="drawer === 'resegment' ? 'secondary' : 'primary-light'"
             />
          <q-separator v-if="['resegment','attributehistory','comment'].includes($route.name)"/>
          <q-btn flat round  title="Rule definition" @click="go('ruleparams')" icon="tune"
            :color="drawer === 'ruleparams' ? 'secondary' : 'primary-light'">
          </q-btn>
          <q-btn flat round title="$t('Implementation Strategies')" @click="go('progressive')" icon="rotate_right"
            :color="drawer === 'progressive' ? 'secondary' : 'primary-light'"/>
          <q-btn flat round :title="$t('Cost+')" @click="go('costpluspath')"
            :color="drawer === 'costpluspath' ? 'secondary' : 'primary-light'"
            icon="expand" v-if="$store.state.pricing.single && $store.state.pricing.rule.method === 'cost+'"/>

          <q-btn flat round :title="$t('Charts')" v-if="!$store.state.pricing.single" @click="go('charts')" icon="bar_chart"  :color="drawer === 'charts' ? 'secondary' : 'primary-light'"></q-btn>
          <q-separator/>
          <q-btn flat round :title="$t('Preferences')" icon="settings" :color="drawer === 'rulepreferences' ? 'secondary' : 'primary-light'"  @click="go('rulepreferences')"></q-btn>
          <q-btn flat round :title="$t('Display Preferences')" v-if="!($store.state.pricing.single && familybased)" @click="go('displaypreferences')"  icon="colorize"  :color="drawer === 'displaypreferences' ? 'secondary' : 'primary-light'"></q-btn>
          <q-separator/>
          <q-btn flat round :title="$t('Load rules')" v-if="!($store.state.pricing.single && familybased)"  icon="folder_open" @click="go('loadrule')" :color="drawer === 'loadrule' ? 'secondary' : 'primary-light'"></q-btn>
          <q-btn flat round :title="$t('Exports')" v-if="!$store.state.pricing.single" icon="upload_file" @click="go('exportsrule')" :color="drawer === 'exportsrule' ? 'secondary' : 'primary-light'"></q-btn>
          <q-separator/>
          <q-btn flat round :title="$t('Comment')" @click="go('rulecomment')" icon="comment" :color="drawer === 'rulecomment' ? 'secondary' : 'primary-light'"></q-btn>
          <q-btn flat round :title="$t('Prices validation')" :disable="!canEdit" @click="go('rulesaveprices')" icon="save" :color="drawer === 'rulesaveprices' ? 'secondary' : 'primary-light'"><div class="savetip">P</div><div v-if="thingstosave" class="thingstosave text-warning">⬤</div></q-btn>
          <q-btn flat round :title="$t('Save rule')" v-if="!($store.state.pricing.single && familybased)" :disable="!canEditRule"  @click="go('rulesave')" icon="save" :color="drawer === 'rulesave' ? 'secondary' : 'primary-light'"><div class="savetip">R</div></q-btn>
          <q-btn flat round :title="$t('Search')" @click="go('searchref')" icon="search" :color="drawer === 'searchref' ? 'secondary' : 'primary-light'"></q-btn>
          <q-btn flat round :title="$t('Set concistency examples')" @click="go('setConsistency')" icon="manage_accounts" :color="drawer === 'setConsistency' ? 'secondary' : 'primary-light'"></q-btn>
          <q-separator v-if="$store.state.pricing.single && familybased"/>
          <q-btn flat round :title="$t('Go to the rule')" v-if="$store.state.pricing.single && familybased && canEditRule"  @click="openrule" icon="forward" color="primary"><div class="savetip">R</div></q-btn>

        </div>
        <router-view @searchTrigerEvent="searchTrigerEvent"/>
      </div>
    </template>
  </q-splitter>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';

import { can } from '@cabinetmariel/scopes';
import PricingChart from './PricingChart.vue';
import localization from '../../lib/mixins'; // eslint-disable-line

import { activefamily } from '../../lib/decisiongrids';
import putils from '../../lib/pictures';

import { store } from '../../lib/nrstore';

const { utils, pricinglib } = pricingUtilities;
const { methods } = pricingMethods;
const { getattrValue, getAttrSet } = utils;
const { computestdmargin, computeweightedmargin } = pricinglib;

export default {
  mixins: [localization],
  data() {
    return {
      fullload: false,
      page: 1,
      pagesize: 100,
      drawerWidth: 362,
      showcomment: true,
      locale: this.$store.state.auth.locale,
      sorted: {},
      ids: [],
      stop: false,
      backlog: [],
      variationcss: Symbol.for('variationcss'),
      pricesets: ['DEFAULT'],
      weighted: false,
    };
  },
  components: {
    PricingChart,
  },
  computed: {
    canEditRule() {
      return can('rules.post', this.$store.state.auth.user.scopes);
    },
    canEdit() {
      return can('products.write', this.$store.state.auth.user.scopes);
    },
    nonPriced() {
      if (!(this.$store.state.pricing.rule.method
        && methods[this.$store.state.pricing.rule.method]
        && methods[this.$store.state.pricing.rule.method].kit)) return [];
      return this.$store.state.pricing.nonpriced || [];
    },
    familybased() {
      if (!(this.$store.state.pricing.rule.method && methods[this.$store.state.pricing.rule.method])) return false;
      return methods[this.$store.state.pricing.rule.method].familymode;
    },
    priceset: {
      get() {
        return this.$store.state.pricing.priceset;
      },
      set(v) {
        this.$store.commit('pricing/updatePriceSet', {
          priceset: v,
          settings: this.$store.state.general.settings,
          locale: this.$store.state.auth.locale,
        });
        this.renderGrid();
      },
    },
    activefamily() {
      return activefamily(store.parts[0], this.$store.state.general.grids.segmentation);
    },
    inruleintervalle() {
      if (this.$store.state.pricing.currenttime < this.$store.state.pricing.rule.t) return false;
      if (this.$store.state.pricing.rule.t2 && this.$store.state.pricing.rule.t2 < this.$store.state.pricing.currenttime) return false;
      return true;
    },
    currenttime: {
      get() {
        return this.toLocaleDate(this.$store.state.pricing.currenttime);
      },
      set(v) {
        this.$store.commit('pricing/setCurrentTime', this.fromLocaleDate(v));
        if (this.$store.state.pricing.nonpriced && this.$store.state.pricing.nonpriced.length) {
          this.$q.notify({ type: 'negative', message: `Some components have no price, you will not be able to price/save : ${this.$store.state.pricing.nonpriced.join(', ')}.`, icon: 'warning' });
        }
        this.renderGrid();
      },
    },
    pages() {
      return Math.ceil(this.getPartsLength() / this.pagesize);
    },
    mustshowcomment() {
      return this.$store.state.pricing.rule && this.$store.state.pricing.rule.comment && this.showcomment;
    },
    thingstosave() {
      const activable = store.parts.reduce((_, p) => {
        if (p.validatedprice && p.validatedprice.v.fixed) _++; // eslint-disable-line
        return _;
      }, 0);
      return activable;/* || this.$store.state.pricing.backlog.length; */
    },
    splitter: {
      get() {
        return this.opened ? this.drawerWidth : 42;
      },
      set(v) {
        if (this.opened) this.drawerWidth = v;
      },
    },
    loaded() {
      return this.$store.state.pricing.loaded;
    },
    opened() {
      return this.$route.path.split('/').length > 3;
    },
    drawer() {
      switch (this.$route.name) {
        case 'ruleparams':
        case 'refine':
        case 'rulehistory':
          return 'ruleparams';
        case 'progressive':
        case 'rulesave':
        case 'searchref':
        case 'rulesaveprices':
        case 'rulepreferences':
        case 'rulecomment':
        case 'loadrule':
        case 'resegment':
        case 'exportsrule':
        case 'charts':
        case 'displaypreferences':
        case 'costpluspath':
          return this.$route.name;
        default:
          return 'unknown';
      }
    },
  },
  watch: {
    '$route.params.id': function routechange() {
      this.load();
    },
    loaded() {
      this.load();
    },
    '$route.params.reload': function reload() {
      this.load();
    },
  },
  mounted() {
    this.$store.state.pricing.partsFilter = { groupable: {}, mode: 'disabled' }; // same different disabled
    if (this.loaded) this.load();
    this.loadPricesets();
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    this.$store.commit('pricing/clearPricing');
    this.$store.commit('pricing/graphmode', false);
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    keyListener(e) {
      const key = this.$store.state.general.settings.ux.key_search_shorcut || 'f';
      if (e.key === key && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.go('searchref');
      }
    },
    updown(value) {
      const trend = Math.round(value * 10) / 10;
      if (Math.abs(trend) < 0.1) return 'east';
      if (trend > 0) return 'north_east';
      return 'south_east';
    },
    instancecomputeweightedmargin(rawturnover, purchasingcost) {
      return computeweightedmargin(rawturnover, purchasingcost, this.$store.state.pricing.rule.definition.common.averagediscount, this.$store.state.general.settings.marquemode);
    },
    instancecomputestdmargin(price = true) {
      return computestdmargin(price, this.$store.state.pricing.rule.definition.common.averagediscount, this.$store.state.pricing.stats.margins);
    },
    friendlyunit(n) {
      if (!Number.isFinite(n)) return '--';
      if (n < 10 ** 4) return Math.round(n);
      if (n < 10 ** 7) return `${Math.round(n / 10 ** 3)}k`;
      if (n < 10 ** 10) return `${Math.round(n / 10 ** 6)}M`;
      return '++';
    },
    isSlave(ref) {
      return this.$store.state.pricing.slaves.findIndex((v) => (v === ref)) >= 0;
    },
    isNonPriced(ref) {
      return this.$store.state.pricing.nonpriced.findIndex((v) => (v === ref)) >= 0;
    },
    isSubNom(ref) {
      return this.$store.state.pricing.subnoms.findIndex((v) => (v === ref)) >= 0;
    },
    getPartsLength() {
      return this.$store.state.pricing.partsLength;// store.parts.length;
    },
    /* ***************************** PAGE NAV ***************************** */
    scrollToSearch(searchGoTo) {
      const idx = this.$store.state.pricing.partsPage.findIndex((item) => item.name.v.toLowerCase().includes(searchGoTo.toLowerCase()));
      if (idx >= 0) {
        this.$refs.actualgrid.scrollTo({ left: idx * 150 });
      }
    },
    scrollToSearchID(idx) {
      if (idx >= 0) {
        this.$refs.actualgrid.scrollTo({ left: idx * 150 });
      }
    },
    pagechange(v) {
      if (v > this.page) {
        this.$refs.actualgrid.scrollTo({ left: 0 });
      } else if (v < this.page) {
        this.$refs.actualgrid.scrollTo({ left: 1000000000 });
      }
      if (this.$store.state.pricing.alreadyrendered === true) {
        return;
      }
      this.page = v;
      this.$store.state.pricing.searchGoTo = '';
      this.renderGrid();
    },
    searchTrigerEvent() {
      this.renderGrid();
    },
    renderGrid() {
      this.$store.state.pricing.alreadyrendered = true;
      this.$store.commit('pricing/setProgress', 'Sorting...');
      setTimeout(() => {
        this.$store.commit('pricing/setPartPage', {
          locale: this.$store.state.auth.locale,
          settings: this.$store.state.general.settings,
          pagesize: this.pagesize,
          currentPage: this.page,
        });

        this.page = this.$store.state.pricing.currentPage;
        if (this.$store.state.pricing.searchGoTo !== '') {
          // this.scrollToSearch(this.$store.state.pricing.searchGoTo);
          this.scrollToSearchID(this.$store.state.pricing.searchGoToRealID);
        }
        this.$store.commit('pricing/setProgress', null);
        // this.$store.state.pricing.alreadyrendered = false;
      });
    },
    /* ************************************************************* */
    updateRuleCommon(name, value) {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/updateRuleCommon', {
        name,
        value,
        settings: this.$store.state.general.settings,
        locale: this.$store.state.auth.locale,
      });
      this.$store.commit('pricing/setProgress', null);
    },
    mkpath(id) {
      return putils.mkpath(id, this.$store.state.auth.token);
    },
    mkproxypath(part) {
      return putils.mkproxypath(part, this.$store.state.auth.token);
    },
    inRule(attr) {
      const { rule } = this.$store.state.pricing;
      if (rule === undefined || rule.definition === undefined || rule.definition.attributes === undefined) return false;
      return rule.definition.attributes.findIndex((item) => (item.name === attr)) >= 0;
    },
    inPivot(attr) {
      const { rule } = this.$store.state.pricing;
      if (rule === undefined || rule.definition === undefined || rule.definition.pivot === undefined) return false;
      return rule.definition.pivot.findIndex((item) => item.name === attr) >= 0;
    },
    loadPricesets() {
      this.$axios.get('attributes/price/tags', {}).then((res) => {
        this.pricesets = res.data.data;
      });
    },
    syncwithrule() {
      this.$store.commit('pricing/setCurrentTime', this.$store.state.pricing.rule.t);
      if (this.$store.state.pricing.nonpriced && this.$store.state.pricing.nonpriced.length) {
        this.$q.notify({ type: 'negative', message: `Some components have no price, you will not be able to price/save : ${this.$store.state.pricing.nonpriced.join(', ')}.`, icon: 'warning' });
      }
    },
    approve(what) {
      this.$store.commit('pricing/approvePrices', {
        what, one: this.$store.state.general.settings.one, locale: this.$store.state.auth.locale, settings: this.$store.state.general.settings,
      });
    },
    openrule() {
      if (!this.activefamily) return;
      const href = `/pricing/${this.activefamily.id}`;
      window.open(href, '_blank');
    },
    getColor(attr) {
      if (!this.sorted[attr]) return 'light';
      if (this.sorted[attr] === 'asc') return 'positive';
      return 'negative';
    },
    sort(attr) {
      if (!this.sorted[attr.name]) {
        this.sorted = { [attr.name]: 'asc' };
      } else if (this.sorted[attr.name] === 'asc') {
        this.sorted[attr.name] = 'desc';
      } else {
        this.sorted[attr.name] = 'asc';
      }
      this.$store.commit('pricing/setProgress', 'Sorting...');
      this.$store.commit('pricing/sortParts', {
        attr,
        direction: this.sorted[attr.name],
        locale: this.$store.state.auth.locale,
        settings: this.$store.state.general.settings,
      });
      this.renderGrid();
    },
    movedown(part, idx, name) {
      this.$store.commit('pricing/setValidatedPrice', {
        nridx: part.nridx,
        idx,
        name,
        one: this.$store.state.general.settings.one,
        locale: this.$store.state.auth.locale,
        settings: this.$store.state.general.settings,
      });
    },
    toggleGroup(idx, index2) {
      this.$store.state.pricing.rule.attrslayout[idx][index2].closed = !this.$store.state.pricing.rule.attrslayout[idx][index2].closed;
    },
    updateAttr(part, name, value, idx, unit) {
      this.$store.commit('pricing/updateAttr', {
        nridx: part.nridx,
        idx, // local index
        name, // attr name
        value, // raw value
        unit, // unit
        one: this.$store.state.general.settings.one,
        locale: this.$store.state.auth.locale,
        settings: this.$store.state.general.settings,
      });

      // console.log(part.id, name, value);
      if (name === 'validatedprice') return;
      if (this.$store.state.general.settings.realtime) {
        this.$axios.patch(`/products/${store.parts[part.nridx].id.v}`, {
          [name]: store.parts[part.nridx][name].v,
        }).then((response) => this.$q.notify({ type: 'positive', message: 'Attribute sucessfully saved', icon: 'done' }))
          .catch((error) => {
            this.$q.notify({ type: 'negative', message: 'Attribute cannot be saved', icon: 'done' });
          });
      } else {
        this.$store.commit('pricing/pushinBacklog', { part: store.parts[part.nridx].id.v, attr: name, value: JSON.parse(JSON.stringify(store.parts[part.nridx][name].v)) });
        this.$q.notify({ type: 'positive', message: 'Attribute kept in backlog (will be saved later) ', icon: 'done' });
      }
    },
    load() {
      this.$store.dispatch('pricing/fullLoad').catch(() => {
        this.stop = true;
      }).finally(() => {
        if (((this.$store.state.general.settings.margins || {}).methods || [])[0] === 'weighted') {
          this.weighted = true;
        }
        this.fullload = true;
        this.renderGrid();
      });
    },
    go(name, params = {}) {
      if (name === this.$route.name && (!params.pid || params.pid === this.$route.params.pid) && (!params.attr || params.attr === this.$route.params.attr)) return;
      const { href } = this.$router.resolve({ name, params });
      this.$router.push(this.$store.state.pricing.single ? href.replace('/pricing', '/one') : { name, params });
    },
    sortGroupable(nridx, name, v) {
      if (!name || !v) return;
      let { mode } = this.$store.state.pricing.partsFilter;
      if (name !== this.$store.state.pricing.partsFilter.groupable.name || v !== this.$store.state.pricing.partsFilter.groupable.v) {
        mode = 'same';
      } else if (mode === 'same') {
        mode = 'different';
      } else if (mode === 'different') {
        mode = 'disabled';
      } else {
        mode = 'same';
      }
      this.$store.state.pricing.partsFilter = {
        groupable: {
          name,
          v,
        },
        mode,
        selected: nridx,
      };
      this.$store.state.pricing.alreadyrendered = true;
      this.renderGrid();
    },
    round(v) {
      return Math.round(v * 100) / 100;
    },
    isResult(item) {
      if (this.$store.state.pricing.searchGoTo === '') return false;

      const searchWord = this.$store.state.pricing.searchGoTo.toLowerCase();
      let result = null;
      if (this.$store.state.pricing.searchType.includes('ref')) {
        result = item.blocs[0].groups[0].name.v.toLowerCase().includes(searchWord);
      }
      if (this.$store.state.pricing.searchType.includes('des')) {
        result = this.localized(item.blocs[0].groups[0].label.v).toLowerCase().includes(searchWord) || result;
      }
      return result;
    },
  },
};
</script>
<style lang="stylus">

input[type="number"] {
  text-align: right;
}
#lg-pricing-spitter {
  .q-splitter__after {
    padding-top: 50px
  }
  .q-splitter__separator > .q-splitter__separator-area {
    right: 0;
  }
  .drawerbar {
    display flex
    > div:first-child {
      display flex
      background-color $grey-3
      flex-direction column
      height: calc(100vh - 51px)
    }
    > div:nth-child(2) {
      flex 1
      overflow: auto
    }
  }
}
.lg-sticky-scroll {
  overflow unset
}
.lg-pagination {
  display flex
  align-items center
  justify-content center
  height: 47px;
  border-top: 1px solid gray;
  position relative
  .lg-pagination-status {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 3rem;
  }
  .lg-actual-pagination {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .lg-time-travel {
    padding-right: 1rem
    padding-left: 1rem
    border-left: 1px solid gray
  }
}
#grid-container {
  height calc(100vh - 100px)
  width 100%
  overflow auto
  display grid
  &.lg-commented-grid {
    height calc(100vh - 158px);

  }

  .grid {
    display flex
    flex-wrap nowrap
    .grid-col {
      width 150px
      min-width 150px
      &.nonpriced {
        .grid-cell {
          &.comparable {
            background: #d2979e;
          }
          &.cell-first {
            &.comparable {
              background: #d2979e;
            }
          }
        }
      }
      &.slavepart {
        .grid-cell {
          background: #a2b5ca;
          &.cell-first {
            background: #a2b5ca;
          }
        }
      }
      &.active-search {
        .grid-cell {
          background: yellow;
          &.cell-first {
            background: yellow;
          }
        }
      }
      &.subnom {
        .grid-cell {
          &.comparable {
            background: #db865e;
          }
          &.cell-first {
            &.comparable {
              background: #db865e;
            }
          }
        }
      }
      .grid-cell {
        height 150px
        overflow hidden
        white-space nowrap
        height auto
        border-right 1px solid gray
        .lg-attr-group {
          min-width 120px
          padding: 0 1rem
          font-variant small-caps
        }
        &.comparable {
          background: #a9a9a9;
          .cell-item:nth-child(even) {
            background: #9a9a9a;
          }
        }
        &.cell-first {
          position sticky
          -webkit-position sticky
          top 0
          background white;
          &.comparable {
            background: #bbb;
            .cell-item:nth-child(even) {
              background: #a9a9a9;
            }
          }
          .photo-cell {
            z-index: 4500;
          }
          .lg-attr-group {
            background: white
            line-height: 1.2rem;
            margin-top: 2px;
          }
          z-index 1400
          li.validated-price {
            height 46px
            line-height: 38px;
            text-align: right;
            padding-right: 4px;
            border-top: 1px solid gray;
          }
        }
        ul {
          padding 0
          margin 0
          list-style none
          li {
            ul.collapsed {
              display: none;
            }
            &.validated-price {
              height 46px
              margin-bottom: 4px;
              .q-if-addon {
                min-width: calc(2.2rem + 4px);
                text-align: left;
                padding-left: 2px;
              }
            }
            &.photo-cell {
              height 106px
              padding-top: 6px;
              text-align center
              position relative
              >.overview {
                display: grid
                align-items stretch
                >div {
                  display: flex
                  align-items: center
                }
                grid-template-columns: 30px 1fr 30px
                grid-template-areas: "purchasealert turnover growing" "numberofparts turnover same" "anomalies turnover shrinking"
                .overview-purchasealert { grid-area: purchasealert; }
                .overview-anomalies { grid-area: anomalies; }
                .overview-numberofparts { grid-area: numberofparts; }
                .overview-growing { grid-area: growing; }
                .overview-shrinking { grid-area: shrinking; }
                .overview-same { grid-area: same; }
                .overview-turnover-margin {
                  grid-area: turnover;
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-template-rows: 1fr 1fr 1fr;
                  grid-template-areas: "margin-validated" "margin" "turnover";
                  .overview-turnover, .overview-margin {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.2em;
                  }
                  .overview-turnover { grid-area: turnover; }
                  .overview-margin { grid-area: margin; }
                  .overview-margin-validated {
                    grid-area: margin-validated;
                    display: flex ;
                    align-items: stretch
                    justify-content: center;
                    > div {
                      flex: .6
                    }
                  }
                }

                margin: 0 6px;
                height 100px
              }
              img {
                height 100px
              }
              .lg-photos-dots {
                position: absolute
                top: 4px
                right 16px
                .lg-photos-dot {
                  background: $grey-4;
                  border: 2px solid white;
                  border-radius: 50%
                  display inline-block
                  width: 10px
                  height: 10px
                  margin-left: 2px
                  &:first-child {
                    margin-left: 0
                  }
                }
              }
              .infozone {
                position: absolute;
                bottom: 0px
                left: 14px;
                font-size: 1rem;
                color: $primary;
              }
            }
            .cell-item {
              height 25px
              padding 0 4px
              position relative
              &.double-line {
                height: 38px
              }
              .cell-string {
                overflow: hidden;
                text-overflow: ellipsis
                text-align center
              }
              .attributehistory {
                position: absolute;
                font-size: 1rem;
                top: 0px;
                left: 4px;
                right: 4px
                bottom: 0;
                z-index: 1001;
              }
              .cell-date, .cell-boolean, .cell-field {
                text-align center
              }
              .cell-field {
                .lg-boolean {
                  margin-top: -.5rem;
                }
                .q-field__control {
                  height 24px
                }
              }
              .cell-amount, .cell-number {
                text-align right
                .cell-unit {
                  min-width: 2.2rem;
                  display inline-block
                  text-align left
                  padding-left: 2px
                }
              }
              .proposed-price {
                position: absolute;
                display: flex;
                justify-content: space-between
                button {
                  /* font-size: 7px;*/
                  font-weight: bold;
                }
              }
              .lg-input-attribute {
                border: 0;
                padding: 0;
                background: transparent;
                text-align: center;
                margin: 0;
                padding-left: .25rem;
              }
              &:nth-child(even) {
                background: lighten($primary, 90%);
              }
            }
            &.attr-set {
              padding-top .6rem
              min-height 2rem
              .attrset-list {
                border-top 1px solid gray
                padding-top .5rem
              }
              &:first-child {
                .attrset-list {
                  border-top none;
                }
              }
            }
          }
        }
      }
      &.col-first {
        background: $primarylightest;
        position sticky
        -webkit-position sticky
        left 0
        z-index 1500
        width auto
        max-width 250px;
        .cell-item {
          text-align right
        }
        .grid-cell.cell-first {
            z-index 1520
        }
        div.attrset-first {
            position: absolute;
            margin-top: -.8rem;
            padding: 2px 10px;
            z-index: 1510;
        }
      }
    }
  }
}
.lg-variation-2.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid $positive;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
.lg-variation-0.photo-cell:after {
  content: '';
  position: absolute;
  border: 2px solid $negative;
  border-radius: 2px;
  bottom: -7px;
  left: 1rem;
  right: 1rem;
}
#rightoggle  {
  z-index 1700
  .q-btn {
    display: block;
  }
}
button.q-btn.q-btn-round.in-row {
  height: 18px;
  width: 18px;
  font-size: auto;
  &.no-sort {
    color: $light !important
  }
  &.sort-asc, &.sort-desc {
    color: $primary !important
  }
}

.savetip {
    position: absolute;
    right: 2px;
    top: 2px;
}
.thingstosave {
  position: absolute;
  right: 6px;
  font-size: 0.6rem;
  top: -7px;
}
.error-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.lg-part-segment {
  position: absolute;
  top: 3px;
  right: 3px;
}

.animated:active {
  background: lighten($secondary, 20%)
}
.animated:not(:active) {
  animation: shadow-drop-center .2s ease 0s 1 normal none;
}
@keyframes shadow-drop-center {
  0%{
    box-shadow: 0 0 0 0 transparent;
  }
  100%{
    box-shadow: 0 0 20px 0 rgba(0,0,0,.35);
  }
}

.ellipsisAttr{
  overflow:hidden;
  text-overflow: ellipsis;
}

</style>
