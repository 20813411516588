/* eslint-disable */

const deepClone = value => JSON.parse(JSON.stringify(value));

export const setRoles = (state, rolesData) => {
  state.roles = rolesData;
};

export const setUsers = (state, usersData) => {
  state.users = usersData;
};