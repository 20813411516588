<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Search reference')}}
      </q-toolbar-title>
    </q-toolbar>

    <div  class="drawer-container">

      <div class="drawer-section">
        <p class="drawer-section-title">{{$t('Search options')}} : </p>
        <div style="width:100%; display: flex; justify-content: space-between;">
          <q-input filled square dense label="Search" fill-mask v-model="searchFilter" ref="searchBar" style="width:100%; padding-right :10px;"/>
          <q-btn icon="delete" color="negative" @click="cancelSearch" :disable="$store.state.pricing.searchFilter==='' && $store.state.pricing.searchGoTo===''"/>
        </div>
        <div  class="drawer-line" style="margin-top:10px;">
          <input type="checkbox" value="ref" name="ref" id="ref" v-model="searchType" @change="changeSearchType"/>
          <label for="ref" style="margin-left:5px;" >{{$t('Reference')}}</label>
        </div>
        <div  class="drawer-line">
          <input type="checkbox" value="des" name="des" id="des" v-model="searchType" @change="changeSearchType"/>
          <label for="des" style="margin-left:5px;">{{$t('Désignation')}}</label>
        </div>
      </div>
      <div class="drawer-line" style="justify-content: space-between;">
        <q-btn :label="$t('Search')" color="secondary" @click="applySearchFilter" style="margin-top:10px;" :disable="searchType.length<1"/>
        <q-btn :label="$t('Go to')" color="secondary" @click="goToRef" style="margin-top:10px;" :disable="searchType.length<1"/>
        <q-btn :disable="$store.state.pricing.searchGoTo===''" :label="$t('Next') + ' ' +$store.state.pricing.searchGoToID +'/'+$store.state.pricing.searchGoToSize  " color="secondary" @click="goToRefNext" style="margin-top:10px;"/>
      </div>

      <!-- <div class="drawer-separator"></div>
      <div class="drawer-section">
        <p class="drawer-section-title">{{$t('Search shorcut')}} : </p>
        <div style="margin: auto;">
          <q-btn :label="$t('CTRL')" color="primary" :disable="true" />
          <q-btn color="primary" icon="add" round flat :disable="true" style="margin:0 5px;" ></q-btn>

          <select style="width:50px; padding:8px; border-radius: 5px; border: 1px solid grey;" v-model="shorcutSelected" @change="changeShorcut">
            <option v-for="shorcut in shorcutOptions" :value="shorcut" :key="'shorcut_'+shorcut">{{shorcut}}</option>
          </select>
        </div>
      </div> -->

    </div>

  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      searchFilter: '',
      searchType: [
        'ref',
        'des',
      ],
      shorcutOptions: [],
      shorcutSelected: '',
    };
  },
  mounted() {
    // generate shorcut option from a to z
    for (let i = 65; i < 91; i += 1) {
      this.shorcutOptions.push(String.fromCharCode(i));
    }
    this.shorcutSelected = 'F';
    this.searchFilter = this.$store.state.pricing.searchFilter;
    this.$refs.searchBar.focus();
  },
  methods: {
    getPartsLength() {
      return this.$store.state.pricing.partsLength;
    },
    changeSearchType() {
      this.$store.state.pricing.searchType = this.searchType;
    },
    applySearchFilter() {
      this.$store.state.pricing.searchGoToID = 0;
      this.$store.state.pricing.searchGoToRealID = 0;
      this.$store.state.pricing.searchGoToSize = 0;
      this.$store.state.pricing.searchGoTo = '';
      this.$store.state.pricing.searchFilter = this.searchFilter;
      this.$emit('searchTrigerEvent');
    },
    cancelSearch() {
      this.searchFilter = '';
      this.$store.state.pricing.searchFilter = '';
      this.$store.state.pricing.searchGoTo = '';
      this.$store.state.pricing.searchGoToID = 0;
      this.$store.state.pricing.searchGoToRealID = 0;
      this.$store.state.pricing.searchGoToSize = 0;
      this.$emit('searchTrigerEvent');
    },
    goToRef() {
      this.$store.state.pricing.partsFilter.mode = 'disabled';
      this.$store.state.pricing.searchFilter = '';
      this.$store.state.pricing.searchGoTo = this.searchFilter;
      this.$store.state.pricing.searchGoToID = 1;
      this.$emit('searchTrigerEvent');
    },
    goToRefNext() {
      if (this.$store.state.pricing.searchGoToID >= this.$store.state.pricing.searchGoToSize) {
        this.$store.state.pricing.searchGoToID = 1;
      } else {
        this.$store.state.pricing.searchGoToID += 1;
      }
      this.$store.state.pricing.searchFilter = '';
      this.$store.state.pricing.searchGoTo = this.searchFilter;
      this.$emit('searchTrigerEvent');
    },
    changeShorcut() {
    },
  },

};
</script>
<style lang="stylus" scoped>
label {
  padding-left: 0;
  padding-right: 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.drawer-container {
  padding: 1rem;
  position relative
  .rule-info {
    position: absolute;
    bottom: 1rem;
    left: 1rem
    right: 1rem
  }
}

.drawer-section{
  width:100%;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  margin-bottom: 10px;

}

.drawer-line {
  width:100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.drawer-separator {
  background-color: grey; height: 2px; width: 80%; margin: 25px auto 15px auto; opacity: 0.5;
}

.drawer-section-title{
    font-size: 17px; font-weight: 500;
  }
</style>
