export const rulemixins = {
  methods: {
    goback() {
      if (this.$store.state.pricing.single) {
        const { href } = this.$router.resolve({ name: 'pricing' });
        this.$router.push(href.replace('/pricing', '/one'));
      } else {
        this.$router.push({ name: 'pricing' });
      }
    },
  },
};

export default rulemixins;
