<template>
  <q-page-container>
    <q-page>
      <finder finder-id="myId" ref="finder" finder-color="green"
      :fetch-data="fetch" :item-details="show"
      :fetch-action-button-data="actions" ></finder>
      <!--div class="file-detail">
        <div v-if="item.id">
          <q-toolbar color="primary">
            <q-toolbar-title>
              {{item.title}}
              <span slot="subtitle">{{item.subtitle}}</span>
            </q-toolbar-title>
          </q-toolbar>
        </div>
        <q-btn v-if="item.id" color="primary" :disable="!item.leaf"
          label="price" class="fixed family-fab"
          @click="$router.push({ name: 'pricing', params: { id: item.id }})"/>
      </div-->
    </q-page>
  </q-page-container>
</template>
<script>
import Finder from '../components/Finder.vue';

const backgroundColor = [
  '#FF0000',
  '#00FF00',
];
const borderColor = [
  '#FFFFFF',
  '#FFFFFF',
];
const borderWidth = [2, 2];

const families = [
  [
    {
      id: 1,
      title: 'Barre de Pinces',
      subtitle: 'Barres SP, SPO, complètes',
      leaf: false,
      icon: 'folder',
      data: {
        labels: ['Tarifée', 'A traiter'],
        datasets: [
          {
            label: '# of x',
            data: [3, 6],
            backgroundColor,
            borderColor,
            borderWidth,
          },
        ],
      },
    },
    {
      id: 3,
      title: 'Autre famille',
      subtitle: 'Information sur cette famille',
      leaf: false,
    },
  ],
  [
    {
      id: 2,
      title: 'Barre de Pinces SP',
      subtitle: 'Tous les barres SP',
      leaf: true,
      data: {
        labels: ['Tarifée', 'A traiter'],
        datasets: [
          {
            label: '# of x',
            data: [1, 6],
            backgroundColor,
            borderColor,
            borderWidth,
          },
        ],
      },
    },
  ],
  [],
];

export default {
  components: {
    Finder,
  },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.$store.commit('header/setHeader', {
      title: 'Family selection',
      subtitle: 'Please select a family for pricing',
    });
  },
  methods: {
    actions(id) {
      return Promise.resolve([]);
    },
    fetch(id) {
      if (id === undefined) return Promise.resolve(families[0]);
      return Promise.resolve(families[id]);
    },
    show(id) {
      [this.item] = families[id - 1];
    },
  },
};
</script>
<style scoped  lang="stylus">

.q-layout-page
  display flex
  .finder-container
    flex 1
  .file-detail
    background: $grey-12
    min-width: 300px
    width 300px
    .doughnut
      width: 100%
    .family-fab
      right: 1rem;
      bottom: 1rem;
</style>
