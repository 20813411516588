<template>
  <div>
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Rule Comment')}}
        <span v-if="!$store.state.pricing.single" class="lg-header-subtitle">
          {{$t('Will be saved with rule')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.rule.definition.common" class="drawer-container">
      <div class="lg-comment" v-if="($store.state.pricing.rule.link || $store.state.pricing.single) && $store.state.pricing.rule.comment !== ''" v-html="$store.state.pricing.rule.comment">
      </div>
      <div class="lg-comment" v-else-if="$store.state.pricing.rule.link || $store.state.pricing.single">
        <p>{{$t('No comment for this rule.')}}</p>
      </div>
      <q-editor v-else minHeight="70vh" maxHeight="70vh" height="70vh" class="ruleeditor"
        :value="$store.state.pricing.rule.comment"
        @input="dochange"/>
      <!-- <div class="lg-rule-comment"><q-input
        v-model="comment"
        type="textarea"
        label="Comment"
        :max-height="100"
        rows="7"
      /></div> -->
      <!-- <q-btn :disable="! $store.state.pricing.rule.id" label="Save" color="secondary" class="fixed ruleparams-fab" @click="save"/> -->
    </div>
  </div>
</template>
<script>
import { rulemixins } from './mixins';

export default {
  mixins: [rulemixins],
  computed: {
    comment: {
      set(v) {
        this.$store.commit('pricing/setComment', v);
      },
      get() {
        return this.$store.state.pricing.rule.comment;
      },
    },
  },
  methods: {
    dochange(v) { this.$store.commit('pricing/setComment', v); },
    save() {},
  },
};
</script>
<style lang="stylus" scoped>
.lg-rule-comment {
  padding .4rem
}
.lg-comment {
  min-height 70vh
  height 70vh
}
</style>
