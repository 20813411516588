<template>
  <div>
    <q-page-container>
      <q-page>

        <div class="fakeDrawer">
          <fieldset><legend>{{$t('Chart Parameters')}}</legend>
          <q-select :label="$t('Base currency')" filled square dense emit-value map-options v-model="from" :options="fromCurrencies" v-bind:class="{ error: devErr }"></q-select>
          <q-select :label="$t('Target currency')" filled square dense emit-value map-options v-model="to" :options="toCurrencies" v-bind:class="{ error: devErr }"></q-select>
          <date-field :label="$t('Display from')" :value="time1" @change="updatetime" name="from" :locale="$store.state.auth.locale" v-bind:class="{ error: dateErr }"></date-field>
          <date-field :label="$t('To')" :value="time2" name="from"  :locale="$store.state.auth.locale" v-bind:class="{ error: dateErr }"></date-field>
          <q-btn :color="'secondary'" @click="fetch">{{$t('Display')}}</q-btn>
          </fieldset>
          <fieldset v-if="point && point.series.options.custom.isOrig && admin" class="applyrateform"><legend>{{$t('Rate implementation')}}</legend>
            <div class="header"> {{point.series.options.custom.localizedlabel}} <span class="suffix">{{point.series.options.custom.suffix}}</span> → {{point.series.options.custom.localizedlabel}} </div>
            <div class="date">{{new Date(point.x).toLocaleDateString()}}</div>
            <div class="value">{{point.y}}</div>
            <div>{{$t('Clicking on apply will implement the above rate as of now.')}}</div>
            <q-btn :color="'secondary'" @click="apply">{{confirmation?$t('Confirm you want it applied'):$t('Apply')}}</q-btn>
          </fieldset>
        </div>

        <div class="mainchart">
          <highcharts ref="chart" class="chart" :options="config"></highcharts>
        </div>

      </q-page>
    </q-page-container>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import More from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import { can } from '@cabinetmariel/scopes';
import { localization } from '../../../lib/mixins';
import DateField from '../../../components/attributesDisplay/DateField.vue';

More(Highcharts);
stockInit(Highcharts);

export default {
  mixins: [localization],
  components: {
    highcharts: Chart,
    DateField,
  },
  data() {
    const now = new Date();
    const oneyearago = new Date();
    oneyearago.setFullYear(now.getFullYear() - 1);
    now.setDate(now.getDate() + 1);
    return {
      admin: can('admin', this.$store.state.auth.user.scopes),
      confirmation: false,
      point: null,
      tags: [],
      from: '',
      to: '',
      devErr: false,
      time1: oneyearago.toISOString().split('T')[0],
      time2: now.toISOString().split('T')[0],
      dateErr: false,
      fromCurrencies: [],
      toCurrencies: [],
      config: {
        chart: {
          // zoomType: 'x',
          inverted: false,
          reflow: true,

        },
        title: {
          text: '',
        },
        subTitle: {
          text: '',
        }, /*
        scrollbar: {
          enabled: true,
        }, */
        xAxis: {
          type: 'datetime',
          title: {
            text: this.$t('Time'),
          },
        },
        yAxis: {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: this.$t('Rate'),
          },
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        series: [],
        tooltip: {
          useHTML: true,
          outside: true,
          hideDelay: 1000,
          style: {
            pointerEvents: 'auto',
          },
          formatter() {
            const ratedef = this.series.options.custom;
            return `
            <div class="ratetooltip">
              <div class="header">${ratedef.localizedlabel} ${ratedef.isOrig ? `<span class="suffix">${ratedef.suffix}</span>` : ''}</div>
              <div class="main">
                <div class="tooltip-time">${new Date(this.x).toLocaleDateString()}</div>
                <div class="tooltip-value">${this.y}</div>
              </div>
            </div>`;
          },
        },
      },
    };
  },
  async mounted() {
    // console.log('mounted', this.$store.state.general.settings.currency);
    this.to = this.$store.state.general.settings.currency;
    await this.fetchCurrencies('from');
    await this.fetchCurrencies('to');
    await this.fetch();
  },

  methods: {
    updatetime(value) {
      if (value.from !== undefined) {
        this.time1 = value.from;
      } else {
        this.time2 = value.to;
      }
    },
    updatechart() {
      const fromCurrency = typeof this.from === 'string' ? this.from.toUpperCase() : '';
      const toCurrency = typeof this.to === 'string' ? this.to.toUpperCase() : '';
      const self = this;
      this.config.title.text = this.$t('Currency rates from <b>{{from}}</b> to <b>{{to}}</b>', { from: fromCurrency, to: toCurrency });
      this.config.series = this.tags
        .filter((tag, i) => (this.currency[i] && this.currency[i].rates))
        .map((tag, i) => {
          const ratedef = JSON.parse(JSON.stringify(this.$store.state.general.settings.rates.find((el) => (el.name === tag) || (`${el.name}ORIG` === tag))));
          if (ratedef.suffix && tag.endsWith(ratedef.suffix)) ratedef.isOrig = true;
          ratedef.localizedlabel = this.localized(ratedef.label);

          return {
            type: 'line',
            name: `${ratedef.localizedlabel}${ratedef.isOrig ? ` ${this.$t('ORIG')}` : ''}`,
            custom: ratedef,
            allowPointSelect: true,
            point: {
              events: {
                select() {
                  self.point = this;
                  console.log('point', this);
                },
              },
            },
            data: this.currency[i].rates.map((el) => [Date.parse(el.t.split(',')[0].slice(1)), el.v]),
          };
        });
      this.$refs.chart.chart.redraw();
    },
    apply() {
      if (this.confirmation) {
        this.confirmation = false;
        this.$axios.post('/rates', {
          from: this.from,
          to: this.to,
          v: this.point.y,
          t: `[${new Date().toISOString().split('T')[0]},)`,
          tag: this.point.series.options.custom.name,
        }).then(() => {
          this.point = null;
          this.fetch(true);
          this.$q.notify({
            message: this.$t('Rate applied'),
            color: 'positive',
            position: 'top',
          });
        }).catch(() => {
          this.$q.notify({
            message: this.$t('Rate not applied'),
            color: 'negative',
            position: 'top',
          });
        });
      } else {
        this.confirmation = true;
      }
    },
    async fetchCurrencies(id) {
      const res = await this.$axios.get(`/currencies/${id}`);
      if (id === 'from') {
        // console.log('from', res.data.data);
        this.fromCurrencies = res.data.data;
        this.from = this.$store.state.general.settings?.ratechart?.preferred?.from && this.fromCurrencies.includes(this.$store.state.general.settings.ratechart.preferred.from)
          ? this.$store.state.general.settings.ratechart.preferred.from
          : res.data.data[0];
      } else if (id === 'to') {
        // console.log('to', res.data.data);
        this.toCurrencies = res.data.data;
        this.to = this.$store.state.general.settings?.ratechart?.preferred?.to && this.fromCurrencies.includes(this.$store.state.general.settings.ratechart.preferred.to)
          ? this.$store.state.general.settings.ratechart.preferred.to
          : res.data.data[0];
      }
    },
    async fetch(refresh = false) {
      this.dateErr = !(this.time1 && this.time2 && (new Date(this.time1) <= new Date(this.time2)));
      this.devErr = (this.from === this.to);
      if (this.dateErr || this.devErr) {
        return;
      }
      this.dateErr = false;
      this.devErr = false;

      const res = await this.$axios.get(`/rates/ALL/${this.from}/${this.to}/${this.time1}/${this.time2}`, {});
      this.tags = res.data.data.map((el) => el.name);
      res.data.data.map((currency) => {
        currency.rates.sort((b, a) => Date.parse(b.t.split(',')[0].slice(1)) - Date.parse(a.t.split(',')[0].slice(1)));
        return currency;
      });
      this.currency = res.data.data;
      this.updatechart(refresh);
    },
  },
};
</script>
<style lang="stylus">
.fakeDrawer {
  position: absolute;
  right: 0;
  height: 100%;
  width: 300px;
  border-left: 1px solid grey;
  background-color: #FBFBFB;
  padding:30px;
  fieldset {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &.applyrateform {
     .header {
      text-align: center;
      font-size: 1.2rem;
     }
     .date {
      text-align: center;
      font-size: 0.8rem;
     }
     .value {
      text-align: center;
      font-size: 2rem;
     }
    }
  }
}
.ratetooltip .suffix, .applyrateform .suffix {
    font-size: 0.7rem;
    background-color: $secondary;
    color: white;
    padding: 0.1rem 0.2rem;
    border-radius: 0.2rem;
  }
.mainchart {
  position: absolute;
  top: 50px;
  left: 20px;
  right: 330px;
  bottom: 20px;
}

.chart {
  position: relative;
  width: 100%;
  height : 100%;
  display: block;
}

.error {
  background-color: rgba(255, 0, 0, 0.4);
}

.ratetooltip {
  min-width: 100px
  .header {
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #f0f0f0;
  }
  .main {
    .tooltip-time {
      font-size: 0.8rem;
      color: grey;
    }
    .tooltip-value {
      text-align: center;
      font-size: 2rem;
    }
  }
}
</style>
