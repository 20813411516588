/* eslint-disable */

if (!Set.prototype.union) {
  Set.prototype.union = function (iterable) {
    if (typeof this !== 'object') {
      throw new TypeError('Must be of object type');
    }
    const Species = this.constructor[Symbol.species];
    const newSet = new Species(this);
    if (typeof newSet.add !== 'function') {
      throw new TypeError('add method on new set species is not callable');
    }
    for (item of iterable) {
      newSet.add(item);
    }
    return newSet;
  };
}
