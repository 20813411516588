<template>
<div v-if="$store.state.pricing.defs.defs && $store.state.pricing.rule.definition"  style ="overflow: auto;">
  <q-toolbar class="bg-primary text-white shadow-2">
    <q-btn flat round dense icon="cancel" @click="goback"/>
    <q-toolbar-title>
      Add an attribute to the rule
      <span slot="subtitle">Please select an attribute</span>
    </q-toolbar-title>
  </q-toolbar>
  <div class="content">
    <q-input square filled dense :placeholder="$t('Type in here ')" v-model="search">
      <template v-slot:before>
        <q-icon name="search"/>
      </template>
    </q-input>
    <div class="header">
      <div>Set</div>
      <div>Type</div>
      <div>Name</div>
    </div>
    <div class="results">
      <template v-for="attr in filter()">
        <div :key="'set.'+attr.name">{{localized($store.state.pricing.sets.sets[attr.root].label)}}</div>
        <div :key="'type'+attr.name">
          <q-icon :name="iconfor(attr.type)" /> /
          <q-icon  v-for="(w, index) in attr.weight" :key="index" :name="w.type ==='rate'?'open_in_full':'money'"/>

        </div>
        <div :key="'lab'+attr.name">{{localized(attr.label)}}<span>{{attr.description}}</span></div>
        <div :key="'but'+attr.name"><q-btn dense color="secondary" @click.native="select(attr)" icon="add" size="sm"/></div>
      </template>
    </div>
  </div>
</div>
</template>
<script>
import { localization } from '../../lib/mixins';

export default {
  mixins: [localization],
  data() {
    return {
      search: '',
    };
  },
  methods: {
    iconfor(type) {
      switch (type) {
        case 'boolean':
          return 'check_circle_outline';
        case 'number':
          return 'filter_2';
        default:
          return 'help_outline';
      }
    },
    go() {
      this.$router.push({ name: 'newattr' });
    },
    select(attr) {
      this.$router.push({ name: 'addattr', params: { name: attr.name } });
    },
    filter() {
      return Object.values(this.$store.state.pricing.defs.defs)
        .filter((item) => !item.name.endsWith('$'))
      // if (!['dimensions', 'features', 'materials', 'other'].includes((this.$store.state.pricing.sets.sets[item.root] || {}).name)) return false;
        .filter((item) => RegExp(this.search, 'i').test(this.localized(item.label))
            && item.weight && item.name !== 'price'
            && this.$store.state.pricing.rule.definition.attributes.findIndex((r) => r.name === item.name) < 0);
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-padding {
  padding 1rem
}
.results {
  overflow: auto;
  max-height: calc(100vh - 210px);
}
.results, .header {
  display: grid;
  grid-template-columns: 80px 60px auto 32px;

}
.results {
  > div {
  padding: 2px 0;
  border-bottom: 1px solid #dfdfdf;
  }
}
.header {
  border-bottom: 1px solid gray;
  div {
    font-weight: bold
  }
}
.content {
  padding: 1rem
}
.ruleparams-fab {
    right 1rem
    bottom 1rem
}
</style>
