<template>
  <div class="drawer-container" >
    <q-toolbar color="primary">
      <q-btn flat round dense icon="arrow_back" @click="$router.go(-1)"/>
      <q-toolbar-title>
        {{$t('Select a smoothing block')}}
        <span slot="subtitle">{{$t('It will be part of your smoothing function')}}</span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="incontainer">
      <q-field helper="Depending on your choice you will have additional parameters to fill in.">
      <q-select fit sublabelLines="10" :exactFit="true" emit-value :float-label="$t('Block Type')" v-model="block.name" :options="options">
        <template v-if="model" v-slot:append>
          <q-icon :name="options.icon" @click.stop="model = null" class="cursor-pointer" />
        </template>
      </q-select>
      </q-field>
      <div v-if="block.name" class="block-params">
        <div class="q-body-2">{{$t('Associated parameters')}}</div>
        <q-field v-for="param in blockdefs.find(b => b.name === block.name).settings" :key="param.name" :helper="param.sublabel">
          <q-input type="number" :float-label="param.label" v-model="block.settings[param.name]"/>
        </q-field>
      </div>
    </div>
    <q-btn color="primary" :label="$t('Add')" class="fixed ruleparams-fab" @click="addit"/>
  </div>
</template>
<script>
const blockdefs = [
  {
    name: 'linear',
    label: 'Linear',
    sublabel: 'A linear increase or decrease based on the attribute value.',
    icon: 'icon-linear',
    settings: [
      { name: 'a', label: 'A', sublabel: 'The slope of the line.' },
      { name: 'b', label: 'B', sublabel: 'The additional markup.' },
    ],
  },
  {
    name: 'identity',
    label: 'Identity',
    sublabel: 'Will always return the very same value, most often 1.',
    icon: 'icon-identity',
    settings: [
      { name: 'value', label: 'Value', sublabel: 'The returned value.' },
    ],
  },
];
export default {
  data() {
    return {
      block: { settings: {} },
      blockdefs,
      model: true,
    };
  },
  computed: {
    options() {
      return blockdefs.map((b) => ({
        value: b.name,
        label: b.label,
        sublabel: b.sublabel,
        icon: b.icon,
      }));
    },
  },
  methods: {
    addit() {
      const { name, settings } = this.block;
      const block = {
        name,
        settings: this.blockdefs
          .find((b) => b.name === name)
          .settings
          .reduce((all, setting) => {
            all[setting.name] = settings[setting.name];
            return all;
          }, {}),
      };
      this.$store.commit('pricing/pushBlock', {
        attr: this.$route.params.attr,
        fid: this.$route.params.fid,
        block,
      });
      this.$router.push({ name: 'refine' });
    },
  },
};
</script>
<style lang="stylus">

.block-params {
  padding-top: 1rem;
}

</style>
