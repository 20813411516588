const slugfrom = [...'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;']
  .map((c) => new RegExp(c, 'g'));
const slugto = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
export const localization = {
  methods: {
    fromLocaleDate(d) {
      if (d instanceof Date) return d;
      // eslint-disable-next-line no-useless-escape
      const [dd, mm, yyyy] = d.split(/[/\.-]/).map((item) => parseInt(item, 10));
      return new Date(`${yyyy.toString().padStart(4, '0')}-${mm.toString().padStart(2, '0')}-${dd.toString().padStart(2, '0')}`);
      // we prefer to return an ISO date and not a local date as with return new Date(yyyy, mm - 1, dd);
    },
    toLocaleDate(d) {
      if (!(d instanceof Date)) return d;
      const yyyy = d.getFullYear();
      const mm = d.getMonth() + 1;
      const dd = d.getDate();
      return `${dd.toString().padStart(2, '0')}/${mm.toString().padStart(2, '0')}/${yyyy.toString().padStart(4, '0')}`;
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '').toLowerCase();
      slugfrom.forEach((r, i) => {
        str = str.replace(r, slugto.charAt(i));
      });
      str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
      return str;
    },
    localized(value, l, defaultlocale = 'en-US') {
      // const defaultlocale = 'en-US';
      if (typeof value === 'string') return value;
      if (typeof value !== 'object') return 'BL!';
      const locale = l || ((this.$store.state.auth.user && this.$store.state.auth.user.locale)
        ? this.$store.state.auth.user.locale
        : defaultlocale);
      if (value[locale]) return value[locale];
      if (value[defaultlocale]) return value[defaultlocale];
      const fallback = Object.values(value).find((v) => !!v);
      if (fallback) return fallback;
      return 'ML!';
    },
    nonemptylocale(value) {
      const defaultlocale = 'en-US';
      if (typeof value === 'string') return undefined;
      if (typeof value !== 'object') return undefined;
      const locale = (this.$store.state.auth.user && this.$store.state.auth.user.locale)
        ? this.$store.state.auth.user.locale
        : defaultlocale;
      if (value[locale]) return locale;
      if (value[defaultlocale]) return defaultlocale;
      const fallback = Object.keys(value)[0];
      if (fallback) return fallback;
      return undefined;
    },
  },
};

export default localization;
