<template>
  <div>
    <q-page-container>
      <q-page :style-fn="pagestyle">
        <finder :fetch="fetch">
          <q-item
            clickable
            :class="{highlight: isSelected(item.id)}"
            slot="item"
            slot-scope="{ item }"
            @click="details(item)"
          >
            <q-item-section side><q-icon name="merge_type"/></q-item-section>
            <q-item-label>{{localized(item.label)}}</q-item-label>
          </q-item>
          <q-fab
            slot="new"
            color="secondary"
            slot-scope="{ parent }"
            :icon="'add'"
            class="finder-fab"
            direction="up"
          >
            <q-fab-action color="secondary" @click="create(parent)" icon="merge_type"/>
          </q-fab>
        </finder>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <router-view/>
    </q-drawer>
  </div>
</template>
<script>
import Finder from '../../../components/Finder.vue';
import localization from '../../../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization],
  components: {
    Finder,
  },
  data() {
    return {
      locale: this.$store.state.auth.locale,
      params: [],
    };
  },
  watch: {},
  computed: {
    drawer: {
      get() {
        const paths = this.$route.path.split('/');
        return paths.includes('drawer');
      },
      set() {},
    },
  },
  methods: {
    deepclone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    isSelected(itemId) {
      return this.$route.params.pathMatch
        .split('/')
        .find((id) => id.substring(2) === itemId);
    },
    details(item) {
      const columnIndex = item.parent ? item.column : 0;
      let routeParams = this.$route.params.pathMatch.split('/');

      routeParams[columnIndex] = `01${item.id}`;
      routeParams = routeParams.slice(0, columnIndex + 1);
      if (routeParams[routeParams.length - 1] !== 'drawer') {
        routeParams.push('drawer/details');
      }
      this.$router.push(`/settings/segmentation/${routeParams.join('/')}`);
    },
    create(parent) {
      const routeParams = this.$route.params;
      routeParams.action = 'new';
      routeParams.column = parent;
      this.$router.push({ name: 'segmentationDetails', params: routeParams });
    },
    pagestyle(offset) {
      return {
        height: offset ? `calc(100vh - ${offset}px)` : '100vh',
        overflow: 'auto',
      };
    },
    fetch(itemId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(itemId ? `/families/${itemId}/children` : '/families', { params: { nolimit: 'yes', sort: 'label' } })
          .then((response) => {
            resolve(response.data.data);
          });
      });
    },
    detail() {},
    go(path) {
      this.$router.push(path);
    },
  },
  created() {
    this.$store.commit('header/setHeader', {
      title: 'Segmentation',
      subtitle: 'Settings',
    });
  },
};
</script>
<style scoped lang="stylus">

.highlight {
  background: rgba(0, 0, 0, 0.1);
}
</style>
