<template>
  <div>
    <q-page-container>
      <q-page>
        <div class="content">
          <div class="error-info">
            <div class="chart-content">

                <div class="css-donut" v-if="showDonut">
                  <svg class="svg-circle" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <circle class="circle_animation2" style="stroke-dashoffset: 440" r="69.85699" cy="81" cx="81" stroke-width="19" stroke="red" fill="none"/>
                    <circle class="circle_animation" :style="{ 'stroke-dashoffset': donutVal}" r="69.85699" cy="81" cx="81" stroke-width="20" stroke="#6fdb6f" fill="none"/>
                  </g>
                  </svg>
                </div>

            </div>
            <div class="errors-founded">
              {{errors.errors}} error{{errors.errors === 1? "": "s" }} found on {{errors.lines}} line{{errors.lines === 1? "": "s" }}.
            </div>
          </div>

          <div v-if="nodata" class="loading">
            <q-inner-loading :visible="true">
              <q-spinner-gears size="50px" color="primary"></q-spinner-gears>
              <span>Recovering API data</span>
            </q-inner-loading>
          </div>
          <div v-else class="grid-content">
            <Grid v-if="errorlines[0]" :pagination="pagination">
              <template slot="header">
                <td></td>
                <td
                  v-for="(cell, cellIndex) in errorlines[0].data"
                  :key="cellIndex"
                  class="headerCell"
                >{{getLabel(cellIndex)}}</td>
              </template>
              <template slot="body">
                <tr
                  class="item"
                  v-for="(errorline, errorLineIndex) in errorlines"
                  :key="errorLineIndex"
                >
                  <td>
                    <q-icon name="warning" color="red" size="24px" v-if="getNoneError(errorline)">
                      <q-tooltip>{{getNoneError(errorline)}}</q-tooltip>
                    </q-icon>
                  </td>
                  <td v-for="(cell, cellIndex) in errorline.data" :key="cellIndex">
                    <q-icon
                      name="warning"
                      color="red"
                      size="24px"
                      v-if="cellHasError(errorline, cellIndex)"
                    >
                      <q-tooltip>{{getError(errorline, cellIndex)}}</q-tooltip>
                    </q-icon>
                    {{cell}}
                  </td>
                </tr>
              </template>
            </Grid>
          </div>
        </div>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <Stepper></Stepper>
    </q-drawer>
  </div>
</template>

<script>
import Grid from '../../components/grid/Grid';
import Stepper from '../../components/drawer/ImpExp/stepper';

export default {
  components: { Grid, Stepper },
  data() {
    return {
      impExp: this.$store.state.importExport,
      mapping: {},
      params: null,
      importData: null,
      errorlines: [],
      columns: [],
      nodata: true,
      id: null,
      drawer: true,
      planetchart: null,
      chart: null,
      showDonut: false,
      errors: {
        lines: 0,
        errors: 0,
      },
      pagination: {
        model: 1,
      },
      windowHeight: 0,
      listner: null,
    };
  },
  computed: {
    currentPage() {
      return this.pagination.model - 1;
    },
    donutVal() {
      // Le donnut fait 440px de circonference donc 440=100%
      return (this.errors.errors / this.errors.lines) * 440;
    },
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.uploadPagebyIndex(this.currentPage);
    },
    currentPage() {
      this.uploadPagebyIndex(this.currentPage);
    },
    $route(to, from) {
      if (to.name === 'preupload') {
        this.$store.commit('auth/unsetObservable', `imports/${this.id}`);

        this.$store.commit('importExport/setCurrentStep', 4);
        this.$store.commit('header/setHeader', {
          title: 'Import CSV',
          subtitle: 'Errors',
        });
      } else if (to.name === 'upload') {
        this.$store.commit('importExport/setCurrentStep', 5);
        this.$store.commit('header/setHeader', {
          title: 'Import CSV',
          subtitle: 'Upload to SAP',
        });
      }

      this.id = to.params.id;
      this.getErrorLines();
      this.$store.commit('auth/setObservable', {
        observable: {
          regex: `imports/${to.params.id}`,
          func: this.getEvent,
        },
      });
    },
  },
  methods: {
    updateChart(lines, errors) {
      this.errors.lines = lines;
      this.errors.errors = errors;
    },
    cellHasError(errorline, cellIndex) {
      const label = this.getLabel(cellIndex);
      for (let i = 0; errorline.error[i]; i += 1) {
        if (errorline.error[i].name === label) {
          return true;
        }
      }
      return false;
    },
    getNoneError(errorline) {
      let errors = '';
      for (let i = 0; errorline.error[i]; i += 1) {
        if (errorline.error[i].name === 'none') {
          if (errors === '') {
            errors = errorline.error[i].code;
          } else {
            errors = `${errors}, ${errorline.error[i].code}`;
          }
        }
      }
      return errors;
    },
    getError(errorline, cellIndex) {
      const label = this.getLabel(cellIndex);
      let errors = '';
      for (let i = 0; errorline.error[i]; i += 1) {
        if (errorline.error[i].name === label) {
          if (errors === '') {
            errors = errorline.error[i].code;
          } else {
            errors = `${errors}, ${errorline.error[i].code}`;
          }
        }
      }
      return errors;
    },
    getLabel(index) {
      const keys = Object.keys(this.params.mapping);
      for (let i = 0; keys[i]; i += 1) {
        if (this.params.mapping[keys[i]].includes(index)) {
          return keys[i];
        }
      }
      return '';
    },
    uploadPagebyIndex(i) {
      if (!this.importData) return;
      let limit = Math.floor((this.windowHeight - 420) / 50);
      if (limit < 5) limit = 5;
      const offset = i > 0 ? i * limit : 0;
      this.$axios
        .get(`/imports/${
          this.$route.params.id
        }/lines?@offset=${offset}&@limit=${limit}`)
        .then((response) => {
          this.errorlines = response.data.data;
          this.nodata = false;
          if (!this.importData.stats) return;
          const newMAx = Math.ceil(this.importData.stats.error / limit);

          this.pagination = {
            display: true,
            max: newMAx,
            model: i + 1,
          };
          if (this.pagination.model > newMAx) {
            this.pagination.model = newMAx;
          }
          this.$nextTick(() => {
            this.showDonut = true;
            this.updateChart(this.importData.stats.line, this.importData.stats.error);
          });
        });
    },
    getErrorLines() {
      this.$axios
        .get(`/imports/${this.$route.params.id}`)
        .then((response) => {
          this.importData = response.data.data;
          this.params = this.importData.config;
          this.params.options = response.data.data.options;
          this.params.file = response.data.data.file;
          this.$store.commit('importExport/setFileId', this.params.file);
          this.uploadPagebyIndex(0);
        })
        .catch((err) => {
          this.nodata = true;
        });
    },
    getEvent(message) {
      if (message.status === 'inprogress') {
        this.$nextTick(() => {
          this.showDonut = true;
          this.updateChart(message.line, message.error);
        });
      } else if (message.status === 'done') {
        this.getErrorLines();
      }
    },
    setWinHeight() {
      this.windowHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
    },
  },
  mounted() {
    if (this.$route.name === 'preupload') {
      this.$store.commit('importExport/setCurrentStep', 4);
      this.$store.commit('header/setHeader', {
        title: 'Import CSV',
        subtitle: 'Errors',
      });
    } else if (this.$route.name === 'upload') {
      this.$store.commit('importExport/setCurrentStep', 5);
      this.$store.commit('header/setHeader', {
        title: 'Import CSV',
        subtitle: 'Upload to SAP',
      });
    }

    this.id = this.$route.params.id;
    this.getErrorLines();
    this.$store.commit('auth/setObservable', {
      observable: {
        regex: `imports/${this.$route.params.id}`,
        func: this.getEvent,
      },
    });
    this.setWinHeight();

    this.listner = () => {
      this.setWinHeight();
    };
    window.addEventListener('resize', this.listner);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.listner);
    this.$root.$off('uploadSAP');
    this.$store.commit('auth/unsetObservable', `imports/${this.id}`);
  },
};
</script>

<style scoped lang="stylus">

.error-info {
  max-height: 250px;
  height: 250px;

  .chart-content {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;

    .css-donut {
      /*border: 1px solid red;*/
    }

    .svg-circle {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    .circle_animation {
      -webkit-animation: css 1s ease-out forwards;
      animation: css 1s ease-out forwards;
      stroke-dasharray: 440;
    }
  }

  .errors-founded {
    max-height: 100px;
    font-size: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
    opacity: 0.5;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 50px);

  .grid-content {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .upload {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
