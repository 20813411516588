<template>
<q-page-container>
      <q-page>
  <div style="padding: 1rem;">
    <p>Please follow the following procedure :</p>
    <q-timeline color="secondary">
      <q-timeline-entry title="Download the Excel template">
        <p>This spreadsheet will contain the actual configuration and the appropriate file layout.</p>
        <p><q-btn color="secondary" @click="download">Download</q-btn></p>
      </q-timeline-entry>
      <q-timeline-entry title="Modify at will">
        <p>You will be able to set the derivation rules.</p>
      </q-timeline-entry>
      <q-timeline-entry title="Re-upload the modified document">
        <p>The new rules will be applied.</p>
        <div class="pickerrow">
          <q-file class="filepicker" dense square outlined v-model="file" label="Filename">
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
          <q-btn color="primary" @click="upload">Upload</q-btn>
        </div>
      </q-timeline-entry>
      <q-timeline-entry title="Wait for the computation to occur">
      </q-timeline-entry>
    </q-timeline>
  </div>
      </q-page>
</q-page-container>
</template>
<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  methods: {
    upload() {
      this.$axios.post('/rules/import', this.file, {
        headers: { 'Content-Type': 'application/octet-stream', responseType: 'blob' },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'import-result.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Export failed', icon: 'warning' });
        });
    },
    download() {
      const params = {};
      this.$axios.get('/rules/export', { params, responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'rules-conf.xlsx');
        document.body.appendChild(link);
        link.click();
      })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Export failed', icon: 'warning' });
        });
    },
  },
  mounted() {
    this.$store.commit('header/setHeader', {
      title: 'Local Price lists Configuration',
      subtitle: 'Bulk import/export of parameters',
    });
  },
};

</script>
<style lang="stylus" scoped>
.pickerrow {
  display: flex;
  gap: 1rem
  .filepicker {
    width: 20rem
    display: inline-block
  }
}
</style>
