<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Save rule')}}
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.rule.definition.common" class="drawer-container">
        <!-- <q-input label="Name" :value="$store.state.pricing.rule.label[locale]" @change="updaterulename"/>-->
        <localizedstring :label="$t('Rule Label')" name="label" editable="yes" :def="{name: 'label'}"
          :data="{ v: $store.state.pricing.rule.label }"
          @change="updaterulename" />

        <q-input filled square dense label="Validity date" fill-mask v-model="t"  class="full-width" mask="##/##/####" />
        <q-field dense filled square>
          <q-toggle :label="activation ? $t('Active') : $t('Inactive')" v-model="activation" color="positive" icon="done"/>
        </q-field>
        <div class="rule-info">
          <q-input dense :disable="true" :label="$t('Last edited by')" :value="$store.state.pricing.rule.modified_by || $store.state.pricing.rule.by"/>
          <q-input dense :disable="true" :label="$t('Created by')" :value="$store.state.pricing.rule.by"/>
          <q-input dense :disable="true" :label="$t('Modified on')" :value="$store.state.pricing.rule.updated_at ? toLocaleDate(new Date($store.state.pricing.rule.updated_at)): ''"/>
          <q-input dense :disable="true" :label="$t('Activated on')" :value="$store.state.pricing.rule.activated_at ? toLocaleDate(new Date($store.state.pricing.rule.activated_at)) : ''"/>
        </div>
    </div>
    <div class="lg-rule-actions">
      <q-btn v-if="candelete" :label="$t('Delete')" color="negative" @click="delprompt"/>&nbsp;
      <q-btn :disable="!cansave" :label="$t('Clone')" color="primary" @click="clone" />&nbsp;
      <q-btn :disable="!cansave" :label="$t('Save')" color="secondary" @click="save"/>
    </div>
    <q-dialog v-model="confirmdelete" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="warning" color="primary" text-color="white" />
          <span class="q-ml-sm">{{$t('Do you really want to do this ?')}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="$t('No')" color="primary" v-close-popup />
          <q-btn flat :label="$t('Yes')" color="negative" @click="del" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { can } from '@cabinetmariel/scopes';
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';
import localizedstring from '../../components/attributesDisplay/localizedstring';

export default {
  mixins: [localization, rulemixins],
  components: {
    localizedstring,
  },
  data() {
    return {
      confirmdelete: false,
      locale: this.$store.state.auth.locale,
    };
  },
  computed: {
    candelete() {
      return can('rules.delete', this.$store.state.auth.user.scopes);
    },
    cansave() {
      return can('rules.post', this.$store.state.auth.user.scopes);
    },
    t: {
      get() {
        return this.toLocaleDate(this.$store.state.pricing.rule.t);
      },
      set(v) {
        this.$store.commit('pricing/updateRuleT', this.fromLocaleDate(v));
      },
    },
    activation: {
      get() {
        if (this.$store.state.pricing.rule && this.$store.state.pricing.rule.active) return true;
        return false;
      },
      set(v) {
        this.$store.commit('pricing/updateRuleActivate', v);
      },
    },
  },
  methods: {
    updaterulename(data) {
      this.$store.commit('pricing/updateRuleName', data.label /* { name, locale: this.locale } */);
    },
    del() {
      this.$store.dispatch('pricing/delRule');
    },
    delprompt() {
      this.confirmdelete = true;
    },
    save() {
      this.$store.dispatch('pricing/save');
    },
    clone() {
      this.$store.dispatch('pricing/clone');
    },
  },

};
</script>
<style lang="stylus" scoped>
label {
  padding-left: 0;
  padding-right: 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.drawer-container {
  padding: 1rem;
  position relative
  .rule-info {
    position: absolute;
    bottom: 1rem;
    left: 1rem
    right: 1rem
  }
}
</style>
