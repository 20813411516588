<template>
  <div class="drawer-container" style ="width:320px;">
 <p>{{$t('Configured volume discounts are :')}}</p>
 <table class="vdiscount-table">
  <thead>
    <tr><th>{{$t('Qty')}}</th><th>{{$t('Discount')}}</th><th>&nbsp;</th></tr>
  </thead>
  <tbody>
    <tr v-for="d in $store.state.pricing.rule.definition.vdiscounts" :key="d.qty">
      <td class="ralign">{{d.qty}}</td>
      <td class="ralign">{{d.discount}} %</td>
      <td><q-btn icon="delete" dense small @click="remove(d.qty)"/></td></tr>
  </tbody>
  <tfoot>
    <tr>
      <td class="ralign"><input v-model="current.qty" type="number"/></td>
      <td><input v-model="current.discount" type="number" placeholder="% of discount"/>&nbsp;%</td>
      <td><q-btn color="primary" small dense icon="add" @click="add"/></td>
    </tr>
  </tfoot>
 </table>

  <q-btn round color="primary" icon="add"
    class="fixed ruleparams-fab" @click="$router.push({name: 'selectattr'})"/>
  </div>
</template>

<script>
import { localization } from '../../lib/mixins';
import { store } from '../../lib/nrstore';

export default {
  mixins: [localization],
  data() {
    return {
      current: {
      },
    };
  },
  methods: {
    add() {
      this.$store.commit('pricing/updateVDiscounts', [...this.$store.state.pricing.rule.definition.vdiscounts, this.current]);
      this.current = {};
    },
    remove(qty) {
      this.$store.commit('pricing/updateVDiscounts', this.$store.state.pricing.rule.definition.vdiscounts.filter((d) => (d.qty !== qty)));
    },
  },
};
</script>

<style lang="stylus" scoped>
.vdiscount-table {
  width: 100%
  input {
    width: 90%
  }
  .ralign {
    text-align: right;
  }
}
</style>
