<template>
  <div>
    <q-toolbar class="bg-primary text-white">
      <q-btn flat round dense icon="cancel" @click="close()"/>
      <q-toolbar-title>{{getTitle()}}</q-toolbar-title>
      <q-icon
        class="locked"
        :name="islocked ? 'lock': 'lock_open'"
        @click.native="toggleLock()"
        size="1.5rem"
      ></q-icon>
    </q-toolbar>
    <div class="content">
        <q-input
          v-model="form.name"
          :error="$v.form.name.$error" bottom-slots :error-message="getError($v.form.name)"
          :disable="islocked"
          type="text"
          :label="$t('Name')"
          @input="$v.form.name.$touch"
        />
        <q-input
          v-model="form.label"
          :disable="islocked"
          :error="$v.form.label.$error" bottom-slots :error-message="getError($v.form.label)"
          type="text"
          :label="$t('Label')"
          @input="$v.form.label.$touch"
        />
      <div class="validation">
        <q-btn @click="close()">cancel</q-btn>
        <q-btn :disable="islocked || cannotsave" color="secondary" @click="localsave()">{{$t('Save')}}</q-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators';

/* function deepclone(value) {
  return JSON.parse(JSON.stringify(value));
} */
function fromitemtoform(item, locale) {
  return item ? { name: item.name, label: item.label[locale] } : { label: {} };
}
export default {
  props: {
    isnew: {
      Type: String,
    },
    item: {
      type: Object,
    },
    close: {
      type: Function,
    },
    save: {
      type: Function,
    },
  },
  data() {
    return {
      search: '',
      action: null,
      locked: true,
      id: null,
      type: null,
      curentTag: null,
      locale: this.$store.state.auth.locale,
      form: { },
    };
  },
  validations: {
    form: {
      label: { required, minLength: minLength(3) },
      name: { required },
    },
  },
  watch: {
    item(to, from) {
      this.locked = !!to.name;
      this.form = fromitemtoform(to, this.locale);
    },
  },
  computed: {
    cannotsave() {
      this.$v.form.$touch();
      return this.$v.form.$error;
    },
    islocked() {
      if (this.isnew) return false;
      return this.locked;
    },
  },
  methods: {
    getTitle() {
      if (this.isnew) return this.isnew === 'tagset' ? 'New Tagset' : 'New Value';
      return this.item.label[this.locale];
    },
    toggleLock() {
      this.locked = !this.locked;
    },
    go() {
      this.$router.push({ name: 'newattr' });
    },
    getError(input) {
      if (!input.required) return 'Ce champ est requis';
      if (!input.minLength) return '3 character minimun.';
      return 'Saisie invalide';
    },
    goback() {
      const index = this.$route.path.indexOf('/drawer');
      this.$router.push(this.$route.path.substr(0, index));
    },
    localsave() {
      this.save(this.item, {
        label: {
          [this.locale]: this.form.label,
        },
        name: this.form.name,
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.content {
  padding: 1rem;
}
</style>
