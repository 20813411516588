<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>Fetch new parts</q-toolbar-title>
    </q-toolbar>
    <div class="drawer-content">
      <q-input label="Part number(s)" filled square type="textarea" v-model="references"/>
      <ul>
        <li>Part numbers must be comma, semicolon or new line separated,</li>
        <li>Processing can take some time and at least few minutes.</li>
      </ul>
      <q-btn color="secondary" label="Fetch" @click="fetch"/>
    </div>
  </div>
</template>
<script>
import { localization } from '../../../lib/mixins';

export default {
  mixins: [localization],
  props: ['data'],
  data() {
    return {
      references: '',
    };
  },
  methods: {
    closeDrawer() {
      this.data.type = undefined;
    },
    fetch() {
      const partsnumbers = this.references.split(/[,;\n]/g).map((i) => i.trim().toUpperCase()).filter((i) => !!i);
      if (!partsnumbers.length) {
        this.$q.notify({ type: 'negative', message: 'Please make sure to type in some part numbers', icon: 'warning' });
        return;
      }
      this.$axios.post('/products', partsnumbers).then(() => {
        this.$q.notify({ type: 'positive', message: 'Parts got marked for fetching and should appear in 5/10 minutes', icon: 'done' });
      }).catch(() => {
        this.$q.notify({ type: 'negative', message: 'Something went wrong, not all parts might have been marked for fetching', icon: 'warning' });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-content {
  padding: .5rem;
}
</style>
