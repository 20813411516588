<template>
  <div ref="treeContainer" class="container">
    <svg ref="svg" v-if="data" class="svg"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3';
import { debounce } from 'lodash';

export default {
  data() {
    return {
      treeHasErrors: false,
      selectedNode: null,
      selectedNodeId: null,
      nodeWidth: 180,
      nodeHeight: 70,
      nodeSpacing: 20,
      ops: {
        sw: 'Start with',
        // ew: 'End with',
        // co: 'Contains',
        // eq: 'Equal',
        // ne: 'Not equal',
        gt: 'Greater than',
        lt: 'Less than',
        // ge: 'Greater than or equal',
        // le: 'Less than or equal',
        bt: 'Between',
        // nb: 'Not between',
        // in: 'In',
        // ni: 'Not in',
        // is: 'Is null',
        // ns: 'Is not null',
      },
    };
  },
  props: ['data', 'attributsList'],
  watch: {
    data: {
      deep: true,
      handler() {
        this.renderTree();
        this.data.forEach((node) => {
          this.checkForErrors(node);
        });
      },
    },
    treeHasErrors(newVal) {
      this.$emit('hasError', newVal);
    },
  },
  mounted() {
    this.renderTree();
    this.debouncedRenderTree = debounce(this.renderTree, 100);
    window.addEventListener('resize', this.renderTree);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedRenderTree);
  },
  methods: {
    highlightPathToRoot(node) {
      // Remove existing highlights
      d3.selectAll('.node').classed('highlighted', false).classed('upper-sibling-highlighted', false);
      d3.selectAll('.link').classed('highlighted', false).classed('upper-sibling-highlighted', false);

      let currentNode = node;
      while (currentNode) {
        // Highlight the current node
        // eslint-disable-next-line no-loop-func
        d3.selectAll('.node').filter((d) => d === currentNode)
          .classed('highlighted', true);

        // Highlight the link to the parent
        if (currentNode.parent) {
          // eslint-disable-next-line no-loop-func
          d3.selectAll('.link').filter((d) => d.target === currentNode)
            .classed('highlighted', true);

          // Find the index of the current node among its siblings
          // eslint-disable-next-line no-loop-func
          const siblingIndex = currentNode.parent.children.findIndex((sibling) => sibling === currentNode);

          // Highlight all upper siblings with a different class
          currentNode.parent.children.forEach((sibling, index) => {
            if (index < siblingIndex) {
              d3.selectAll('.node').filter((d) => d === sibling)
                .classed('upper-sibling-highlighted', true);
              d3.selectAll('.link').filter((d) => d.target === sibling)
                .classed('upper-sibling-highlighted', true);
            }
          });
        }

        // Move up the tree
        currentNode = currentNode.parent;
      }
    },

    selectNode(nodeElement, d) {
      console.log('nodeElement:', nodeElement);
      console.log('d:', d);
      // Clear any previous selections
      if (this.selectedNode) {
        this.selectedNode.classed('selected', false);
      }
      d3.selectAll('.node').classed('selected', false);
      d3.selectAll('.link').classed('selected', false);

      // Select the current node and add the 'selected' class
      this.selectedNode = d3.select(nodeElement).classed('selected', true);
      this.selectedNodeId = d.data.id;

      // Highlight the path to the root
      this.highlightPathToRoot(d); // Here 'd' is the actual D3 node data

      // Emit an event with the selected node data
      this.$emit('nodeSelected', d);
    },

    initializeSvg() {
      d3.select(this.$refs.svg).selectAll('*').remove();
      const container = this.$refs.treeContainer;

      const width = (container && container.clientWidth) || 800; // default width
      const height = (container && container.clientHeight) || 600; // default height

      const svg = d3.select(this.$refs.svg)
        .attr('width', width)
        .attr('height', height);

      return svg;
    },

    drawLinks(g, root) {
      g.selectAll('.link')
        .data(root.links())
        .enter().append('path')
        .attr('class', 'link')
        .attr('d', (d) => {
          const sourceX = d.source.x + this.nodeWidth;
          const sourceY = d.source.y;

          // Calculate the targetX considering the radius if it's a decision node
          const targetX = d.target.x;
          const targetY = d.target.y;

          return `M${sourceX},${sourceY}
              H${sourceX + (this.nodeSpacing + this.nodeWidth / 2)}
              V${targetY}
              H${targetX}`;
        })
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-width', 2);
    },

    drawNodes(g, root) {
      this.treeHasErrors = false;
      const vm = this;
      const nodes = g.selectAll('.node')
        .data(root.descendants())
        .enter().append('g')
        .attr('class', 'node')
        .attr('transform', (d) => `translate(${d.x},${d.y})`)
        .on('click', (event, d) => {
          vm.selectNode(event.currentTarget, d);
        });

      nodes.each(function walknode(d) {
        // d.data.nodeElement = this;
        const node = d3.select(this);
        // Check if d.data is not null and has a 'decision' property
        if (d.data && 'decision' in d.data) {
          vm.drawDecisionNode(node, d);
        } else if (d.data && 'condition' in d.data) {
          vm.drawConditionNode(node, d);
        }
        if (vm.checkForErrors(d)) {
          vm.treeHasErrors = true; // Set error flag if any node has an error
        }
      });

      // Emit event if tree has errors
      this.$emit('hasError', this.treeHasErrors);
    },

    drawDecisionNode(node, d) {
      const radius = Math.min(this.nodeWidth, this.nodeHeight) / 2;

      // Check if d.data exists and has a 'decision' property
      if (d.data && 'decision' in d.data) {
        // Draw a circle for decision nodes
        node.append('circle')
          .attr('r', radius)
          .attr('stroke', '#000')
          .attr('stroke-width', 2)
          .attr('transform', `translate(${this.nodeWidth / 2}, 0)`);

        // Prepare and add the text for decision nodes
        const text = node.append('text')
          .attr('dy', '.35em')
          .attr('x', this.nodeWidth / 2)
          .attr('y', 0)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle');

        // Process the decision text
        const lines = [
          'Decision:',
          `${d.data.decision}`,
        ];

        // Call the shared function to append text
        this.appendNodeText(node, lines, this.nodeWidth / 2, -3, true);
      }
    },

    drawConditionNode(node, d) {
      const chamferSize = 10;

      // Draw the chamfered rectangle for condition nodes
      node.append('path')
        .attr('d', `
      M ${chamferSize},0
      H ${this.nodeWidth - chamferSize}
      L ${this.nodeWidth},${chamferSize}
      V ${this.nodeHeight - chamferSize}
      L ${this.nodeWidth - chamferSize},${this.nodeHeight}
      H ${chamferSize}
      L 0,${this.nodeHeight - chamferSize}
      V ${chamferSize}
      Z
    `)
        .attr('transform', `translate(0, ${-this.nodeHeight / 2})`)
        .attr('stroke', '#000')
        .attr('stroke-width', 2);

      // Prepare and add the text for condition nodes
      const text = node.append('text')
        .attr('dy', '.35em')
        .attr('x', this.nodeWidth / 2)
        .attr('y', -this.nodeHeight / 2 + 20)
        .attr('text-anchor', 'start');

      // Process and truncate the condition text
      // eslint-disable-next-line prefer-const
      let { op, attribute, args } = d.data.condition;
      op = this.ops[op] || op;
      attribute = this.attributsList.find((a) => a.value === attribute)?.text || attribute;

      const displayArgs = args.map((arg) => {
        if (typeof arg === 'object') {
          return `${arg.number} ${arg.unit}`;
        }
        return arg;
      });

      const maxLength = 15;
      const maxLength2 = 20;
      const truncatedArgs = displayArgs.map((arg) => (arg.length > maxLength ? `${arg.substring(0, maxLength)}...` : arg));
      const truncatedAttribute = attribute.length > maxLength2 ? `${attribute.substring(0, maxLength2)}...` : attribute;

      // Combine the condition parts into lines
      const lines = [
        // 'Condition:',
        `${truncatedAttribute}`,
        `${op}`,
        `${truncatedArgs.join(', ')}`,
      ];

      // Call the shared function to append text
      this.appendNodeText(node, lines, this.nodeWidth / 2, -this.nodeHeight / 2 + 20 - 3, false);

      // Append an icon
      node.append('text')
        .attr('class', 'material-icons')
        .attr('x', 10)
        .attr('y', -16)
        .text('key');

      node.append('text')
        .attr('class', 'material-icons')
        .attr('x', 10)
        .attr('y', 6)
        .text('functions');

      node.append('text')
        .attr('class', 'material-icons')
        .attr('x', 10)
        .attr('y', 27)
        .text('123');
    },

    appendNodeText(node, lines, xPosition, yPosition, center) {
      const text = node.append('text')
        .attr('dy', '.35em')
        .attr('x', xPosition - (center ? 0 : 50))
        .attr('y', yPosition)
        .attr('text-anchor', center ? 'middle' : 'start')
        .attr('alignment-baseline', 'middle');

      // Append tspans for each line
      lines.forEach((line, i) => {
        text.append('tspan')
          .attr('x', text.attr('x'))
          .attr('dy', i ? '1.75em' : 0)
          .text(line)
          .attr('text-anchor', center ? 'middle' : 'start')
          .attr('font-weight', i === 0 ? 'bold' : 'normal');
      });
    },

    // Calculate the width of a subtree
    getSubtreeWidth(node) {
      let width = this.nodeWidth;
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          width += this.getSubtreeWidth(child) + this.nodeSpacing;
        });
      }
      return width;
    },

    // Calculate the height of a subtree
    getSubtreeHeight(node) {
      if (!node.children || node.children.length === 0) {
        return this.nodeHeight;
      }
      let totalHeight = 0;
      node.children.forEach((child) => {
        totalHeight += this.getSubtreeHeight(child) + this.nodeSpacing;
      });
      return totalHeight;
    },

    // Custom layout for nodes in the tree
    customLayout(node, depth = 0, currentX = -150, yOffset = -230) {
      // Set the x position for nodes at the same depth
      node.x = currentX + (depth * (this.nodeWidth + this.nodeSpacing));
      // Increment y position for each node at the same depth
      node.y = yOffset;

      let newOffset = yOffset;

      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
        // Recursively lay out the child nodes
          this.customLayout(child, depth + 1, currentX, newOffset);
          // Update the y offset for the next sibling
          newOffset += this.getSubtreeHeight(child) + this.nodeSpacing;
        });
      }
    },

    checkForErrors(node) {
      let nodeHasError = false;
      if (!node || !node.data) {
        return false;
      }

      // Remove all error classes
      d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', false);

      // Condition 1: End nodes should be decision nodes only
      if ((!node.children || node.children.length === 0) && !node.data.decision) {
        d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
        nodeHasError = true;
      }

      // Condition 2: Decision nodes should not have children
      if (node.data && node.data.decision && node.children && node.children.length > 0) {
        d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
        nodeHasError = true;
      }

      // Condition 3: Condition nodes should have children
      if (node.data && node.data.condition && (!node.children || node.children.length === 0)) {
        d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
        nodeHasError = true;
      }

      // Condition 4: Node can't have a decision parent
      if (node.parent && node.parent.data && node.parent.data.decision) {
        d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
        nodeHasError = true;
      }

      // Condition 5: Check if the node is 'auto' and its parent is not 'between'
      if (node.data && node.data.decision && node.data.decision === 'auto' && node.parent && (!node.parent.data.condition || node.parent.data.condition.op !== 'bt')) {
        d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
        nodeHasError = true;
      }

      // Condition 6: Condition nodes can't have empty attributes, operators or arguments
      if (node.data && node.data.condition) {
        const { attribute, op, args } = node.data.condition;
        if (!attribute || !op || !args || args.length === 0) {
          d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
          nodeHasError = true;
        }
      }

      // Condition 7: A decision node can't be the brother of a condition node
      if (node.parent && node.parent.children && node.parent.children.length > 0 && node.data && node.data.decision) {
        const siblings = node.parent.children;
        const nodeIndex = siblings.findIndex((n) => n === node);
        if (nodeIndex > 0 && siblings[nodeIndex - 1].data && siblings[nodeIndex - 1].data.condition) {
          d3.selectAll('.node').filter((d) => d.data === node.data).classed('error', true);
          nodeHasError = true;
        }
      }

      // auto auto ?

      // Condition 8: Check if a 'auto' decision node has numeric decision siblings
      if (node.data && node.data.decision && node.data.decision === 'auto' && node.parent) {
        const siblings = node.parent.children || [];
        const nodeIndex = siblings.findIndex((n) => n === node);

        // Assume there is no error to start with
        let hasError = false;

        // Only check the previous sibling if it exists
        if (nodeIndex > 0 && siblings[nodeIndex - 1].data) {
          const prevSiblingDecision = siblings[nodeIndex - 1].data.decision;
          // eslint-disable-next-line no-restricted-globals
          hasError = hasError || isNaN(Number(prevSiblingDecision));
        }

        // Only check the next sibling if it exists and no error has been found yet
        if (nodeIndex < siblings.length - 1 && siblings[nodeIndex + 1].data) {
          const nextSiblingDecision = siblings[nodeIndex + 1].data.decision;
          // eslint-disable-next-line no-restricted-globals
          hasError = hasError || isNaN(Number(nextSiblingDecision));
        }

        // Apply error class if an error was detected
        if (hasError) {
          d3.selectAll('.node').filter((d) => d === node).classed('error', true);
          nodeHasError = true;
        }
      }

      return nodeHasError;
    },

    renderTree() {
      // Remove existing content in the SVG container
      d3.select(this.$refs.svg).selectAll('*').remove();

      const container = this.$refs.treeContainer;
      const width = container.clientWidth || 500;
      let height = container.clientHeight;

      // Initialize the SVG container
      const svg = d3.select(this.$refs.svg)
        .attr('width', width)
        .attr('height', height);

      const g = svg.append('g');
      g.attr('transform', `translate(0, ${(height
       / 2 + ((Math.abs(height - 550) / 550)) * 300)})`);

      // Compute the layout of the tree
      const tmp = { children: [...this.data] };
      const root = d3.hierarchy(tmp);
      this.customLayout(root);

      // Resize the SVG container based on the layout
      const maxY = d3.max(root.descendants(), (d) => d.y);
      height = maxY + this.nodeHeight + this.nodeSpacing + 250;
      svg.attr('height', height * 1.2 + 800);

      // Draw the links between nodes
      this.drawLinks(g, root);

      // Draw the nodes themselves
      this.drawNodes(g, root);

      if (this.selectedNodeId) {
        const nodeToSelect = d3.selectAll('.node').filter((d) => d.data.id === this.selectedNodeId).node();
        if (nodeToSelect) {
          // eslint-disable-next-line no-underscore-dangle
          this.selectNode(nodeToSelect, nodeToSelect.__data__);
        }
      }
    },

  },
};
</script>

<style lang="stylus">

.container{
  width: 100%;
  height: 100%;
}

.svg {
}

.link {
  stroke: #555;
  stroke-width: 2px;
}

.node {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text {
    font: 12px sans-serif;
    alignment-baseline: middle;
    user-select : none;
  }

  circle,
  path {
    fill: #FFF;
  }

  &:hover {
    circle,
    path {
      stroke: blue;
    }
  }
}

.node text.material-icons {
  font-family: 'Material Icons';
  font-size:  25px;
}

/*** SELECTED ***/
.selected circle,
.selected path {
  stroke: blue !important;
  fill: rgb(205, 205, 250) !important;
}
.link.selected {
  stroke: blue !important;
  stroke-width: 3px;
}

/*** HIGHLIGHTED ***/

.highlighted circle,
.highlighted path {
  fill: rgb(199, 255, 197);
  stroke: rgba(6, 146, 1, 0.5);
}
.link.highlighted {
  stroke: rgba(6, 146, 1,  0.5);
  stroke-width: 3px;
}

/*** ORANGE HIGHLIGHT FOR UPPER SIBLINGS ***/
.upper-sibling-highlighted circle,
.upper-sibling-highlighted path {
  fill: rgb(253, 225, 173);
  stroke: rgba(255, 140, 0, 0.5);
}

.link.upper-sibling-highlighted {
  stroke: rgba(255, 140, 0, 0.5);
  stroke-width: 3px;
}

/*** ERROR ***/
.error circle,
.error path {
  fill: rgb(255, 233, 233);
  stroke: rgba(255, 0, 0, 0.5);
}

</style>
