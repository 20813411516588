<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>Resend data to ERP</q-toolbar-title>
    </q-toolbar>
    <div class="drawer-content">
      <q-banner rounded class="lg-banner bg-negative text-white">
        <template v-slot:avatar>
          <q-icon name="warning" color="white" />
        </template>
        {{$t('This action will be applied to all parts matching the current filter. So be extra careful with this action.')}}
      </q-banner>
      <q-banner rounded class="lg-banner bg-positive text-white">
        <template v-slot:avatar>
          <q-icon name="mood" color="white" />
        </template>
        {{$t('You can resend to your ERP any of the synchronized attributes listed below.')}}
      </q-banner>
      <q-input  :label="$t('Validity date :')" mask="##/##/####" fill-mask class="full-width" v-model="activationdateinput" />
      <div class="q-field" style="position: relative;"><div class="q-field--float">
        <span class="q-field__label absolute ellipsis">{{$t('Attribute(s) to resend :')}}</span>
        <div class="syncattrlist">
          <q-checkbox dense size="sm" v-for="a in Object.values($store.state.pricing.defs.defs2).filter((a) => (!!a.sync))" :key="a.id" :val="a.name" :label="localized(a.label)" v-model="selection" />
        </div>
      </div></div>
    </div>
    <div class="lg-rule-actions"> <q-btn :disable="selection.length === 0" color="secondary" label="Resend" @click="resend"/></div>
  </div>
</template>
<script>
import { localization } from '../../../lib/mixins';

export default {
  mixins: [localization],
  props: ['data'],
  data() {
    return {
      activationdate: this.$store.state.catalog.when,
      references: '',
      selection: [],
    };
  },
  computed: {
    activationdateinput: {
      get() {
        return this.toLocaleDate(this.activationdate);
      },
      set(v) {
        this.activationdate = this.fromLocaleDate(v);
      },
    },
  },
  methods: {
    closeDrawer() {
      this.data.type = undefined;
    },
    resend() {
      if (this.selection.length === 0) return;
      this.$axios.post('/bulk/resend', {
        filter: this.$store.state.catalog.filters,
        attributes: {
          selection: this.selection,
          when: this.activationdate,
        },
        when: this.$store.state.catalog.when,
      }).then((response) => {
        this.$q.notify({ type: 'positive', message: this.$t('Parts will have the selected attributed retransmitted'), icon: 'done' });
        this.$emit('update');
      }).catch((error) => {
        this.$q.notify({ type: 'negative', message: this.$t('Something wrong happen trying to processs parts for retransmission'), icon: 'warning' });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-content {
  padding: .5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.syncattrlist {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}
.lg-banner:not(:first-child) {
  margin-top: .5rem;
}
</style>
