<template>
  <q-page-container v-if="imports">
    <q-page>
      <q-table
        class="fulltable importable"
        :data="imports"
        :columns="columns"
        :pagination.sync="serverPagination"
        :rows-per-page-options="[]"
        :loading="loading"
        @request="fetch"
        table-class="lg-import-table">

        <q-td slot="body-cell-id" auto-width slot-scope="props" :props="props" >
          <span class="uuid" :title="props.value">{{props.value.slice(0, 6)}}</span>
        </q-td>
        <q-td slot="body-cell-stats" slot-scope="props" :props="props"  :title="props.value ? props.value.tooltip : ''">
          <svg v-if="props.value" class="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 32">
            <defs>
              <filter id="f2" x="0" y="0" width="120%" height="120%">
                <feOffset result="offOut" in="SourceGraphic" dx="4" dy="4" />
                <feColorMatrix result="matrixOut" in="offOut" type="matrix"
                values="0.6 0 0 0 0 0 0.6 0 0 0 0 0 0.6 0 0 0 0 0 1 0" />
                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="2" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <rect class="bar-error" x="0" y="2" height="24" :width="props.value.error*100"/>
            <rect class="bar-ok" :x="props.value.error*100" y="2"
              height="24" :width="props.value.ok*100"
              filter="url(#f2)"/>
          </svg>
        </q-td>
        <q-td slot="body-cell-stats2" slot-scope="props" :props="props" :title="props.value ? props.value.tooltip : ''" >
          <svg v-if="props.value" class="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 32">
            <defs>
              <filter id="f2" x="0" y="0" width="120%" height="120%">
                <feOffset result="offOut" in="SourceGraphic" dx="4" dy="4" />
                <feColorMatrix result="matrixOut" in="offOut" type="matrix"
                values="0.6 0 0 0 0 0 0.6 0 0 0 0 0 0.6 0 0 0 0 0 1 0" />
                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="2" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <rect v-for="(s, index) in statuses" :key="index" :class="`bar-${s}`"
              :x="props.value.offsets[s]*100" y="2" height="24" :width="props.value[s]*100" filter="url(#f2)"/>
          </svg>
        </q-td>
        <q-td slot="body-cell-actions" slot-scope="props" :props="props">
          <q-btn-group>
            <q-btn
              color="primary" :icon="props.row.dryrun ? 'fast_forward' : 'speed'"
              @click="goTo(props.row.id, props.row.dryrun)"
            ></q-btn>
            <q-btn icon="description" type="a" color="primary" :href="mkpath(props.row.file)"></q-btn>
            <q-btn icon="delete" @click="deleteImport(props.row.id)"></q-btn>
          </q-btn-group>
        </q-td>

      </q-table>
      <div class="action">
        <q-btn
          round
          color="primary"
          icon="create_new_folder"
          class="add-user"
          @click="newImport()"
        />
      </div>
    </q-page>
  </q-page-container>
</template>

<script>
// TODO check for quasar import

import putils from '../../lib/pictures';

export default {
  data() {
    return {
      statuses: ['waiting', 'transmitted', 'rejected', 'acknowledged', 'active', 'inconsistent'],
      columns: [
        {
          field: 'id', name: 'id', label: this.$i18n.t('ID'), align: 'left', sortable: true,
        },
        {
          field: 'created_at', name: 'created_at', label: this.$i18n.t('Date'), align: 'left', sortable: true,
        },
        {
          field: 'name', name: 'name', label: this.$i18n.t('File name'), align: 'left', sortable: true,
        },
        {
          field: 'by', name: 'by', label: this.$t('User'), align: 'left', sortable: true,
        },
        {
          field: 'status', name: 'status', label: this.$i18n.t('Status'), align: 'left', sortable: true,
        },
        {
          field: 'stats', name: 'stats', label: this.$i18n.t('Stat. Import'), align: 'left',
        },
        {
          field: 'stats2', name: 'stats2', label: this.$i18n.t('Stat. ERP'), align: 'left',
        },
        {
          field: 'actions', name: 'actions', label: '',
        },
      ],
      loading: false,
      serverPagination: {
        page: 1,
        rowsPerPage: 20,
        rowsNumber: null,
      },
      imports: [],
      requestSettings: {},
    };
  },
  methods: {
    mkpath(file) {
      return putils.mkpath(file, this.$store.state.auth.token);
    },
    getData(row, field) {
      const lang = this.$store.state.auth.locale || navigator.language || navigator.userLanguage;
      switch (field) {
        case 'name':
        case 'id':
        case 'by':
          return row[field];
        case 'status':
          return this.$i18n.t(row[field]);
        case 'created_at':
          return new Date(row.created_at).toLocaleString(lang);
        case 'stats':
          if (!row[field]) return '';
          return `${row[field].error} / ${row[field].line}`; // JSON.stringify(row.field);
        case 'stats2':
          if (!row[field]) return '';
          return `${row[field].acknowledged || 0} - ${row[field].rejected || 0} / ${row[field].transmitted || 0}`; // JSON.stringify(row.field);
        default:
          return '';
      }
    },
    deleteImport(id) {
      this.$axios.delete(`/imports/${id}`).then(() => {
        this.fetch({ pagination: this.serverPagination });
        this.$q.notify({ icon: 'done', color: 'positive', message: this.$i18n.t('Import successfully deleted') });
      }).catch((error) => {
        this.$q.notify({ icon: 'warning', color: 'negative', message: this.$i18n.t('Import deletion failed') });
      });
    },
    goTo(id, dryrun) {
      this.$router.push(`/importexport/${dryrun ? 'preupload' : 'upload'}/${id}`);
    },
    newImport() {
      this.$store.commit('importExport/resetAllSteps');
      this.$router.push('/importexport/imports');
    },
    fetch({ pagination }) {
      this.loading = true;
      const limit = pagination.rowsPerPage || 0;
      const offset = limit * (pagination.page - 1) || 0;
      const params = {
        '@offset': offset,
        '@limit': limit,
      };
      if (pagination.sortBy) {
        params.sort = `${pagination.descending ? '-' : '+'}${pagination.sortBy}`;
      }
      this.$axios
        .get('/imports', { params })
        .then((r) => {
          const imports = r.data.data;
          const { meta } = r.data;
          this.serverPagination = {
            ...pagination,
            rowsNumber: meta.count,
          };
          imports.forEach((i) => {
            i.created_at = (new Date(i.created_at)).toLocaleString();
            if (i.stats) {
              const total = i.stats.line;
              if (!total) {
                i.stats = undefined;
                return;
              }
              i.stats.error = (i.stats.error || 0) / total;
              i.stats = { error: i.stats.error, ok: 1 - i.stats.error };
              i.stats.tooltip = `error: ${i.stats.error * total}\nok: ${i.stats.ok * total}`;
            }
            if (i.stats2) {
              let total = 0;
              i.stats2.offsets = { };
              this.statuses.forEach((s) => {
                i.stats2[s] = i.stats2[s] || 0;
                i.stats2.offsets[s] = 0;
                total += i.stats2[s];
              });
              if (!total) {
                i.stats2 = undefined;
                return;
              }
              this.statuses.forEach((s, j) => {
                if (j > 0) {
                  i.stats2.offsets[s] = i.stats2.offsets[this.statuses[j - 1]] + i.stats2[this.statuses[j - 1]];
                }
                i.stats2[s] /= total;
              });
              i.stats2.tooltip = this.statuses
                .map((s) => `${s}: ${i.stats2[s] * total}`).join('\n');
            }
          });
          this.imports.splice(0, this.imports.length, ...imports);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetch({ pagination: this.serverPagination });
  },
  created() {
    this.$store.commit('header/setHeader', {
      title: 'Import List',
      subtitle: '',
    });
  },
};
</script>

<style scoped lang="stylus">
>>> .lg-import-table {
  table.q-table {
    width: 100%;
    max-width 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  .action {
    .add-user {
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
  }
  .inline-svg {
    width: 100%;
    height: 100%;
    rect.bar-ok {
      fill: $positive;
      stroke: white;
    }
    rect.bar-error {
      fill: $negative;
      stroke: white;
    }
    rect.bar-transmitted {
      stroke white
      fill $green-4
    }
    rect.bar-acknowledged {
      stroke white
      fill $green-6
    }
    rect.bar-active {
      stroke white
      fill $green-8
    }
    rect.bar-waiting {
      stroke white
      fill $green-2
    }
    rect.bar-rejected {
      stroke white
      fill $red-6
    }
    rect.bar-inconsistent {
      stroke white
      fill $red-8
    }
  }
}
</style>
