import { render, staticRenderFns } from "./Attributes.vue?vue&type=template&id=3b0b5742&scoped=true&"
import script from "./Attributes.vue?vue&type=script&lang=js&"
export * from "./Attributes.vue?vue&type=script&lang=js&"
import style0 from "./Attributes.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./Attributes.vue?vue&type=style&index=1&id=3b0b5742&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0b5742",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSelect,QSeparator,QList,QExpansionItem,QItem});
