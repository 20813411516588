import Vue from 'vue';
import axios from 'axios';
import { logError } from './sentry';

Vue.prototype.$axios = axios;

export default ({ app }) => {
  axios.defaults.baseURL = process.env.API;
  axios.interceptors.request.use((config) => {
    if (app.store.state.auth.token) {
      config.headers.Authorization = `Bearer ${app.store.state.auth.token}`;
    }
    return config;
  }, (error) => {
    logError(error);
    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => response, (err) => {
    const { config, response: { status, data } } = err;

    if (status === 401) {
      const prom = app.store.dispatch('auth/standby', { config, data });
      return prom;
    }

    logError(err);
    return Promise.reject(err);
  });
};
