<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title style="max-width: 250px;">
        {{$t('Save Attributes & Prices')}}
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.rule.definition.common" class="drawer-container">
      <div v-if="inprogress">
        <div>
          <p>{{$t('Saving attributes :')}}</p>
          <q-linear-progress size="25px" stripe  v-model="progressa" color="accent">
          </q-linear-progress>
        </div>
        <div>
          <p>{{$t('Saving prices :')}}</p>
          <q-linear-progress size="25px" stripe  v-model="progressp" color="accent">
          </q-linear-progress>
        </div>
      </div>
      <div v-else>
        <q-banner v-if="activable.length === 0 && $store.state.pricing.backlog.length === 0" rounded class="price-banner bg-warning text-white">
          <template v-slot:avatar>
            <q-icon name="mood" color="white" />
          </template>
          {{$t('There is nothing to save yet.')}}</q-banner>
        <q-banner v-if="activable.length>0" rounded class="price-banner bg-positive text-white">
          <template v-slot:avatar>
            <q-icon name="check_circle_outline" color="white" />
          </template>
          {{activable.length}} {{$t('price(s) is/are going to be implemented upon save.')}}</q-banner>
        <q-banner v-if="$store.state.pricing.backlog.length>0" rounded class="price-banner bg-positive text-white">
          <template v-slot:avatar>
            <q-icon name="check_circle_outline" color="white" />
          </template>
          {{$store.state.pricing.backlog.length}} {{$t('part attribute(s) is/are going to be updated at the same time')}}</q-banner>
        <q-banner v-if="$store.state.pricing.rule.t && (toISODate($store.state.pricing.rule.t) !== toISODate(activationdate)) && ($store.state.pricing.rule.t >= (new Date()))" rounded class="price-banner bg-warning text-white">
          <template v-slot:avatar>
            <q-icon name="report_problem" color="white" />
          </template>
          {{$t('Beware! Rule activation date is :')}} {{toLocaleDate($store.state.pricing.rule.t)}}, {{$t('you should set the prices activation date to the same value unless you are sure of what you do.')}}</q-banner>
        <q-banner v-if="!$store.state.pricing.rule.activated_at" rounded class="price-banner bg-negative text-white">
          <template v-slot:avatar>
            <q-icon name="error_outline" color="white" />
          </template>
          {{$t('You cannot save prices without activating the rule first.')}}
        </q-banner>
        <q-input v-if="activable.length" :label="$t('Validity date')" mask="##/##/####" fill-mask class="full-width" v-model="activationdateinput" />
        <div v-if="$store.state.general.settings.classification &&  $store.state.pricing.defs.defs2.classification.settings && $store.state.pricing.defs.defs2.classification.settings.values">
          <p>Additionally you might want to override the classification :</p>
          <select v-model="classification">
            <option value="">{{$t('(AUTO)')}}</option>
            <option v-for="item in $store.state.pricing.defs.defs2.classification.settings.values" :key="item.name" :value="item.name">{{item.name}}</option>
          </select>
          <p>You can also force the following information :</p>
          <p class="warning">Beware: there is no computation at all, computed data will be overwritten by those values.</p>
          <dl>
            <dt>Currency:</dt><dd><select v-model="currency" class="unitselect">
              <option value="">(AUTO)</option>
              <option v-for="(u, index) in $store.state.general.settings.units.amount" :key="index" :value="u">{{u}}</option>
              </select></dd>
            <dt>UOM:</dt><dd><input v-model="qty" class="uomqty" type="number">&nbsp;<select v-model="uom" class="unitselect">
              <option value="">(AUTO)</option>
              <option v-for="(u, index) in $store.state.general.settings.units.number" :key="index" :value="u">{{u}}</option>
              </select></dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="lg-rule-actions">
      <q-btn v-if="!inprogress" :disable="nogo()" :label="$t('Save')" color="secondary" @click="save"/>
      <q-btn v-else :label="$t('Cancel')" color="negative" @click="cancel"/>
    </div>
  </div>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';

const { pricinglib, utils } = pricingUtilities;
const {
  serial, findactualdate, getPartValue, getValueFromRawEx,
} = utils;
const { isMasterPart } = pricinglib;
const daterangere = /[[\]()]/g;
const zerore = /[,. 0]/g;

function findvalue(part, key, targetcurrency) {
  const v = getPartValue(part, key, undefined, undefined, getValueFromRawEx);
  if (v === undefined) return { number: 0, fixed: '0', unit: key.endsWith('>$') ? targetcurrency : '%' };
  return v;
}
export default {
  mixins: [rulemixins, localization],
  computed: {
    activable() {
      // console.log('toto', parts[0], 'titi');
      return this.$store.state.pricing.activable;
    },
    activationdateinput: {
      get() {
        return this.toLocaleDate(this.activationdate);
      },
      set(v) {
        this.activationdate = this.fromLocaleDate(v);
      },
    },
  },
  data() {
    return {
      progressa: 0,
      progressp: 0,
      progresss: 0,
      inprogress: false,
      activationdate: this.$store.state.pricing.currenttime,
      classification: '',
      currency: '',
      qty: '1',
      uom: '',
    };
  },
  methods: {
    toISODate(d) {
      const yyyy = d.getFullYear();
      const mm = d.getMonth() + 1;
      const dd = d.getDate();
      return `${yyyy.toString().padStart(4, 0)}-${mm.toString().padStart(2, 0)}-${dd.toString().padStart(2, 0)}`;
    },
    mkexport() {
      this.$store.dispatch('pricing/exportParts');
    },
    nogo() {
      if (this.activable.length === 0 && this.$store.state.pricing.backlog.length === 0) return true;
      if (this.activable.length && !this.$store.state.pricing.rule.activated_at) return true;
      if (this.$store.state.pricing.nonpriced.length) return true;
      return false;
    },
    save() {
      this.inprogress = true;
      const startdate = this.toISODate(this.activationdate);
      const blen = this.$store.state.pricing.backlog.length;
      serial(this.$store.state.pricing.backlog.map((mutation) => () => this.$axios.patch(`/products/${mutation.part}`, {
        [mutation.attr]: mutation.value,
      })
        .then(() => {
          this.$store.commit('pricing/shiftFromBacklog');
          this.progressa = this.$store.state.pricing.backlog.length / blen;
          return Promise.resolve();
        })))
        .then(() => serial(this.activable.map((part, i) => () => {
          const priceset = this.$store.state.pricing.rule.tag;
          const idx = part.price ? findactualdate(part.price[priceset], this.activationdate) : -1;
          let end = idx < 0 ? '' : part.price[priceset][idx].t.split(',')[1].replace(')', '');
          if (idx < 0 && part.price && part.price[priceset] && part.price[priceset].length) {
            for (let j = 0; j < part.price[priceset].length; j++) { // eslint-disable-line
              const { t } = part.price[priceset][j];
              const lower = t.replace(daterangere, '').split(',')[0];
              if ((new Date(lower)) > this.activationdate) {
                end = lower;
              } else {
                break;
              }
            }
          }
          if (end === startdate) end = '';
          const tag = priceset === 'DEFAULT' ? undefined : priceset;
          let classificationrec = {};
          if (this.$store.state.general.settings.classification) { // classification support is enabled
            // we need to override classification if required
            // but master slave will override
            const classification = (this.$store.state.general.settings.masterslave
                && this.$store.state.general.settings.masterslave.forceclassification
                && part.masterpart && part.masterpart.v)
              ? this.$store.state.general.settings.masterslave.forceclassification
              : this.classification || part.classification.v;

            classificationrec = { classification };
          }
          let crcrec = {};
          if (this.$store.state.pricing.rule.definition.autocrc.enabled || this.$store.state.pricing.rule.definition.pivottype === 'multi2') {
            const crcvalue = part?.fullcostingratioproposed?.DEFAULT?.[0]?.v;
            if (crcvalue) {
              crcrec = { fullcostingratio: crcvalue };
            }
          } else {
            crcrec = { fullcostingratio: { number: 0, fixed: '0', unit: '%' } };
          }
          let pricestatus = 'manual';
          let proprec = {};
          if (part.proposedprice
            && part.proposedprice[priceset]
            && part.proposedprice[priceset][0]
            && part.proposedprice[priceset][0].v
            && part.proposedprice[priceset][0].v.fixed
            && part.proposedprice[priceset][0].v.fixed.replace(zerore, '') !== '') {
            if (part.proposedprice[priceset][0].v.fixed
              && (part.validatedprice.v.fixed === part.proposedprice[priceset][0].v.fixed)) {
              pricestatus = 'auto';
            }
            proprec = {
              proposedprice: part.proposedprice[priceset][0].v,
              purchasealert: part.purchasealert[priceset][0].v,
              rawproposedprice: part.rawproposedprice[priceset][0].v,
            };
          }
          if (this.currency) {
            part.validatedprice.v.unit = this.currency;
          }
          if (this.uom) {
            part.validatedprice.v.qty = { unit: this.uom, fixed: this.qty.toString(), number: parseFloat(this.qty) };
          }
          const validatedprice = JSON.parse(JSON.stringify(part.validatedprice.v));
          return this.$axios.patch(`/products/${part.id.v}`, {
            price: part.validatedprice.v,
            t: `[${startdate},${end})`,
            pricestatus,
            ...proprec,
            partstatus: false,
            tag,
            pricingmethod: this.$store.state.pricing.rule.method,
            ...classificationrec,
          })
            .then((response) => {
              const topatch = Object.keys(part).filter((key) => key.endsWith('>$'));
              topatch.push('fullcostingratio');
              const values = topatch.reduce((_, key) => {
                _[key.replace('>$', '$')] = findvalue(part, key, part.validatedprice.v.unit);
                return _;
              }, {
                t: `[${startdate},${end})`,
                fuzzy: true,
                tag,
              });
              return this.$axios.patch(`/products/${part.id.v}`, values).then((secondresponse) => {
                Object.keys(topatch).map((k) => k.replace('>$', '$')).forEach((key) => {
                  response.data.data[key] = secondresponse.data.data[key];
                });
                return Promise.resolve(response);
              });
            })
            .then((response) => {
              this.progressp = (i + 1) / this.activable.length;
              this.$store.commit('pricing/updatePrice', {
                data: response.data.data,
                one: this.$store.state.general.settings.one,
                locale: this.$store.state.auth.locale,
                settings: this.$store.state.general.settings,
              });
              if (!isMasterPart(part)) return Promise.resolve();
              return this.$axios.get('/products', {
                params: {
                  nolimit: 'yes',
                  columns: 'id,name,pricingmethod',
                  '@filters': JSON.stringify([{ column: 'masterpart', op: 'equals', args: [part.name.v] }]),
                },
              }).then((response2) => {
                const slaves = response2.data.data;
                return serial(slaves.map((slave) => () => {
                  const sid = slave.id.v;
                  if (sid === part.id.v) return Promise.resolve();
                  return this.$axios.patch(`/products/${sid}`, {
                    price: validatedprice,
                    t: `[${startdate},${end})`,
                    pricestatus,
                    partstatus: false,
                    tag,
                    ...classificationrec,
                  });
                }));
              });
            });
        })))
        .then(() => {
          this.$q.notify({ type: 'positive', message: 'All attributes and prices saved successfully', icon: 'done' });
          this.inprogress = false;
        })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Something went wrong', icon: 'warning' });
        });
    },
    cancel() {
      this.inprogress = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.price-banner {
  margin-bottom .5rem
}
.warning {
  padding 0
  font-size: smaller
  color: red;
}
.uomqty {
  width: 70px
  height 26px
}
.unitselect {
  height: 26px
}
</style>
