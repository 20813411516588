/*
export function someAction (context) {
}
*/
/* eslint-disable */

import axios from 'axios';

const windowheight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const meta = {
  columns: [
    "label",
    "name",
    "price",
  ],
  count: 49
}

export const setCatalog = (context, defs) =>
  new Promise((resolve, reject) => {
    const maxElem = Math.floor((windowheight - 150) / 50);
    const options = {
      offset: context.state.pages.current * maxElem,
      size: maxElem
    }
    axios.get('/products').then(response => {
      const catalogList = {
        data: response.data.data,
        meta
      }      
      context.commit("setMaxPages", catalogList.meta.count / maxElem);
      catalogList.data = catalogList.data.filter((val, index) => index < maxElem);
      context.commit("setCatalog", catalogList);
      resolve();
    })

  });

export const sortCatalog = (state, by) => {
  // state.data.data = state.data.data.sort((a, b) => {
  //   return a.name.value < b.name.value ? -1 : 1;
  // });
};
