<template>
  <q-page-container>
    <q-page>
      <div class="content">
       <h3>PROCESSES</h3>

       <q-table
          :data="processes"
          :columns="columns"
          row-key="id"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="id" :props="props">
                {{ props.row.id }}
              </q-td>
              <q-td key="definition" :props="props">
                {{ findLabel(props.row.processdefinition) }}
              </q-td>
              <q-td key="created_at" :props="props">
                {{ new Date(props.row.created_at).toLocaleDateString() }} {{ new Date(props.row.created_at).toLocaleTimeString() }}
              </q-td>
              <q-td key="status" :props="props">
                <q-icon name="hourglass_empty" class="activeLoop" color="grey" v-if="props.row.active === true" />
                <template v-else >
                  <q-icon name="check" color="green" v-if="props.row.success" />
                  <q-icon name="warning" color="red" v-else />
                </template>
              </q-td>
            </q-tr>
          </template>
        </q-table>

        <q-btn @click="showModale = true" :label="$t('Add a process')" color="secondary" style="margin-top:25px" icon="add" />

      </div>

      <q-dialog v-model="showModale" persistent transition-show="scale" transition-hide="scale">
        <q-card class="modaleCard">

          <q-card-section>
            <div class="text-h6">{{ $t('Start a new process') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none section1">
            {{ $t('Select a process and his options') }}
          </q-card-section>

          <q-card-section class="q-pt-none">
              <q-select
              style="margin-bottom:25px"
                :label="$t('Choose a process')"
                v-model="selectedProcess"
                :options="processDefinitionOptions"
                option-value="id"
                option-label="label"
                @input="updateSelectedProcess"
              ></q-select>

              <label v-if="selectedProcess">{{ $t('Options :') }}</label>
              <template v-for="(option, index) in selectedProcessOptions">
                <div :key="'string-option-' + index">
                  <q-input :label="localized(option.label['en-US'])"  v-model="option.value" v-if="option.type === 'number'" type="number" />
                  <q-checkbox :label="localized(option.label['en-US'])" v-model="option.value" v-else-if="option.type === 'boolean'" />
                  <q-input :label="localized(option.label['en-US'])"  v-model="option.value" v-else-if="option.type === 'date'" type="date" />
                  <q-input :label="localized(option.label['en-US'])"  v-model="option.value" v-else />
                </div>
              </template>
            </q-card-section>

          <q-card-actions class="bg-white text-teal cardAction">
            <q-btn :label="$t('Abord and quit')" flat  @click="showModale = false" />
            <q-btn :label="$t('Validate and run process')" flat  @click="addProcesses(); showModale = false" />
          </q-card-actions>
        </q-card>
      </q-dialog>

    </q-page>
  </q-page-container>
</template>

<script>
import { localization } from '../lib/mixins';

export default {
  components: { },
  mixins: [localization],
  data() {
    return {
      processes: [],
      processDefinition: [],
      columns: [
        {
          name: 'id', required: true, label: 'ID', align: 'left', field: (row) => row.id, sortable: true,
        },
        {
          name: 'definition', align: 'left', label: 'Definition label', field: (row) => row.definition, sortable: true,
        },
        {
          name: 'created_at', label: 'Created At', field: (row) => row.created_at, sortable: true,
        },
        {
          name: 'status', label: 'Status', field: (row) => row.status, sortable: true,
        },
      ],
      // Modale variables
      showModale: false,
      selectedProcess: null,
      selectedProcessOptions: null,
    };
  },
  mounted() {
    this.getProcesses();
    this.getProcessDefinition();
  },
  beforeDestroy() {},
  computed: {
    processDefinitionOptions() {
      return this.processDefinition.map((def) => ({
        id: def.id,
        label: this.localized(def.definition.label),
      }));
    },
  },
  methods: {
    findLabel(id) {
      const def = this.processDefinition.find((item) => item.id === id);
      const label = def?.definition?.label;
      return label ? this.localized(label['en-US']) : '';
    },
    getProcesses() {
      this.$axios.get('/processes')
        .then((response) => {
          console.log('processes: ', response.data.data);
          this.processes = response.data.data;
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    },
    getProcessDefinition() {
      this.$axios.get('/processdefinitions')
        .then((response) => {
          console.log('process definition: ', response.data.data);
          this.processDefinition = response.data.data;
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    },
    updateSelectedProcess() {
      const def = this.processDefinition.find((item) => item.id === this.selectedProcess.id);
      const options = def?.definition?.options;
      this.selectedProcessOptions = options;
    },
    addProcesses() {
      console.log('this.selectedProcess', this.selectedProcess);
      console.log('selectedProcessOptions', this.selectedProcessOptions);
      const params = {
        processdefinition: this.selectedProcess.id,
        options: this.selectedProcessOptions,
      };

      params.options = JSON.stringify(params.options);
      this.$axios.post('/processes', { ...params })
        .then((response) => {
          console.log('=> processes: ', response.data.data);
          this.getProcesses();
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.modaleCard {
  width:100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  overflow: hidden;
}

.cardAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid grey;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.activeLoop{
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
</style>
