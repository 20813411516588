import { render, staticRenderFns } from "./BugReport.vue?vue&type=template&id=1d3f3af9&scoped=true&"
import script from "./BugReport.vue?vue&type=script&lang=js&"
export * from "./BugReport.vue?vue&type=script&lang=js&"
import style0 from "./BugReport.vue?vue&type=style&index=0&id=1d3f3af9&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d3f3af9",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QLayout,QHeader,QToolbar,QToolbarTitle,QPageContainer,QPage,QInput,QSelect,QFooter,QBtn});
