<template>
  <span>{{name}}</span>
</template>

<script>
import { localization } from '../../lib/mixins';

export default {
  mixins: [localization],
  props: ['data'],
  components: {},
  data() {
    return {};
  },
  computed: {
    name() {
      if (!this.data) return this.localized('Unset');
      if (this.data.v === false) return this.localized('Up to Date');
      if (this.data.v === true) return this.localized('To review');
      return 'ERR-1';
    },
  },
};
</script>
