<template>
  <div>
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{part.name.v}}
        <span class="lg-header-subtitle">
          {{$t('Part comment')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="drawer-container">
      <div class="actualcomment" v-html="part.comment.v"></div>
    </div>
  </div>
</template>
<script>

import { rulemixins } from './mixins';
import { localization } from '../../lib/mixins';
import { store } from '../../lib/nrstore';

export default {
  data() {
    return {
      options: null,
      value: null,
    };
  },
  mixins: [rulemixins, localization],
  computed: {
    part() {
      return store.parts.find((p) => (p.id.v === this.$route.params.pid));
    },
  },
  methods: {
  },
};
</script>
<style lang="stylus" scoped>
p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  .actualcomment {
    height: 100%
    padding: 1rem
    background: #f9f9f9;
  }
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
</style>
