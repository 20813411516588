<template>
  <div class="costpluspath">
      <template v-for="(node, index) in path">
        <div v-if="node.type === 'condition'" class="condition" :key="index+ 'c'">
          <div class="attribute">{{localized(defs[node.attribute].label)}}</div>
          <div class="operation">{{localized(feexs[node.op].label)}}</div>
          <ul class="args">
            <li v-for="(a,index) in node.args" :key="index">
              <div v-if="feexs[node.op].type === 'number'">
                {{a.fixed}} {{a.unit}}
              </div><div v-else>
                {{a}}
              </div>
            </li>
          </ul>
        </div>
        <div class="decision" v-else-if="node.type === 'decision'" :key="index + 'd'">
          <div class="value">{{node.mode === 'auto'?'⚙':'웃'}}<br>{{Math.round(Number(node.value)*100)/100}}</div>
        </div>
        <div class="downarrow" :key="index + 'd'" v-if="index !== path.length - 1">↓</div>
      </template>
  </div>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { localization } from '../lib/mixins';

const { utils, pricinglib } = pricingUtilities;
const { mklib, findCurrentRule } = pricinglib;
const { findactualdate } = utils;
const { pmp: priorityfetch } = pricingMethods;

function fromIsoDateString(d) {
  const [yyyy, mm, dd] = d.split('-');
  return new Date(yyyy, mm - 1, dd);
}
export default {
  mixins: [localization],
  props: {
    rule: Object,
    feexs: Object,
    defs: Object,
    family: String,
    part: Object,
    currenttime: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      pmpset: priorityfetch(this.$store.state.general.settings.pmppriority || []),
      computedrule: undefined,
    };
  },
  computed: {
    actualrule() {
      return this.rule || this.computedrule;
    },
    actualpart() {
      const tmp = JSON.parse(JSON.stringify(this.part));
      const rule = this.actualrule;
      const { set, idx } = this.pmpset(tmp, rule.definition.common.pmpattr, rule.definition, new Date(), findactualdate);
      tmp[rule.definition.common.pmpattr][Symbol.for('actualset')] = set;
      return tmp;
    },
    path() {
      if (!this?.actualrule?.definition) return [];
      console.log('hello');
      const tmp = this.actualrule.definition.costPlusFunc(this.actualpart, this.actualrule.tag, this.currenttime, this.actualrule.definition.costplus, undefined, 0, true);
      return tmp;
    },
  },
  methods: {

  },
  mounted() {
    if (this.family) {
      Promise.all([
        this.$axios.get(`/families/${this.family}/rules`),
        this.$axios.get(`/rates/ALL/ALL/ALL/${(new Date()).toISOString().split('T')[0]}`),
      ]).then(([response, rates]) => {
        this.$store.commit('pricing/setRates', rates.data.data);
        const virtualstate = { rates: this.$store.state.pricing.rates, rule: undefined };
        response.data.data.forEach((data) => {
          // small prepare for the rule
          if (data.t) data.t = fromIsoDateString(data.t); else data.t = new Date();
          if (data.t2) data.t2 = fromIsoDateString(data.t2);
          const lib = mklib(virtualstate, this.$store.state.general.settings, () => {}); // warning side effets on lib.getRuleFromRegistry & lib.setPartClassification
          data.costPlusFactory = lib.costPlusFactory;
          if (data.method === 'cost+') {
            data.definition.costPlusFunc = lib.costPlusFactory(data.definition.costplus, this.$store.state.pricing.defs.defs2);
          }
        });
        this.computedrule = findCurrentRule(response.data.data, this.$store.state.general.settings.defaultpriceset);
        virtualstate.rule = this.computedrule;
      }).catch((error) => {
        console.error(error);
        this.computedrule = undefined;
      });
    }
  },
};
</script>
<style lang="stylus" scoped>
.costpluspath {
  .decision, .condition  {
    box-shadow: 2px 2px 4px 2px #ccc;
  }
  .condition {
    border: 1px solid #ccc;
    .operation {
      padding: .5rem;
    }
    .attribute {
      font-weight: bold;
      padding: .5rem;
      background: #f0f0f0;
    }
    .args {

    }
  }
  .decision {
    font-weight: bold;
    border-radius: 50%
    background: #f0f0f0;
    text-align: center;
    line-height: 1.8rem;
    width: 4rem;
    height: 4rem;
    margin: 1rem auto;
    background: lightgreen;
  }
  .downarrow {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;

  }
}
</style>
