<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Customer validation')}}
      </q-toolbar-title>
    </q-toolbar>

    <div  class="drawer-container">

      <div class="drawer-section">
        <p class="drawer-section-title">{{$t('Highlighted parts')}} : </p>

        <div class="partSelect" >
          <q-select filled square dense label="Part1" style="width:100%" v-model="highlightedParts[0]" :options="availlableParts" option-value="id" option-label="name" />
          <!-- <q-btn icon="delete" color="negative" @click="empty(0)" :disable="!highlightedParts[0]"/> -->
        </div>

        <div class="partSelect">
          <q-select filled square dense label="Part2" style="width:100%" v-model="highlightedParts[1]" :options="availlableParts" option-value="id" option-label="name" />
          <!-- <q-btn icon="delete" color="negative" @click="empty(1)" :disable="!highlightedParts[1]"/> -->
        </div>

        <div class="partSelect">
          <q-select filled square dense label="Part3" style="width:100%" v-model="highlightedParts[2]" :options="availlableParts" option-value="id" option-label="name" />
          <!-- <q-btn icon="delete" color="negative" @click="empty(2)" :disable="!highlightedParts[2]"/> -->
        </div>

        <q-btn style="margin-bottom: 25px" color="secondary" icon="save" @click="save()">{{$t('Save')}}</q-btn>

        <q-btn color="secondary" icon="link" @click="generateLink()">{{$t('Copy link')}}</q-btn>
      </div>
    </div>

  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';
import { store } from '../../lib/nrstore';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      highlightedParts: [null, null, null],
      availlableParts: [],
    };
  },
  mounted() {
    // keep only part name and id for select
    // console.log('STORE', store.parts);
    this.availlableParts = store.parts.map((p) => ({ id: p.id.v, name: p.name.v }));
    // console.log('STORE', this.availlableParts);
    // console.log('STORE SAVED', this.$store.state.pricing.rule.definition.highlightedParts);
    if (!this.$store.state.pricing.rule.definition) this.$store.state.pricing.rule.definition = {};
    if (this.$store.state.pricing.rule.definition.highlightedParts) {
      this.highlightedParts = this.$store.state.pricing.rule.definition.highlightedParts;
    }
  },
  methods: {
    empty(id) {
      this.highlightedParts[id] = '';
    },
    save() {
      // console.log('SAVE', this.highlightedParts);
      this.$store.commit('pricing/setHighlightedParts', { highlightedParts: this.highlightedParts });
      this.$store.dispatch('pricing/save');
      this.$store.dispatch('pricing/storecomputed');
    },
    generateLink() {
      const customerAppUrl = this.$store.state.general.settings.customerappurl;
      const familyId = this.$store.state.pricing.rule.family;
      const ruleId = this.$store.state.pricing.rule.id;
      if (!customerAppUrl) {
        this.$q.notify({
          message: this.$t('Customer app url is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      if (!familyId) {
        this.$q.notify({
          message: this.$t('Family id is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      if (!ruleId) {
        this.$q.notify({
          message: this.$t('Rule id is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      // generate link
      const link = `${customerAppUrl}/summary?familyId=${familyId}&ruleId=${ruleId}`;
      // copy to clipboard
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$q.notify({
        message: this.$t('Link copied to clipboard'),
        color: 'positive',
        icon: 'done',
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
label {
  padding-left: 0;
  padding-right: 0;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
.drawer-container {
  padding: 1rem;
  position relative
  .rule-info {
    position: absolute;
    bottom: 1rem;
    left: 1rem
    right: 1rem
  }
}

.drawer-section{
  width:100%;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  margin-bottom: 10px;

}

.drawer-line {
  width:100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.drawer-separator {
  background-color: grey; height: 2px; width: 80%; margin: 25px auto 15px auto; opacity: 0.5;
}

.drawer-section-title{
    font-size: 17px; font-weight: 500;
  }

  .partSelect{
    width:100%; display: flex; justify-content: space-between;
    margin-bottom:25px;
  }
</style>
