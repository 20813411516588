import { render, staticRenderFns } from "./FamilySelection.vue?vue&type=template&id=4fb43c28&scoped=true&"
import script from "./FamilySelection.vue?vue&type=script&lang=js&"
export * from "./FamilySelection.vue?vue&type=script&lang=js&"
import style0 from "./FamilySelection.vue?vue&type=style&index=0&id=4fb43c28&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb43c28",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QLayout});
