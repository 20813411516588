<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$route.name === 'searchresult' ? $t('Pickup a rule') : $t('Available rules')}}
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.rule.definition.common" class="drawer-container">
      <div class="lg-rules">
        <div class="lg-rule2 shadow-2" v-for="rule in rules" :key="rule.id">
          <div class="label"><span class="title">{{localized(rule.label)}}</span>&nbsp;<span>({{rule.by}})</span></div>
          <div class="status">
            <q-badge :color="rule.activated_at ? 'primary' : 'grey-4'" text-color="white" :label="$t('In Use')"/>
            <q-badge :color="rule.id === $store.state.pricing.rule.id ? 'positive' : 'grey-4'" text-color="white" :label="$t('Current')"/>
            <q-badge :color="!rule.link ? 'grey-4' : 'info'" text-color="white" :label="$t('Linked')"/>
          </div>
          <div class="modified">
            {{formatDate(rule.last_modified)}}
          </div>
          <div class="modifiedicon"><q-icon name="restore"/></div>
          <div class="activeicon"><q-icon name="check_circle_outline"/></div>
          <div class="active">
            {{ rule.activated_at ? `${formatDate(rule.t)} → ${rule.t2 ? formatDate(rule.t2) : '∞'}` : '?  →  ?' }}
          </div>
          <div class="segmentation">
            <ul class="nodes">
              <li v-for="(s, index) in rule.segmentation" :key="index">{{localized(s.label)}}</li>
            </ul>
          </div>
          <div v-if="rule.id !== $store.state.pricing.rule.id || $route.name==='searchresult'" class="action">
            <q-btn v-if="(rule.id !== $store.state.pricing.rule.id) && ($route.name !== 'searchresult')" size="sm" label="Load"
                color="secondary" @click="load({ id: rule.id, locale: $store.state.auth.locale })"/>
            <template v-if="$route.name==='searchresult'">
              <q-btn size="sm" color="secondary" label="Clone" @click="copy(rule.id, 'clone')"/>
              <q-btn size="sm" color="primary" label="Link" @click="copy(rule.id, 'link')"/>
            </template>
          </div>
          <!--<div class="lg-rule-header">
            <div class="lg-rule-header-title">
              <div>{{localized(rule.label)}}
                <q-badge v-if="rule.activated_at" color="primary" text-color="white" label="Active"/>
              </div>
              <div>{{rule.by}}</div>
            </div>
            <div>
              <q-badge v-if="rule.id === $store.state.pricing.rule.id" color="positive" text-color="white" label="Current"/>
              <q-btn  size="sm" v-else label="Load"
                color="secondary" @click="load({ id: rule.id, locale: $store.state.auth.locale })"/>
            </div>
          </div>
          <div class="lg-rule-main lg-rule-dates">
              <span class="smalldatehead">Last modified on :</span>
              <span class="smalldatehead">Active starting :</span>
              <span class="smalldate">{{rule.last_modified}}</span>
              <span class="smalldate">{{rule.activated_at ? `${rule.t} → ${rule.t2 ? rule.t2 : '∞'}` : '--'}}</span>
          </div>-->

              <!--span class="smalldate">{{rule.activated_at ? `${rule.t} → ${rule.t2 ? rule.t2 : '∞'}` : '--'}}</span>
          </div>
              <span class="smalldate">{{rule.activated_at ? `${toLocaleDate(rule.t)} → ${rule.t2 ? toLocaleDate(rule.t2) : '∞'}` : '--'}}</span>
          </div-->

        </div>
      </div>
    </div>
    <div v-if="$route.name !== 'searchresult'" class="lg-rule-actions">
      <q-btn label="Search" colore="primary" @click="go('loadsearch')"/>&nbsp;
      <q-btn label="New" color="secondary" @click="load({ familyid: $route.params.id, locale: $store.state.auth.locale })"/>
    </div>
  </div>
</template>
<script>
import { errorMonitor } from 'events';
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';

function fromIsoDateString(d) {
  const [yyyy, mm, dd] = d.split('-');
  return new Date(yyyy, mm - 1, dd);
}
export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      searchresult: [],
      locale: this.$store.state.auth.locale,
    };
  },
  computed: {
    rules() {
      if (this.$route.name === 'searchresult') return this.searchresult;
      const tmp = [...this.$store.state.pricing.rulestore];
      return tmp.sort((a, b) => {
        if ((a.activated_at || a.created_at) < (b.activated_at || b.created_at)) return 1;
        if ((a.activated_at || a.created_at) > (b.activated_at || b.created_at)) return -1;
        return 0;
      });
      // return this.$store.state.pricing.rulestore;
    },
    loaded() {
      return this.$store.state.pricing.loaded2;
    },
  },
  watch: {
    loaded(n) {
      if (n) this.loadlist();
    },
    $route(n, o) {
      this.loadlist();
    },
  },
  methods: {
    go(name, params = {}) {
      if (name === this.$route.name && (!params.pid || params.pid === this.$route.params.pid)) return;
      const { href } = this.$router.resolve({ name, params });
      this.$router.push(this.$store.state.pricing.single ? href.replace('/pricing', '/one') : { name, params });
    },
    formatDate(d) {
      // return d.toLocaleDateString('en-US');

      const year = d.getFullYear();
      let month = (1 + d.getMonth()).toString();
      month = month.length > 1 ? month : `0${month}`;
      let day = d.getDate().toString();
      day = day.length > 1 ? day : `0${day}`;
      return `${month}/${day}/${year}`;
    },
    copy(rule, mode) {
      this.$axios.post(`/rules/${rule}`, {
        mode,
        destination: this.$store.state.pricing.family, // this must be the current family
      }).then(() => {
        this.go('loadrule', { id: this.$route.params.id });
      }).catch((error) => {
        this.$q.notify({ type: 'negative', message: 'Unable to load rule', icon: 'warning' });
      });
    },
    intervale(ss, es, ds) {
      if (!ss) return false;
      const s = new Date(ss);
      const e = es ? new Date(es) : undefined;
      const d = ds ? new Date(ds) : new Date();
      if (d < s) return false;
      if (!e) return true;
      if (d > e) return false;
      return true;
    },
    load(payload) {
      this.$store.dispatch('pricing/load', payload);
    },
    loadlist() {
      const id = this.$route.name === 'searchresult' ? this.$route.params.ifid : this.$route.params.id;
      this.$axios.get(`/families/${id}/rules`)
        .then((response) => {
          if (this.$route.name === 'searchresult') {
            this.searchresult = response.data.data;
            this.searchresult.forEach((r) => {
              // r.last_modified = (new Date(r.updated_at)).toISOString().substring(0, 10);
              r.last_modified = new Date(r.updated_at);
              r.t = r.t ? fromIsoDateString(r.t) : (new Date());
              if (r.t2) r.t2 = fromIsoDateString(r.t2);
            });
            return;
          }
          this.$store.commit('pricing/loadRules', {
            rules: response.data.data,
            locale: this.$store.state.auth.locale,
            currency: 'CHF',
            settings: this.$store.state.general.settings,
          });
        })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Unable to load rules', icon: 'warning' });
        });
    },
  },
  mounted() {
    if (!this.loaded) return;
    this.loadlist();
  },
};
</script>
<style lang="stylus" scoped>
.drawer-container {
  .lg-rules {
    height: calc(100vh - 164px);
    overflow-y: auto;
    .lg-rule2 {
      display: grid;
      margin: .4rem
      grid-template-areas: "label label label" "activeicon active status" "modifiedicon modified status" "segmentation segmentation status" "action action action";
      grid-template-columns: 20px 1fr 100px;
      /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */

      > div {
        border-bottom: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;
      }
      .label {
        grid-area: label;
        background-color: $primary;
        color: white;
        padding: .4rem;
        span.title {
          font-weight bold
        }
      }
      .status {
        grid-area: status;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 .4rem;
        border-right: 0
      }
      .modified {
        grid-area: modified;
        text-align center
      }
      .modifiedicon {
        grid-area: modifiedicon;
      }
      .activeicon {
        grid-area: activeicon;
      }
      .active {
        grid-area: active;
        text-align center
      }
      .segmentation {
        grid-area: segmentation;
        ul.nodes {
          margin: 0;
          padding: 0 0 0 1rem;
          font-size: .7rem;
          list-style-type: "→";
        }
      }
      .action {
        padding: .4rem;
        grid-area: action;
        display flex
        flex-direction row-reverse
        border-right: 0
        border-bottom: 0
        > button {
          margin-left: .4rem
        }
      }
    }

    .lg-rule {
      padding .4rem
      border-bottom: 1px solid #bbbbbb
      &:hover {
        background: #cccccc
      }
      &:nth-child(even) {
        background white
      }
      .lg-rule-header {
        display grid
        grid-template-columns 1fr 100px
        &.lg-rule-header-title-only {
          grid-template-columns 1fr
        }
        .lg-rule-header-title {
          div:first-child {
            font-weight bold
          }
          div:nth-child(2) {
            font-size 1rem
            color: gray
          }
        }
        > div:nth-child(2) {
          text-align right
        }
      }
      .lg-rule-dates {
        display grid
        grid-template-columns 50% 50%
        .smalldatehead {
          font-weight: bold;
          font-size: 0.8rem;
        }
        .smalldate {
          font-size .8rem
        }
      }
      .lg-rule-activation {
        display grid
        grid-template-columns 50px 1fr
      }
    }
  }
}
.lg-rule-actions {
  padding-right: .5rem
  text-align right
}
</style>
