<template>
  <div class="maindrawer">
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title v-if="part">
        {{`${$t('Move')} ${part.name.v||null}`}}
        <span v-if="!$store.state.pricing.single" class="lg-header-subtitle">
          {{$t('Part will leave the family')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="drawer-container2">

      <div class="selection-header">
        <b>{{$t('Parts selected: ') + Object.keys($store.state.pricing.selectedParts).length}}</b>
        <q-btn dense :label="$t('Reset selection')" color="primary" @click="$store.state.pricing.selectedParts={}"/>
      </div>

      <p>{{$t('Please select the target family:')}}</p>
      <TreeSelect v-if="value" class="treeSelect" :placeholder="$t('Please make a selection')" :columns="mandatorycolumns" :filter="!$store.state.general.settings.transparentproxypicture"
      v-model="value" :imgs="$store.state.general.settings.segmentwithpictures || false" style="margin-bottom:25px">
        <slot slot-scope="{ part }">
          <div class="imgs">
            <q-btn  round size="sm"  icon="arrow_left" style="height:30px; margin: auto 0" @click="part.prev()"/>
            <img class="img"  :src="mkpath(part)" />
            <q-btn round size="sm"  icon="arrow_right" style="height:30px; margin: auto 0" @click="part.next()"/>
          </div>
        </slot>
      </TreeSelect>

    </div>
    <div class="lg-rule-actions">
      <q-btn :label="$t('Cancel')" color="primary" @click="cancel()"/>
      &nbsp;
      <q-btn :disable="!value" :label="$t('Save')" color="secondary" @click="save()"/>
    </div>
  </div>
</template>
<script>

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { rulemixins } from './mixins';
import { localization } from '../../lib/mixins';
import { store } from '../../lib/nrstore';
import putils from '../../lib/pictures';

import TreeSelect from '../../components/treeSelect/TreeSelect.vue';

export default {
  data() {
    return {
      value: null,
    };
  },
  mixins: [rulemixins, localization],
  components: { TreeSelect },
  computed: {
    part() {
      return store.parts.find((p) => (p.id.v === this.$route.params.pid));
    },
    mandatorycolumns() {
      if (this.$store.state.general.settings?.catalog?.mandatory) {
        return this.$store.state.general.settings.catalog.mandatory;
      }
      return [];
    },
  },
  mounted() {
    this.value = this.part.families.v[0][this.part.families.v[0].length - 1].id;
    this.$store.state.pricing.selectedParts = { [this.part.id.v]: true };
  },
  destroyed() {
    this.$store.state.pricing.selectedParts = { };
    this.$store.state.pricing.modeMultipleSelect = false;
  },
  methods: {
    mkpath(part) {
      // console.log('part', part);
      if (!part.id) return 'default.png';
      if (this.$store.state.general.settings.transparentproxypicture) {
        return putils.mkproxypath2(part, this.$store.state.auth.token);
      }
      if (part.pictures?.v?.[0]) {
        return putils.mkpath(part.pictures.v[0], this.$store.state.auth.token);
      }
      return 'default.png';
    },
    async movePart(partId, familyId) {
      // need to patch the family
      this.$axios.patch(`/products/${partId}`, {
        families: [{
          product: partId,
          family: familyId,
        }],
      }).then((response) => {
        (Promise.resolve()).then(() => {
          this.$q.notify({ type: 'positive', message: 'Part got moved.', icon: 'done' });

          // remove part from the list
          this.$store.commit('pricing/movePart', partId);
          // console.log('partsPage', this.$store.state.pricing.partsPage);
          const partIndex = this.$store.state.pricing.partsPage.findIndex((p) => p.id === partId);
          // console.log('partIndex', partIndex);
          if (partIndex > -1) {
            this.$store.state.pricing.partsPage.splice(partIndex, 1);
          }
        });
      }).catch((error) => {
        console.error(error);
        this.$q.notify({ type: 'negative', message: 'Something wrong happen trying to move the part.', icon: 'warning' });
      });
    },
    async save() {
      // loop on every selected part
      // eslint-disable-next-line no-restricted-syntax
      for (const partId of Object.keys(this.$store.state.pricing.selectedParts)) {
        // console.log('partId', partId);
        // eslint-disable-next-line no-await-in-loop
        await this.movePart(partId, this.value);
      }

      if (this.$store.state.pricing.single) {
        this.$store.dispatch('pricing/fullLoad');
        this.$router.push({ params: { reload: 'true' } });
        this.$router.go(-1);
      }
    },
    cancel() {
      this.$store.state.pricing.selectedParts = { };
      this.$store.state.pricing.modeMultipleSelect = false;
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.maindrawer {
  display: flex;
  flex-direction: column;
  .drawer-container2 {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    .selection-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.lg-rule-actions {
  text-align: right;
  padding: 1rem;
}
</style>
