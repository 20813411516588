<template>
  <div class="fixed-center text-center">
    <q-card>
      <q-card-section class="text-white bg-primary text-subtitle2">Login</q-card-section>
      <q-separator/>
      <q-card-section>
          <q-input square filled v-model="email" type="email" label="Email"/>
          <q-input square filled v-model="password" type="password" label="Password"/>
          <q-btn label="Connect" color="secondary" @click="login()"></q-btn>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      const params = {
        email: this.email,
        password: this.password,
      };
      this.$axios
        .post('/login', params)
        .then((response) => {
          this.$store.dispatch('auth/login', response.data);
          this.$router.push('/index');
        })
        .catch((err) => {
          this.$q.notify({
            message: 'login error',
            icon: 'warning',
          });
        });
    },
  },
};
</script>
