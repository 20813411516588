<template>
  <div>
    <q-page-container>
      <q-page class="q-pa-sm">
        <table v-if="$store.state.auth.user">
          <thead class="notifhead">
            <tr>
              <th rowspan="2">{{$t('Event')}}</th>
              <th rowspan="2">{{$t('Subscription')}}</th>
              <th colspan="4">{{$t('Integrations')}}</th>
            </tr>
            <tr>
              <th v-for="(integration, index) in integrations" :key="index">{{localized(integration.label)}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(event, index) in events" :key="index">
              <td :class="`lg-padding-${event.name.split('.').length - 1}`">
                {{localized(event.label)}}
              </td>

              <td><q-toggle filled square dense v-model="subscriptions[event.name]" :value="true" @input="(val) => eventswitch(event.name, val)"></q-toggle></td>
              <td v-for="(integration, index2) in integrations" :key="index2">
                <q-select filled square dense :disable="integration.disabled" v-model="aggopt[event.name][integration.name]" :options="aggregations.filter((a) => (!a.disabled))"></q-select>
              </td>
            </tr>
          </tbody>
        </table>
      </q-page>
    </q-page-container>
  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization],
  data() {
    return {
      events: [],
      /* events: [
        { name: 'Part update', subevents: [{ label: 'New', value: 'new' }, { label: 'Proposed Price', value: 'proposed' }] },
        { name: 'Part export', subevents: [{ label: 'Sent', value: 'sent' }, { label: 'Success', value: 'success' }, { label: 'Failure', value: 'failure' }] },
        {
          name: 'Import',
          subevents: [
            { label: 'Progress', value: 'progress' },
            { label: 'Success', value: 'success' },
            { label: 'Failure', value: 'failure' },
          ],
        },
        {
          name: 'SAP Export',
          subevents: [{ label: 'Sent', value: 'sent' }, { label: 'Success', value: 'success' }, { label: 'Failure', value: 'failure' }],
        },
        {
          name: 'Rule',
          subevents: [
            { label: 'New', value: 'new' },
            { label: 'Update', value: 'update' },
          ],
        },
      ], */
      subscriptions: {},
      integrations: [
        { label: { 'en-US': 'In App' }, name: 'app', disabled: true },
        { label: { 'en-US': 'Email' }, name: 'email' },
        { label: { 'en-US': 'WhatsApp' }, name: 'whatsapp', disabled: true },
        { label: { 'en-US': 'Slack' }, name: 'slack', disabled: true },
      ],
      aggregations: [
        { label: this.$t('None, on the fly'), value: 'none' },
        { label: this.$t('Once an hour'), value: 'hour', disabled: true },
        { label: this.$t('Once a day'), value: 'day', disabled: true },
        { label: this.$t('Once a week'), value: 'week', disabled: true },
      ],
    };
  },
  methods: {
    eventswitch(event, value) {
      Object.entries(this.subscriptions).forEach(([e, v]) => {
        if (e.startsWith(event)) this.subscriptions[e] = value;
      });
    },
  },
  mounted() {
    this.$store.commit('header/setHeader', {
      title: 'Manage your subscriptions',
      subtitle: 'Notifications',
    });
    Promise.all([
      this.$axios.get('/events'),
      this.$axios.get('/subscriptions'),
    ]).then(([events, subscriptions]) => {
      this.events = events.data.data;
      this.subscriptions = this.events.reduce((_, e) => {
        _[e.name] = !(subscriptions.data.data.findIndex((s) => (s.event === e.name)) < 0);
        return _;
      }, {});
      this.aggopt = this.events.reduce((_, e) => {
        _[e.name] = this.integrations.reduce((__, i) => {
          __[i.name] = this.aggregations[0]; // eslint-disable-line
          return __;
        }, {});
        return _;
      }, {});
    });
  },
};
</script>
<style lang="stylus" scoped>
.notifhead {
  border-bottom 1px solid gray
}
.lg-padding-1 {
  padding-left: 2rem;
}
</style>
