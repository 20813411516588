<template>
  <q-header>
    <q-toolbar class="lg-app-header shadow-2">
      <q-btn
        flat
        dense
        round
        @click="toggle"
        aria-label="Menu"
      >
        <q-icon name="menu" />
      </q-btn>

      <q-toolbar-title shrink>
        <span v-if="$store.state.header.parent.id">
          <span>{{localized($store.state.header.parent.label)}}</span>
          →
        </span>
        {{$store.state.header.title}}
        <q-btn v-if="$store.state.header.siblings.length" round size="sm" flat color="white" icon="more_horiz">
          <q-menu auto-close>
            <q-list style="min-width: 100px">
              <q-item v-for="sibling in $store.state.header.siblings" :key="sibling.id" :to="`/pricing/${sibling.id}`" clickable>
                <q-item-section>{{localized(sibling.label)}}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <span class="lg-header-subtitle">{{$store.state.header.subtitle}}</span>
      </q-toolbar-title>
      <div class="grow">
      </div>
      <!-- <q-btn flat class="float-right" round dense icon="search" /> -->
      <q-btn title="Help assistant" flat class="float-right" round dense icon="help_outline" @click="help"/>
      <q-btn title="Toggle full screen" flat class="float-right" round dense icon="fullscreen" @click='toggleFullscreen()'/>
      <!-- <q-btn flat round dense icon="notification_important" /> -->
      <q-btn title="Log-out" flat round dense icon="input" @click="logout()"/>
    </q-toolbar>
  </q-header>
</template>
<script>
import localization from '../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization],
  methods: {
    help(ev2) {
      const that = this;
      function helplistener(ev) {
        ev.stopImmediatePropagation();
        document.body.classList.remove('help-cursor');
        document.body.removeEventListener('click', helplistener, true);
        const path = ev.composedPath();
        const topics = path.slice(0, path.length - 4).reduce((__, el) => {
          const helps = [...el.classList].reduce((_, name) => {
            if (/^help-/.test(name)) _.push(name);
            return _;
          }, []);
          if (helps.length) Array.prototype.push.apply(__, helps);
          return __;
        }, []);
        that.$root.$emit('help-show', topics);
      }
      ev2.stopPropagation();
      document.body.classList.add('help-cursor');
      document.body.addEventListener('click', helplistener, true);
    },
    toggle() {
      this.$store.commit('mainmenu/toogleDrawer');
    },
    toggleFullscreen(event) {
      const elem = document.documentElement;
      if (
        document.fullscreenEnabled
        || document.webkitFullscreenEnabled
        || document.mozFullScreenEnabled
        || document.msFullscreenEnabled
      ) {
        if (!this.isFullscreen) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
            this.isFullscreen = true;
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            this.isFullscreen = true;
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            this.isFullscreen = true;
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
            this.isFullscreen = true;
          }
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
          this.isFullscreen = false;
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
          this.isFullscreen = false;
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          this.isFullscreen = false;
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
          this.isFullscreen = false;
        }
      }
    },
    logout() {
      this.$store.commit('auth/logout');
    },
  },
};
</script>
<style lang="stylus" scoped>
.lg-app-header {
  background darken($primary, 30%)
}
.grow {
  flex 1
}
</style>
