import pricingUtilities from '@cabinetmariel/pricing-utilities';

const { utils } = pricingUtilities;
const { findactualdate } = utils;

function getPartAttribute(part, attribute, when) {
  if (part[attribute] === undefined) return undefined;
  let record = part[attribute];
  if (part[attribute].DEFAULT) { // no timeserie
    // we need to find the idx
    const idx = findactualdate(part[attribute].DEFAULT, when);
    // then the record
    if (idx < 0) return undefined;
    record = part[attribute].DEFAULT[idx];
  }
  return record.v;
}

export const getdecision = function getdecision(grid, part, when = new Date()) {
  if (grid === undefined) return undefined;
  const operations = {
    true() {
      return true;
    },
    false() {
      return false;
    },
    and(condition) {
      return condition.args.every((c) => {
        if (operations[c.op]) return operations[c.op](c);
        return false;
      });
    },
    or(condition) {
      return condition.args.some((c) => {
        if (operations[c.op]) return operations[c.op](c);
        return false;
      });
    },
    equals(condition) {
      const value = getPartAttribute(part, condition.column, when);
      if (!value) return false;
      if (value === condition.args[0]) return true;
      return false;
    },
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const rule of grid) {
    if (operations[rule.condition.op] && operations[rule.condition.op](rule.condition)) return rule.decision;
  }
  return undefined;
};
export const activefamily = function activefamily(product, segmentationgrid) {
  if (!(product && product.families && product.families.v.length)) return undefined;
  // we do not use decision grids for now, not sure it's ok
  const segmentation = product.families.v[0];
  if (!segmentation.length) return undefined;
  return segmentation.slice(-1)[0];
  /*
  const actualsegmentation = getdecision(segmentationgrid, product);
  // eslint-disable-next-line no-restricted-syntax
  for (const segmentation of product.families.v) {
    if (segmentation.some((s) => (s.id === actualsegmentation))) return segmentation.slice(-1)[0];
  }
  return undefined;
  */
};

export default {
  getdecision,
  activefamily,
};
