<template>
  <div>
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{title}}
        <span slot="subtitle">{{subtitle}}</span>
      </q-toolbar-title>
      <q-icon
        v-if="action.startsWith('details')"
        class="locked"
        :name="locked ? 'lock': 'lock_open'"
        @click.native="toggleLock()"
        size="1.5rem"
      ></q-icon>
    </q-toolbar>
    <div class="content">

      <q-input :error="$v.form.name.$error" bottom-slots :error-message="getError('name')"
        v-model="form.name"
        :disable="locked"
        type="text"
        :label="$t('Name')"
        @input="$v.form.label.$touch"
      />
      <localizedstring :label="$t('Label')" name="label" :disable="locked" v-if="form.label" editable="yes" :def="{name: 'label'}" :data="{ v: form.label }"
        :rules="rules" @change="dochange" />

      <q-select bottom-slots filled square dense v-model="form.method" :options="pricingmethods" name="Method"  type="text" :label="$t('Method')"/>
      <q-checkbox v-model="form.secured" :disable="locked" :options="['secured']" name="secured" label="Secured" />

      <localizedstring :label="$t('Description')" name="description" :disable="locked" v-if="form.description" editable="yes" :def="{name: 'description'}" :data="{ v: form.description }"
        :rules="rules" @change="dochangeDescription" />

      <div class="imgSection">
        <p>Family picture</p>
        <img v-if="imagePreview || imageSrc" :src="imagePreview || imageSrc" alt="Image preview" style="max-width:100%; height: 100px; object-fit: contain;">
        <!-- <img v-else-if="imageSrc" :src="imageSrc" alt="Image preview" style="max-width:100%; height: 100px; object-fit: contain;"> -->
        <input  type="file" ref="fileInput" @change="handleFileUpload" style="display: none;">
        <q-btn v-if="!locked" @click="$refs.fileInput.click()" label="Choose File" style="margin-top:10px"/>
        <!-- <button @click="saveImageInStore" :disable="locked || !selectedFile">Save image</button> -->
      </div>

      <div class="validation">
        <q-btn-dropdown :label="$t('Actions')" :disable="cannotEdit()">
          <q-list>
            <q-item v-if="$store.state.clipboard.selection">
              <q-item-section>
                <q-item-label>{{localized($store.state.clipboard.selection.label)}}</q-item-label>
                <q-item-label caption>(current selection)</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator/>
            <q-item v-if="!!id" clickable v-close-popup
              @click="$store.commit('clipboard/setSelection', { mode: 'cut', selection: { id, label: form.label } })">
              <q-item-section avatar>
                <q-avatar icon="content_cut" color="primary" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Cut</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="!!id" clickable v-close-popup
              @click="$store.commit('clipboard/setSelection', { mode: 'copy', selection: { id, label: form.label } })">
              <q-item-section avatar>
                <q-avatar icon="content_copy" color="primary" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Copy</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator/>
            <q-item v-if="$store.state.clipboard.selection && $store.state.clipboard.mode === 'copy' && $store.state.clipboard.selection.id !== id" clickable v-close-popup
              @click="pasteFamily({ id, label: form.label })">
              <q-item-section avatar>
                <q-avatar icon="content_paste" color="primary" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Clone family hierarchy here</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="$store.state.clipboard.selection && $store.state.clipboard.mode === 'cut' && $store.state.clipboard.selection.id !== id" clickable v-close-popup
              @click="moveParts({ id, label: form.label })">
              <q-item-section avatar>
                <q-avatar icon="drive_file_move" color="primary" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Move only the parts in here</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="$store.state.clipboard.selection && $store.state.clipboard.mode === 'cut' && $store.state.clipboard.selection.id !== id" clickable v-close-popup
              @click="pasteFamily({ id, label: form.label }, $store.state.clipboard.mode)">
              <q-item-section avatar>
                <q-avatar icon="drive_file_move" color="primary" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Move Families and Parts in here</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator/>
            <q-item clickable v-close-popup @click="deleteme">
              <q-item-section avatar>
                <q-avatar icon="delete" color="negative" text-color="white" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Delete</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        &nbsp;
        <q-btn :disable="cannotSeeRule" :to="`/pricing/${id}`">rule <span v-if="curentTag && curentTag.count">&nbsp;({{curentTag.count}})</span> </q-btn>
        &nbsp;
        <q-btn :disable="locked && cannotEdit()" color="secondary" @click="save()">save</q-btn>
      </div>

    </div>

    <q-dialog v-model="shouldbedeleted" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="warning" color="negative" text-color="white" />
          <span class="q-ml-sm">{{$t('Are you sure that your really want to delete this part ? There is no undoing.')}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="$t('Cancel')" color="primary" v-close-popup />
          <q-btn flat :label="$t('Yes, I do')" color="negative" v-close-popup @click="actualdelete"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { can } from '@cabinetmariel/scopes';
import localization from '../../../lib/mixins'; //eslint-disable-line
import localizedstring from '../../attributesDisplay/localizedstring';

const { methods } = pricingMethods;
const localizedMinLength = (length) => (value) => Object.values(value).every((v) => (v.length >= length));

export default {
  mixins: [localization],
  components: {
    localizedstring,
  },
  data() {
    return {
      rules(fullmodel) {
        return [
          (val) => Object.entries(fullmodel).filter(([k, v]) => !!v).every(([k, v]) => v.length > 3)
            || `Label cannot be shorter than 3 characters, ${val}`,
          (val) => (Object.entries(fullmodel).filter(([k, v]) => !!v).length >= 1)
            || 'At least a label in one language is required',
        ];
      },
      shouldbedeleted: false,
      search: '',
      form: {
        name: null,
        label: null,
        method: null,
        secured: false,
        description: null,
        picture: null,
        type: {
          model: null,
          options: [
            { label: 'String', value: 'string' },
            { label: 'Localizedstring', value: 'localizedstring' },
            { label: 'Date', value: 'date' },
            { label: 'Interval', value: 'interval' },
            { label: 'Number', value: 'number' },
            { label: 'Amount', value: 'amount' },
            { label: 'Pricing', value: 'pricing' },
            { label: 'Boolean', value: 'boolean' },
          ],
        },
        weight: [],
        options: [],
      },
      action: null,
      column: null,
      id: null,
      type: null,
      curentTag: null,
      locked: true,
      locale: this.$store.state.auth.locale,
      title: '',
      subtitle: '',
      selectedFile: null,
      imageSrc: null,
    };
  },
  validations: {
    form: {
      label: { required, minLength: localizedMinLength(3) },
      name: { required },
      method: { required },
    },
  },
  computed: {
    pricingmethods() {
      return Object.keys(methods);
    },
    cannotSeeRule() {
      if (this.curentTag && this.curentTag.method && !methods[this.curentTag.method].familymode) return true;
      return !can('rules.read', this.$store.state.auth.user.scopes);
    },
    candelete() {
      return this.id !== 'new' && can('segmentation.delete', this.$store.state.auth.user.scopes);
    },
    imagePreview() {
      return this.selectedFile ? URL.createObjectURL(this.selectedFile) : null;
    },
  },
  mounted() {
    if (!can('segmentation', this.$store.state.auth.user.scopes)) {
      this.$q.notify({ type: 'done', message: 'Access to segmentation is READ ONLY ([segmentation.read] rights)', icon: 'warning' });
    }
  },
  methods: {
    cannotEdit() {
      return !can('segmentation.write', this.$store.state.auth.user.scopes);
    },
    deleteme() {
      this.shouldbedeleted = true;
    },
    pasteFamily(selection, mode = 'copy') {
      if (mode === 'cut') {
        this.$store.dispatch('clipboard/moveFamilies', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      } else if (mode === 'copy') {
        this.$store.dispatch('clipboard/cloneSegmentation', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      }
    },
    moveParts(selection) {
      this.$store.dispatch('clipboard/moveParts', { from: this.$store.state.clipboard.selection.id, to: selection.id });
    },
    actualdelete() {
      this.$axios.delete(`/families/${this.id}`).then(() => {
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: 'Part successfully deleted.',
        });
        // we should refresh
        this.goback();
      }).catch((error) => {
        let message = this.$t('Something wrong happened, this family could not be deleted.');
        if (error.response.data && error.response.data.code) {
          switch (error.response.data.code) {
            case 'LP010':
              message = this.$t('This family still has at least one child, remove it/them first.');
              break;
            case 'LP011':
              message = this.$t('At least one rule is still attached to this family. Remove it/them first.');
              break;
            case 'LP012':
              message = this.$t('At least one part still belongs to this family, re-segment all those parts.');
              break;
            default:
          }
        }
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message,
        });
      });
    },
    setTitle() {
      if (this.action === 'new') {
        this.title = 'New familiy';
        if (this.id) {
          this.fetch(this.id).then((response) => {
            this.curentTag = response;
            this.subtitle = `in ${this.localized(response.label)}`;
          });
        } else {
          this.subtitle = '';
        }
      }
      if (this.curentTag) {
        this.title = this.locked ? 'Family details' : 'Family edit';
        this.subtitle = `in ${this.localized(this.curentTag.label)}`;
      }
    },
    toggleLock() {
      this.locked = !this.locked;
    },
    go() {
      this.$router.push({ name: 'newattr' });
    },
    getError(elem) {
      return '3 character minimun.';
    },
    goback() {
      const index = this.$route.path.indexOf('/drawer');
      this.$router.push(this.$route.path.substr(0, index));
    },
    dochange(v) {
      this.form.label = v.label;
      this.$v.form.label.$touch();
    },
    dochangeDescription(v) {
      this.form.description = v.description;
      // this.$v.form.description.$touch();
    },
    async save() {
      await this.saveImageInStore();
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const params = ['label', 'name', 'method', 'secured', 'description', 'picture'].reduce((acc, key) => {
          if (this.form[key] !== null) acc[key] = this.form[key];
          return acc;
        }, {});
        if (this.type === 'attributes') {
          params.set = this.id;
          params.type = this.form.type.model;
          params.primary = this.form.options.includes('primary');
          params.groupable = this.form.options.includes('groupable');
          params.computed = this.form.options.includes('computed');
          params.timeserie = this.form.options.includes('timeserie');
          params.weight = [];
          if (this.form.weight.includes('rate')) {
            params.weight.push({
              type: 'rate',
            });
          }
          if (this.form.weight.includes('amount')) {
            params.weight.push({
              type: 'amount',
            });
          }
          if (params.type === 'pricing') {
            params.expandable = [2, 4];
          } else if (params.type === 'amount' || params.type === 'number') {
            params.expandable = [1, 2];
          }
        } else if (this.action === 'new') {
          params.parent = this.id;
        }

        if (this.action.startsWith('details')) {
          this.$axios
            .patch(`/${this.type}/${this.id}`, params)
            .then((response) => {
              this.form.label = response.data.data.label;
              this.form.description = response.data.data.description;
              this.form.picture = response.data.data.picture;
              this.form.name = response.data.data.name;
              this.form.secured = response.data.data.secured;
              this.goback();
            });
        } else if (this.action === 'new') {
          this.$axios.post(`/${this.type}`, params).then((response) => {
            this.form.label = response.data.data.label;
            this.form.description = response.data.data.description;
            this.form.picture = response.data.data.picture;
            this.form.name = response.data.data.name;
            this.form.secured = response.data.data.secured;
            this.goback();
          });
        }
      }
    },
    fetch(id) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/families/${id}`).then((response) => {
          resolve(response.data.data);
        });
      });
    },
    setId(route) {
      const routeParams = route.params.pathMatch.split('/');
      const i = this.column ? this.column - 1 : routeParams.length - 1;
      if (this.action === 'new' && this.column === 0) {
        this.id = null;
      } else {
        this.id = routeParams[i].substring(2);
      }
    },
    fetchAttribute() {
      this.fetch(this.id).then((response) => {
        this.curentTag = response;
        this.setTitle();

        this.form.method = response.method;
        this.form.label = response.label;
        this.form.description = response.description;
        this.form.picture = response.picture;
        if (this.form.picture) {
          this.getImageFromStore(this.form.picture);
        }
        this.form.name = response.name;
        this.form.type.model = response.type;
        this.form.secured = response.secured;
        this.form.weight = [];
        if (response.weight) {
          for (let i = 0; response.weight[i]; i += 1) {
            if (response.weight[i].type) {
              this.form.weight.push(response.weight[i].type);
            }
          }
        }
        this.form.options = [];
        if (response.timeserie) {
          this.form.options.push('timeserie');
        }
        if (response.groupable) {
          this.form.options.push('groupable');
        }
        if (response.primary) {
          this.form.options.push('primary');
        }
        if (response.computed) {
          this.form.options.push('computed');
        }
      });
    },
    initAttribute() {
      this.curentTag = undefined;
      this.form.label = { [this.$store.state.auth.locale]: 'New Label' };
      this.form.description = null;
      this.form.picture = null;
      this.form.name = null;
      this.form.secured = false;
      this.form.type.model = null;
      this.form.weight = [];
      this.form.options = [];
      this.setTitle();
    },
    fetchAttributeSet() {},
    initData(route) {
      this.action = route.params.action;
      this.column = route.params.column;
      this.type = 'families';
      this.setId(route);
      if (this.action === 'new') {
        this.locked = false;
        this.initAttribute();
      } else {
        this.locked = true;
        this.fetchAttribute();
      }
    },

    handleFileUpload(event) {
      [this.selectedFile] = event.target.files;
    },
    async saveImageInStore() {
      if (!this.selectedFile) {
        return;
      }
      const file = this.selectedFile;
      try {
        const response = await this.$axios.post('/store', file, {
          headers: {
            'Content-Type': file.type,
            'X-filename': file.name,
          },
        });
        if (response.data && response.data.data) {
          const fileId = response.data.data.id;
          console.log('response.data.data.id', fileId);
          if (fileId) {
            this.form.picture = fileId;
          }
        }
      } catch (error) {
        console.error('Error saving image in store:', error);
      }
    },
    getImageFromStore(id) {
      this.$axios
        .get(`/store/${id}`, { responseType: 'blob' })
        .then((response) => {
          console.log('Response headers:', response.headers);
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            this.imageSrc = url;
          }
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to);
    next();
  },
  created() {
    this.initData(this.$route);
  },
};
</script>
<style lang="stylus" scoped>
.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*
  padding: 1rem;
  */
  margin-top: 10px;
}

.content {
  padding: 1rem;
}

.imgSection{
  margin-top:15px;
  // border : 1px solid #ccc;
  // border-radius: 5px;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  p{
    margin:0;
    margin-bottom:5px
  }
}
</style>
