import { render, staticRenderFns } from "./Segmentation.vue?vue&type=template&id=7485f84b&scoped=true&"
import script from "./Segmentation.vue?vue&type=script&lang=js&"
export * from "./Segmentation.vue?vue&type=script&lang=js&"
import style0 from "./Segmentation.vue?vue&type=style&index=0&id=7485f84b&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7485f84b",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QItem,QItemSection,QIcon,QItemLabel,QFab,QFabAction,QDrawer});
