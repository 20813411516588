<template>
  <q-btn class="btn" label="Filter" icon-right="filter_list" >
    <q-menu >
      <div>
        <div class="clear" @click="clear">clear filters</div>
        <div class="content">
            <q-input v-model="model" placeholder="Filter" icon="search" />
            <q-btn color="primary" label="add" @click="hanldeClick"></q-btn>
        </div>
      </div>
    </q-menu>
  </q-btn>
</template>

<script>
export default {
  props: [],
  components: {},
  computed: {},
  data() {
    return {
      model: '',
      opened: false,
    };
  },
  methods: {
    hanldeClick() {
      this.$emit('update-data', this.model);
      this.close();
    },
    clear() {
      this.$emit('update-data', '');
      this.close();
    },
    close() {
      this.opened = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.btn {
  margin: 0 0.5em;
}

.content {
  display: flex;
  padding: 1em;
}

.clear {
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0.5em 0 0;
  font-size: 0.8em;
  cursor: pointer;
}
</style>
