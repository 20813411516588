export default {
  currentStep: 1,
  template: {
    value: null,
    canSave: false,
    select: [
    ],
    data: [],
  },
  step1: {
    file: {
      uploaded: null,
      parsed: null,
      table: null,
      expandedCollumns: [],
      parsedLen: 0,
    },
  },
  step2: {
    form: {
      encoding: {
        value: 'utf8',
        data: [
          // {
          //   label: 'Auto',
          //   value: '',
          // },
          {
            label: 'ISO-8859-15',
            value: 'iso885915',
          },
          {
            label: 'UTF-8',
            value: 'utf8',
          },
        ],
      },
      separator: {
        value: '',
        data: [
          {
            label: 'Auto',
            value: '',
          },
          {
            label: 'Comma',
            value: ',',
          },
          {
            label: 'Semicolon',
            value: ';',
          },
          {
            label: 'Tab',
            value: '\t',
          },
          {
            label: 'Space',
            value: ' ',
          },
        ],
      },
      checkbox: {
        value: [],
      },
      headingLines: { value: 1 },
      footerLines: { value: 0 },
    },
  },
  step3: {
    attributesList: [],
    filtredAttributesList: [],
    draggableRow: [],
    similarCurrencies: [
      {
        symbol: '$',
        defaultUnit: 'USD',
        units: [
          'USD',
          'ARS',
          'AUD',
          'BBD',
          'BMD',
          'BND',
          'BSD',
          'BZD',
          'CAD',
          'CLP',
          'COP',
          'CUC',
          'CUP',
          'DOP',
          'FJD',
          'GYD',
          'JMD',
          'KYD',
          'LRD',
          'MXN',
          'NAD',
          'NZD',
          'SBD',
          'SGD',
          'SRD',
          'TTD',
          'TWD',
          'USD',
          'UYU',
          'XCD',
        ],
      },
      {
        symbol: 'Bs',
        defaultUnit: 'BOB',
        units: ['BOB', 'VEF'],
      },
      {
        symbol: 'kr',
        defaultUnit: 'DKK',
        units: ['DKK', 'ISK', 'NOK', 'SEK'],
      },
      {
        symbol: '£',
        defaultUnit: 'GBP',
        units: ['EGP', 'FKP', 'GBP', 'GIP', 'SHP', 'SSP', 'SYP'],
      },
      {
        symbol: 'L',
        defaultUnit: 'HNL',
        units: ['HNL', 'MDL'],
      },
      {
        symbol: '₩',
        defaultUnit: 'KPW',
        units: ['KPW', 'KRW'],
      },
      {
        symbol: 'Rs',
        defaultUnit: 'LKR',
        units: ['LKR', 'MUR', 'NPR'],
      },
      {
        symbol: 'K',
        defaultUnit: 'MMK',
        units: ['MMK', 'PGK', 'ZMW'],
      },
      {
        symbol: 'UM',
        defaultUnit: 'MRO',
        units: ['MRO', 'MRU'],
      },
      {
        symbol: 'Db',
        defaultUnit: 'STD',
        units: ['STD', 'STN'],
      },
    ],
    locales: {
      value: 'fr_FR',
      data: [
        { value: 'fr_FR', label: 'Francais (France)' },
        { value: 'fr_CH', label: 'Français (Suisse)' },
        { value: 'en_US', label: 'Anglais (Américain)' },
      ],
    },
    currencies: {
      value: 'USD',
      data: [
        { value: 'AFN', label: 'AFN', symbol: '؋' },
        { value: 'ALL', label: 'ALL', symbol: 'Lekë' },
        { value: 'AMD', label: 'AMD', symbol: '֏' },
        { value: 'ANG', label: 'ANG', symbol: 'Naf' },
        { value: 'AOA', label: 'AOA', symbol: 'Kz' },
        { value: 'ARS', label: 'ARS', symbol: '$' },
        { value: 'AUD', label: 'AUD', symbol: '$' },
        { value: 'AZN', label: 'AZN', symbol: '₼' },
        { value: 'BAM', label: 'BAM', symbol: 'KM' },
        { value: 'BBD', label: 'BBD', symbol: '$' },
        { value: 'BDT', label: 'BDT', symbol: '৳' },
        { value: 'BIF', label: 'BIF', symbol: 'FBu' },
        { value: 'BMD', label: 'BMD', symbol: '$' },
        { value: 'BND', label: 'BND', symbol: '$' },
        { value: 'BOB', label: 'BOB', symbol: 'Bs' },
        { value: 'BRL', label: 'BRL', symbol: 'R$' },
        { value: 'BSD', label: 'BSD', symbol: '$' },
        { value: 'BWP', label: 'BWP', symbol: 'P' },
        { value: 'BZD', label: 'BZD', symbol: '$' },
        { value: 'CAD', label: 'CAD', symbol: '$' },
        { value: 'CDF', label: 'CDF', symbol: 'FC' },
        { value: 'CHF', label: 'CHF', symbol: 'CHF' },
        { value: 'CLP', label: 'CLP', symbol: '$' },
        { value: 'CNY', label: 'CNY', symbol: '￥' },
        { value: 'COP', label: 'COP', symbol: '$' },
        { value: 'CRC', label: 'CRC', symbol: '₡' },
        { value: 'CUC', label: 'CUC', symbol: '$' },
        { value: 'CUP', label: 'CUP', symbol: '$' },
        { value: 'CZK', label: 'CZK', symbol: 'Kč' },
        { value: 'DJF', label: 'DJF', symbol: 'Fdj' },
        { value: 'DKK', label: 'DKK', symbol: 'kr' },
        { value: 'DOP', label: 'DOP', symbol: '$' },
        { value: 'DZD', label: 'DZD', symbol: 'DA' },
        { value: 'EGP', label: 'EGP', symbol: '£' },
        { value: 'ERN', label: 'ERN', symbol: 'Nfk' },
        { value: 'ETB', label: 'ETB', symbol: 'Br' },
        { value: 'EUR', label: 'EUR', symbol: '€' },
        { value: 'FJD', label: 'FJD', symbol: '$' },
        { value: 'FKP', label: 'FKP', symbol: '£' },
        { value: 'GBP', label: 'GBP', symbol: '£' },
        { value: 'GEL', label: 'GEL', symbol: '₾' },
        { value: 'GHS', label: 'GHS', symbol: 'GH₵' },
        { value: 'GIP', label: 'GIP', symbol: '£' },
        { value: 'GMD', label: 'GMD', symbol: 'D' },
        { value: 'GNF', label: 'GNF', symbol: 'FG' },
        { value: 'GTQ', label: 'GTQ', symbol: 'Q' },
        { value: 'GYD', label: 'GYD', symbol: '$' },
        { value: 'HKD', label: 'HKD', symbol: 'HK$' },
        { value: 'HNL', label: 'HNL', symbol: 'L' },
        { value: 'HRK', label: 'HRK', symbol: 'kn' },
        { value: 'HTG', label: 'HTG', symbol: 'G' },
        { value: 'HUF', label: 'HUF', symbol: 'Ft' },
        { value: 'IDR', label: 'IDR', symbol: 'Rp' },
        { value: 'ILS', label: 'ILS', symbol: '₪' },
        { value: 'INR', label: 'INR', symbol: '₹' },
        { value: 'IRR', label: 'IRR', symbol: 'ریال' },
        { value: 'ISK', label: 'ISK', symbol: 'kr' },
        { value: 'JMD', label: 'JMD', symbol: '$' },
        { value: 'JPY', label: 'JPY', symbol: '¥' },
        { value: 'KES', label: 'KES', symbol: 'Ksh' },
        { value: 'KGS', label: 'KGS', symbol: 'сом' },
        { value: 'KHR', label: 'KHR', symbol: '៛' },
        { value: 'KMF', label: 'KMF', symbol: 'CF' },
        { value: 'KPW', label: 'KPW', symbol: '₩' },
        { value: 'KRW', label: 'KRW', symbol: '₩' },
        { value: 'KYD', label: 'KYD', symbol: '$' },
        { value: 'KZT', label: 'KZT', symbol: '₸' },
        { value: 'LAK', label: 'LAK', symbol: '₭' },
        { value: 'LBP', label: 'LBP', symbol: 'L£' },
        { value: 'LKR', label: 'LKR', symbol: 'Rs' },
        { value: 'LRD', label: 'LRD', symbol: '$' },
        { value: 'LSL', label: 'LSL', symbol: 'lLS' },
        { value: 'MDL', label: 'MDL', symbol: 'L' },
        { value: 'MGA', label: 'MGA', symbol: 'Ar' },
        { value: 'MKD', label: 'MKD', symbol: 'den' },
        { value: 'MMK', label: 'MMK', symbol: 'K' },
        { value: 'MNT', label: 'MNT', symbol: '₮' },
        { value: 'MOP', label: 'MOP', symbol: 'MOP$' },
        { value: 'MRO', label: 'MRO', symbol: 'UM' },
        { value: 'MRU', label: 'MRU', symbol: 'UM' },
        { value: 'MUR', label: 'MUR', symbol: 'Rs' },
        { value: 'MWK', label: 'MWK', symbol: 'MK' },
        { value: 'MXN', label: 'MXN', symbol: '$' },
        { value: 'MYR', label: 'MYR', symbol: 'RM' },
        { value: 'MZN', label: 'MZN', symbol: 'MTn' },
        { value: 'NAD', label: 'NAD', symbol: '$' },
        { value: 'NGN', label: 'NGN', symbol: '₦' },
        { value: 'NIO', label: 'NIO', symbol: 'C$' },
        { value: 'NOK', label: 'NOK', symbol: 'kr' },
        { value: 'NPR', label: 'NPR', symbol: 'Rs' },
        { value: 'NZD', label: 'NZD', symbol: '$' },
        { value: 'PGK', label: 'PGK', symbol: 'K' },
        { value: 'PHP', label: 'PHP', symbol: '₱' },
        { value: 'PKR', label: 'PKR', symbol: 'ر' },
        { value: 'PLN', label: 'PLN', symbol: 'zł' },
        { value: 'PYG', label: 'PYG', symbol: '₲' },
        { value: 'RUB', label: 'RUB', symbol: '₽' },
        { value: 'RWF', label: 'RWF', symbol: 'RF' },
        { value: 'SBD', label: 'SBD', symbol: '$' },
        { value: 'SCR', label: 'SCR', symbol: 'SR' },
        { value: 'SEK', label: 'SEK', symbol: 'kr' },
        { value: 'SGD', label: 'SGD', symbol: '$' },
        { value: 'SHP', label: 'SHP', symbol: '£' },
        { value: 'SLL', label: 'SLL', symbol: 'Le' },
        { value: 'SOS', label: 'SOS', symbol: 'S' },
        { value: 'SRD', label: 'SRD', symbol: '$' },
        { value: 'SSP', label: 'SSP', symbol: '£' },
        { value: 'STD', label: 'STD', symbol: 'Db' },
        { value: 'STN', label: 'STN', symbol: 'Db' },
        { value: 'SYP', label: 'SYP', symbol: '£' },
        { value: 'SZL', label: 'SZL', symbol: 'E' },
        { value: 'THB', label: 'THB', symbol: '฿' },
        { value: 'TMT', label: 'TMT', symbol: 'ТМТ' },
        { value: 'TND', label: 'TND', symbol: 'DT' },
        { value: 'TOP', label: 'TOP', symbol: 'T$' },
        { value: 'TRY', label: 'TRY', symbol: '₺' },
        { value: 'TTD', label: 'TTD', symbol: '$' },
        { value: 'TWD', label: 'TWD', symbol: '$' },
        { value: 'TZS', label: 'TZS', symbol: 'TSh' },
        { value: 'UAH', label: 'UAH', symbol: '₴' },
        { value: 'UGX', label: 'UGX', symbol: 'USh' },
        { value: 'USD', label: 'USD', symbol: '$' },
        { value: 'UYU', label: 'UYU', symbol: '$' },
        { value: 'UZS', label: 'UZS', symbol: 'сўм' },
        { value: 'VEF', label: 'VEF', symbol: 'Bs' },
        { value: 'VND', label: 'VND', symbol: '₫' },
        { value: 'VUV', label: 'VUV', symbol: 'VT' },
        { value: 'WST', label: 'WST', symbol: 'WS$' },
        { value: 'XAF', label: 'XAF', symbol: 'FCFA' },
        { value: 'XCD', label: 'XCD', symbol: '$' },
        { value: 'XOF', label: 'XOF', symbol: 'CFA' },
        { value: 'XPF', label: 'XPF', symbol: 'CFP' },
        { value: 'ZAR', label: 'ZAR', symbol: 'R' },
        { value: 'ZMW', label: 'ZMW', symbol: 'K' },
      ],
    },
    tagFilter: {
      value: '',
    },
  },
};
