/*
export function someGetter (state) {
}
*/

// const mapGettersAndMutations = (module, properties) => {
//     const props = {};
//     if (Array.isArray(properties)) {
//       properties.forEach((property) => {
//         props[property] = {
//           get() {
//             return this.$store.getters[`${module}/${property}`];
//           },
//           set(value) {
//             this.$store.commit(`${module}/${property}`, value);
//           },
//         };
//       });
//     } else {
//       Object.keys(properties).forEach((key) => {
//         const property = properties[key];
//         props[key] = {
//           get() {
//             return this.$store.getters[`${module}/${property}`];
//           },
//           set(value) {
//             this.$store.commit(`${module}/${property}`, value);
//           },
//         };
//       });
//     }
//     return props;
//   };
