<template>
  <div v-if="part && part.name">
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{part.name.v}}
        <span class="lg-header-subtitle">
          {{$t('Attribute history')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="drawer-container">
       <attribute v-if="part[attrname] !== undefined"
        :def="defs[attrname]" :data="part[attrname]" :related="{yes: true}"
        mode="view-only" space="expanded" wrap="yes" :settings="$store.state.general.settings" :owner="true"/>
    </div>
  </div>
</template>
<script>

import { rulemixins } from './mixins';
import { localization } from '../../lib/mixins';
import attribute from '../../components/attributesDisplay/attribute';
import { store } from '../../lib/nrstore';

export default {
  components: {
    attribute,
  },
  data() {
    return {
      options: null,
      value: null,
      part: {},
    };
  },
  mixins: [rulemixins, localization],
  computed: {
    attrname() {
      return this.$route.params.attr;
    },
    defs() {
      return this.$store.state.pricing.defs.defs2;
    },
  },
  watch: {
    '$route.params.pid': {
      handler() {
        this.fetchPart(this.$route.params.pid);
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchPart(this.$route.params.pid);
  },
  methods: {
    fetchPart(id) {
      this.$axios.get(`/products/${id}`).then((res) => {
        this.part = res.data.data;
      }).catch((error) => {
        this.$q.notify({ type: 'negative', message: 'Something wrong happen trying to get the part infos.', icon: 'warning' });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  height: calc(100vh - 100px);
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
</style>
