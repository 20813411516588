<template>
  <div>
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Lookup in this family')}}
        <span v-if="!$store.state.pricing.single" class="lg-header-subtitle">
          {{$t('you will choose the rule after')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="drawer-container">
        <p>Please select the target family</p>
        <Treeselect ref="treeselect" :placeholder="$t('Please make a selection')"
          zIndex="7000" :appendToBody="true" v-model="value" :options="options" :load-options="loadOptions" />
    </div>
    <div class="lg-rule-actions">
      <q-btn label="Cancel" color="primary" @click="goback"/>
      &nbsp;
      <q-btn :disable="!value" :label="$t('Next')" color="secondary" @click="go('searchresult', { ifid: value })"/>
    </div>
  </div>
</template>
<script>
import { Treeselect, LOAD_ROOT_OPTIONS, LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { rulemixins } from './mixins';
import { localization } from '../../lib/mixins';

export default {
  data() {
    return {
      options: null,
      value: null,
    };
  },
  mixins: [rulemixins, localization],
  components: {
    Treeselect,
  },
  methods: {
    go(name, params = {}) {
      if (name === this.$route.name && (!params.pid || params.pid === this.$route.params.pid)) return;
      const { href } = this.$router.resolve({ name, params });
      this.$router.push(this.$store.state.pricing.single ? href.replace('/pricing', '/one') : { name, params });
    },
    loadOptions({ action, parentNode, callback }) {
      switch (action) {
        case LOAD_CHILDREN_OPTIONS:
        case LOAD_ROOT_OPTIONS:
          this.$axios
            .get(parentNode
              ? `/families/${parentNode.id}/children`
              : '/families', { params: { nolimit: 'yes', sort: 'label' } })
            .then((response) => {
              const options = response.data.data.map((f) => ({ id: f.id, children: null, label: this.localized(f.label) }));
              if (parentNode) {
                parentNode.children = options;
              } else {
                this.options = options;
              }
              callback();
            })
            .catch((error) => callback(error));
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
</style>
