import { render, staticRenderFns } from "./toto.vue?vue&type=template&id=481635b9&scoped=true&"
import script from "./toto.vue?vue&type=script&lang=js&"
export * from "./toto.vue?vue&type=script&lang=js&"
import style0 from "./toto.vue?vue&type=style&index=0&id=481635b9&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481635b9",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QList,QItemLabel,QItem,QItemSection,QOptionGroup,QSelect,QChip,QInput,QIcon,QBtn,QField});
