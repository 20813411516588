<template>
  <div :id="`finder${id}`" class="row full-height items-strech finder-container">
    <div v-for="(column, $index) in columns" :key="$index" class="full-height finder-column">
      <!-- <pre>{{column}}</pre> -->
      <q-list no-border>
        <Container lock-axis="y" @drop="$emit('drop', { column, ...$event })">
          <Draggable v-for="item in column" name="item" :key="item[field]">
            <slot name="item" :item="item"></slot>
          </Draggable>
        </Container>
      </q-list>
      <!-- <pre>{{getParent($index)}}</pre> -->
      <slot :parent="$index" name="new"></slot>
    </div>
  </div>
</template>

<script>
let ids = 0;
import { Container, Draggable } from 'vue-smooth-dnd';
import localization from '../lib/mixins'; //eslint-disable-line

export default {
  mixins: [localization],
  components: {
    Container,
    Draggable,
  },
  props: {
    field: {
      type: String,
      default() {
        return 'id';
      },
    },
    fetch: {
      type: Function,
      default() {
        return () => [];
      },
    },
    noSort: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /* drop: {
      type: Function,
    }, */
  },
  watch: {
    $route(to, from) {
      // console.log('ROUTE CHANGE');
      if (from.path.includes('drawer') && !to.path.includes('drawer')) {
        this.columns = [];
        this.routeParams = this.$route.params.pathMatch.split('/');
        this.initRouteParams().then((value) => {
          this.scrollToLastElem();
        });
      } else {
        const newParams = to.params.pathMatch.split('/');
        this.actualiseRouteParams(newParams);
      }
    },
  },
  data() {
    return {
      id: ids++, // eslint-disable-line
      columns: [],
      routeParams: [],
    };
  },
  methods: {

    getChildPayload(index) {
      return { index };
    },
    deepclone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    actualiseRouteParams(newParams) {
      let noChildren = false;

      for (let i = 0; i < newParams.length; i += 1) {
        if (newParams[i] === 'drawer') return;
        if (newParams[i] !== this.routeParams[i]) {
          if (newParams[i + 1] || newParams[i].startsWith('01')) {
            this.routeParams[i] = newParams[i];
            this.addColumn(this.routeParams[i], i + 1).then(() => {
              this.scrollToLastElem();
            });
          } else {
            noChildren = true;
          }
        }
      }
      const i = noChildren ? newParams.length : newParams.length + 1;
      this.columns = this.columns.slice(0, i);
      this.sortAndCleanColumn();
      this.routeParams = this.routeParams.slice(0, i - 1);
    },
    initRouteParams() {
      const that = this;
      let bool = true;
      const filtredRoutes = this.routeParams.filter((value) => {
        if (value === 'drawer') bool = false;
        return bool;
      });
      if (
        filtredRoutes[filtredRoutes.length - 1]
        && filtredRoutes[filtredRoutes.length - 1].startsWith('02')
      ) {
        filtredRoutes.splice(-1, 1);
      }
      return this.$utils.serial([
        () => that.addColumn('', 0),
        ...filtredRoutes.map((param, i) => () => {
          if (filtredRoutes[i]) return that.addColumn(filtredRoutes[i], i + 1);
          return Promise.resolve();
        }),
      ]);
    },
    addColumn(itemId, columnIndex) {
      return this.fetch(itemId.substring(2)).then((response) => {
        response.map((elem) => {
          elem.parent = columnIndex > 0;
          elem.column = columnIndex;
          return elem;
        });
        this.$set(this.columns, columnIndex, response);
        this.columns = this.columns.slice(0, columnIndex + 1);
        this.sortAndCleanColumn();
        Promise.resolve();
      });
    },
    sortAndCleanColumn() {
      // loop on all item
      this.columns = this.columns.map((col) => col.map((item) => {
        item.label = this.localized(item.label).normalize('NFD');
        return item;
      }));

      if (!this.noSort) {
        this.columns = this.columns.map((col) => col.sort((a, b) => {
          // upper case and remove accent from all labels and compare them
          const labelA = this.localized(a.label).toUpperCase().replace(/[\u0300-\u036f]/g, '');
          const labelB = this.localized(b.label).toUpperCase().replace(/[\u0300-\u036f]/g, '');
          if (labelA > labelB) return 1;
          if (labelA < labelB) return -1;
          return 0;
        }));
      }
    },
    scrollToLastElem() {
      this.$nextTick(() => {
        const el = document.getElementById(`finder${this.id}`);
        if (el) {
          el.parentElement.scrollTo({
            behavior: 'smooth',
            left: el.scrollWidth - el.offsetWidth,
          });
        }
      });
    },
  },
  created() {
    this.routeParams = this.$route.params.pathMatch.split('/');
    this.initRouteParams().then((value) => {
      this.scrollToLastElem();
    });
  },
};
</script>
<style lang="stylus">

.finder-container {
  flex-wrap: nowrap;

  .finder-column {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    border-right: 1px solid $light;
    position: relative;
    div.q-list {
      position relative
      overflow auto
      height 100%
    }
    .finder-fab {
      position: absolute;
      bottom: 50px;
      right: 50px;
    }
  }
}
</style>
