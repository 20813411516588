<template>
  <!-- Step 1 -->
  <div class="file-drop">
    <vue-dropzone
      id="drop1"
      class="drop-circle shadow-2"
      :options="dropOptions"
      @vdropzone-file-added="afterComplete"
    ></vue-dropzone>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'; // all dropOptions here: https://www.dropzonejs.com/#configuration

export default {
  components: {
    vueDropzone,
  },
  data() {
    return {
      lenParsedFile: 0,
      dropOptions: {
        url: '/toto', // canot remove it.
        acceptedFiles: '.csv,.xlsx',
        autoProcessQueue: false,
        previewTemplate: '<div class="dz-preview dz-file-preview"></div>', // for remove default template.
      },
    };
  },
  methods: {
    afterComplete(file) {
      this.$emit('upload-file', file);
    },
  },
};
</script>

<style lang="stylus">
.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 168px - 48px);

  .drop-circle {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>
