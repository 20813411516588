import { render, staticRenderFns } from "./CostPlusEdit.vue?vue&type=template&id=70f2b9a4&scoped=true&"
import script from "./CostPlusEdit.vue?vue&type=script&lang=js&"
export * from "./CostPlusEdit.vue?vue&type=script&lang=js&"
import style0 from "./CostPlusEdit.vue?vue&type=style&index=0&id=70f2b9a4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f2b9a4",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSelect,QBtn,QCardActions});
