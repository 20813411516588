import { render, staticRenderFns } from "./import.vue?vue&type=template&id=5c75553e&scoped=true&"
import script from "./import.vue?vue&type=script&lang=js&"
export * from "./import.vue?vue&type=script&lang=js&"
import style0 from "./import.vue?vue&type=style&index=0&id=5c75553e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c75553e",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QField from 'quasar/src/components/field/QField.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QDrawer,QField,QStepper});
