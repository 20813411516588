<template>
<div>
  <q-toolbar color="primary">
    <q-btn flat round dense icon="arrow_back" @click="goback"/>
    <q-toolbar-title>
      Define a new attribute
      <span slot="subtitle">Set parameters for this new attribute</span>
    </q-toolbar-title>
  </q-toolbar>
  <div class="attr-form">
    <q-field helper="This is the top level set" :error="$v.attr.set.$error"
      error-label="Attribute must be part of a set. Choose one." >
      <q-select @blur="$v.attr.set.$touch" v-model="attr.set" float-label="Set" :options="sets"/>
    </q-field>
    <q-field helper="Must be unique, only letters, digits, - and _" :error="$v.attr.name.$error"
      error-label="Check that name contains only letters, digits, dash or underscore.">
      <q-input  @blur="$v.attr.name.$touch" v-model="attr.name" float-label="Name"/>
    </q-field>
    <q-field helper="A Short human friendly label" :error="$v.attr.label.$error"
      error-label="A label is required.">
      <q-input @blur="$v.attr.label.$touch" v-model="attr.label" float-label="Label"/>
    </q-field>

    <q-field helper="Most often a number or boolean" :error="$v.attr.type.$error"
      error-label="Please select the attribute type" >
      <q-select @blur="$v.attr.type.$touch" v-model="attr.type"
        float-label="Base type " :options="types"/>
    </q-field>
    <q-field helper="Unit symbol (none is ok): mm, Kg, ..." v-if="attr.type === 'number'"
      :error="$v.attr.unit.$error"
      error-label="Please select the unit of attribute values">
      <q-input @blur="$v.attr.unit.$touch" v-model="attr.unit" float-label="Unit"/>
    </q-field>
    <q-field helper="Currency in which data will be saved" v-if="attr.type === 'amount'"
      :error="$v.attr.currency.$error"
      error-label="A currency is mandatory for amounts.">
      <q-select @blur="$v.attr.currency.$touch"  v-model="attr.currency" float-label="Base type"
        :options="currencies"/>
    </q-field>
    <q-field helper="Indices are coefficient applied to the price. Amount are markups."
      :error="$v.attr.weights.$error"
      error-label="Select at least a weight type.">
      <q-option-group @blur="$v.attr.weights.$touch" type="checkbox" v-model="attr.weights"
        :options="weighttypes"/>
    </q-field>
  </div>
  <q-btn color="primary" label="Add" class="fixed ruleparams-fab" @click="create"/>
</div>
</template>
<script>
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';

const identifier = (value) => RegExp('^\\w+$', 'i').test(value);

export default {
  data() {
    return {
      attr: { weights: ['rate'] },
      currencies: [
        { label: 'Swiss Franc', value: 'CHF' },
        { label: 'Euro', value: 'EUR' },
        { label: 'US Dollar', value: 'USD' },
      ],
      types: [
        { label: 'Number', value: 'number' },
        { label: 'Amount', value: 'amount' },
        { label: 'Boolean', value: 'boolean' },
      ],
      weighttypes: [
        { label: 'Indice', value: 'rate' },
        { label: 'Amount', value: 'amount' },
      ],
    };
  },
  computed: {
    sets() {
      return Object.entries(this.$store.state.pricing.sets.sets)
        .map(([name, set]) => ({ value: name, label: set.label }));
    },
  },
  validations: {
    attr: {
      set: { required },
      name: { required, identifier },
      label: { required },
      type: { required },
      unit: { maxLength: maxLength(6) },
      currency: { required: requiredIf((attr) => attr.type === 'amount') },
      weights: { required },
    },
  },
  methods: {
    create() {
      this.$v.attr.$touch();
      if (this.$v.attr.$error) {
        this.$q.notify({ message: 'Please make sure all fields are ok', icon: 'warning' });
      } else {
        this.$q.notify({ type: 'positive', message: 'Attribute sucessfully created', icon: 'done' });
        this.$router.push({ name: 'addattr', params: { name: this.attr.name } });
      }
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="stylus" scoped>
.attr-form {
  padding: 1rem;
}
.ruleparams-fab {
  bottom: 1rem
  right 1rem
}
</style>
