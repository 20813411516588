export default {
  emptyrule: null,
  progressmessage: null,
  graphmode: false,
  graphtype: 'price+sales24m',
  graphsort: 'price',
  xaxis: null,
  graphrules: [],
  family: undefined,
  currenttime: new Date(),
  priceset: 'DEFAULT',
  partsPage: [],
  activable: [],
  partsLength: [],
  nonpriced: [],
  slaves: [],
  partsFilter: {
    groupable: {},
    mode: 'disabled',
  },
  backlog: [],
  defs: {
    order: [],
    defs: {},
    grouped: [],
  },
  sets: {
    order: [],
    defs: {},
  },
  loaded: false,
  loaded2: false,
  rule: {},
  rulestore: [],
  actualturnover: 0,
  averagereferences: {},
  stats: {
    turnover: 0,
    purchase: 0,
    negativeprices: 0,
    purchasealerts: 0,
    variations: [0, 0, 0],
    margins: {
      sum: 0,
      sumv: 0,
      count: 0,
      countv: 0,
    },
  },
  computed: {},
  alldimensions: false,

  /* Search */
  currentPage: -1,
  searchFilter: '',
  searchGoTo: '',
  searchGoToID: 0,
  searchGoToRealID: 0,
  searchGoToSize: 0,
  alreadyrendered: false,
  searchType: 'ref',

  /* Select multiple parts */
  selectedParts: {},
  modeMultipleSelect: false,
  loadAfterMove: false,
};
