<template>
  <!-- eslint-disable max-len -->
  <div>
    <q-page-container v-if="loaded2 && $store.state.pricing && $store.state.pricing.defs && Object.keys(defs || {})">
      <q-layout view="lhr lpr lfr" container class="content">
        <q-page-container>
          <q-page>
            <HeaderProduct :product="product" />
            <div class="productdetails">
              <Attributes class="block"
                v-if="filtredAttributes.length"
                :filtredAttributes="filtredAttributes"
                :product="product"
                @update="patchProduct" @add="addAnAttribute" @error="showError"
                :disabled="candEdit"
              />
              <div class="block fullposition right">
                <q-card>
                  <q-tabs inline-label v-model="tab">
                    <q-tab name="segmentation" icon="account_tree" :label="$t('Segmentation')"/>
                    <q-tab name="comment" icon="feedback" :label="$t('Comment')" />
                    <q-tab name="kit" icon="extension" :label="$t('Components') + ' ['+components.length+']'" />
                    <q-tab name="dependencies" icon="share" :label="$t('Dependencies') + ' ['+dependencies.length+']'" />
                    <q-tab name="master-slave" icon="link" :label="$t('Master/Slave') + ' ['+slaves.length+']'" />
                    <q-tab v-if="pricingmethod === 'cost+'" name="costplus" icon="attach_money" :label="$t('Cost Plus')"/>
                  </q-tabs>
                  <q-separator />
                  <q-tab-panels v-model="tab" class="panels">
                    <q-tab-panel name="segmentation">

                        <TreeSelect class="treeSelect" :disabled="candEdit" :placeholder="$t('Please make a selection')" :columns="mandatorycolumns" :filter="!$store.state.general.settings.transparentproxypicture"
                        v-model="segmentationValue" @select="treeSelectChange" :imgs="$store.state.general.settings.segmentwithpictures || false" style="margin-top:10px">
                          <slot slot-scope="{ part }">
                            <div class="imgs">
                              <q-btn  round size="sm"  icon="arrow_left" style="height:30px; margin: auto 0" @click="part.prev()"/>
                              <img class="img"  :src="mkpath2(part)" />
                              <q-btn round size="sm"  icon="arrow_right" style="height:30px; margin: auto 0" @click="part.next()"/>
                            </div>
                          </slot>
                        </TreeSelect>
                        <!-- <div v-else>
                          <q-icon class="q-mr-sm" name="disabled_visible" size="xl" />
                          Not allowed to edit segmentation
                        </div> -->

                    </q-tab-panel>
                    <q-tab-panel name="comment">
                      <q-editor :disable="candEdit" v-model="comment"/>
                      <q-btn :disable="candEdit" :label="$t('Save')" color="secondary" @click="savecomment"/>
                    </q-tab-panel>
                    <q-tab-panel name="costplus">
                      <cost-plus-path-renderer :part="product" :family="segmentationValue" :feexs="feexs" :defs="$store.state.pricing.defs.defs2"/>
                    </q-tab-panel>
                    <q-tab-panel id="compcontainer" ref="container" name="kit" @dragover.prevent @drop.prevent="addFile">
                      <div >
                        <table class="component-table">
                          <thead>
                            <tr>
                              <th class="component-name">{{$t('#')}}</th>
                              <th class="component-label">{{$t('Label')}}</th>
                              <th v-if="$store.state.general.settings.discountoncomponents" class="component-label">{{$t('Discount')}}</th>
                              <th class="component-uom">{{$t('UOM')}}</th>
                              <th class="component-qty">{{$t('Qty')}}</th>
                              <th class="component-action"><div><q-btn :disable="candEdit" @click="removeall" dense color="negative" icon="delete" size="sm"/></div></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="components.length === 0"><td colspan="4">
                              <div class="file-drop">
                                <div class="drop-circle"><span>{{$t('Drag & Drop your XLSX file here')}}</span></div>
                              </div>
                            </td></tr>
                            <tr v-for="(component, index) in components" :key="component.component">
                              <td style="cursor:pointer" @click="gotoid(component.component)">{{component.name}}</td>
                              <td style="cursor:pointer" @click="gotoid(component.component)" class="component-label" >{{localized(component.label)}}</td>
                              <td v-if="$store.state.general.settings.discountoncomponents" class="component-discount">
                                <q-input v-model="component.discount" dense square filled type="number" @input="changediscount(component)"/>
                              </td>
                              <td>{{component.unit}}</td>
                              <td class="component-qty">
                                <q-input :disable="candEdit" v-model="component.qty" dense square filled type="number" @input="changeqty(component)"/>
                              </td>
                              <td><q-btn :disable="candEdit" @click="removecomponent(component.component, index)" icon="delete" dense color="negative" size="sm"/></td>
                            </tr>
                          </tbody>
                          <tfoot v-if="!candEdit">
                            <tr>
                              <td colspan="2">
                                <q-select ref="selectpart" popup-content-class="partpopup" use-input hide-selected fill-input input-debounce="0" option-label="label2"
                                v-model="upcomingcomponent.part" :options="aheadparts" @filter="componentfilter" dense square filled type="text" >
                                </q-select>
                              </td>
                              <td>
                                <q-select v-model="upcomingcomponent.unit" :options="units" dense square filled />
                              </td>
                              <td v-if="$store.state.general.settings.discountoncomponents"><q-input v-model="upcomingcomponent.discount" dense square filled type="number"/></td>
                              <td><q-input v-model="upcomingcomponent.qty" dense square filled type="number"/></td>
                              <td><q-btn :disable="!(upcomingcomponent.part && upcomingcomponent.qty>0)" @click="addcomponent" icon="add" color="secondary" dense size="sm" /></td></tr>
                          </tfoot>
                        </table>
                      </div>
                    </q-tab-panel>
                    <q-tab-panel id="compcontainer2" ref="container" name="dependencies">
                      <table class="component-table">
                        <thead>
                          <tr>
                            <th class="component-name" >{{$t('#')}}</th>
                            <th class="component-label">{{$t('Label')}}</th>
                            <th class="component-action"><q-btn icon="download" :label="localized('Download')" color="secondary" @click="exportDependencies()" style="padding-left:6px"></q-btn></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="dependencie in dependencies" :key="dependencie.id.v">
                            <td style="cursor:pointer" @click="gotoid(dependencie.id.v)">{{dependencie.name.v}}</td>
                            <td style="cursor:pointer" @click="gotoid(dependencie.id.v)" class="component-label">{{dependencie.label?localized(dependencie.label.v):''}}</td>
                           </tr>
                        </tbody>
                      </table>
                    </q-tab-panel>
                    <q-tab-panel id="compcontainer3" ref="container" name="master-slave">
                      <table class="component-table">
                        <thead>
                          <tr>
                            <th class="component-name" >{{$t('#')}}</th>
                            <th class="component-label">{{$t('Label')}}</th>
                            <th class="component-price">{{$t('Price')}}</th>
                            <th class="component-action">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="slave in slaves" :key="slave.id.v">
                            <td style="cursor:pointer" @click="gotoid(slave.id.v)">{{slave.name.v}}</td>
                            <td style="cursor:pointer" @click="gotoid(slave.id.v)" class="component-label">{{localized(slave.label.v)}}</td>
                            <td style="cursor:pointer" @click="gotoid(slave.id.v)" class="component-price">
                              <attribute :data="slave.price" :def="defs.price" :settings="$store.state.general.settings" />
                            </td>
                            <td>
                              <span v-if="slave.id.v === product.id.v">{{$t('(current)')}}</span>
                              <q-btn color="secondary" @click="refreshSlaves()" small dense v-if="slave.name.v === product.masterpart.v">
                                {{$t('Apply')}}
                              </q-btn>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </q-tab-panel>
                  </q-tab-panels>

                </q-card>
              </div>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <q-list no-border v-if="product.pictures" >

        <div  v-cloak @drop.prevent="addPict" @dragover.prevent class="draggable-container">
          <div  class="draggable">
          <span style="margin: auto;">{{$t('Drop your images here')}}</span>
          </div>
        </div>
        <Draggable :options="{ group: 'item'}" v-model="pictList"  draggable=".item">
          <q-item class="item" v-for="(picture, i) in pictList" :key="i">
            <div class="img" @click="showpic(mkpath(picture))">
              <img :src="mkpath(picture)" :alt="`image ${i}`" />
              <!-- {{picture}} -->
            </div>
          </q-item>
        </Draggable>

      </q-list>
      <q-list no-border v-else-if="$store.state.general.settings.transparentproxypicture">
        <q-item class="item">
          <div class="img" @click="showpic2">
            <img :src="proxypath" alt="Image 0" />
          </div>
        </q-item>
      </q-list>
      <div class="fixed ruleparams-fab">
        <q-btn color="negative" :disable="candelete" v-if="!candelete" @click="deleteme" icon="delete"/>&nbsp;
        <q-btn color="secondary" :disable="candEdit" v-if="activefamily" @click="go">{{$t('Pricing')}}</q-btn>&nbsp;
        <q-btn color="secondary" :disable="canEditRule" v-if="activefamily && familybased" :to="`/pricing/${activefamily.id}`">{{$t('Rule')}}</q-btn>
      </div>
    </q-drawer>
    <q-dialog v-if="(product.pictures && product.pictures.v) || $store.state.general.settings.transparentproxypicture" v-model="modalpictureopened">
      <img :src="modalpictureurl" :alt="product.name.v" />
    </q-dialog>
    <q-dialog v-model="shouldbedeleted" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="warning" color="negative" text-color="white" />
          <span class="q-ml-sm">{{$t('Are you sure that your really want to delete this part ? There is no undoing.')}}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat :label="$t('Cancel')" color="primary" v-close-popup />
          <q-btn flat :label="$t('Yes, I do')" color="negative" v-close-popup @click="actualdelete"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import xlsx from 'xlsx';
import Draggable from 'vuedraggable';
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { can } from '@cabinetmariel/scopes';
import localization from '../../lib/mixins'; // eslint-disable-line
import Attributes from './Attributes/Attributes';
import putils from '../../lib/pictures';
import HeaderProduct from './HeaderProduct/HeaderProduct';
import { getdecision, activefamily } from '../../lib/decisiongrids';
import Attribute from '../../components/attributesDisplay/attribute.vue';
import TreeSelect from '../../components/treeSelect/TreeSelect.vue';
import CostPlusPathRenderer from '../../components/CostPlusPathRenderer.vue';

const { utils, pricinglib } = pricingUtilities;
const { methods } = pricingMethods;
const { feexs } = pricinglib;
const {
  serial, findactualdate, findpricedate, getPartValue, getValueFromRawEx,
} = utils;
const redecimal = /\.?0*$/;
function mkemptyvalue(type, unit) {
  switch (type) {
    case 'rate':
      return 1;
    case 'number':
    case 'amount':
    case 'pricing':
      return { fixed: '0', number: 0, unit };
    case 'boolean':
      return false;
    case 'string':
    case 'collection':
      return '';
    case 'localizedstring':
      return { 'en-US': 'Replace me' };
    default:
      console.log('Unsupported type');
  }
  return false;
}
export default {
  mixins: [localization],
  components: {
    Attributes,
    Draggable,
    HeaderProduct,
    Attribute,
    TreeSelect,
    CostPlusPathRenderer,
  },
  data() {
    return {
      feexs,
      shouldbedeleted: false,
      components: [],
      upcomingcomponent: {
        part: null,
        discount: 0,
        qty: 1,
        unit: '',
      },
      aheadparts: [],
      comment: '',
      tab: 'segmentation',
      modalpictureopened: false,
      modalpictureurl: undefined,
      product: {},
      error: false,
      model: [],
      drawer: true,
      currentTagsets: '',
      tagsets: [],
      tags: [],
      filtredAttributes: [],
      locale: this.$store.state.auth.locale,
      tree: {
        headers: {
          current: {},
          list: [],
        },
        data: [],
        mappedData: [],
        expanded: [],
        selected: '',
      },
      families: [],
      loaded2: false,
      dependencies: [],
      slaves: [],
      ignoreNextSegmentationChange: true,
      segmentationValue: null,
    };
  },
  computed: {
    pricingmethod() {
      return getPartValue(this.product, 'pricingmethod');
    },
    proxypath() {
      if (!(this.product && this.product.id)) return '/default.png';
      return putils.mkproxypath2(this.product, this.$store.state.auth.token);
    },
    pictList: {
      get() {
        return this.product.pictures.v;
      },
      set(value) {
        this.product.pictures.v = value;
        this.patchProduct({ pictures: value });
        // this.picturesgotreordered();
      },
    },
    units() {
      return this.$store.state.general.settings.units.number;
    },
    familybased() {
      if (!(this.product.families && this.product.families.v.length && this.product.families.v[0].length)) return false;
      const family = this.product.families.v[0][this.product.families.v[0].length - 1];
      if (!(family.method && methods[family.method])) return false;
      return methods[family.method].familymode;
    },
    candelete() {
      return !can('products.delete', this.$store.state.auth.user.scopes);
    },
    candEdit() {
      return !can('products.write', this.$store.state.auth.user.scopes);
    },
    canEditRule() {
      return !can('rules.post', this.$store.state.auth.user.scopes);
    },
    firstsegmentation() {
      if (!(this.product.families && this.product.families.v.length)) return [];
      return this.product.families.v[0];
    },
    activefamily() {
      return activefamily(this.product, this.$store.state.general.grids.segmentation);
    },
    treeNode() {
      const pos = this.tree.mappedData
        .map((e) => e.label)
        .indexOf(this.tree.headers.current);
      return pos >= 0 ? [this.tree.mappedData[pos]] : [];
    },
    catalog() {
      return this.$store.state.catalog.data;
    },
    defs() {
      return this.$store.state.pricing.defs.defs2;
    },
    loaded() {
      return this.$store.state.pricing.loaded;
    },
    sets() {
      return this.$store.state.pricing.sets;
    },
    mandatorycolumns() {
      if (this.$store.state.general.settings?.catalog?.mandatory) {
        return this.$store.state.general.settings.catalog.mandatory;
      }
      return [];
    },
  },
  watch: {
    loaded() {
      this.initLoad();
    },
  },
  mounted() {
    // this.getTagsets();
    if (this.loaded) this.initLoad();
    this.listdependencies();
  },
  methods: {
    treeSelectChange(id) {
      if (!this.loaded2) return;
      // console.log('change to', id);
      if (id) {
        const families = [{
          product: this.product.id.v,
          family: id,
        }];
        // console.log('patching families', families);
        this.patchProduct({ families });
      }
    },
    deleteme() {
      this.shouldbedeleted = true;
    },
    actualdelete() {
      this.$axios.delete(`/products/${this.$route.params.id}`).then(() => {
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Part successfully deleted.'),
        });
        this.$router.push({ name: 'products' });
      }).catch((error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Something wrong happened, at leat part of the information related to this part could not be deleted.'),
        });
      });
    },
    removeall(silent = false) {
      return this.$axios.delete(`/products/${this.$route.params.id}/components`)
        .then((response) => {
          this.components = [];
          if (!silent) {
            this.$q.notify({
              icon: 'done',
              color: 'positive',
              message: this.$t('All subcomponents got removed.'),
            });
          }
          return Promise.resolve();
        }).catch((error) => {
          if (!silent) {
            this.$q.notify({
              icon: 'warning',
              color: 'negative',
              message: this.$t('Something wrong happened, at least some of the component could not be removed.'),
            });
          }
          return Promise.resolve();
        });
    },
    addFile(e) {
      if (this.candEdit) return;
      const { files } = e.dataTransfer;
      if (!files.length) return;
      if (files.length > 1) {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Please drop only one file.'),
        });
        return;
      }
      const [file] = files;
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Please drop an XLSX file'),
        });
        return;
      }
      const rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const wb = xlsx.read(data, { type: rABS ? 'binary' : 'base64' });
        const kit = wb.Sheets[wb.SheetNames[0]].A3;
        if (!kit || kit.v !== this.product.name.v) {
          this.$q.notify({
            icon: 'warning',
            color: 'negative',
            message: this.$t('Please drop a file related to {{pname}}!!! The one use is related to {{kv}}.', { pname: this.product.name.v, kv: kit.v }),
          });
          return;
        }
        const composition = wb.Sheets[wb.SheetNames[0]];
        const assembly = wb.Sheets[wb.SheetNames[1]];
        const lines = xlsx.utils.decode_range(composition['!ref']).e.r;
        const lines2 = assembly ? xlsx.utils.decode_range(assembly['!ref']).e : 0;
        const components = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 3; i < lines - 1; i++) {
          // eslint-disable-next-line no-continue
          if (!composition[`G${i}`]) continue;
          const reference = composition[`G${i}`].v.trim();
          const component = components.find((c) => (c.component === reference));
          if (component !== undefined) {
            component.qty += composition[`J${i}`].v;
            if (component.unit !== composition[`K${i}`].v.trim()) {
              throw new Error('Incompatible units');
            }
          } else {
            components.push({
              component: reference,
              qty: composition[`J${i}`].v,
              unit: composition[`K${i}`].v.trim(),
              discount: '0',
            });
          }
        }
        let checked = true;
        components.forEach((c) => {
          if ((typeof c.qty) !== 'number') {
            this.$q.notify({
              icon: 'warning',
              color: 'negative',
              message: this.$t('At least one of the quantities ({{component}} : {{qty}}) are not a number in Excel file.', c),
            });
            checked = false;
            return;
          }
          c.qty = c.qty.toFixed(6).replace(redecimal, '');
        });
        if (!checked) return;
        const taux = {
          '6cf67738-d439-4ef6-9781-70503ddcd9f1': {
            name: 'accueil-lyon-nom',
            taux: 150,
          },
          '5ccf8130-26c0-4246-a409-5b2ebc9f2034': {
            name: 'accueil-grenchen-nom',
            taux: 250,
          },
          '23d9c973-32f0-4d01-b591-a4dfb4736cad': {
            name: 'accueil-bielefeld-nom',
            taux: 150,
          },
          '3f04a0ba-2b82-46b9-8937-7a4b21894457': {
            name: 'accueil-mex-nom',
            taux: 250,
          },
          '0022fe14-1d17-4b26-8f49-3dc86c22a715': {
            name: 'accueil-san-giorgio-nom',
            taux: 150,
          },
          '59dda4e1-db52-4b6c-9be2-43af58e7f74c': {
            name: 'accueil-manchester-nom',
            taux: 150,
          },
          '5d6c545c-d52c-4119-aff7-29e6eafe4571': {
            name: 'accueil-piacenza-nom',
            taux: 150,
          },
          'a66e5728-07c4-4c44-98df-8816e81f77b7': {
            name: 'accueil-shanghai-nom',
            taux: 150,
          },
          '9f167bfb-e8b8-4222-8bf4-0981ad52e14d': {
            name: 'accueil-changzhou-nom',
            taux: 150,
          },
          '5d4773e3-ad6e-4445-9c57-41c4ad3ea892': {
            name: 'dev-test',
            taux: 1,
          },
        };

        const l = this.product.families.v[0].length;
        const { id } = this.product.families.v[0][l - 1];
        let defaulttaux;
        if (!taux[id] || !taux[id].taux) {
          defaulttaux = 250;
          /*
          this.$q.notify({
            icon: 'warning',
            color: 'negative',
            message: this.$t('Invalid segmentation found.'),
          });
          return; */
        }
        const cost = assembly ? assembly[xlsx.utils.encode_cell(lines2)] : undefined;
        const time = cost ? cost.v / (defaulttaux || taux[id].taux) : undefined;

        this.$axios.delete(`/products/${this.$route.params.id}/components`)
          .then(() => Promise.allSettled(components.map((c) => this.$axios.post(`/products/${this.$route.params.id}/components`, c)))
            .then((results) => {
              const status = results.reduce((_, r, i) => {
                if (typeof r !== 'object') {
                  _.error = true;
                } else if (r.status === 'fulfilled') {
                  _.added.push(r.value);
                } else if (r.reason?.response?.status === 404) {
                  _.missing.push(components[i].component);
                } else {
                  _.error = true;
                }
                return _;
              }, { error: false, missing: [], added: [] });
              if (status.error || status.missing.length) {
                return this.removeall(true).then(() => Promise.reject(status.error || status.missing));
              }
              return Promise.resolve(status.added);
            })
            .then((responses) => {
              this.components = responses.map((r) => {
                const c = r.data.data;
                c.qty = c.qty.replace(redecimal, '');
                return c;
              });
              if (!time) return Promise.resolve();
              const fixed = time.toFixed(6).replace(redecimal, '');
              return this.patchProduct({
                assemblytime: {
                  unit: 'h',
                  number: parseFloat(fixed),
                  fixed,
                },
              }, undefined, true);
            })
            .then(() => {
              this.$q.notify({
                icon: 'done',
                color: 'positive',
                message: this.$t('File successfully processed'),
              });
            })
            .catch((error) => {
              if (error === true) {
                return this.$q.notify({
                  icon: 'warning',
                  color: 'negative',
                  message: this.$t('An error occured while processing the file'),
                });
              }
              if (!Array.isArray(error)) {
                return this.$q.notify({
                  icon: 'warning',
                  color: 'negative',
                  message: this.$t('An error occured while processing the components'),
                });
              }
              return this.$q.notify({
                icon: 'warning',
                color: 'negative',
                timeout: 0,
                actions: [
                  {
                    label: 'Copy Parts',
                    color: 'white',
                    handler: () => {
                      navigator.clipboard.writeText(error.join(',')).then(() => {
                        this.$q.notify({
                          icon: 'done',
                          color: 'positive',
                          message: this.$t('Missing parts copied in the clipboard'),
                        });
                      });
                    },
                  },
                  { label: 'Dismiss', color: 'white' },
                ],
                message: this.$t('The following parts are missing : {{err}}', { err: error.join(',') }),
              });
            }))
          .catch((error) => this.$q.notify({
            icon: 'warning',
            color: 'negative',
            message: this.$t('An error occured while fulfilling deletion of product components'),
          }));
      };
      reader.onerror = (error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Unable to read the file'),
        });
      };
      reader.readAsBinaryString(file);
    },
    addPict(e) {
      const { files } = e.dataTransfer;
      if (!files.length) return;
      if (files.length > 1) {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Please drop only one file.'),
        });
        return;
      }

      const [file] = files;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('Please drop an jpg/png file'),
        });
        return;
      }

      this.$axios
        .post('/store', file, {
          headers: {
            'Content-Type': file.type,
            'X-filename': encodeURIComponent(file.name),
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.product.pictures.v.push(response.data.data.id);
          }
        });
    },
    changeqty(component) {
      this.$axios.put(`/products/${this.$route.params.id}/components/${component.component}`, {
        component: component.component,
        unit: component.unit,
        qty: parseFloat(component.qty).toFixed(6).replace(redecimal, ''),
        discount: component.discount,
      }).then((response) => {
        // this.components.push(response.data.data);
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Sub component successfully updated'),
        });
      }).catch((error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('The sub component could not be updated'),
        });
      });
    },
    changediscount(component) {
      this.$axios.put(`/products/${this.$route.params.id}/components/${component.component}`, {
        component: component.component,
        unit: component.unit,
        qty: component.qty,
        discount: component.discount, // parseInt(component.discount, 10),
      }).then((response) => {
        // this.components.push(response.data.data);
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Sub component successfully updated'),
        });
      }).catch((error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('The sub component could not be updated'),
        });
      });
    },
    removecomponent(component, index) {
      this.$axios.delete(`/products/${this.$route.params.id}/components/${component}`).then(() => {
        this.components.splice(index, 1);
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Sub component successfully updated'),
        });
      }).catch((error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('The sub component could not be updated'),
        });
      });
    },
    addcomponent() {
      this.$axios.post(`/products/${this.$route.params.id}/components`, {
        component: this.upcomingcomponent.part.id,
        unit: this.upcomingcomponent.unit,
        qty: parseFloat(this.upcomingcomponent.qty).toFixed(6).replace(redecimal, ''),
        discount: parseFloat(this.upcomingcomponent.discount).toFixed(6).replace(redecimal, ''),
      }).then((response) => {
        const component = response.data.data;
        component.qty = component.qty.replace(redecimal, '');
        component.discount = component.discount.replace(redecimal, '');
        this.components.push(component);
        this.upcomingcomponent = { part: null, qty: 1 };
        const { container } = this.$refs;
        this.$nextTick(() => {
          if (!container?.$el) return;
          const el = container.$el.parentElement;
          el.scrollTop = el.scrollHeight;
        });
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Sub component successfully added to this part'),
        });
      }).catch((error) => {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('the sub component cannot be added to this part'),
        });
      });
    },
    listdependencies() {
      this.$axios.get(`/products/${this.$route.params.id}/dependencies`).then((res) => {
        this.dependencies = res.data.data;
      }).catch((err) => {
        this.$q.notify({
          message: 'List dependencies failed',
          icon: 'warning',
        });
      });
    },
    exportDependencies() {
      const tmp = this.dependencies.map((item) => ({
        id: item.id,
        name: item.name.v,
        label: this.localized(item.label.v),
      }));
      const ws = xlsx.utils.json_to_sheet(tmp);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
      const url = window.URL.createObjectURL(new Blob([excelBuffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `dependencies_${this.product.name.v}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    listslaveparts() {
      if (!(this.product.masterpart && this.product.masterpart.v)) return;
      this.$axios.get('/products', {
        params: {
          nolimit: 'yes',
          columns: 'id,name,pricingmethod,price,classification',
          '@filters': JSON.stringify([{ column: 'masterpart', op: 'equals', args: [this.product.masterpart.v] }]),
        },
      }).then((res) => {
        this.slaves = res.data.data;
      }).catch((err) => this.$q.notify({
        icon: 'warning',
        color: 'negagive',
        message: this.$t('Unable to list parts'),
      }));
    },
    toISODate(d) {
      const yyyy = d.getFullYear();
      const mm = d.getMonth() + 1;
      const dd = d.getDate();
      return `${yyyy.toString().padStart(4, 0)}-${mm.toString().padStart(2, 0)}-${dd.toString().padStart(2, 0)}`;
    },
    refreshSlaves() {
      const startdate = this.toISODate(new Date());
      const master = this.slaves.find((p) => (this.product.masterpart.v === p.name.v));
      if (!master) {
        return this.$q.notify({
          icon: 'warning',
          color: 'negagive',
          message: this.$t('Unable to find the master part'),
        });
      }
      const masterpricerecord = getPartValue(master, 'price', undefined, undefined, (raw) => raw);
      if (!master) {
        return this.$q.notify({
          icon: 'warning',
          color: 'negagive',
          message: this.$t('Unable to find the master price'),
        });
      }
      return serial(this.slaves.map((slave) => () => {
        const sid = slave.id.v;
        let classificationrec = {};
        if (this.$store.state.general.settings.classification) { // classification support is enabled
          const classification = (this.$store.state.general.settings.masterslave
              && this.$store.state.general.settings.masterslave.forceclassification)
            ? this.$store.state.general.settings.masterslave.forceclassification
            : slave.classification.v;
          classificationrec = { classification };
        }
        if (slave.name.v === this.product.masterpart.v) return Promise.resolve();
        return this.$axios.patch(`/products/${sid}`, {
          price: masterpricerecord,
          t: `[${startdate},)`,
          pricestatus: 'auto',
          partstatus: false,
          tag: 'DEFAULT',
          pricingmethod: (slave.pricingmethod ? slave.pricingmethod.v : undefined) || this.$store.state.pricing.rule.method,
          ...classificationrec,
        });
      })).then(() => {
        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Great, all prices got pushed - Do not forget to refresh this page.'),
        });
      }).catch((error) => {
        console.error(error);
        this.$q.notify({
          icon: 'warning',
          color: 'negagive',
          message: this.$t('At least one slave price cannot be pushed'),
        });
      });
    },

    componentfilter(val, update, abort) {
      if (val.length < (this.$store.state.general.settings.meaningfullength || 7)) { abort(); return; }
      this.$axios.get('/products', {
        params: {
          columns: 'id,name,label',
          sort: '+name',
          '@filters': JSON.stringify([{ op: 'startswith', column: 'name', args: [val] }]),
          '@limit': 10,
        },
      }).then((response) => {
        const products = response.data.data;
        this.aheadparts = products.map((p) => {
          const rec = { id: p.id.v, name: p.name.v, label: this.localized(p.label.v) };
          rec.label2 = `${rec.name} : ${rec.label}`;
          return rec;
        });
        /* if (products.length === 1) {
          [this.upcomingcomponent.part] = this.aheadparts;
          this.$refs.selectpart.hidePopup();
          this.$refs.selectpart.validate();
          return;
        } */
        update();
      }).catch((error) => abort());
    },
    savecomment() {
      this.patchProduct({ comment: this.comment });
    },
    // picturesgotreordered() {
    //   this.patchProduct({ pictures: this.product.pictures.v });
    // },
    addAnAttribute({ value }, update = true) {
      if (this.product[value] !== undefined) return;
      if (this.defs[value] === undefined) return;
      this.$set(this.product, value, this.defs[value].timeserie
        ? {
          [this.defs[value].settings && this.defs[value].settings.tags && this.defs[value].settings.tags.length
            ? this.defs[value].settings.tags[0]
            : 'DEFAULT'
          ]: [],
        }
        : {
          name: value,
          set: this.defs[value].set,
          type: this.defs[value].type,
          v: mkemptyvalue(this.defs[value].type, (this.defs[value].settings || {}).unit || ''),
        });
      if (update) this.updateProductAttr();
    },
    go() {
      this.$router.push(`/one/${this.product.id.v}`);
    },
    gotoid(id) {
      if (id === this.product.id.v) return;
      const routeData = this.$router.resolve(`/products/${id}`);
      window.open(routeData.href, '_blank');
    },
    showpic2() {
      this.modalpictureopened = true;
      this.modalpictureurl = this.proxypath;
    },
    showpic(p) {
      this.modalpictureopened = true;
      this.modalpictureurl = p;
    },
    mkpath(id) {
      return putils.mkpath(id, this.$store.state.auth.token);
    },
    mkpath2(part) {
      // console.log('part', part);
      if (!part.id) return 'default.png';
      if (this.$store.state.general.settings.transparentproxypicture) {
        return putils.mkproxypath2(part, this.$store.state.auth.token);
      }
      if (part.pictures?.v?.[0]) {
        return putils.mkpath(part.pictures.v[0], this.$store.state.auth.token);
      }
      return 'default.png';
    },
    patchProduct(params, cb, silent = false) {
      return this.$axios
        .patch(`/products/${this.product.id.v}`, params)
        .then((response) => {
          this.product = response.data.data;
          this.updateProductAttr();
          // const attributeName = this.localized(this.defs[Object.keys(params).filter((k) => (k !== 't' && k !== 'tag'))[0]].label);
          const name = Object.keys(params).filter((k) => (!['t', 'tag', 'close', 'delete'].includes(k)))[0];
          const label = name ? this.localized(this.defs[name].label) : '';
          if (!silent) {
            this.$q.notify({
              icon: 'done',
              color: 'positive',
              message: this.$t('the attribute {{label}} has been updated', { label }),
            });
          }
          this.$nextTick(() => {
            if (cb) cb();
          });
          return Promise.resolve();
        })
        .catch((error) => {
          if (!silent) {
            this.$q.notify({
              icon: 'warning',
              color: 'negagive',
              message: this.$t('the attribute cannot be updated'),
            });
          }
          return Promise.reject();
        });
    },
    showError(err) {
      this.$q.notify({
        icon: 'warning',
        color: 'negagive',
        message: err,
      });
    },
    updateProductAttr() {
      const attributesList = this.defs;
      const excludes = [
        // filtrer compute et autre
        'tag',
        'families',
        'comment',
      ];
      if (this.$store.state.general.settings.autoadd && this.$store.state.general.settings.autoadd.length) {
        this.$store.state.general.settings.autoadd.forEach((name) => this.addAnAttribute({ value: name }, false));
      }
      const excludesTypes = ['collection', 'documentset'];
      function format(value, type) {
        switch (type) {
          case 'string':
          case 'boolean':
            return value;
          case 'number':
            return `${value.number} ${value.unit ? value.unit : ''}`;
          case 'amount':
            return `${value.fixed} ${value.currency}`;
          default:
            return type;
        }
      }
      this.filtredAttributes = Object.keys(this.product)
        .filter((name) => (
          !!attributesList[name]
            && !excludes.includes(name)
            && !excludesTypes.includes(attributesList[name].type)
        ))

        .map((name) => {
          const def = attributesList[name];
          const set = this.sets.sets[def.root];
          return {
            name,
            label: this.localized(def.label),
            set,
            position: def.position,
            value: format(this.product[name], def.type),
          };
        });
    },
    proceed() {
      if (this.defs) {
        this.comment = (this.product.comment || {}).v || '';
        this.updateProductAttr();
      }
      this.listslaveparts();
    },
    initLoad() {
      return Promise.all([
        this.$axios.get(`/products/${this.$route.params.id}`),
        this.$axios.get(`/products/${this.$route.params.id}/components`),
      ])
        .then(([response, response2]) => {
          this.product = response.data.data;
          if (this.product?.families?.v?.[0]) {
            this.segmentationValue = this.product.families.v[0][this.product.families.v[0].length - 1].id;
          }
          if (!this.product.label) {
            this.$q.notify({
              type: 'negative',
              actions: [{ label: 'Dismiss', color: 'white' }],
              timeout: 100000,
              message: this.$t('Most of data are missing for this part. If you asked for it few minutes ago you may need to wait a litte more before refreshing this page. Or did you forget have to ask for that part from the catalog fetch drawer.'),
              icon: 'warning',
            });
          }
          this.$store.commit('header/setHeader', {
            title: `${this.product.name.v} : ${this.localized((this.product.label || { v: '' }).v)}`,
            subtitle: this.firstsegmentation.map((child) => this.localized(child.label)).join(' → '),
            translated: true,
          });
          this.components = response2.data.data;
          this.components.forEach((c) => {
            c.qty = c.qty.replace(redecimal, '');
            c.discount = c.discount.replace(redecimal, '');
          });
          this.upcomingcomponent.unit = this.$store.state.general.settings.one.unit;
          this.proceed();
          this.loaded2 = true;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.$router.push('/productdoesnotexistsanymore');
          } else {
            this.$q.notify({ type: 'negative', message: this.$t('Something wrong occured'), icon: 'warning' });
          }
        });
    },
  },
};
</script>
<style lang="stylus">
.q-menu.partpopup {
  background-color #f1f1f1;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}
</style>
<style scoped lang="stylus">
.ruleparams-fab {
  right 1rem
  bottom 1rem
  left: 1rem
  text-align center
}
.lg-part-panels {
  height: calc(100vh - 50px);
}
.productdetails {
  height: calc(100vh - 170px);
  width: 100%;
  display flex
  align-items: stretch
  .block {
    flex 1
  }
  .block.right {
    height 100%
    .panels {
      height: calc(100vh - 270px);
    }
  }
}
.inline-flex {
  display: inline-flex;
}

.q-table-container {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.img {
  cursor: pointer;
  padding: 10px;

  img {
    width: 100%;
  }
}

.selected {
  background: rgba(0, 0, 0, 0.1);
}

.content {
  width: 100%;
  height: calc(100vh - 50px);
}

.fullposition > div.q-card {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
}

.component-table {
  width: 100%;
  thead, tfoot {
    background-color white;
  }
  thead {
    border-bottom: 1px solid gray;
  }
  tfoot {
    border-top: 1px solid gray;
  }
  th.component-name {
    width: 140px;
  }
  th.component-action {
    width: 25px;
  }
  th.component-qty {
    width: 100px;
  }
  th.component-discount {
    width: 100px;
  }
  td.component-qty {
    text-align center;
  }
  td.component-label {
    overflow: hidden;
  }
  >>> .q-field--dense .q-field__control, >>> .q-field--dense .q-field__marginal, >>> .q-field--dense .q-field__append {
    height 30px
  }
  >>> .q-field--auto-height.q-field--dense .q-field__control {
    min-height unset;
  }
  >>> .q-field--auto-height.q-field--dense .q-field__native {
    min-height: unset;
  }
}
.file-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  .drop-circle {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f1f1f1;
  }
}

.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    cursor: pointer;
  }

  .placeHolder {
    opacity: 0.5;
  }

  .draggable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
  }

  p {
    margin: 0;
  }
}
</style>
