<template>
  <div class="drawer-container">
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Part Preferences')}}
      </q-toolbar-title>
    </q-toolbar>
    <div v-if="$store.state.pricing.rule">
      <q-banner v-if="!$store.state.pricing.single" class="bg-orange text-white">
        <template v-slot:avatar>
          <q-icon name="warning" color="white" />
        </template>
        <div>{{$t('This selection applies to individual part pricing.')}}</div>
      </q-banner>
      <q-list>
        <q-item>
          <q-item-section>
            <q-item-label>{{$t('How many part should be displayed')}}</q-item-label>
            <q-item-label caption>{{$t('This will be applied for both closest parts and top sellers.')}}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
                <q-slider @input="setmax" :value="$store.state.pricing.rule.similars.max" :min="3" :max="20" :step="1" label label-always color="secondary"/>
          </q-item-section>
        </q-item>
        <q-item tag="label" v-ripple>
          <q-item-section>
            <q-item-label>{{$t('Show some parts similar in weight')}}</q-item-label>
            <!-- <q-item-label caption>Select the dimension to use and choose the number of parts to display.</q-item-label>-->
          </q-item-section>
          <q-item-section avatar>
            <q-toggle color="positive" :value="$store.state.pricing.rule.similars.closests" @input="setclosests" val="similar" />
          </q-item-section>

        </q-item>
        <!-- <q-item>
          <q-item-section>
            <q-select :value="dimension" emit-value map-options :options="dimensions" dense filled square label="Dimension"/>
          </q-item-section>
        </q-item> -->
        <q-item tag="label" v-ripple>
          <q-item-section>
            <q-item-label>{{$t('Show best sellers')}}</q-item-label>
            <!-- <q-item-label caption>Choose the number of parts to display</q-item-label>-->
          </q-item-section>
          <q-item-section avatar>
            <q-toggle color="positive" @input="setsellers" :value="$store.state.pricing.rule.similars.sellers" val="friend" />
          </q-item-section>
        </q-item>
        <q-item tag="label" v-ripple>
          <q-item-section>
            <q-item-label>{{$t('Show parts with identical attribute value')}}</q-item-label>
            <q-item-label caption>{{$t('Select the attribute')}}</q-item-label>
            <select class="attrselect" :value="$store.state.pricing.rule.similars.identical || ''" @input="identical=$event.target.value">
              <option v-for="a in Object.values($store.state.pricing.defs.defs2).filter((v) => ['string','collection'].includes(v.type))" :key="a.name" :value="a.name">{{localized(a.label)}}</option>
            </select>
          </q-item-section>
          <q-item-section avatar>
            {{!!$store.state.pricing.rule.similars.identical}}
            <q-toggle color="positive" @input="setidentical" :value="!!$store.state.pricing.rule.similars.identical" val="identical" />
          </q-item-section>
        </q-item>
        <q-item tag="label" v-ripple>
          <q-item-section>
            <q-item-label>{{$t('Show your own list of parts')}}</q-item-label>
            <q-item-label caption>{{$t('Type in the part references (comma separated)')}}</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-toggle color="positive" :value="$store.state.pricing.rule.similars.hasincludes" @input="sethasincludes" val="picture" />
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-input dense type="textarea" @input="setincludes" :value="$store.state.pricing.rule.similars.includes.join(',')" filled square label="Part references"/>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="lg-rule-actions">
      <q-btn v-if="$store.state.pricing.single" :label="$t('Reload')" color="secondary" @click="reload"/>
    </div>
  </div>
</template>
<script>
import { rulemixins } from './mixins';
import localization from '../../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      identical: '',
      selection: [],
      dimension: 'masse',
      samecontext: true,
      dimensions: [{ label: 'Masse', value: 'masse' }],
    };
  },
  methods: {
    setmax(v) {
      this.$store.commit('pricing/setSimilars', { max: v });
    },
    setclosests(v) {
      this.$store.commit('pricing/setSimilars', { closests: v });
    },
    setsellers(v) {
      this.$store.commit('pricing/setSimilars', { sellers: v });
    },
    setidentical(v) {
      const identical = v ? this.identical : false;
      this.$store.commit('pricing/setSimilars', { identical });
    },
    sethasincludes(v) {
      this.$store.commit('pricing/setSimilars', { hasincludes: v });
    },
    setincludes(v) {
      this.$store.commit('pricing/setSimilars', { includes: v.split(',').map((i) => i.trim()) });
    },
    reload() {
      this.$store.dispatch('pricing/setParts');
    },
  },
};
</script>
<style lang="stylus" scoped>
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}

.attrselect {
    width: 100%;
    height: 2rem;
}
</style>
