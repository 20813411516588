<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>{{$t('Bulk price approval')}}</q-toolbar-title>
    </q-toolbar>
    <div class="drawer-content">
      <q-banner rounded class="price-banner bg-negative text-white">
        <template v-slot:avatar>
          <q-icon name="warning" color="white" />
        </template>
        {{$t('This action will be applied to all parts matching the current filter. So be extra careful with this action.')}}
      </q-banner>
        <q-input  :label="$t('Validity date')" mask="##/##/####" fill-mask class="full-width" v-model="activationdateinput" />
        <div v-if="$store.state.general.settings.classification &&  $store.state.pricing.defs.defs2.classification.settings && $store.state.pricing.defs.defs2.classification.settings.values">
          <div class="q-field" style="position: relative;"><div class="q-field--float">
            <span class="q-field__label absolute ellipsis">{{$t('Classification override :')}}</span>
            <select class="fieldpad htmlselect" v-model="classification">
              <option value="">{{$t('(AUTO)')}}</option>
              <option v-for="item in $store.state.pricing.defs.defs2.classification.settings.values" :key="item.name" :value="item.name">{{item.name}}</option>
            </select>
          </div></div>

          <p class="sectionheader">{{$t('You can also force the following information :')}}</p>
          <div class="q-field" style="position: relative;"><div class="q-field--float">
            <span class="q-field__label absolute ellipsis">{{$t('Currency :')}}</span>
            <select v-model="currency" class="fieldpad htmlselect">
              <option value="">(AUTO)</option>
              <option v-for="(u, index) in $store.state.general.settings.units.amount" :key="index" :value="u">{{u}}</option>
            </select>
          </div></div>
          <div class="q-field" style="position: relative;"><div class="q-field--float">
            <span class="q-field__label absolute ellipsis">{{$t('UOM :')}}</span>
            <div class="uomdiv">
              <input v-model="qty" class="htmlselect" type="number">
              <select v-model="uom" class="htmlselect">
              <option value="">(AUTO)</option>
              <option v-for="(u, index) in $store.state.general.settings.units.number" :key="index" :value="u">{{u}}</option>
              </select>
            </div>
          </div></div>
          <q-banner rounded class="price-banner bg-warning text-white">
            <template v-slot:avatar>
              <q-icon name="warning" color="white" />
            </template>
            {{$t('Beware: there is no computation at all for these fields, computed data will be overwritten by those values.')}}
          </q-banner>
        </div>
    </div>
    <div class="lg-rule-actions"> <q-btn color="secondary" label="Approve" @click="approve"/></div>
  </div>
</template>
<script>
import { localization } from '../../../lib/mixins';

export default {
  mixins: [localization],
  props: ['data'],
  data() {
    return {
      activationdate: this.$store.state.catalog.when,
      classification: '',
      currency: '',
      qty: '1',
      uom: '',
    };
  },
  computed: {
    activationdateinput: {
      get() {
        return this.toLocaleDate(this.activationdate);
      },
      set(v) {
        this.activationdate = this.fromLocaleDate(v);
      },
    },
  },
  methods: {
    closeDrawer() {
      this.data.type = undefined;
    },
    approve() {
      this.$axios.post('/bulk/approve', {
        filter: this.$store.state.catalog.filters,
        when: this.$store.state.catalog.when,
        price: {
          classification: this.classification || undefined,
          currency: this.currency || undefined,
          uom: {
            unit: this.uom,
            number: this.qty,
          },
          when: this.activationdate,
        },
      }).then((response) => {
        this.$q.notify({ type: 'positive', message: this.$t('Prices are about to be approved... Please give it time.'), icon: 'done' });
        this.$emit('update');
      }).catch((error) => {
        this.$q.notify({
          type: 'negative',
          message: (error.response && error.response.status === 409)
            ? this.$t('A similar action is already in progress, please wait for it to finish.')
            : this.$t('Something wrong happened trying to approve prices'),
          icon: 'warning',
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-content {
  padding: .5rem;
}
.lg-rule-actions {
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.fieldpad {
  margin-top: 2rem;
}
.htmlselect {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.24);
  transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
.htmlselect:hover {
  outline: none;
  border-color: #000
}
.htmlselect:focus {
  outline: none;
  border-bottom: 2px solid $primary;
  transition: transform 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
.sectionheader {
  padding-top: 2rem;
}
.uomdiv {
  padding-top: 2rem;
    display: flex;
    gap: 1rem;
}
.price-banner {
  margin-top: .5rem
}
</style>
