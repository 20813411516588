<template>
    <svg class="ad-SVG" :width="setting.w" :height="setting.h" @mousemove="handleMouseMove" @mouseup="cancelDragging"  ref="mysvg" @svgedit:addleft="addLeft" @svgedit:addright="addRight">
      <g :class="{'ad-Grid': true, 'is-hidden': !setting.grid.show}">
        <line v-for="(el,i) in gridComp" :key="i"
          :x1="el.x1"
          :y1="el.y1"
          :x2="el.x2"
          :y2="el.y2"
        />
      </g>

      <g style="stroke: rgb(39, 39, 39);">
        <line style="stroke-width: 3px;" :x1="setting.grid.size" :y1="setting.h-setting.grid.size" :x2="setting.w" :y2="setting.h-setting.grid.size"/>
        <g v-for="x in (setting.w/setting.grid.size)-1" :key="x*100" >
          <line v-if="x!==(setting.w/setting.grid.size)-1 && x!==1 && x!==2" :x1="(x*setting.grid.size)" :y1="setting.h-34" :x2="(x*setting.grid.size)" :y2="setting.h-20"></line>
          <text v-if="x!==(setting.w/setting.grid.size)-1 && x!==1  && x!==2" class="unselectable" font-size="10" :x="(x*setting.grid.size)-5" :y="setting.h-10">{{ Math.round(setting.xmin + ((setting.xmax-setting.xmin)/((setting.w-(setting.grid.size*5))/setting.grid.size)) * (x-3)) }}</text>
          <text v-if="x===1" class="unselectable" font-size="10" :x="(x*setting.grid.size)+5" :y="setting.h-10">0</text>
          <text v-if="x===(setting.w/setting.grid.size)-1" class="unselectable" font-size="25" :x="(x*setting.grid.size)+3" :y="setting.h-5">∞</text>
        </g>

        <line style="stroke-width: 3px;" :x1="setting.grid.size" :y1="setting.h-setting.grid.size" :x2="setting.grid.size" :y2="0"/>
        <g v-for="y in (setting.h/setting.grid.size)-1" :key="y" >
          <line :x1="20" :y1="setting.h-(y*setting.grid.size)" :x2="34" :y2="setting.h-(y*setting.grid.size)"></line>
          <text class="unselectable" font-size="9" :x="1" :y="setting.h-(y*setting.grid.size)+3">{{ Math.round( (((y-1)/((setting.h-(setting.grid.size*2))/setting.grid.size))*(setting.ymax-setting.ymin)+ setting.ymin)*100) /100 }}</text>
        </g>
      </g>
      <path class="ad-Path" :d="path"/>

      <g class="ad-Points">
        <g v-for="(p,i) in points" :key="i" :class="{'ad-PointGroup': true,  'is-active': activePoint === i, 'first': (i===0 || i === points.length-1) }">
          <circle class="ad-Point"
            @mousedown="(e) => setDraggedPoint(i)"
            :cx="p.x"
            :cy="p.y"
            :r="8" />

          <g v-if="p.q" class="ad-Anchor">
            <line
              class="ad-Anchor-line"
              :x1="points[i - 1].x"
              :y1="points[i - 1].y"
              :x2="p.q.x"
              :y2="p.q.y"/>
            <line
              class="ad-Anchor-line"
              :x1="p.q.x"
              :y1="p.q.y"
              :x2="p.x"
              :y2="p.y"/>
            <circle
              class="ad-Anchor-point"
              @mousedown="(e) => setDraggedQuadratic(i)"
              :cx="p.q.x"
              :cy="p.q.y"
              :r="6"/>
          </g>

          <g v-else-if="p.c" class="ad-Anchor">
            <line
              class="ad-Anchor-line"
              :x1="points[i - 1].x"
              :y1="points[i - 1].y"
              :x2="p.c[0].x"
              :y2="p.c[0].y" />
            <line
              class="ad-Anchor-line"
              :x1="p.x"
              :y1="p.y"
              :x2="p.c[1].x"
              :y2="p.c[1].y" />
            <circle
              class="ad-Anchor-point"
              @mousedown="(e) => setDraggedCubic(i, 0)"
              :cx="p.c[0].x"
              :cy="p.c[0].y"
              :r="6" />
            <circle
              class="ad-Anchor-point"
              @mousedown="(e) => setDraggedCubic(i, 1)"
              :cx="p.c[1].x"
              :cy="p.c[1].y"
              :r="6" />
          </g>
        </g>
      </g>
    </svg>
</template>

<script>
export default {
  props: [
    'setting',
    'input',
    'interactive',
  ],
  data() {
    return {
      points: [],
      activePoint: 2,
      draggedPoint: false,
      draggedQuadratic: false,
      draggedCubic: false,
    };
  },
  watch: {
    input: {
      handler() {
        this.convertPoints(this.input);
      },
      immediate: true,
    },
  },
  computed: {
    gridComp() {
      const { show, snap, size } = this.setting.grid;
      const grid = [];

      for (let i = 1; i < (this.setting.w / size); i += 1) {
        grid.push({
          x1: i * size,
          y1: 0,
          x2: i * size,
          y2: this.setting.h,
        });
      }

      for (let i = 1; i < (this.setting.h / size); i += 1) {
        grid.push({
          x1: 0,
          y1: i * size,
          x2: this.setting.w,
          y2: i * size,
        });
      }
      return grid;
    },
    path() {
      let d = '';
      this.points.forEach((p, i) => {
        if (i === 0) {
          // first point
          d += 'M ';
        } else if (p.q) {
          // quadratic
          d += `Q ${p.q.x} ${p.q.y} `;
        } else if (p.c) {
          // cubic
          d += `C ${p.c[0].x} ${p.c[0].y} ${p.c[1].x} ${p.c[1].y} `;
        } else if (p.a) {
          // arc
          d += `A ${p.a.rx} ${p.a.ry} ${p.a.rot} ${p.a.laf} ${p.a.sf} `;
        } else {
          d += 'L ';
        }

        d += `${p.x} ${p.y} `;
      });

      return d;
    },
  },
  mounted() {
    this.convertPoints(this.input);
  },
  methods: {
    updateInput(nerwIn) {
      this.input = nerwIn;
    },
    convert(x, y) {
      if (x === '∞') {
        x = this.setting.w;
      } else if (x === 0) {
        x = this.setting.grid.size;
      } else {
        x = Math.round(this.setting.grid.size + (this.setting.grid.size * 2) + ((x - this.setting.xmin) / (this.setting.xmax - this.setting.xmin)) * (this.setting.w - this.setting.grid.size - (this.setting.grid.size * 2) - (this.setting.grid.size * 2)));
      }
      y = (this.setting.h - this.setting.grid.size) - Math.round(((y - this.setting.ymin) / (this.setting.ymax - this.setting.ymin)) * (this.setting.h - 2 * this.setting.grid.size));
      return { x, y };
    },
    unconvert(x, y) {
      if (x > this.setting.w - 5) {
        x = '∞';
      } else if (x === this.setting.grid.size) {
        x = 0;
      } else {
        x = (((x - (this.setting.grid.size + (this.setting.grid.size * 2))) / (this.setting.w - this.setting.grid.size - (this.setting.grid.size * 2) - (this.setting.grid.size * 2))) * (this.setting.xmax - this.setting.xmin)) + this.setting.xmin;
      }
      y = this.setting.ymin + ((this.setting.ymax - this.setting.ymin) * (((this.setting.h - this.setting.grid.size) - y) / (this.setting.h - 2 * this.setting.grid.size)));
      return { x, y };
    },
    convertPoints(input) {
      const tmp = JSON.parse(JSON.stringify(input));
      this.points = tmp.map((p) => {
        p.x = this.convert(p.x, p.y).x;
        p.y = this.convert(p.x, p.y).y;

        if (p.q) {
          p.q = this.convert(p.q.x, p.q.y);
        } else if (p.c) {
          p.c[0] = this.convert(p.c[0].x, p.c[0].y);
          p.c[1] = this.convert(p.c[1].x, p.c[1].y);
        } else if (p.a) {
          // const tmpA = this.convert(p.x, p.y);
        }
        return p;
      });
      this.points.sort((a, b) => a.x - b.x);
    },
    unConvertPoints() {
      return this.points.map((p) => {
        p.x = this.unconvert(p.x, p.y).x;
        p.y = this.unconvert(p.x, p.y).y;

        if (p.q) {
          p.q = this.unconvert(p.q.x, p.q.y);
        } else if (p.c) {
          p.c[0] = this.unconvert(p.c[0].x, p.c[0].y);
          p.c[1] = this.unconvert(p.c[1].x, p.c[1].y);
        } else if (p.a) {
          // const tmpA = this.convert(p.x, p.y);
        }
        return p;
      });
    },
    save() {
      return this.points.map((p) => {
        p.x = this.unconvert(p.x, p.y).x;
        p.y = this.unconvert(p.x, p.y).y;

        if (p.q) {
          p.q = this.unconvert(p.q.x, p.q.y);
        } else if (p.c) {
          p.c[0] = this.unconvert(p.c[0].x, p.c[0].y);
          p.c[1] = this.unconvert(p.c[1].x, p.c[1].y);
        } else if (p.a) {
          // const tmpA = this.convert(p.x, p.y);
        }
        return p;
      });
    },

    addLeft() {
      const active = this.activePoint;
      if (active > 1 && active < this.points.length - 1) {
        const x = this.points[active].x - (this.points[active].x - this.points[active - 1].x) / 2;
        this.points.push({ x: Math.round(x), y: 200 });
        this.points.sort((a, b) => a.x - b.x);
      } else {
        this.$q.notify({ type: 'negative', message: 'Can\'t add left point here', icon: 'error' });
      }
    },
    addRight() {
      const active = this.activePoint;
      if (active < this.points.length - 2 && active > 0) {
        const x = this.points[active].x + (this.points[active + 1].x - this.points[active].x) / 2;
        this.points.push({ x: Math.round(x), y: 200 });
        this.points.sort((a, b) => a.x - b.x);
      } else {
        this.$q.notify({ type: 'negative', message: 'Can\'t add right point here', icon: 'error' });
      }
    },
    changeType(t) {
      const active = this.activePoint;
      if (active > 1 && active < this.points.length - 1) {
        this.setPointType(t.toLowerCase());
      } else {
        this.$q.notify({ type: 'negative', message: 'The type of this point can\'t be changed', icon: 'error' });
      }
    },
    rm(e) {
      const active = this.activePoint;
      if (active > 1 && active < this.points.length - 2) {
        this.removeActivePoint(e);
      } else {
        this.$q.notify({ type: 'negative', message: 'This point can\'t be removed', icon: 'error' });
      }
    },

    /** **************************************************** */

    positiveNumber(n) {
      n = parseInt(n, 10);
      if (Number.isNaN(n) || n < 0) n = 0;
      return n;
    },

    setWidth(e) {
      let v = this.positiveNumber(e.target.value); const
        min = 1;
      if (v < min) v = min;
      this.w = v;
    },

    setHeight(e) {
      let v = this.positiveNumber(e.target.value); const
        min = 1;
      if (v < min) v = min;
      this.h = v;
    },

    setGridSize(e) {
      let v = this.positiveNumber(e.target.value);
      const min = 1;
      const max = Math.min(this.setting.w, this.setting.h);

      if (v < min) v = min;
      if (v >= max) v = max / 2;

      this.setting.grid.size = v;
    },

    setGridSnap(e) {
      this.setting.grid.snap = e.target.checked;
    },

    setGridShow(e) {
      this.setting.grid.show = e.target.checked;
    },

    getMouseCoords(e) {
      const rect = this.$refs.mysvg.getBoundingClientRect();
      let x = Math.round(e.pageX - rect.left);
      let y = Math.round(e.pageY - rect.top);

      if (this.setting.grid.snap) {
        x = this.setting.grid.size * Math.round(x / this.setting.grid.size);
        y = this.setting.grid.size * Math.round(y / this.setting.grid.size);
      }

      return { x, y };
    },

    getPointType(p) {
      if (!p) {
        return '';
      }
      if (p.q) {
        return 'q';
      } if (p.c) {
        return 'c';
      } if (p.a) {
        return 'a';
      }
      return 'l';
    },

    setPointType(e) {
      const active = this.activePoint;

      // not the first point
      if (active !== 0) {
        const v = e;// .target.value;

        switch (v) {
          case 'l':
            this.points[active] = {
              x: this.points[active].x,
              y: this.points[active].y,
            };
            break;
          case 'q':
            this.points[active] = {
              x: this.points[active].x,
              y: this.points[active].y,
              q: {
                x: (this.points[active].x + this.points[active - 1].x) / 2,
                y: (this.points[active].y + this.points[active - 1].y) / 2,
              },
            };
            break;
          case 'c':
            this.points[active] = {
              x: this.points[active].x,
              y: this.points[active].y,
              c: [
                {
                  x: (this.points[active].x + this.points[active - 1].x - 50) / 2,
                  y: (this.points[active].y + this.points[active - 1].y) / 2,
                },
                {
                  x: (this.points[active].x + this.points[active - 1].x + 50) / 2,
                  y: (this.points[active].y + this.points[active - 1].y) / 2,
                },
              ],
            };
            break;
          case 'a':
            this.points[active] = {
              x: this.points[active].x,
              y: this.points[active].y,
              a: {
                rx: 50,
                ry: 50,
                rot: 0,
                laf: 1,
                sf: 1,
              },
            };
            break;
          default:
        }
        this.points = this.points.slice();
        this.points.sort((a, b) => a.x - b.x);
        this.$set(this.points);
      }
    },

    setPointPosition(coord, e) {
      const coords = this.points[this.activePoint];
      let v = this.positiveNumber(e.target.value);

      if (coord === 'x' && v > this.setting.w) v = this.setting.w;
      if (coord === 'y' && v > this.setting.h) v = this.setting.h;

      coords[coord] = v;

      this.setPointCoords(coords);
    },

    setQuadraticPosition(coord, e) {
      const coords = this.points[this.activePoint].q;
      let v = this.positiveNumber(e.target.value);

      if (coord === 'x' && v > this.setting.w) v = this.setting.w;
      if (coord === 'y' && v > this.setting.h) v = this.setting.h;

      coords[coord] = v;

      this.setQuadraticCoords(coords);
    },

    setCubicPosition(coord, anchor, e) {
      const coords = this.points[this.activePoint].c[anchor];
      let v = this.positiveNumber(e.target.value);

      if (coord === 'x' && v > this.setting.w) v = this.setting.w;
      if (coord === 'y' && v > this.setting.h) v = this.setting.h;

      coords[coord] = v;

      this.setCubicCoords(coords, anchor);
    },

    setPointCoords(coords) {
      const active = this.activePoint;
      if (active < (this.points.length - 1) && coords.x > this.points[active + 1].x) {
        this.points[active].x = this.points[active + 1].x;
      } else if (active > 0 && coords.x < this.points[active - 1].x) {
        this.points[active].x = this.points[active - 1].x;
      } else {
        this.points[active].x = coords.x;
      }
      this.points[active].y = coords.y;
    },

    setQuadraticCoords(coords) {
      const active = this.activePoint;

      if (active < (this.points.length - 1) && coords.x > this.points[active].x) {
        this.points[active].q.x = this.points[active].x;
      } else if (active > 0 && coords.x < this.points[active - 1].x) {
        this.points[active].q.x = this.points[active - 1].x;
      } else {
        this.points[active].q.x = coords.x;
      }

      // this.points[active].q.x = coords.x;
      this.points[active].q.y = coords.y;
    },

    setArcParam(param, e) {
      const active = this.activePoint;
      let v;
      if (['laf', 'sf'].indexOf(param) > -1) {
        v = e.target.checked ? 1 : 0;
      } else {
        v = this.positiveNumber(e.target.value);
      }
      this.points[active].a[param] = v;
    },

    setCubicCoords(coords, anchor) {
      const active = this.activePoint;

      if (active < (this.points.length - 1) && coords.x > this.points[active].x) {
        this.points[active].c[anchor].x = this.points[active].x;
      } else if (active > 0 && coords.x < this.points[active - 1].x) {
        this.points[active].c[anchor].x = this.points[active - 1].x;
      } else {
        this.points[active].c[anchor].x = coords.x;
      }

      // this.points[active].c[anchor].x = coords.x;
      this.points[active].c[anchor].y = coords.y;
    },

    setDraggedPoint(index) {
      if (!this.interactive) {
        return;
      }
      this.activePoint = index;
      this.draggedPoint = true;
    },

    setDraggedQuadratic(index) {
      if (!this.interactive) {
        return;
      }
      this.activePoint = index;
      this.draggedQuadratic = true;
    },

    setDraggedCubic(index, anchor) {
      if (!this.interactive) {
        return;
      }
      this.activePoint = index;
      this.draggedCubic = anchor;
    },

    cancelDragging(e) {
      this.draggedPoint = false;
      this.draggedQuadratic = false;
      this.draggedCubic = false;
    },

    addPoint(e) {
      const coords = this.getMouseCoords(e);
      this.points.push(coords);
      this.activePoint = this.points.length - 1;
    },

    removeActivePoint(e) {
      const active = this.activePoint;
      if (this.points.length > 1 && active !== 0 && active !== this.points.length - 1) {
        this.points.splice(active, 1);
        this.activePoint = this.points.length - 1;
      }
    },

    handleMouseMove(e) {
      if (!this.interactive) {
        return;
      }
      this.$emit('getSelectType', this.getPointType(this.points[this.activePoint]).toUpperCase());
      if (this.draggedPoint) {
        const xy = this.getMouseCoords(e);
        if (this.activePoint === 0) {
          xy.x = this.setting.grid.size;
        } else if (this.activePoint === 1) {
          xy.x = this.setting.grid.size + (this.setting.grid.size * 2);
        } else if (this.activePoint === this.points.length - 1) {
          xy.x = this.setting.w;
        } else if (this.activePoint === this.points.length - 2) {
          xy.x = this.setting.w - (this.setting.grid.size * 2);
        }
        this.setPointCoords(xy);
      } else if (this.draggedQuadratic) {
        this.setQuadraticCoords(this.getMouseCoords(e));
      } else if (this.draggedCubic !== false) {
        this.setCubicCoords(this.getMouseCoords(e), this.draggedCubic);
      }
    },
    reset(e) {
      this.points = [{ x: this.setting.w / 2, y: this.setting.h / 2 }];
      this.activePoint = 0;
    },
  },
};
</script>

<style scoped>
.axis {
  fill: none;
  stroke: rgb(39, 39, 39);
  stroke-width: 5px;
}

.ad-SVG {
    display: block;
    background: #fff;
    border-radius: 4px;
    border: 1px solid grey;
}
    .ad-Grid {
        fill: none;
        stroke: #eee;
        stroke-width: 1px;
    }
    .ad-Grid.is-hidden {
        opacity: 0;
    }
    .ad-Path {
        fill: none;
        stroke: #555;
        stroke-width: 4px;
        stroke-linecap: round;
    }
    .ad-Point {
        cursor: pointer;
        fill: #fff;
        stroke: #555;
        stroke-width: 5px;
        transition: fill .2s;
    }
    .ad-Point:hover,
    .ad-PointGroup.is-active .ad-Point {
        fill:#00E676;
    }
    .ad-PointGroup.first .ad-Point {
        stroke: #00E676;
    }
    .ad-Anchor {
        opacity: .5;
    }
    .ad-PointGroup.is-active .ad-Anchor {
        opacity: 1;
    }
        .ad-Anchor-point {
            cursor: pointer;
            fill: #fff;
            stroke: #888;
            stroke-width: 5px;
        }
        .ad-Anchor-line {
            stroke: #888;
            stroke-width: 1px;
            stroke-dasharray: 5 5;
        }

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
