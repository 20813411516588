<template>
  <q-page-container>
    <q-page class="fullpagebg">
      <div class="content">
        <!-- <transition
        enter-active-class="animated bounceInLeft"
        leave-active-class="animated bounceOutRight"
        appear
        >-->
        <div v-if="welcome" class="title" type="positive">{{$i18n.t('Welcome to \{\{productName\}\}', { productName })}}</div>
        <div v-if="maintenance && maintenance.type" class="maintenance-banner bg-warning text-black">
          <div class="maintenance-icon">
            <q-icon :name="maintenance.before ? maintenance.type : 'dangerous'" color="negative" />
          </div>
          <div class="maintenance-message" v-html="maintenance.message[maintenance.before ? 0 : 1]"></div>
          <div v-if="maintenance.before">
            <flip-countdown v-if="maintenance.when" :showDays="false" :deadline="maintenance.when"></flip-countdown>
          </div>
        </div>
        <div class="copyright" v-t>© Syrus, 2018-2024, All right reserved.</div>
        <!-- </transition> -->
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown';

export default {
  components: { FlipCountdown },
  data() {
    return {
      productName: '',
      maintenance: {},
      interval: undefined,
      welcome: true,
    };
  },

  mounted() {
    this.productName = window.productName;
    this.$store.commit('header/setHeader', {
      title: 'Home',
      subtitle: '',
    });
    setTimeout(() => {
      this.welcome = false;
    }, 5000);
    const foo = () => {
      this.$axios({ baseURL: '/', url: '/maintenance.json', method: 'GET' }).then((response) => {
        this.maintenance = response.data;
        this.maintenance.before = (this.maintenance.type !== 'warning') && this.maintenance.when ? (new Date(this.maintenance.when)) > (new Date()) : false;
      }).catch(() => {});
    };
    foo();
    this.interval = setInterval(foo, 10000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  },
};
</script>

<style lang="stylus" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .copyright {
    opacity: 0.5;
    position: absolute;
    bottom: 50px;
    left: 50px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    opacity: 0.5;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    opacity: 0.5;
  }
}
.maintenance-banner {
  min-width: 600px;
  display: flex;
  align-items: center;
  .maintenance-icon {
    font-size 4rem
  }
  .maintenance-message {
    flex: 1;
  }
  >>> .flip-card {
    font-size: 2rem;
  }
}
</style>
