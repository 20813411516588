<template>
  <!-- TODO put all steps in component or make it routable -->
  <q-stepper
    flat
    class="stepper"
    ref="stepper"
    contracted
    v-model="localstep"
    header-class="importsteps"
  >
    <!-- <Dialog :model="addTemplateModel" /> -->
    <!-- Step 1 -->
    <q-step default title="Step 1" prefix="1" name="step-1" class="step">
      <div class="content">
        <Step1 @upload-file="parseFile"></Step1>
      </div>

      <q-stepper-navigation class="nav" v-if="file.parsed !== null">
        <div></div>
        <q-btn @click="goNextStep()" label="Next" color="primary" />
      </q-stepper-navigation>
    </q-step>

    <!-- Step 2 -->
    <q-step title="Step 2" prefix="2" name="step-2" class="step">
      <div class="content">
        <step2></step2>
      </div>
      <q-stepper-navigation class="nav">
        <div>
          <q-btn flat @click="goPrevStep()" v-if="backPossible()" label="Back" />
        </div>
        <div>
          <q-btn @click="goNextStep()" label="Next" color="primary" />
        </div>
      </q-stepper-navigation>
    </q-step>

    <!-- Step 3 -->
    <q-step title="Step 3" prefix="3" name="step-3" class="step">
      <div class="content">
        <step3></step3>
      </div>
      <q-stepper-navigation class="nav">
        <div>
          <q-btn flat @click="goPrevStep()" v-if="backPossible()" label="Back" />
        </div>
        <div class="doubleBtnEnd">
          <q-btn
            :disabled="!allAttributesPlaced()"
            class="btnsp"
            @click="uploadFile()"
            label="Analyze"
            color="primary"
          />
        </div>
      </q-stepper-navigation>
    </q-step>

    <!-- Step 4 -->
    <q-step title="Step 4" prefix="4" name="step-4" class="step">
      <div class="content">
        <p>Votre fichier est en cours d'analyse. Merci de patienter.</p>
        <p>Rien ne sera importé pour à cette étape.</p>
      </div>
      <q-stepper-navigation class="nav">
        <div></div>
        <div>
          <q-btn @click="uploadFile()" label="Actual Import" color="primary" />
        </div>
      </q-stepper-navigation>
    </q-step>

    <!-- Step 5 -->
    <q-step title="Step 5" prefix="5" name="step-5" class="step">
      <div class="content">
        <p>L'import a démarré il n'est plus possible de l'interrompre à ce stade</p>
        <p>Vous pouvez observer la progression ou continuer à travailler et revenir plus tard.</p>
      </div>
    </q-step>
  </q-stepper>
</template>

<script>
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

export default {
  components: {
    Step1,
    Step2,
    Step3,
  },
  data() {
    return {
      localstep: 'step-1',
      impExp: this.$store.state.importExport,
      addTemplateModel: false,
      newTemplateName: '',
      displayNewTemplate: false,
    };
  },
  computed: {
    draggableRow: {
      get() {
        return this.$store.state.importExport.step3.draggableRow;
      },
    },
    file: {
      get() {
        return this.$store.state.importExport.step1.file;
      },
      set(data) {
        this.$store.commit('importExport/setFile', data);
      },
    },
    currentStep: {
      get() {
        return this.$store.state.importExport.currentStep;
      },
      set(currentStep) {
        if (currentStep === 1) {
          this.$store.commit('header/setHeader', {
            title: 'Import CSV',
            subtitle: 'Upload',
          });
        } else if (currentStep === 2) {
          this.$store.commit('header/setHeader', {
            title: 'Import CSV',
            subtitle: 'Formating',
          });
        } else if (currentStep === 3) {
          this.$store.commit('header/setHeader', {
            title: 'Import CSV',
            subtitle: 'Mapping',
          });
        }
        this.$store.commit('importExport/setCurrentStep', currentStep);
      },
    },
    template: {
      get() {
        return this.$store.state.importExport.template;
      },
      set(value) {
        this.$store.commit('importExport/setTemplate', value);
      },
    },
  },
  watch: {
    currentStep(val) {
      this.$refs.stepper.goTo(`step-${this.$store.state.importExport.currentStep}`);
    },
  },
  methods: {
    asTemplateSelected() {},
    selected() {
      // TODO: Handle selected
    },
    backPossible() {
      // return this.$route.params.id ? this.currentStep > 4 : true;
      return this.currentStep < 4;
    },
    allAttributesPlaced() {
      let containName = false;
      let index = 0;
      for (let i = 0; this.draggableRow[i]; i += 1) {
        if (this.draggableRow[i] && this.draggableRow[i][0]) {
          index += 1;
          if (this.draggableRow[i][0].name === 'name') {
            containName = true;
          }
        }
      }
      return index >= 2 && containName;
    },
    goNextStep() {
      this.currentStep += 1;
    },
    goPrevStep() {
      this.currentStep -= 1;
    },
    parseFile(file) {
      this.$store.dispatch('importExport/parseFile', file).then(() => {
        this.goNextStep();
      }).catch((err) => {
        this.$q.notify({
          message: 'Import error',
          icon: 'warning',
        });
      });
    },
    isExpanded(elem) {
      return elem && elem && elem.expanded;
    },
    isFourColumn(attribute) {
      const twoColAttribute = ['pricing', 'interval'];
      return attribute && attribute && twoColAttribute.includes(attribute.type);
    },
    getRealIndex(index) {
      let corrector = 0;
      for (let i = 0; i < index; i += 1) {
        if (this.isExpanded(this.impExp.step3.draggableRow[i][0])) {
          if (this.isFourColumn(this.impExp.step3.draggableRow[i][0])) {
            corrector += 2;
          } else {
            corrector += 1;
          }
        }
      }
      return index + corrector;
    },
    getColumnsFromAttribute(attribute, index) {
      if (this.isExpanded(attribute)) {
        const realIndex = this.getRealIndex(index);
        if (this.isFourColumn(attribute)) {
          return [realIndex, realIndex + 1, realIndex + 2, realIndex + 3];
        }
        return [realIndex, realIndex + 1];
      } if (this.isFourColumn(attribute)) {
        const realIndex = this.getRealIndex(index);
        return [realIndex, realIndex + 1];
      }
      return [this.getRealIndex(index)];
    },
    getMapping() {
      return this.impExp.step3.draggableRow.reduce(
        (accumulator, current, index) => {
          if (
            current[0]
            && current[0].type !== 'interval-to'
            && current[0].type !== 'price-unit'
          ) {
            const name = current[0].importedtag ? `${current[0].name}@${current[0].importedtag}` : current[0].name;
            accumulator[name] = this.getColumnsFromAttribute(current[0], index);
          }
          return accumulator;
        },
        {},
      );
    },
    getFormat() {
      return {
        encoding: this.impExp.step2.form.encoding.value,
        footerLines: this.impExp.step2.form.footerLines.value,
        headingLines: this.impExp.step2.form.headingLines.value,
        separator: this.impExp.step2.form.separator.value,
        locale: this.impExp.step3.locales.value,
        defaultCurrency: this.impExp.step3.currencies.value,
      };
    },
    getOptions() {
      return {
        noupdate: this.impExp.step2.form.checkbox.value.includes('noupdate'),
        noinsert: this.impExp.step2.form.checkbox.value.includes('noinsert'),
      };
    },

    uploadFile() {
      let promise;
      if (this.$route.name === 'preupload') {
        promise = Promise.resolve({ data: { data: { id: this.$store.state.importExport.fileId } } });
      } else {
        let contenType = this.impExp.step1.file.uploaded.type;
        if (contenType === '') {
          if (
            this.impExp.step1.file.uploaded.name.toLowerCase().endsWith('.csv')
          ) {
            contenType = 'text/csv';
          } else {
            this.$q.notify({
              message: 'File format not supported',
              icon: 'warning',
            });
            return;
          }
        }
        promise = this.$axios
          .post('/store', this.impExp.step1.file.uploaded, {
            headers: {
              'Content-Type': contenType,
              'X-filename': encodeURIComponent(this.impExp.step1.file.uploaded.name),
            },
          });
      }
      promise
        .then((response) => {
          if (response.data && response.data.data) {
            let req;
            if (this.$route.name === 'preupload') {
              req = this.$axios.put(`/imports/${this.$route.params.id}`, {});
            } else {
              req = this.$axios.post('/imports', {
                mapping: this.getMapping(),
                format: this.getFormat(),
                options: this.getOptions(),
                file: response.data.data.id,
              });
            }
            req
              .then((response2) => {
                this.$axios
                  .get(`/imports/${response2.data.data.id}/lines`)
                  .then((response3) => {
                    this.errorlines = response3.data.data;
                    const nextstep = this.$route.name === 'preupload' ? 'upload' : 'preupload';
                    this.$router.push(`/importexport/${nextstep}/${response2.data.data.id}`);
                  });
              })
              .catch((err) => {
                this.$q.notify({
                  message: 'CSV upload error',
                  icon: 'warning',
                });
              });
          }
        });
    },
  },
  mounted() {
    this.$refs.stepper.goTo(`step-${this.currentStep}`);
    this.$axios.get('/importstemplates').then((r) => {
      this.$store.commit('importExport/setImportsTemplates', r.data);
    });
  },
};
</script>

<style lang="stylus">
.btnsp {
  margin-left: 3px;
}

.nobtn {
  height: 36px;
}

.doubleBtnEnd {
  display: flex;
  justify-content: flex-end;
}

.stepper {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);

  .q-stepper-header {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .q-stepper-step-inner {
    padding: 0 !important;
    margin: 0;
  }

  .q-stepper-nav {
    margin: 0 !important;
    padding: 0;
  }

  .content {
    height: calc(100vh - 171px);
    overflow: auto;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    padding: 5px;
    bottom: 1rem;
    right: 0;
  }
}
</style>
