<template>
  <div :id="`finder${id}`" class="row full-height items-strech finder-container">
    <div v-for="(column, index) in columns" :key="index" class="full-height finder-column">
      <q-list no-border>
        <div :class="{highlight: isselected(item, index)}"
          v-for="(item, index2) in column" :key="index2" >
          <slot name="item" :item="item" :select="select(item, index)"></slot>
        </div>
      </q-list>
      <slot :parent="parent(index)" name="new"></slot>
    </div>
  </div>
</template>
<script>
let ids = 0;
export default {
  props: {
    fetch: {
      type: Function,
      default() {
        return () => [];
      },
    },
  },
  watch: {
    /* $route(to, from) {
      // console.log('ROUTE CHANGE');
      if (from.path.includes('drawer') && !to.path.includes('drawer')) {
        this.columns = [];
        this.routeParams = this.$route.params['0'].split('/');
        this.initRouteParams().then((value) => {
          this.scrollToLastElem();
        });
      } else {
        const newParams = to.params['0'].split('/');
        this.actualiseRouteParams(newParams);
      }
    }, */
  },
  data() {
    return {
      id: 0,
      path: [],
      // id: ids++, // eslint-disable-line
      columns: [],
      selected: [],
    };
  },
  methods: {
    refresh(id) {
      const path = this.$route.params.path ? this.$route.params.path.split('/') : [];
      this.fetch(id, true).then((column) => {
        const index = id ? path.indexOf(id) + 1 : 0;
        this.columns.splice(index, 1, column);
      });
    },
    parent(index) {
      const path = this.$route.params.path ? this.$route.params.path.split('/') : [];
      return path[index - 1];
    },
    isselected(item, index) {
      return this.selected[index] && this.selected[index] === item.name;
    },
    select(item, index) {
      return (fetch = true) => {
        this.selected[index] = item.name; // FIXME => this assume name must exist and be unique
        if (fetch) {
          this.columns.splice(index + 1);
          this.path.splice(index);
          this.fetch(item.id).then((column) => {
            this.columns.push(column);
            this.path.push(item.id);
            this.$router.push(`/settings/tags/${this.path.join('/')}`);
          });
        }
      };
    },
    /* deepclone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    actualiseRouteParams(newParams) {
      let noChildren = false;
      // console.log('ACTUALISE ROUTE', newParams);
      for (let i = 0; i < newParams.length; i += 1) {
        if (newParams[i] === 'drawer') return;
        if (newParams[i] !== this.routeParams[i]) {
          if (newParams[i + 1] || newParams[i].startsWith('01')) {
            this.routeParams[i] = newParams[i];
            this.addColumn(this.routeParams[i], i + 1).then(() => {
              this.scrollToLastElem();
            });
          } else {
            noChildren = true;
          }
        }
      }
      const i = noChildren ? newParams.length : newParams.length + 1;
      this.columns = this.columns.slice(0, i);
      this.routeParams = this.routeParams.slice(0, i - 1);
    },
    initRouteParams() {
      // console.log('INIT ROUTE');
      const that = this;
      let bool = true;
      const filtredRoutes = this.routeParams.filter((value) => {
        if (value === 'drawer') bool = false;
        return bool;
      });
      if (
        filtredRoutes[filtredRoutes.length - 1] &&
        filtredRoutes[filtredRoutes.length - 1].startsWith('02')
      ) {
        filtredRoutes.splice(-1, 1);
      }
      return this.$utils.serial([
        () => that.addColumn('', 0),
        ...filtredRoutes.map((param, i) => () => {
          if (filtredRoutes[i]) return that.addColumn(filtredRoutes[i], i + 1);
          return Promise.resolve();
        }),
      ]);
    },
    addColumn(itemId, columnIndex) {
      // console.log('ADD COLUMN', columnIndex);
      return this.fetch(itemId.substring(2)).then((response) => {
        response.map((elem) => {
          elem.parent = columnIndex > 0;
          elem.column = columnIndex;
          return elem;
        });
        this.$set(this.columns, columnIndex, response);
        this.columns = this.columns.slice(0, columnIndex + 1);
        Promise.resolve();
      });
    },
    scrollToLastElem() {
      this.$nextTick(() => {
        const el = document.getElementById(`finder${this.id}`);
        el.scrollTo({
          behavior: 'smooth',
          left: el.scrollWidth - el.offsetWidth,
        });
      });
    }, */
  },
  created() {
    this.id = ids++; // eslint-disable-line
    this.path = this.$route.params.path ? this.$route.params.path.split('/') : [];
    Promise.all([
      this.fetch(),
      ...this.path.map((p) => this.fetch(p)),
    ]).then((columns) => {
      this.columns = columns;
    });
  },
};
</script>
<style lang="stylus">

.finder-container {
  flex-wrap: nowrap;

  .finder-column {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    border-right: 1px solid $light;
    position: relative;
    div.q-list {
      position relative
      overflow auto
      height 100%
    }
    .finder-fab {
      position: absolute;
      bottom: 50px;
      right: 50px;
    }
  }
}
</style>
