<template>
  <div>
    <div class="q-pa-sm text-right">
      <q-btn-dropdown class="help-toto" :disable="!availabletagsets.length" icon="add" label="Tag Set">
        <q-list link>
          <q-item v-close-overlay v-for="tagset in availabletagsets" :key="tagset.name" @click.native="addtagset(tagset)">
            <q-item-main>
              <q-item-tile label>{{tagset.label[locale]}}</q-item-tile>
            </q-item-main>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
    <div class="q-pa-sm">
      <table id="decisiongrid">
        <thead>
          <tr>
            <td></td>
            <td>#</td>
            <td v-for="(tagset, index) in usedtagsets" :key="index" class="header">
              <div class="title">
                <div class="icon" @click="deleteTagset(index)">
                  <q-icon name="close"/>
                </div>
                {{tagset.label[locale]}}
                <div class="icon" @click="changeRow('left', index)">
                  <q-icon name="keyboard_arrow_left"/>
                </div>
                <div class="icon" @click="changeRow('right', index)">
                  <q-icon name="keyboard_arrow_right"/>
                </div>
              </div>
            </td>
            <td>
              Decision
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, lineIndex) in grid" :key="lineIndex">
            <td>
              <q-btn icon="close" color="negative" @click="removeLine(lineIndex)"/>
            </td>
            <td>{{lineIndex + 1}}</td>
            <td v-for="(cell, cellIndex) in grid[lineIndex].prerequisites" :key="cellIndex">
              <div class="cell">
                <q-field class="operatorSelect">
                  <q-select
                    float-label="Operator"
                    v-model="grid[lineIndex].prerequisites[cellIndex].feel.op"
                    :options="getOperator(cell.tagset)"
                    @input="cleanarg(cell)"
                  />
                </q-field>
                <q-field class="tagSelect">
                  <q-select
                    v-if="cell.feel.op !== 'ANY'"
                    multiple
                    float-label="Tags"
                    chip
                    :name="'select' + lineIndex + '-' + cellIndex"
                    v-model="grid[lineIndex].prerequisites[cellIndex].feel.args"
                    :options="getTags(cell.tagset)"
                  />
                </q-field>
              </div>
            </td>
            <td>
              <slot name="resultContent" :lineIndex="lineIndex"></slot>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"><q-btn icon="add" label="Add Rule" color="primary" @click="addLine()"/></td>
            <td v-for="(missing, index) in getMissing()" :key="index">
                <q-chip icon="cancel" color="negative" v-for="tagset in missing" :key="tagset.name">
                  {{tagset.label[locale]}}</q-chip>
            </td>
          <!--
                    <td v-for="(missings, missingsIndex) in getMissingTag()" :key="missingsIndex">
                    <div v-if="missings.length">
                      <span class="error">Missing</span>
                      <span
                        class="error"
                        v-for="(missing, missingIndex) in missings"
                        :key="missingIndex"
                      >{{` ${missing.label}${missings[missingIndex + 1] ? ',': ''}`}}</span>
                    </div>
                  </td>
            -->
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    grid: Array,
    tagsets: Array,
  },
  data() {
    return {
      availabletagsets: [],
      usedtagsets: [],
      locale: this.$store.state.auth.locale,
      missing: null,
    };
  },
  mounted() {
    if (!this.grid.length) {
      this.usedtagsets = [];
      this.availabletagsets = [].concat(this.tagsets);
    } else {
      this.usedtagsets = this.grid[0].prerequisites.map((p) => this.tagsets.find(((t) => (t.name === p.tagset))));
      this.availabletagsets = this.tagsets.filter((t) => !this.usedtagsets.includes(t));
    }
  },
  methods: {
    cleanarg(cell) {
      cell.feel.args = [];
    },
    addtagset(tagset) {
      this.availabletagsets.splice(this.availabletagsets.indexOf(tagset), 1);
      this.usedtagsets.push(tagset);
      this.grid.forEach((line) => {
        line.prerequisites.push({
          tagset: tagset.name,
          feel: {
            op: 'ANY',
            args: [],
          },
        });
      });
    },
    getMissing() {
      if (!this.grid.length) return [];
      const chosen = this.grid.reduce((_, line) => {
        if (!_.length) {
          return line.prerequisites.map((cell) => [].concat(cell.feel.args));
        }
        line.prerequisites.forEach((cell, i) => {
          Array.prototype.push.apply(_[i], [].concat(cell.feel.args));
        });
        return _;
      }, []);
      const missing = this.usedtagsets.map((tagset, i) => tagset.tags.filter((t) => !chosen[i].includes(t.name)));
      return missing;
    },
    swap(arr, i, i2) {
      if (arr[i] && arr[i2]) {
        const temp = arr[i];
        arr.splice(i, 1, arr[i2]);
        arr.splice(i2, 1, temp);
      }
    },
    deleteTagset(index) {
      this.usedtagsets.splice(index, 1);
      this.grid.forEach((line) => {
        line.prerequisites.splice(index, 1);
      });
    },
    changeRow(direction, index) {
      const index2 = index + (direction === 'left' ? -1 : 1);
      this.swap(this.usedtagsets, index, index2);
      this.grid.forEach((line) => {
        this.swap(line.prerequisites, index, index2);
      });
    },
    getOperator(name) {
      const result = [
        {
          label: 'ANY',
          value: 'ANY',
        },
      ];
      const tagset = this.tagsets.find((t) => (t.name === name));
      if (tagset && tagset.tags.length) {
        result.push({
          label: 'SELECT',
          value: 'SELECT',
        });
      }
      return result;
    },

    getTags(name) {
      const tagset = this.tagsets.find((t) => (t.name === name));
      if (!(tagset && tagset.tags.length)) return [];
      return tagset.tags.map((t) => ({ value: t.name, label: t.label[this.locale] }));
    },
    pagestyle(offset) {
      return {
        height: offset ? `calc(100vh - ${offset}px)` : '100vh',
        overflow: 'auto',
      };
    },
    removeLine(index) {
      this.grid.splice(index, 1);
    },
    addLine() {
      this.grid.push({
        prerequisites: this.usedtagsets.map((t) => ({ tagset: t.name, feel: { op: 'ANY', args: [] } })),
      });
    },
  },
};
</script>
<style scoped lang="stylus">

#decisiongrid {
  width: 100%;
  thead {
    tr {
      td:first-child {
        width: 40px;
      }
    }
  }
}
.topTitle {
  display: block;
  padding: 30px;
  font-size: 2rem;
  opacity: 0.5;
}

.content {
  width: 100%;
}

.error-content {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header {
  min-width: 400px;

  .title {
    display: flex;
  }
}

.icon {
  cursor: pointer;
}

.cell {
  display: flex;

  .operatorSelect {
    width: 100px;
  }

  .tagSelect {
    min-width: 300px;
    width: 100%;
  }
}
</style>
