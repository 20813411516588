<template>
  <div v-if="!editable" class="ellipsis" :class="[align]">
    <span v-if="data && data.v" :title="localized(data.v)" >{{localized(data.v)}}</span>
  </div>
  <div v-else class="localizededition">
      <q-input v-if="data && data.v" v-bind="getallprops()" filled square dense :value="rawvalue[locale.value]" @input="propagate">
        <q-select slot="after" v-model="locale" :options="locales" :filled="false" dense />
      </q-input>
  </div>
</template>
<script>
import { localization } from '../../lib/mixins';

const empty = { 'en-US': '' };
const availablelocales = [
  { value: 'en-US', label: 'EN' },
  { value: 'fr-FR', label: 'FR' },
  { value: 'it-IT', label: 'IT' },
  { value: 'de-DE', label: 'DE' },
];
const allprops = ['rules', 'label', 'name', 'disable'];
export default {
  mixins: [localization],
  props: ['data', 'space', 'editable', 'def', 'align', ...allprops],
  components: {},
  data() {
    return {
      rawvalue: JSON.parse(JSON.stringify({ ...empty, ...(this.data || {}).v })),
      locale: availablelocales.find((l) => (l.value === (this.nonemptylocale((this.data || {}).v) || 'en-US'))),
      locales: availablelocales,
    };
  },
  watch: {
    data() {
      this.rawvalue = JSON.parse(JSON.stringify({ ...empty, ...(this.data || {}).v }));
      if (!this.rawvalue[this.locale?.value]) {
        this.locale = availablelocales.find((l) => (l.value === (this.nonemptylocale((this.data || {}).v) || 'en-US')));
      }
    },
  },
  methods: {
    getallprops() {
      const props = allprops.reduce((_, p) => {
        if (!this[p]) return _;
        if (p === 'rules') {
          _[p] = this.rules(this.rawvalue);
        } else {
          _[p] = this[p];
        }
        return _;
      }, {});
      return props;
    },
    propagate(value) {
      this.rawvalue[this.locale.value] = value;
      this.$emit('change', { [this.def.name]: this.rawvalue });
    },
  },
};
</script>
<style lang="stylus" scoped>
.ellipsis {
  overflow hidden
  text-overflow ellipsis
  .center {
    text-align center;
  }
}
.localizededition {
  width: 100%
  padding: 5px 0;
  >>> .q-field {
    padding: 0
  }
}
</style>
