<template>
  <div class="fixed-center text-center">
    <p>
      <img
        src="~assets/sad.svg"
        style="width:30vw;max-width:150px;"
      >
    </p>
    <p class="text-faded">Désolé, mais la plateforme ne semble pas opérationnelle</p>
    <q-btn color=primary label="retry" @click="document.location = '/'"></q-btn>
  </div>
</template>

<script>
export default {
  name: 'FatalError',
  data() {
    return {
      document,
    };
  },
};
</script>
