<template>
  <div class="chartContainer" :class="{ graphmode: ($store.state.pricing.graphmode && !$store.state.pricing.single) }">
      <div id="hcharts" ref="hcharts"></div>
  </div>
</template>

<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import Highcharts from 'highcharts';
import boost from 'highcharts/modules/boost';
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import { store } from '../../lib/nrstore';

const { utils } = pricingUtilities;
const { getattrValue, getAttrSet } = utils;

boost(Highcharts);
const defaultchartdata = JSON.stringify({
  chart: {
    zoomType: 'x',
    inverted: false,
    reflow: true,
  },
  boost: {
    useGPUTranslations: true,
  },
  title: {
    text: '',
  },
  subTitle: {
    text: '',
  },
  scrollbar: {
    enabled: true,
  },
  tooltip: {},
  xAxis: {},
  yAxis: {},
  plotOptions: {},
  series: [],
});

export default {
  mixins: [localization],
  data() {
    return {
      chart: null,
      locale: this.$store.state.auth.locale,
      sorted: {},
      ids: [],
      chartdata: JSON.parse(defaultchartdata),
      weighted: false,
    };
  },
  computed: {},
  watch: {
    opened() {
      return this.$route.path.split('/').length > 3;
    },
  },
  mounted() {
    this.$store.state.pricing.partsFilter = { groupable: {}, mode: 'disabled' }; // same different disabled
    this.$root.$on('updatechart', (val) => this.updatechart(val));
  },
  beforeDestroy() {
    this.$store.commit('pricing/clearPricing');
    this.$store.commit('pricing/graphmode', false);
    this.destroychart();
  },
  methods: {
    destroychart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
    updatechart(update = false) {
      if (!update) this.destroychart();
      if (!this.$store.state.pricing.graphmode) return;
      if (store.parts.length < 1) {
        return;
      }

      console.log('graphtype: ', this.$store.state.pricing.graphtype);
      console.log('sorted by: ', this.$store.state.pricing.graphsort);

      if (this.$store.state.pricing.graphtype === 'price+sales24m') {
        this.drawPriceSales24m();
      } else if (this.$store.state.pricing.graphtype === 'xyplot') {
        this.drawXYplot();
      } else if (this.$store.state.pricing.graphtype === 'proposedprice') {
        this.drawProposedPrice();
      } else if (this.$store.state.pricing.graphtype === 'comparerules') {
        this.drawCompareRules();
      } else if (this.$store.state.pricing.graphtype === 'comparerulesxy') {
        this.drawCompareRulesXY();
      }

      if (!update) {
        if (this.$refs.hcharts) {
          this.$nextTick(() => {
            this.chart = Highcharts.chart(this.$refs.hcharts, this.chartdata);
          });
        }
      } else {
        this.chart.update(this.chartdata);
      }
      // this.chart.redraw();
    },
    /* Draw functions */
    drawPriceSales24m() {
      const label = this.localized(this.$store.state.pricing.defs.defs2[this.$store.state.pricing.graphsort].label) || this.$store.state.pricing.graphsort || 'Volume';
      // label = label.charAt(0).toUpperCase() + label.slice(1);

      const { priceattr, salesattr } = this.$store.state.pricing.rule.definition.common;
      const chartdata = JSON.parse(defaultchartdata);
      // console.log('AttrV: ', this.getattrValueSimple(store.parts[0], this.$store.state.pricing.graphsort));

      if (store.parts.length < 1) return;
      const truncated = store.parts.map((part) => ({
        x: this.round(this.getattrValueSimple(part, this.$store.state.pricing.graphsort)) || part.name.v,
        s: this.getattrValueSimple(part, this.$store.state.pricing.graphsort),
        y: [
          this.getattrValueSimple(part, 'proposedprice') - this.getattrValueSimple(part, 'minimalprice'),
          this.getattrValueSimple(part, 'minimalprice'),
          this.getattrValueSimple(part, priceattr),
          this.getattrValueSimple(part, salesattr),
        ],
        c: this.getattrValueSimple(part, 'purchasealert') ? '#DA4824' : '#777777',
      })).sort((a, b) => (a.s - b.s)).reduce((_, part) => {
        _.x.push(part.x);
        _.c.push(part.c);
          for (let i = 0; i < 4; i++) _.y[i].push(part.y[i]); // eslint-disable-line
        return _;
      }, { x: [], y: [[], [], [], []], c: [] });
      chartdata.chart = {
        zoomType: 'x',
        inverted: false,
        reflow: true,
      };
      chartdata.tooltip = {
        formatter() {
          let labelValue = '';
          let proposedPrice = '';
          let minimumPrice = '';
          let validatedPrice = '';
          if (this.points[0] && this.points[1]) {
            proposedPrice = `<br>Prix proposé : <b>${Math.round(this.points[0].y + this.points[1].y)}</b>`;
          }
          if (this.points[1]) {
            minimumPrice = `<br>Prix minimum : <b>${Math.round(this.points[1].y)}</b>`;
          }
          if (this.points[2]) {
            validatedPrice = `<br>Prix validé : <b>${Math.round(this.points[2].y)}</b>`;
          }
          if (this.points[3]) {
            labelValue = `<br>${label}: <b>${Math.round(this.points[3].y)}</b>`;
          }
          return `<b>${this.x}</b>${proposedPrice}${minimumPrice}${validatedPrice}${labelValue}`;
        },
        shared: true,
      };

      chartdata.xAxis = {
        min: 0,
        title: { text: 'Pièces' },
        categories: truncated.x,
      };

      chartdata.yAxis = [
        {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: 'Prix',
          },
          height: '80%',
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: label,
          },
          top: '85%',
          height: '15%',
          offset: 0,
          lineWidth: 2,
        },
      ];

      chartdata.plotOptions = {
        series: {
          stacking: 'normal',
        },
      };

      chartdata.series = [
        {
          boostThreshold: 1000,
          type: 'column',
          name: 'Prix proposé',
          color: '#639CD8',
        },
        {
          boostThreshold: 1000,
          type: 'column',
          name: 'Prix minimum',
          colorByPoint: true,
          colors: truncated.c,
        },
        {
          boostThreshold: 1000,
          type: 'line',
          name: 'Prix de vente',
          color: '#90E310',
        },
        {
          boostThreshold: 1000,
          type: 'column',
          name: label,
          yAxis: 1,
          color: '#E6BC2D',
        },
      ];
      for (let i = 0; i < 4; i++) chartdata.series[i].data = truncated.y[i]; // eslint-disable-line
      this.chartdata = chartdata;
    },

    drawXYplot() {
      const { priceattr, salesattr } = this.$store.state.pricing.rule.definition.common;
      const chartdata = JSON.parse(defaultchartdata);

      chartdata.chart = {
        type: 'scatter',
        zoomType: 'xy',
      };
      const titlex = this.localized(this.$store.state.pricing.defs.defs2[this.$store.state.pricing.xaxis].label);
      chartdata.xAxis = {
        title: {
          enabled: true,
          text: titlex,
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      };
      chartdata.yAxis = {
        title: {
          text: 'Prix',
        },
      };
      const truncated = store.parts.reduce((_, part) => {
        const x = this.getattrValueSimple(part, this.$store.state.pricing.xaxis);
        if (x === undefined) return _;
        _[0].data.push({ x, y: this.getattrValueSimple(part, 'proposedprice'), ref: part.name.v });
        _[1].data.push({ x, y: this.getattrValueSimple(part, priceattr), ref: part.name.v });
        return _;
      }, [{
        name: 'Prix Proposé',
        data: [],
      }, {
        name: 'Prix Validé',
        data: [],
      }]);
      chartdata.tooltip = {
        formatter() {
          return `<b>${this.point.options.ref}</b><br>
              ${titlex}: ${this.x}<br>
              ${this.series.name} :${this.y}`;
        },
      };
      if (truncated[0].data.length < 1) return;
      chartdata.series = truncated;
      this.chartdata = chartdata;
    },

    drawProposedPrice() {
      const { priceattr, salesattr } = this.$store.state.pricing.rule.definition.common;
      const chartdata = JSON.parse(defaultchartdata);

      const truncated = store.parts.reduce((_, part) => {
        const x = part.name.v;
        const s = this.getattrValueSimple(part, this.$store.state.pricing.graphsort);
        if (s === undefined) return _;
        _.push({
          x,
          s,
          y1: this.getattrValueSimple(part, 'proposedprice'),
          y2: {
            y: this.getattrValueSimple(part, priceattr),
            z: this.getattrValueSimple(part, salesattr),
          },
        });
        return _;
        }, []) //eslint-disable-line
        .sort((a, b) => ((a.s - b.s)));
        // Filter and sort
      if (truncated.length < 1) return;

      chartdata.tooltip = {
        formatter: (a) => {
          const point = a.chart.hoverPoint;
          if (point && point.index) {
            const part = truncated[point.index];
            return `
                  <b>${part.x}</b></br>
                  Prix proposé : <b>${this.round(part.y1)}</b></br>
                  Prix de vente: <b>${this.round(part.y2.y)}</b></br>
                  Volume: <b>${this.round(part.y2.z)}</b>`;
          }
          return `Prix proposé: <b>${this.round(point.y)}</b></br>Pour: <b>${point.x}</b> ${truncated[0][this.$store.state.pricing.xaxis].v.unit}`;
        },
        shared: true,
      };

      chartdata.xAxis = {
        min: 0,
        title: {
          text: 'Références',
        },
        categories: truncated.map((a) => a.x),
      };

      chartdata.yAxis = {
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'Prix',
        },
        height: '100%',
        lineWidth: 2,
        resize: {
          enabled: true,
        },
      };

      chartdata.series = [
        {
          boostThreshold: 1000,
          type: 'line',
          name: 'Prix proposé',
          // color: '#4572A7'
          data: truncated.map((a) => a.y1),
        },
        {
          boostThreshold: 1000,
          type: 'bubble',
          name: 'Volume x Prix validé',
          // color: '#DB843D',
          data: truncated.map((a) => a.y2),
        },
      ];

      this.chartdata = chartdata;
    },

    drawCompareRules() {
      const { priceattr, salesattr } = this.$store.state.pricing.rule.definition.common;
      const chartdata = JSON.parse(defaultchartdata);

      // Sort only
      const truncated = store.parts.slice().sort((a, b) => (((this.getattrValueSimple(a, this.$store.state.pricing.graphsort) > this.getattrValueSimple(b, this.$store.state.pricing.graphsort))) ? 1 : -1));
      if (truncated.length < 1) return;

      chartdata.tooltip = {
        formatter() {
          if (this.point && this.point[0]) {
            const part = truncated[this.point[0]];
            return `<b>${part.name.v}</b></br> Prix de vente: <b>${this.round(this.y)}</b></br> Volume: <b>${this.round(this.getattrValueSimple(part, salesattr))}</b>`;
          }
          return `<b>${this.x}</b></br> Prix de vente: <b>${Math.round(this.y)}</b>`;
        },
        shared: true,
      };

      chartdata.xAxis = {
        min: 0,
        title: {
          text: 'Pièces',
        },
        categories: truncated.map((part) => part.name.v), // this exists for sure
      };

      chartdata.yAxis = [
        {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: 'Prix',
          },
          height: '80%',
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: 'Volume',
          },
          top: '85%',
          height: '15%',
          offset: 0,
          lineWidth: 2,
        },
      ];

      chartdata.series = [
        {
          boostThreshold: 1000,
          type: 'line',
          name: 'Prix proposé',
          dashStyle: 'dot',
          color: '#639CD8',
          data: truncated.map((part) => this.getattrValueSimple(part, 'proposedprice')),
        },
        {
          boostThreshold: 1000,
          type: 'area',
          name: 'Prix minimum',
          color: '#777777',
          data: truncated.map((part) => this.getattrValueSimple(part, 'minimalprice')),
        },
        {
          boostThreshold: 1000,
          type: 'column',
          name: 'Volume',
          yAxis: 1,
          color: '#E6BC2D',
          data: truncated.map((part) => this.getattrValueSimple(part, salesattr)),
        },
      ];

      this.$store.state.pricing.graphrules.map((rule) => {
        chartdata.series.push({
          boostThreshold: 1000,
          type: 'line',
          name: this.localized(rule.label),
          data: truncated.map((part) => rule.rawfunc(part, false).price),
        });
        return rule;
      });
      this.chartdata = chartdata;
    },

    drawCompareRulesXY() {
      const { priceattr, salesattr } = this.$store.state.pricing.rule.definition.common;
      const chartdata = JSON.parse(defaultchartdata);

      chartdata.chart = {
        type: 'scatter',
        zoomType: 'xy',
      };
      const titlex = this.localized(this.$store.state.pricing.defs.defs2[this.$store.state.pricing.xaxis].label);
      chartdata.xAxis = {
        title: {
          enabled: true,
          text: titlex,
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      };
      chartdata.yAxis = {
        title: {
          text: 'Prix',
        },
      };
      const truncated = store.parts.reduce((_, part) => {
        const x = this.getattrValueSimple(part, this.$store.state.pricing.xaxis);
        if (x === undefined) return _;
        _[0].data.push({ x, y: this.getattrValueSimple(part, 'proposedprice'), ref: part.name.v });
        _[1].data.push({ x, y: this.getattrValueSimple(part, priceattr), ref: part.name.v });
        this.$store.state.pricing.graphrules.forEach((rule, i) => {
          _[i + 2].data.push({
            x,
            y: rule.rawfunc(part, false).price,
            ref: part.name.v,
          });
        });
        return _;
      }, [
        {
          name: 'Prix Proposé (Règle en cours)',
          data: [],
        }, {
          name: 'Prix Validé',
          data: [],
        },
        ...this.$store.state.pricing.graphrules.map((r) => ({
          name: this.localized(r.label),
          data: [],
        })),
      ]);
      chartdata.tooltip = {
        formatter() {
          return `<b>${this.point.options.ref}</b><br>
              ${titlex}: ${this.x}<br>
              ${this.series.name} :${this.y}`;
        },
      };
      if (truncated[0].data.length < 1) return;
      chartdata.series = truncated;
      this.chartdata = chartdata;
    },

    /* utils */
    getattrValueSimple(part, attr) {
      const pset = getAttrSet(part, attr, this.priceset, this.$store.state.pricing.rule.definition.common.pmpattr, this.$store.state.general.settings);
      // if (!part[attr][pset] && part[attr].DEFAULT) pset = 'DEFAULT';
      return getattrValue(part, attr, pset);
    },
    sort(attr) {
      if (!this.sorted[attr.name]) {
        this.sorted = { [attr.name]: 'asc' };
      } else if (this.sorted[attr.name] === 'asc') {
        this.sorted[attr.name] = 'desc';
      } else {
        this.sorted[attr.name] = 'asc';
      }
      this.$store.commit('pricing/setProgress', 'Sorting...');
      this.$store.commit('pricing/sortParts', {
        attr,
        direction: this.sorted[attr.name],
        locale: this.$store.state.auth.locale,
        settings: this.$store.state.general.settings,
      });
      this.renderGrid();
    },
    round(v) {
      return Math.round(v * 100) / 100;
    },
  },
};
</script>
<style lang="stylus">
.chartContainer {
  display none
  height calc(100vh - 100px)
  &.graphmode {
    display block
  }
  #hcharts {
    height calc(100vh - 100px)
  }
}
</style>
