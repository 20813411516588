<template>
  <q-dialog  v-model="data.model">
    <q-layout class="lg-dialog-bug" container view="hHh lpR fFf">

      <q-header elevated class="bg-primary text-white">
        <q-toolbar >
          <q-toolbar-title>Bug Report</q-toolbar-title>
        </q-toolbar>
      </q-header>

      <q-page-container>
        <q-page class="bg-white">
          <div class="row">
            <div id="screenshot">
              <img :src="data.src" id="screenImg">
            </div>
          </div>
          <div class="row">
            <div class="col-8">
                <q-input :error="$v.form.title.model.$error" bottom-slots error-message="Required" v-model="form.title.model" type="text" label="Title"/>
            </div>
            <div class="col-4">
                <q-select :error="$v.form.criticity.model.$error" bottom-slots error-message="Required"
                  v-model="form.criticity.model"
                  :options="form.criticity.options"
                  label="Criticity"
                />
            </div>
          </div>
          <div>
            <div>
                <q-input :error="$v.form.description.model.$error" bottom-slots error-message="Required"
                  v-model="form.description.model"
                  type="textarea"
                  label="Description"
                  :max-height="100"
                  rows="4"
                />
            </div>
          </div>
        </q-page>
      </q-page-container>

      <q-footer elevated class="bg-grey-8 text-white">
          <q-btn label="cancel" @click="closeModal"></q-btn>
          <q-btn label="Send" color="secondary" @click="submit()"></q-btn>
      </q-footer>
    </q-layout>>
  </q-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['data'],
  components: {},
  data() {
    return {
      form: {
        title: {
          model: '',
        },
        criticity: {
          model: '',
          options: [
            {
              value: 'low',
              label: 'Low',
            },
            {
              value: 'moderate',
              label: 'Moderate',
            },
            {
              value: 'high',
              label: 'High',
            },
          ],
        },
        description: {
          model: '',
        },
      },
    };
  },
  validations: {
    form: {
      title: {
        model: { required },
      },
      criticity: {
        model: { required },
      },
      description: {
        model: { required },
      },
    },
  },
  computed: {},
  methods: {
    submit() {
      this.$v.form.$touch();
      const params = {
        title: this.form.title.model,
        criticity: this.form.criticity.model,
        desc: this.form.description.model,
        file: this.data.src,
      };
      // console.log(params);
      this.$axios.post('/report', params).then((response) => {
        // console.log(response);
      });
    },
    closeModal() {
      this.data.model = false;
    },
  },
  created() {},
};
</script>

<style scoped lang="stylus">
.lg-dialog-bug {
  height 600px
}
#screenshot {
  padding: 5px;
  position relative
  img {
    width: 100%;
  }
}
</style>
