<template>
  <div>
    <q-toolbar class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{part.name.v}}
        <span class="lg-header-subtitle">
          {{$t('Part cost plus computation')}}
        </span>
      </q-toolbar-title>
    </q-toolbar>
    <div class="drawer-container costpluspath">
      <cost-plus-path-renderer :part="part" :rule="$store.state.pricing.rule" :currenttime="$store.state.pricing.currenttime" :feexs="feexs" :defs="$store.state.pricing.defs.defs2"/>
    </div>
  </div>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import { rulemixins } from './mixins';
import { localization } from '../../lib/mixins';
import { store } from '../../lib/nrstore';
import CostPlusPathRenderer from '../../components/CostPlusPathRenderer.vue';

const { pricinglib } = pricingUtilities;
const { feexs } = pricinglib;

export default {
  components: { CostPlusPathRenderer },
  data() {
    return {
      options: null,
      value: null,
      feexs,
    };
  },
  mixins: [rulemixins, localization],
  computed: {
    part() {
      return store.parts.find((p) => (p.id.v === this.$route.params.id));
    },
    path() {
      const { rule, currenttime } = this.$store.state.pricing;
      const tmp = rule.definition.costPlusFunc(this.part, rule.tag, currenttime, rule.definition.costplus, undefined, 0, true);
      return tmp;
    },
  },
  methods: {
  },
};
</script>
<style lang="stylus" scoped>
p {
  padding-top 1rem
}
.drawer-container {
  padding: 1rem;
  .decision, .condition  {
    box-shadow: 2px 2px 4px 2px #ccc;
  }
  .condition {
    border: 1px solid #ccc;
    .operation {
      padding: .5rem;
    }
    .attribute {
      font-weight: bold;
      padding: .5rem;
      background: #f0f0f0;
    }
    .args {

    }
  }
  .decision {
    font-weight: bold;
    border-radius: 50%
    background: #f0f0f0;
    text-align: center;
    line-height: 1.8rem;
    width: 4rem;
    height: 4rem;
    margin: 1rem auto;
    background: lightgreen;
  }
  .downarrow {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;

  }
}
.lg-rule-actions {
  text-align: right;
  padding-right: 1rem;
}
</style>
