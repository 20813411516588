export default {
  token: 'invalid',
  user: null,
  subscheme: false,
  locale: 'en-US',
  sse: {
    evSource: null,
    observable: [],
  },
};
