<template>
  <div>
    <q-page-container>
      <q-page :style-fn="pagestyle">
        <finder :fetch="fetch" field="id" :noSort="true" @drop="onDrop">
          <q-item
            clickable
            :class="{highlight: isSelected(item.id)}"
            slot="item"
            slot-scope="{ item }"
            @click="details(item)"
          >
            <q-item-section avatar><q-icon :name="item.type === 'attributesets' ? 'folder' : 'local_offer'"/></q-item-section>
            <q-item-section><q-item-label>{{localized(item.label).normalize('NFD').replace(/[\u0300-\u036f]/g, '')}}</q-item-label></q-item-section>
          </q-item>
          <q-fab
            slot="new"
            color="secondary"
            slot-scope="{ parent }"
            :icon="'add'"
            class="finder-fab"
            direction="up">
            <q-fab-action
              color="secondary"
              @click="create('newattributesets', parent)"
              icon="folder"
            />
            <q-fab-action
              color="secondary"
              v-if="parent !== 0"
              @click="create('newattribute', parent)"
              icon="local_offer"
            />
          </q-fab>
        </finder>
      </q-page>
    </q-page-container>
    <q-drawer v-model="drawer" :content-class="['shadow-2']" side="right">
      <router-view/>
    </q-drawer>
  </div>
</template>
<script>
import Finder from '../../../components/Finder.vue';
import localization from '../../../lib/mixins'; // eslint-disable-line
function move(a, o, n) { if (n >= a.length) a.push(undefined); a.splice(n, 0, a.splice(o, 1)[0]); return a; }
export default {
  mixins: [localization],
  components: {
    Finder,
  },
  data() {
    return {
      locale: this.$store.state.auth.locale,
      params: [],
    };
  },
  watch: {},
  computed: {
    drawer() {
      const paths = this.$route.path.split('/');
      return paths.includes('drawer');
    },
  },
  methods: {
    onDrop({ column, removedIndex, addedIndex }) {
      const { id, type } = column[removedIndex];
      const params = {};
      let offset = 0;
      if (removedIndex === addedIndex) return;
      if (removedIndex < addedIndex) offset = 1;
      if ((addedIndex + offset) > 0) params.after = column[addedIndex - 1 + offset].position;
      if ((addedIndex + offset) < column.length) params.before = column[addedIndex + offset].position;
      this.$axios.patch(`/${type}/${id}/position`, params).then((response) => {
        column[removedIndex].position = response.data.data.position;
        move(column, removedIndex, addedIndex);
        this.$q.notify({ type: 'positive', message: 'Attribute sucessfully moved', icon: 'done' });
      }).catch(() => {
        this.$q.notify({ type: 'negative', message: 'Attribute cannot be moved', icon: 'done' });
      });
    },
    deepclone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    isSelected(itemId) {
      return this.$route.params.pathMatch
        .split('/')
        .find((id) => id.substring(2) === itemId);
    },
    details(item) {
      const columnIndex = item.parent ? item.column : 0;
      let routeParams = this.$route.params.pathMatch.split('/');

      routeParams[columnIndex] = `${item.type === 'attributes' ? '02' : '01'}${
        item.id
      }`;
      routeParams = routeParams.slice(0, columnIndex + 1);
      if (routeParams[routeParams.length - 1] !== 'drawer') {
        routeParams.push(item.type === 'attributes' ? 'drawer/details' : 'drawer/detailsset');
      }
      this.$router.push(`/settings/attributes/${routeParams.join('/')}`);
    },
    create(action, parent) {
      const routeParams = this.$route.params;
      routeParams.action = action;
      routeParams.column = parent;
      this.$router.push({ name: 'attributesDetails', params: routeParams });
    },
    pagestyle(offset) {
      return {
        height: offset ? `calc(100vh - ${offset}px)` : '100vh',
        overflow: 'auto',
      };
    },
    fetch(itemId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(itemId ? `/attributesets/${itemId}/children` : '/attributesets', { params: { nolimit: 'yes' } })
          .then((response) => {
            resolve(response.data.data);
          });
      });
    },
    detail() {},
    go(path) {
      this.$router.push(path);
    },
  },
  created() {
    this.$store.commit('header/setHeader', {
      title: 'Attributes',
      subtitle: 'Settings',
    });
  },
};
</script>
<style scoped lang="stylus">

.highlight {
  background: rgba(0, 0, 0, 0.1);
}
</style>
