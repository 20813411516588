<template>
  <!-- eslint-disable max-len -->
  <div class="content">
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="stylus">

.content {
  width: 100%;
  // margin-top 50px;
}
</style>
