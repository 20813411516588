import Vue from 'vue';
import Vuex from 'vuex';

import mainmenu from './mainmenu';
import pricing from './pricing';
import header from './header';
import auth from './auth';
import importExport from './importExport';
import catalog from './catalog';
import users from './users';
import tags from './tags';
import product from './product';
import general from './general';
import clipboard from './clipboard';

Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      mainmenu,
      pricing,
      header,
      auth,
      importExport,
      catalog,
      tags,
      users,
      product,
      general,
      clipboard,
    },
  });

  if (process.env.DEV && module.hot) {
    module.hot.accept(['./mainmenu'], () => {
      const newmod = require('./mainmenu').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { mainmenu: newmod } });
    });
    module.hot.accept(['./pricing'], () => {
      const newmod = require('./pricing').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { pricing: newmod } });
    });
    module.hot.accept(['./header'], () => {
      const newmod = require('./header').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./auth'], () => {
      const newmod = require('./auth').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./importExport'], () => {
      const newmod = require('./importExport').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./catalog'], () => {
      const newmod = require('./catalog').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./tags'], () => {
      const newmod = require('./tags').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./users'], () => {
      const newmod = require('./users').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
    module.hot.accept(['./product'], () => {
      const newmod = require('./product').default; // eslint-disable-line global-require
      Store.hotUpdate({ modules: { header: newmod } });
    });
  }

  return Store;
}
