<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>
        <span >{{data.data.name.v}}</span>
        <span class="lg-header-subtitle" v-if="part.label">{{localized(part.label.v)}}</span>
      </q-toolbar-title>
    </q-toolbar>

    <q-carousel
      height="200px"
      arrows
      quick-nav
      v-model="slide"
      v-if="!(part.pictures && part.pictures.v && part.pictures.v.length>0)">
      <q-carousel-slide :name="0" img-src="/default.png" v-if="!(part.pictures && part.pictures.v && part.pictures.v.length)" />
    </q-carousel>

    <q-carousel
      height="200px"
      arrows
      quick-nav
      v-else
      v-model="slide">
      <q-carousel-slide
        v-for="(picture, index) in part.pictures.v"
        :key="index"
        :name="index"
        :img-src="mkpath(picture)"
      />
    </q-carousel>

    <div class="placeholder q-ma-sm">
      <highcharts class="chart" :options="updatechart" v-if="part.sales"></highcharts>
    </div>

    <div style="margin-top:20px; margin-left: 15px; margin-right: 15px;">
      <attribute v-for="attr in attrs" :key="attr" :label="localized($store.state.pricing.defs.defs2[attr].label)" :def="$store.state.pricing.defs.defs2[attr]" :data="part[attr]"
      style="margin-top:15px; padding-bottom:5px; border-bottom: 1px solid grey"/>
    </div>

    <div class="bottom q-pa-sm">
      <q-btn label="Details" @click="go1"/>
      <q-btn :disable="!(actualfamily && canproductswrite())" label="Pricing" @click="go" />
      <q-btn :disable="!(actualfamily && canSeeRule() && familybased)" label="Rule" :to="`/pricing/${actualfamily}`" />
    </div>
  </div>
</template>
<script>
import { colors } from 'quasar';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { can } from '@cabinetmariel/scopes';
import localization from '../../../lib/mixins'; // eslint-disable-line import/no-named-as-default
import putils from '../../../lib/pictures';
import attribute from '../../../components/attributesDisplay/attribute';

const { methods } = pricingMethods;

stockInit(Highcharts);

export default {
  props: ['data'],
  mixins: [localization],
  components: {
    attribute,
    highcharts: Chart,
  },
  data() {
    return {
      part: {},
      attrs: [],

      slide: 0,
      drawer: this.$store.state.catalog.drawer,
      locked: true,
      locale: this.$store.state.auth.locale,
      sections: [
        { value: 25, color: colors.getBrand('primary') },
        { value: 75, color: colors.getBrand('secondary') },
      ],
    };
  },
  computed: {
    actualfamily() {
      if (!(this.part.families && this.part.families.v.length && this.part.families.v[0].length)) {
        return undefined;
      }
      return this.part.families.v[0][this.part.families.v[0].length - 1]
        .id;
    },
    familybased() {
      if (!(this.part.families && this.part.families.v.length && this.part.families.v[0].length)) return false;
      const family = this.part.families.v[0][this.part.families.v[0].length - 1];
      if (!(family.method && methods[family.method])) return false;
      return methods[family.method].familymode;
    },
    updatechart() {
      if (!this.part.sales) {
        return {};
      }

      /*
      let tmp = Object.entries(this.part.sales).map(([key, dataset]) => {
        const label = `Sales (${key})`;
        return { label, data: dataset.map((p) => ({ y: Math.round(p.v.number * 100) / 100, t: p.t.split(',')[0].slice(1) })) };
      });
      console.log(tmp);
      tmp = tmp[0].data;
      */

      const dataSet = this.part.sales.DEFAULT.map((p) => ([Date.parse(p.t.split(',')[0].slice(1)), Math.round(p.v.number * 100) / 100]));
      const chartdatatmp = {
        chart: {
          inverted: false,
          reflow: true,
          height: 250,
        },
        title: {
          text: '',
        },
        subTitle: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: '',
          },
        },
        yAxis: {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: '',
          },
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        series: [
          {
            type: 'line',
            name: 'Ventes',
            data: dataSet,
          },
        ],
      };

      return chartdatatmp;
    },
  },
  watch: {
    data(n) {
      this.updatePreview();
    },
  },
  methods: {
    canproductswrite() {
      return can('products.write', this.$store.state.auth.user.scopes);
    },
    canSeeRule() {
      return can('rules.read', this.$store.state.auth.user.scopes);
    },
    go1() {
      this.$router.push(`/products/${this.data.data.id}`);
    },
    go() {
      this.$router.push(`/one/${this.data.data.id}`);
    },
    updatePreview() {
      // Small wide-used hack to force complete re-render & fix gragh scaling issue
      this.part.sales = null;
      this.$axios.get(`/products/${this.data.data.id}`).then((response) => {
        this.part = response.data.data;

        // Get dimentions root set
        let dimRootSetId = '';
        Object.entries(this.$store.state.pricing.sets.sets).forEach(([i, set]) => {
          if (set.name === 'dimensions') {
            dimRootSetId = set.id;
          }
        });

        // Get dimentions attr
        this.attrs = [];
        Object.entries(this.$store.state.pricing.defs.defs2).forEach(([i, attr]) => {
          if (attr.root === dimRootSetId) {
            if (this.part[i]) {
              this.attrs.push(i);
            }
          }
        });
      });
    },
    mkpath(id) {
      return putils.mkpath(id, this.$store.state.auth.token);
    },
    closeDrawer() {
      this.data.type = undefined;
    },
    showDetails() {
      this.$router.push(`/${this.drawer.content.data.self}`);
    },
  },
  mounted() {
    this.updatePreview();
  },
};
</script>

<style scoped lang="stylus">
/*
.attributeTable {
  height: calc(100vh - 540px);
  margin: 15px 15px;
}
*/

.chart {
  //margin-top:-115px;
  margin-left: -5px;
  width: calc(100%- 10px);
}

.placeholder {
  margin-top: 10px;
  text-align: center;
  height: 205px;
  line-height: 150px;
  border: 1px solid #bbbbbb;
  overflow:hidden;
}

.bottom {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;

  .q-btn {
    margin: 5px;
  }
}
</style>
