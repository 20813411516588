/*
export function someMutation (state) {
}
*/

export const resetAttributes = (state, name) => {
  if (name) {
    state.attributes[name] = undefined;
  } else {
    state.attributes = {};
  }
};

export const updateAttribute = (state, data) => {
  state.attributes[data.name] = data.value;
};
