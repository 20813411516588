<template>
  <div class="dateField">
    <q-input :label="label" dense filled square v-model="rawvalue" mask="##/##/####" :placeholder="placeholder" :disable="disabled" debounce="500" @input="propagate" />
  </div>
</template>

<script>
import { dateDefs as mapLocaleToDate } from './datedefs';

const defaultLocale = 'en-US';

export default {
  name: 'DateField',
  props: ['value', 'disabled', 'name', 'locale', 'label'],

  data() {
    return {
      rawvalue: this.value ? (mapLocaleToDate[this.locale] || mapLocaleToDate[defaultLocale]).getLocaleDate(new Date(this.value)) : '',
      mask: (mapLocaleToDate[this.locale] || mapLocaleToDate[defaultLocale]).mask,
      placeholder: (mapLocaleToDate[this.locale] || mapLocaleToDate[defaultLocale]).placeholder,
    };
  },
  methods: {
    propagate(value) {
      this.rawvalue = value;

      const { day, month, year } = (mapLocaleToDate[this.locale] || mapLocaleToDate[defaultLocale]).getIsoDate(value);
      if (new Date(year, month - 1, day).toString() === 'Invalid Date') {
        this.$emit('change', {
          [this.name]: null,
        });
        return;
      }
      this.$emit('change', {
        [this.name]: `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
</style>
