<template>
  <div class="mainmenu">
    <q-drawer
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      v-model="leftDrawerOpen"
      class="lg-menu-drawer">
      <div class="userblock">
        <div class="row justify-center items-center">
          <q-avatar color="secondary" text-color="white">{{getAvatar()}}</q-avatar>
        </div>
        <div class="row justify-center items-center">
          <div class="q-mini-drawer-hide">
            {{getUserName()}}
            <!-- <q-icon name="expand_more" /> -->
          </div>
        </div>
      </div>
      <q-list>
        <template v-for="entry in entries">
          <q-item clickable active-class="text-secondary" v-if="!entry.children" :to="entry.href" :key="entry.name">
            <q-item-section avatar><q-icon :name="entry.icon"/></q-item-section>
            <q-item-section>{{entry.label}}</q-item-section>
          </q-item>
          <q-expansion-item
            v-if="entry.children"
            color="white"  :icon="entry.icon"
            :label="entry.label"
            :key="entry.name"
            :content-inset-level=".3"
          >
            <template v-for="child in entry.children"  >
              <q-item clickable active-class="text-secondary" v-if="!child.children" :to="child.href" :key="child.name">
                <q-item-section avatar><q-icon :name="child.icon"/></q-item-section>
                <q-item-section>{{child.label}}</q-item-section>
              </q-item>
                <q-expansion-item
                  v-if="child.children" color="white"
                  :icon="child.icon"
                  :label="child.label"
                  :key="child.name"
                  :content-inset-level=".3"
                >
                  <q-item clickable active-class="text-secondary" v-for="grandchild in child.children" :to="grandchild.href" :key="grandchild.name">
                    <q-item-section avatar><q-icon :name="grandchild.icon"/></q-item-section>
                    <q-item-section>{{grandchild.label}}</q-item-section>
                  </q-item>
                </q-expansion-item>
            </template>

          </q-expansion-item>
        </template>
      </q-list>
      <q-list class="fixed-bottom lg-menu-bottom">
        <q-item>
          <q-item-section avatar>
            <q-icon name="bug_report" size="24px" @click.native="reportbug()">
              <q-tooltip>Report bug</q-tooltip>
            </q-icon>
          </q-item-section>
          <q-item-section>
            <span>web: {{appVersion}}</span>
          </q-item-section>
          <q-item-section>
            <span>api: {{apiVersion}}</span>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <BugReport :data="bugreportData"/>
  </div>
</template>
<script>
import html2canvas from 'html2canvas';
import md5 from 'md5';
import { can } from '@cabinetmariel/scopes';
import BugReport from '../components/BugReport.vue';
import { localization } from '../lib/mixins';

console.log('---> can: ', can);
export default {
  mixins: [localization],
  components: {
    BugReport,
  },
  data() {
    return {
      entries: [],
      miniState: true,
      apiVersion: '',
      appVersion: '',
      bugreportData: {
        model: false,
        src: '',
      },
    };
  },
  computed: {
    leftDrawerOpen: {
      get() {
        return this.$store.state.mainmenu.leftDrawerOpen;
      },
      set() {},
    },
  },
  methods: {
    getAvatar(size) {
      return `${this.$store.state.auth.user.firstname.charAt(0).toUpperCase()}${this.$store.state.auth.user.lastname.charAt(0).toUpperCase()}`;
    },
    reportbug() {
      html2canvas(document.body, { logging: false }).then((canvas) => {
        this.bugreportData.src = canvas.toDataURL('image/png');
        this.bugreportData.model = true;
        // document.getElementById('screenshot').appendChild(img);
      }).catch((error) => console.log('canvas failed', error));
    },
    getApiVersion() {
      this.$axios.get('/hello').then((response) => {
        if (response.data.info.commit === '__COMMIT__') {
          this.apiVersion = 'xxxxxxx';
        } else {
          this.apiVersion = response.data.info.commit;
        }
      });
    },
    getUserName() {
      if (this.$store.state.auth.user) {
        return `${this.$store.state.auth.user.firstname} ${
          this.$store.state.auth.user.lastname
        }`;
      }
      return 'Anonym';
    },
  },
  created() {
    Promise.all([
      this.$axios.get('/menu'),
      this.$axios.get('/filters'),
    ])
      .then(([response, response2]) => {
        if (response && response.data) {
          if (!can('attributs.read', this.$store.state.auth.user.scopes)) {
            response.data.data = response.data.data.filter((el) => el.name !== 'attributes');
          }
          if (!can('products.read', this.$store.state.auth.user.scopes)) {
            response.data.data = response.data.data.filter((el) => el.name !== 'products');
          }
          if (!can('segmentation.read', this.$store.state.auth.user.scopes)) {
            response.data.data = response.data.data.filter((el) => el.name !== 'families');
          }
          if (can('bulk', this.$store.state.auth.user.scopes)) {
            const filters = (response2.data.data || []).filter((f) => (f.shortcut && (!f.email || f.email === this.$store.state.auth.user.email)));

            // we need to add the sortcuts
            const catalogIdx = response.data.data.findIndex((el) => (el.name === 'products'));
            const record = {
              icon: 'developer_board',
              label: this.$t('Filters'),
              name: 'filters',
              children: filters.map((f) => ({
                icon: 'developer_board',
                label: this.localized(f.label),
                href: `/products?shortcut=${f.id}`,
                name: f.id,
              })),
            };
            record.children.push({
              icon: 'developer_board',
              label: this.$t('New filter'),
              name: 'new',
              href: '/products?shortcut=00000000-0000-0000-0000-000000000000',
            });
            response.data.data.splice(catalogIdx + 1, 0, record);
          }
          this.entries = response.data.data;
        }
      })
      .catch((error) => console.error('LOGIPARTS FATAL', error));
    this.getApiVersion();
    this.appVersion = window.apprelease;
  },
};
</script>

<style lang="stylus">
.mainmenu {
  .lg-menu-drawer {
    .q-drawer {
      background: $grey-3;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px rgba(0,0,0,0.14), 0 1px 10px rgba(0,0,0,0.12);

    }
    .lg-menu-bottom {
       background-color: $primary
       color: white;
      .bug-report {
        // position: absolute;
        bottom: 50px;
        cursor: pointer;

      }
    }

    .userblock {
      min-height 50px
    }

  }
}
</style>
