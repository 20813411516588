<template>
  <div class="fullposition">
    <q-card v-if="sets && sets.order">
      <q-card-section class="bg-grey-3 lg-section-header">
        <div class="title" v-if="!addAttributeCard">
          Attributes
          <div class="actions">
            <addFilter @update-data="updateFilter" />
            <q-select v-model="v3" class="shadow-2 addattribute" dense filled square label="Add"
            :options="attributes" use-input input-debounce="0"   @filter="attributefilter" @input="attributeselected"/>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section class="lg-section-content">
        <!-- <q-list no-border>
          <div > -->
        <q-expansion-item class="attribute-list" v-for="set in nonemptysets" :key="set" default-opened dense :label="set" header-class="lg-attribute-set">
          <div class="leftborder">
            <div v-for="attr in getAttrsBySet(set)" :key="attr.name" class="attribute">
              <attribute v-if="product[attr.name] !== undefined"
                :when="new Date()"
                :disabled="disabled"
                :label="attr.label"  :def="defs[attr.name]" :data="product[attr.name]"
                mode="view" space="expandable" wrap="yes" @update="propagatebis" :settings="$store.state.general.settings" />
            </div>
          </div>
        </q-expansion-item>
          <!-- </div>
        </q-list> -->
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { localization } from '../../../lib/mixins';
import attribute from '../../../components/attributesDisplay/attribute';
// import addAttribute from './addAttribute';
import addFilter from './addFilter';

const supportedtypes = [
  'string',
  'localizedstring',
  'pricing',
  'number',
  'boolean',
  'amount',
  'interval',
  'unsupported',
  'tag',
  'collection',
];
const supportedcomponents = supportedtypes.reduce((_, t) => {
  // eslint-disable-next-line
  _[t] = () => import('../../../components/attributesDisplay/' + t);
  return _;
}, {});
function rationalcmp(a, b) {
  const [an, ad] = a.split('/');
  const [bn, bd] = b.split('/');
  const af = an / ad;
  const bf = bn / bd;
  return af - bf;
}
export default {
  props: ['filtredAttributes', 'product', 'disabled'],
  mixins: [localization],
  components: {
    ...supportedcomponents,
    // addAttribute,
    addFilter,
    attribute,
  },
  data() {
    return {
      v3: null,
      attributes: [],
      showtimeserie: '',
      addAttributeCard: false,
      editionPage: '',
      attributeFilter: '',
      locale: this.$store.state.auth.locale,
    };
  },
  created() {
    this.attributes = this.allattributes;
  },
  computed: {
    allattributes() {
      return Object.values(this.$store.state.pricing.defs.defs)
        .filter((a) => (Object.keys(this.product).findIndex((s) => (s === a.name)) < 0))
        .map((a) => ({ value: a.name, label: this.localized(a.label) }));
    },
    defs() {
      return this.$store.state.pricing.defs.defs2;
    },
    sets() {
      return this.$store.state.pricing.sets;
    },
    nonemptysets() {
      return this.$store.state.pricing.sets.order.filter((set) => (this.getAttrsBySet(set).length > 0));
    },
  },
  watch: {},
  methods: {
    attributeselected(v) {
      this.v3 = null;
      this.$emit('add', v);
    },
    attributefilter(val, update) {
      val = val.toLowerCase();
      if (val === '') {
        update(() => {
          this.attributes = this.allattributes;
        });
        return;
      }
      update(() => {
        this.attributes = this.allattributes.filter((a) => a.label.toLowerCase().includes(val));
      });
    },
    propagatebis(e) {
      this.$emit('update', e);
    },
    updateFilter(data) {
      this.attributeFilter = data;
    },
    /* updateAttributes(params) {
      this.$emit('update', params);
    }, */
    setshowtimeserie(n) {
      this.showtimeserie = (this.showtimeserie === n) ? '' : n;
    },
    setEditionPage(attrName) {
      // console.log('TCL: setEditionPage -> attrName', attrName);
      if (this.editionPage === attrName) {
        this.editionPage = '';
      } else {
        this.editionPage = attrName;
      }
    },
    containSearch(name, label) {
      if (!this.attributeFilter) return true;
      return (
        name.toLowerCase().includes(this.attributeFilter.toLowerCase())
        || label.toLowerCase().includes(this.attributeFilter.toLowerCase())
      );
    },
    getAttrsBySet(set) {
      const test = this.filtredAttributes
        .filter((v) => v.set.name === set && this.containSearch(v.name, v.label))
        .sort((a, b) => rationalcmp(a.position, b.position));
      return test;
    },
    asAttrValue(attr, key) {
      const { type } = this.defs[key];
      switch (type) {
        case 'pricing' || 'number' || 'amount':
          return !!this.product[key].fixed;
        case 'documentset':
        case 'interval':
        case 'boolean':
          return this.product[key] !== undefined;
        case 'string':
        case 'localizedstring':
        case 'segmentation':
        default:
          return this.product[key] !== undefined;
      }
    },
  },
};
</script>
<style lang="stylus">
.fullposition {
  height: 100%
  position relative
  > div.q-card {
    position absolute
    top: 1rem
    left: 1rem
    right: 1rem
    bottom 1rem

    div.lg-section-content {
      position: absolute;
      width: 100%;
      top: 50px;
      bottom: 0;
      overflow-y: auto;
    }
  }
}
</style>
<style scoped lang="stylus">
.lg-section-header {
  padding: 5px 1rem;
}
.attribute-list {
  >>> .q-expansion-item-sub-item {
    padding-right: .5rem;
  }
  >>> .q-item {
    padding-left: 0;
    .q-item__label {
      font-weight bold;
    }
  }
}
.leftborder:before {
    content: "";
    border-left: 4px solid $secondary;
    position: absolute;
    margin-left: -1rem;
    border-radius: 4px;
    top: 0;
    bottom: 0;
}

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .actions {
    display: flex;
  }
}

.edition {
  padding: 0 0em 1rem 0em;
  display: flex;
  justify-content: center;
  > div.q-card {
    width 100%
    margin: 1px
  }
}

.clickable {
  cursor: pointer;
}

.flex-end {
  justify-content: center;
  // justify-content: flex-end;
  width: 100%;
  display: flex;
}
>>> .lg-attribute-set {
  .q-item__label {
    font-variant small-caps
  }
}

.addattribute {
  display: inline-block;
  width: 100px;
  padding: 0;
}
</style>
