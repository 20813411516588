<template>
<div>
  <q-toolbar  class="bg-primary shadow2 text-white" style="max-width: 320px; overflow: hidden;">
    <q-btn flat round dense icon="cancel" @click="goback"/>
    <q-toolbar-title >
      {{$t('Charts and Rule comparison')}}
    </q-toolbar-title>
  </q-toolbar>
  <div class="drawer-container">
    <div class="graphmode" v-if="this.$store.state.pricing.sets && this.$store.state.pricing.sets && $store.state.general.settings && $store.state.general.settings.attrs">
      <q-toggle :value="$store.state.pricing.graphmode" @input="graphmodeChange" left-label :label="$t('Switch to charts mode')"/>
      <q-select @input="change" v-model="graphtype" :label="$t('Graph Type')" :options="graphOptions" map-options emit-value />
      <q-select @input="change" v-model="graphsort" :label="$t('Sorted by')" :options="attributsOptions" map-options emit-value v-if="$store.state.pricing.graphtype=='proposedprice' || $store.state.pricing.graphtype=='price+sales24m'"/>
      <q-select @input="change" v-model="xaxis" :label="$t('X Axis')" :options="dimensionsOptions" map-options emit-value v-if="$store.state.pricing.graphtype=='xyplot' || $store.state.pricing.graphtype=='comparerulesxy'"/>
      <q-select @input="change" v-model="rules" :label="$t('Règles')" multiple use-chips :options="rulesOptions" map-options emit-value v-if="$store.state.pricing.graphtype=='comparerules' || $store.state.pricing.graphtype=='comparerulesxy'"/>
    </div>
  </div>
</div>
</template>
<script>
import { localization } from '../../lib/mixins';
import { rulemixins } from './mixins';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      graphOptions: [
        { value: 'price+sales24m', label: 'Prix par ventes 24m' },
        { value: 'proposedprice', label: 'Prix proposé' },
        { value: 'comparerules', label: 'Comparaison de règles' },
        { value: 'xyplot', label: 'XY' },
        { value: 'comparerulesxy', label: 'Comparaison de règles (XY)' },
      ],
      samecontext: true,
    };
  },
  computed: {
    graphtype: {
      get() {
        return this.$store.state.pricing.graphtype;
      },
      set(v) {
        this.$store.commit('pricing/setGraphtype', v);
        if (this.$store.state.pricing.graphmode) {
          // make sure default values are ok :
          switch (v) {
            case 'xyplot':
            case 'proposedprice':
              this.setXaxisDefault();
              break;
            case 'price+sales24m':
              this.$store.commit('pricing/setGraphSort', this.attributsOptions[0].value);
              break;
            default:
          }
        }
      },
    },
    xaxis: {
      get() {
        if (!this.$store.state.general.settings && this.$store.state.general.settings.attrs) return null;
        this.setXaxisDefault();
        return this.$store.state.pricing.xaxis;
      },
      set(v) {
        const init = !this.$store.state.pricing.xaxis;
        this.$store.commit('pricing/setXaxis', v);
      },
    },
    graphsort: {
      get() {
        return this.$store.state.pricing.graphsort;
      },
      set(v) {
        this.$store.commit('pricing/setGraphSort', v);
      },
    },
    rules: {
      get() {
        return this.$store.state.pricing.graphrules;
      },
      set(v) {
        this.$store.commit('pricing/setGraphRules', v);
      },
    },
    dimensionsOptions() {
      const dimset = (Object.values(this.$store.state.pricing.sets.sets || {}).find((set) => (set.name === 'dimensions')) || {}).id;
      const options = Object.values(this.$store.state.pricing.defs.defs).filter((def) => (def.root === dimset)).map((def) => {
        const option = {
          value: def.name,
          label: this.localized(def.label),
        };
        return option;
      });
      return options;
    },
    attributsOptions() {
      let dimset = [
        ...this.$store.state.pricing.rule.definition.show || [],
        ...this.$store.state.pricing.rule.definition.attributes.map((a) => a.name),
      ];
      // let dimset = Object.keys(this.$store.state.pricing.defs.defs2).map((i) => this.$store.state.pricing.defs.defs2[i].name);
      dimset = dimset.sort();
      const options = dimset.map((item) => {
        const option = {
          value: item,
          label: this.localized(this.$store.state.pricing.defs.defs2[item].label),
        };
        return option;
      });
      return options;
    },
    rulesOptions() {
      return this.$store.state.pricing.rulestore.map((rule) => ({
        value: rule,
        label: this.localized(rule.label),
      }));
    },
  },
  methods: {
    change(v) {
      this.$root.$emit('updatechart');
    },
    setXaxisDefault() {
      if (!this.$store.state.pricing.xaxis) {
        const defaultx = this.$store.state.general.settings.attrs.weight;
        this.$store.commit('pricing/setXaxis', defaultx);
      }
    },
    graphmodeChange(val) {
      this.$store.commit('pricing/graphmode', val);
      this.$root.$emit('updatechart');
    },
  },
};
</script>
<style lang="stylus" scoped>
.graphmode{
  padding: 20px;
}
</style>
