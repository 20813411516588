<template>
    <q-dialog v-model="showMe" persistent transition-show="scale" transition-hide="scale">
      <q-card class="modaleCard">

        <q-card-section>
          <div class="text-h6"> {{$t('Smoothing function')}} - {{ localized($store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.smoothing[smoothID].attribute].label) | uppercase}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none section1">
           {{$t('Use the drag and drop on the points to edit the curve')}}
          <q-input :label="$t('Max value')" v-model.number="svgSetting.ymax" @input="changeCoef()" type="number" filled style="width: 120px"/>
        </q-card-section>

        <q-card-section>
          <div >
              <SvgExt ref="svgDisplay"
                v-if="resetSvg"
                @getSelectType="getSelectType"
                :setting="svgSetting"
                :input="points"
                :interactive="true"/>

              <div style="padding-top: 15px; display: flex; flex-direction: row; justify-content: space-around; margin-bottom: -15px;">
                <q-btn :label="$t('New point left')" style="width: 150px; height:60px;" @click="addLeft"/>
                <q-btn :label="$t('New point right')" style="width: 150px; height:60px;" @click="addRight"/>
                <q-select v-model="selectType" :options="['L','Q','C']" :label="$t('Change point type')" @input="changeType(selectType)" style="width:150px; margin-bottom: 20px;"/>
                <q-btn :label="$t('Remove point')" style="width: 150px; height:60px;" @click="removeActivePoint"/>
              </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal cardAction">
          <q-btn :label="$t('Abord and quit')" flat @click="abord"/>
          <q-btn :label="$t('Save and quit')" flat @click="validate"/>
        </q-card-actions>

      </q-card>
    </q-dialog>
</template>
<script>
import { localization } from '../../lib/mixins';
import SvgExt from './SvgExtend';

export default {
  props: ['show', 'smoothID'],
  components: {
    SvgExt,
  },
  mixins: [localization],
  filters: {
    uppercase(value) {
      if (!value) return '';
      return value.toUpperCase();
    },
  },
  data() {
    return {
      selectType: 'L',
      svgSetting: {
        xmin: 0,
        xmax: 100,
        ymin: 0,
        ymax: 2,
        w: 800,
        h: 400,
        grid: {
          show: true,
          snap: true,
          size: 25,
        },
      },
      points: [],
      activePoint: 2,
      maxCoef: 2,
      resetSvg: true,
    };
  },
  computed: {
    showMe: {
      get() { return this.show; },
      set(value) { this.$emit('closemodale'); },
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    changeCoef() {
      // this.points = this.$refs.svgDisplay.save();
      this.$refs.svgDisplay.convertPoints(this.points);
    },
    getSelectType(t) {
      this.selectType = t;
    },
    load() {
      this.points = this.$store.state.pricing.rule.definition.smoothing[this.smoothID].points;
      this.svgSetting.xmin = this.$store.state.pricing.rule.definition.smoothing[this.smoothID].min;
      this.svgSetting.xmax = this.$store.state.pricing.rule.definition.smoothing[this.smoothID].max;
      this.activePoint = 0;
    },
    addLeft() {
      this.$refs.svgDisplay.addLeft();
    },
    addRight() {
      this.$refs.svgDisplay.addRight();
    },
    changeType(t) {
      this.$refs.svgDisplay.changeType(t);
    },
    removeActivePoint(e) {
      this.$refs.svgDisplay.rm(e);
    },
    validate() {
      this.$store.commit('pricing/updateSmoothingAttribute', { id: this.smoothID, nv: this.$refs.svgDisplay.save() });
      this.$q.notify({ type: 'positive', message: 'Your modifications have been saved', icon: 'done' });
      this.$emit('closemodale');
      this.$root.$emit('updatechart', true);
    },
    abord() {
      this.$q.notify({ type: 'warning', message: 'Your modifications have been discard', icon: 'done' });
      this.$emit('closemodale');
    },

  },
};
</script>

<style scoped>
.modaleCard {
  max-width: 80vw;
  max-height: 90vh;
}

.section1 {
  margin-bottom: -20px;
}

.cardAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid grey;
}
</style>
