function dateparts(date) {
  const d = (date instanceof Date) ? date : new Date(date || Date.now());
  return {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
  };
}

export const dateDefs = {
  'en-US': {
    placeholder: 'MM/DD/YYYY',
    mask: '##/##/####',
    getIsoDate: (dateString = '') => {
      const [month, day, year] = dateString.split('/');
      return { month, day, year };
    },
    getLocaleDate: (date) => {
      const { year, month, day } = dateparts(date);
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year.toString().padStart(4, '0')}`;
    },
  },
  'fr-FR': {
    placeholder: 'DD/MM/YYYY',
    mask: '##/##/####',
    getIsoDate: (dateString = '') => {
      const [day, month, year] = dateString.split('/');
      return { month, day, year };
    },
    getLocaleDate: (date) => {
      const { year, month, day } = dateparts(date);
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString().padStart(4, '0')}`;
    },
  },
  'fr-CH': {
    placeholder: 'DD.MM.YYYY',
    mask: '##.##.####',
    getIsoDate: (dateString = '') => {
      const [day, month, year] = dateString.split('.');
      return { month, day, year };
    },
    getLocaleDate: (date) => {
      const { year, month, day } = dateparts(date);
      return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year.toString().padStart(4, '0')}`;
    },

  },
};

export default { dateDefs };
