<template>
  <!-- eslint-disable max-len -->
  <div class="draggable-container">
    <draggable class="draggable" v-model="draggableRow" v-bind="dragOption">
      <p slot="header" class="placeHolder" v-if="!this.draggableRow.length">Drop attribute here</p>
      <div v-else class="item">
        <div>{{localized(draggableRow[0].label)}}</div>
        <q-btn dense size="sm" color="primary" icon="close" @click="dropChip()"/>

        <div class="tag-selector"><select v-model="tag" v-if="(draggableRow[0].settings && draggableRow[0].settings.tags)">
          <option value="">{{$t('DEFAULT')}}</option>
          <option v-for="option in draggableRow[0].settings.tags" :key="option" :value="option">{{option}}</option>
        </select>
        <select v-else disabled="true"><option value="">DEFAULT</option></select>
        </div>
      </div>
    </draggable>
    <!-- <pre>
      value: {{draggableRow[0]}}
    </pre>-->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { localization } from '../lib/mixins';

export default {
  props: ['colIndex'],
  components: { draggable },
  mixins: [localization],
  data() {
    return {};
  },
  computed: {
    dragOption() {
      return {
        group: 'attributes',
        draggable: '.item',
        disabled: !!this.draggableRow.length,
      };
    },
    tag: {
      get() {
        return this.$store.state.importExport.step3.draggableRow[this.colIndex][0].importedtag || '';
      },
      set(t) {
        const data = this.$store.state.importExport.step3.draggableRow[this.colIndex];
        data[0].importedtag = t;
        this.$store.commit('importExport/setdraggableRow', {
          index: this.colIndex,
          value: data[0],
        });
        // this.checkTemplate();
      },
    },
    draggableRow: {
      get() {
        return this.$store.state.importExport.step3.draggableRow[this.colIndex];
      },
      set(data) {
        this.$store.commit('importExport/setdraggableRow', {
          index: this.colIndex,
          value: data[0],
        });
        this.checkTemplate();
      },
    },
  },
  methods: {
    checkTemplate() {
      this.$store.commit('importExport/checkTemplate');
    },
    dropChip() {
      this.$store.dispatch('importExport/dropdraggableRowElem', {
        columnIndex: this.colIndex,
        localized: (a) => this.localized(a),
      });
      this.checkTemplate();
    },
  },
};
</script>

<style lang="stylus">
.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 2rem;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .tag-selector {
      grid-area: 2 / 1 / 3 / 3;
      select { width: 100% }
    }
  }

  .placeHolder {
    opacity: 0.5;
  }

  .draggable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
  }

  p {
    margin: 0;
  }
}
</style>
