import axios from 'axios';
import { Notify } from 'quasar';
import { i18n } from '../../boot/locize';

function goUp(router) {
  const path = router.currentRoute.path.replace('/drawer/details', '').split('/');
  path.pop();
  router.push(path.join('/'));
}

// pour déplacer les pièces d'une famille c'est: PUT /families/{id}/parts
// avec en body: {family: <uuid de destination> }
export function moveParts({ commit }, data) {
  if (!data.from || !data.to || data.from === data.to) {
    return;
  }
  const router = this.$router;
  axios.put(`/families/${data.from}/parts`, { family: data.to }).then((response) => {
    commit('clearSelection');
    goUp(router);
    Notify.create({ type: 'positive', message: i18n.instance.t('Parts moved'), icon: 'done' });
  }).catch((error) => {
    Notify.create({ type: 'negative', message: i18n.instance.t('Unable to move parts'), icon: 'warning' });
  });
}

// pour cloner une segmentation donc sans les pièces, mais toutes la hiérarchie c'est: PUT /families/{id}/clone/to/{dst}
// avec un body vide et dst = id de la famille réceptacle
export function cloneSegmentation({ commit }, data) {
  if (!data.from || !data.to || data.from === data.to) {
    return;
  }
  const router = this.$router;
  axios.put(`/families/${data.from}/clone/to/${data.to}`).then((response) => {
    commit('clearSelection');
    goUp(router);
    Notify.create({ type: 'positive', message: i18n.instance.t('Family tree cloned'), icon: 'done' });
  }).catch((error) => {
    Notify.create({ type: 'negative', message: i18n.instance.t('Unable clone the family tree'), icon: 'warning' });
  });
}

// pour déplacer carrément une famille c'est PATCH /families/{id}
// avec dans le body {parent: <id du nouveau parent> }
export function moveFamilies({ commit }, data) {
  if (!data.from || !data.to || data.from === data.to) {
    return;
  }
  const router = this.$router;
  axios.patch(`/families/${data.from}`, { parent: data.to }).then((response) => {
    commit('clearSelection');
    goUp(router);
    Notify.create({ type: 'positive', message: i18n.instance.t('Whole family tree moved (including parts)'), icon: 'done' });
  }).catch((error) => {
    Notify.create({ type: 'negative', message: i18n.instance.t('Unable to move the family tree'), icon: 'warning' });
  });
}
